export class ReferanceCity {
    constructor() {
      this.iata = '';
      this.icao = '';
      this.regionId = null;
      this.countryId = null;
      this.dtRange = [null, null];
      this.name = [null, null];
      this.lastupdate = undefined;
    }
  
    id: number;
    iata: string;
    icao: string;
    private name: Array<string>;
    regionId: number;
    regionIata: string;
    regionIcao: string;
    region: Array<string>;
    countryId: number;
    countryIata: string;
    countryIcao: string;
    private country: Array<string>;
    private dtRange: Array<Date>;
    lastupdate: string;
        
    /**
     * Функции чтения/записи времени актуальности записи
     */
  
    get dtRangeStart(): Date {
      if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
        return new Date(this.dtRange[0]);
      } else {
        return null;
      }
    }
  
    set dtRangeStart(value: Date) {
      this.dtRange[0] = value;
    }
  
    get dtRangeFinish(): Date {
      if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
        return new Date(this.dtRange[1]);
      } else {
        return null;
      }
    }
  
    set dtRangeFinish(value: Date) {
      this.dtRange[1] = value;
    }
  
    /**
     * Функции чтения/записи  на английском и локальном языке
     */
  
    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }
  
    set nameEnglish(value: string) {
      this.name[0] = value;
    }
  
    get nameLocal(): string {
      if ((this.name) && (this.name.length === 2)) {
        return this.name[1];
      } else {
        return null;
      }
    }
  
    set nameLocal(value: string) {
      this.name[1] = value;
    }
  
    /**
     * Функция вывода наименований в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.name.join('/');
      } else {
        return '';
      }
    }

    /**
     * Функция вывода стран в строку
     */
    get countrys(): string {
      if ((this.country) && (this.country.length > 0)) {
        return this.country.join('/');
      } else {
        return '';
      }
    }
  }
  