import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '@settings/global-settings';
import { Flight, Terminal } from '../types/flights-reference';

@Injectable({
  providedIn: 'root'
})

export class FlightsRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Stands
  getStands(): Observable<Flight[]> {
    return this.http.get<Flight[]>(this.globalSettings.apiScheduleURL + '/admin/stands')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getStand(id: number): Observable<Flight> {
    return this.http.get<Flight>(this.globalSettings.apiScheduleURL + '/admin/stands/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addStand(data: any): Observable<Flight> {
    return this.http.post<Flight>(this.globalSettings.apiScheduleURL + '/admin/stands', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateStand(id: number, data: any): Observable<Flight> {
    return this.http.put<Flight>(this.globalSettings.apiScheduleURL + '/admin/stands/' + id, JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Terminals
  getTerminals(): Observable<Terminal[]> {
    return this.http.get<Terminal[]>(this.globalSettings.apiScheduleURL + '/admin/terminals')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTerminal(id: number): Observable<Terminal> {
    return this.http.get<Terminal>(this.globalSettings.apiScheduleURL + '/admin/terminals/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addTerminal(data: any): Observable<Terminal> {
    return this.http.post<Terminal>(this.globalSettings.apiScheduleURL + '/admin/terminals', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateTerminal(id: number, data: any): Observable<Terminal> {
    return this.http.put<Terminal>(this.globalSettings.apiScheduleURL + '/admin/terminals/' + id, JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(error.error);
  }
}
