import { Component, OnInit, ViewChild } from '@angular/core';
import { ScheduleModuleRestApiService } from '../schedule-module-data/schedule-module-rest-api.service';
import {
  FlightListItem,
  Flight,
  Route,
  Flights,
  FlightCombined,
  ReferanceStatuses,
  Process,
  ProcessesResources,
  ReferanceDelaySourceOperator,
  FilterParams,
  ReferanceGeo
} from '../schedule-module-data/flight';
import {
  ReferanceAirport,
  ReferanceAirline,
  ReferanceMovement,
  ReferanceDelay,
  ReferanceDelaySource,
  ReferanceAircraft,
  ReferanceTail,
  ReferanceStand,
  ReferanceTerminal
} from '../referance-module-data/referance';
import { Md5 } from 'ts-md5/dist/md5';
import { CookieService } from 'ngx-cookie-service';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { GlobalI18n } from '@settings/global-i18n';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

@Component({
             selector: 'app-schedule-module',
             templateUrl: './schedule-module.component.html',
             styleUrls: ['./schedule-module.component.less']
           })
export class ScheduleModuleComponent implements OnInit {

  // Переменная для подсчета md5, используется для подсчета хеша данных
  // так как обновления реализованны по таймеру, перед перезаписи структур
  private md5 = new Md5();

  flights: Flights = new Flights();
  flightsCombined: FlightCombined[] = [];
  // flights_arrival;
  // flights_departure;
  flight: Flight = new Flight(this.globalSettings.homeAirport);
  flightCardActiveTab = 'flight-card-tab-route';
  activeState = 'state-append';
  referanceAirports: Array<ReferanceAirport> = [];
  referanceAirlines: ReferanceAirline[];
  referanceMovements: ReferanceMovement[];
  referanceDelays: ReferanceDelay[];
  referanceDelaySources: ReferanceDelaySource[];
  referanceDelaySourceOperators: ReferanceDelaySourceOperator[];
  referanceAircraftTypes: ReferanceAircraft[];
  referanceTails: ReferanceTail[];
  referanceTailsA: ReferanceTail[] = []; // Фильтрованные хвосты для прилета
  referanceTailsD: ReferanceTail[] = []; // Фильтрованные хвосты для вылета
  referanceStands: ReferanceStand[] = [];
  referanceTerminals: ReferanceTerminal[] = [];
  referanceStatusesArrival: ReferanceStatuses[] = [];
  referanceStatusesBaggage: ReferanceStatuses[] = [];
  referanceStatusesCheckin: ReferanceStatuses[] = [];
  referanceStatusesBoarding: ReferanceStatuses[] = [];
  referanceStatusesDeparture: ReferanceStatuses[] = [];
  referanceGeoTypes: ReferanceGeo[] = [];
  errorMessage = '';
  errorType = '';
  deleteQueue = new Set();
  refreshFlightsInterval: any;
  flightsHash: string | Int32Array;
  flightsCombinedHash: string | Int32Array;
  // Вид суточника
  displayCombined = false;
  // Модалка стоек регистрации
  showModalCounters = false;
  // Удаление элементов
  userAction = '';
  indexDelete: number;

  startPeriod: Date;
  endPeriod: Date;
  yesterdayDate: Date = new Date(Date.now() - 1000 * 60 * 60 * 24); //86400000
  tomorrowDate: Date = new Date(Date.now() + 1000 * 60 * 60 * 48);

  filterParams: FilterParams = new FilterParams();
  filterApply = false;
  showFilter = false;

  // Блок переменных для работы ng-select списков
  // Размер отображаемых данных в выпадающем списке
  referanceBufferSize = 20;
  // Количество элементов до конца списка, перед загрузкой новой порции
  numberOfItemsFromEndBeforeFetchingMore = 10;

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Типов Движения
  referanceMovementsLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceMovementsBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Авиакомпаний
  referanceAirlinesLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceAirlinesBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Типов Воздушных Судов
  referanceAircraftTypesLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceAircraftTypesBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Бортовых Номеров
  referanceTailsLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceTailsBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Направлений
  referanceAirportsLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceAirportsBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Стоянок
  referanceStandsLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceStandsBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Терминалов
  referanceTerminalsLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  referanceTerminalsBuffer = [];

  // Добавлять ли в форму выбор след. рейса
  selectNextFlight = false;

  // Загрузка
  loading = false;

  // тип рейса для определния обязательно ли заполнять тип в вылете и прилете
  typeFlight = '';

  searchFlight = '';

  messages: Array<string> = [];

  @ViewChild('flightModalForm') flightModalForm: any;
  @ViewChild('flightForm') flightForm: any;

  newTrustFormVisible = false;

  constructor(
    public restApi: ScheduleModuleRestApiService,
    private cookieService: CookieService,
    public globalI18n: GlobalI18n,
    public globalSettings: GlobalSettings,
    private logger: NGXLogger
  ) {
    globalSettings.loadDefaultConfig();
  }

  async ngOnInit() {
    this.displayCombined = (this.cookieService.get('combined') === 'true' ? true : false);

    await this.globalSettings.loadComplete();

    this.messages['current'] = this.globalI18n.getMessage(ModuleName.Schedule, 'current');
    this.messages['lmgPrompt'] = this.globalI18n.getMessage(ModuleName.Schedule, 'lmgPrompt');

    if (this.displayCombined) {
      this.loadFlightsCombined();
    }

    Promise.all([
      this.loadStatusesArrival(),
      this.loadStatusesDeparture()
    ]).then(() => {
      this.loadFlights();
    });

    this.loadAirlines();
    this.loadAirports();
    this.loadMovements();
    this.loadDelays();
    this.loadDelaySources();
    this.loadAircrafts();
    this.loadTails();
    this.loadStatusesBaggage();
    this.loadStatusesBoarding();
    this.loadStatusesCheckin();
    this.loadStands();
    this.loadTerminals();
    this.loadGeoTypes();

    this.refreshFlightsInterval = setInterval(() => {
      this.refreshData();
    }, 15000);
  }

  async refreshData() {
    console.log('refresh fligh OK');
    await this.loadFlights();
  }

  // Функции прокрутки для Типов Движения
  onScrollReferanceMovements({ end }) {
    if (this.referanceMovementsLoading || this.referanceMovements.length <= this.referanceMovementsBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceMovementsBuffer.length) {
        this.referanceMovementsFetchMore();
    }
  }

  onScrollReferanceMovementsToEnd() {
    this.referanceMovementsFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Типов Движения
  private referanceMovementsFetchMore() {
    const len = this.referanceMovementsBuffer.length;
    const more = this.referanceMovements.slice(len, this.referanceBufferSize + len);
    this.referanceMovementsLoading = true;
    this.referanceMovementsBuffer = this.referanceMovements.concat(more);
    this.referanceMovementsLoading = false;
  }

  // Функции прокрутки для Авиакомпаний
  onScrollReferanceAirlines({ end }) {
    if (this.referanceAirlinesLoading || this.referanceAirlines.length <= this.referanceAirlinesBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAirlinesBuffer.length) {
        this.referanceAirlinesFetchMore();
    }
  }

  onScrollReferanceAirlinesToEnd() {
    this.referanceAirlinesFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Авиакомпаний
  private referanceAirlinesFetchMore() {
    const len = this.referanceAirlinesBuffer.length;
    const more = this.referanceAirlines.slice(len, this.referanceBufferSize + len);
    this.referanceAirlinesLoading = true;
    this.referanceAirlinesBuffer = this.referanceAirlines.concat(more);
    this.referanceAirlinesLoading = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirline} item Элемент для поиска
   */
  customSelectSearchAirports(term: string, item: ReferanceAirline) {
    term = term.toLowerCase();
    return item.iata.toLowerCase().indexOf(term) > -1 ||
           item.icao.toLowerCase().indexOf(term) > -1 ||
           item.names.toLowerCase().indexOf(term) > -1
  }

  // Функции прокрутки для Типов Воздушных Судов
  onScrollReferanceAircraftTypes({ end }) {
    if (this.referanceAircraftTypesLoading || this.referanceAircraftTypes.length <= this.referanceAircraftTypesBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAircraftTypesBuffer.length) {
        this.referanceAircraftTypesFetchMore();
    }
  }

  onScrollReferanceAircraftTypesToEnd() {
    this.referanceAircraftTypesFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Типов Воздушных Судов
  private referanceAircraftTypesFetchMore() {
    const len = this.referanceAircraftTypesBuffer.length;
    const more = this.referanceAircraftTypes.slice(len, this.referanceBufferSize + len);
    this.referanceAircraftTypesLoading = true;
    this.referanceAircraftTypesBuffer = this.referanceAircraftTypes.concat(more);
    this.referanceAircraftTypesLoading = false;
  }

  // Функции прокрутки для Бортовых Номеров
  onScrollReferanceTails({ end }) {
    if (this.referanceTailsLoading || this.referanceTails.length <= this.referanceTailsBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceTailsBuffer.length) {
        this.referanceTailsFetchMore();
    }
  }

  onScrollReferanceTailsToEnd() {
    this.referanceTailsFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Бортовых Номеров
  private referanceTailsFetchMore() {
    const len = this.referanceTailsBuffer.length;
    const more = this.referanceTails.slice(len, this.referanceBufferSize + len);
    this.referanceTailsLoading = true;
    this.referanceTailsBuffer = this.referanceTails.concat(more);
    this.referanceTailsLoading = false;
  }

  // Функции прокрутки для Направлений
  onScrollReferanceAirports({ end }) {
    if (this.referanceAirportsLoading || this.referanceAirports.length <= this.referanceAirportsBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.referanceAirportsBuffer.length) {
        this.referanceAirportsFetchMore();
    }
  }

  onScrollReferanceAirportsToEnd() {
    this.referanceAirportsFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Направлений
  private referanceAirportsFetchMore() {
    const len = this.referanceAirportsBuffer.length;
    const more = this.referanceAirports.slice(len, this.referanceBufferSize + len);
    this.referanceAirportsLoading = true;
    this.referanceAirportsBuffer = this.referanceAirports.concat(more);
    this.referanceAirportsLoading = false;
  }

  // Функция отображения всплывающей подсказки в карточке рейса для максимальной
  // взлетной массы согласно справочной информации системы
  get showMTOWForDepartureFlight(): string {
    const aircraftType: ReferanceAircraft = this.getById(this.referanceAircraftTypes, this.flight.aircraftTypeIdD);
    if (aircraftType) {
      return aircraftType.mtow.toString();
    } else {
      return '';
    }
  }

  selectFlightDisplay(value: boolean) {
    this.cookieService.set( 'combined', String(value) );
    this.displayCombined = (value === true ? true : false);
    if (value === true) {
      this.loadFlightsCombined();
    } else {
      this.loadFlights();
    }
  }

  filterApp() {
    this.filterApply = true;
    this.filterFlight();
  }

  filterSwitch() {
    this.filterApply = !this.filterApply;
    this.filterFlight();
  }

  filterFlight() {
    this.showFilter = false;
    if (this.displayCombined == true) {
      this.loadFlightsCombined();
    } else {
      this.loadFlights();
    }
  }

  clearFilterParametr(field: string, event) {
    event.stopPropagation();
    if (field === 'date') {
      delete this.filterParams['start'];
      delete this.filterParams['finish'];
    } else {
      delete this.filterParams[field];
    }
    if (this.quickSearchCheck()) {
      this.filterApply = false;
    }
    this.filterFlight();
  }

  quickSearchCheck() {
    const newFilter = new FilterParams();
    console.log(newFilter);
    console.log(this.filterParams);
    return JSON.stringify(this.filterParams) === JSON.stringify(newFilter);
  }

  clearFilter() {
    this.filterParams = new FilterParams();
    this.filterApply = false;
    this.filterFlight();
  }

  loadFlights() {
    return this.restApi.getFlights(this.filterApply ? this.filterParams : null).subscribe((data: Flights) => {
      console.log(data);
      this.md5.start();
      const newDataHash = this.md5.appendStr(JSON.stringify(data)).end();
      // Проверка хеша текущих и новых данных
      if (this.flightsHash !== newDataHash) {
        this.flights.arrival = [];
        this.flights.departure = [];

        // Обновление данных
        for (const item of data.arrival) {
          const flight = new FlightListItem();
          Object.assign(flight, item);
          if (this.globalSettings.language === 'en') {
            flight.statusName = this.getStatusesArrivalName(flight.status)[0];
          } else if (this.globalSettings.language === 'ru') {
            flight.statusName = this.getStatusesArrivalName(flight.status)[1];
          }
          this.flights.arrival.push(flight);
        }

        for (const item of data.departure) {
          const flight = new FlightListItem();
          Object.assign(flight, item);
          if (this.globalSettings.language === 'en') {
            flight.statusName = this.getStatusesDepartureName(flight.status)[0];
          } else if (this.globalSettings.language === 'ru') {
            flight.statusName = this.getStatusesDepartureName(flight.status)[1];
          }
          this.flights.departure.push(flight);
        }

        // Обновление хеша сохраненных данных
        this.flightsHash = newDataHash;
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  get flights_arrival(): FlightListItem[] {
    return this.flights.arrival
      .filter(item => {
        if (this.searchFlight !== '') {
          return ((item.flight && item.flight.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.airline && item.airline.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.route && JSON.stringify(item.route).toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1));
        } else {
          return item;
        }
      });
  }

  get flights_departure(): FlightListItem[] {
    return this.flights.departure
      .filter(item => {
        if (this.searchFlight !== '') {
          return ((item.flight && item.flight.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.airline && item.airline.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.route && JSON.stringify(item.route).toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1));
        } else {
          return item;
        }
      });
  }

  loadFlightsCombined() {
    return this.restApi.getFlightsCombined(this.filterApply ? this.filterParams : null).subscribe((data: FlightCombined[]) => {
      console.log(data);
      this.md5.start();
      const newDataHash = this.md5.appendStr(JSON.stringify(data)).end();
      // Проверка хеша текущих и новых данных
      if (this.flightsCombinedHash !== newDataHash) {
        this.flightsCombined = [];
        // Обновление данных
        for (const item of data) {
          const flight = new FlightCombined();
          Object.assign(flight, item);
          if (this.globalSettings.language === 'en') {
            flight.statusAName = this.getStatusesArrivalName(flight.statusA)[0];
            flight.statusDName = this.getStatusesDepartureName(flight.statusD)[0];
          } else if (this.globalSettings.language === 'ru') {
            flight.statusAName = this.getStatusesArrivalName(flight.statusA)[1];
            flight.statusDName = this.getStatusesDepartureName(flight.statusD)[1];
          }
          this.flightsCombined.push(flight);
        }
        // Обновление хеша сохраненных данных
        this.flightsCombinedHash = newDataHash;
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  get flights_combined(): FlightCombined[] {
    return this.flightsCombined
      .filter(item => {
        if (this.searchFlight !== '') {
          return ((item.flightA && item.flightA.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.flightD && item.flightD.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.routeA && JSON.stringify(item.routeA).toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.routeD && JSON.stringify(item.routeD).toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1));
        } else {
          return item;
        }
      });
  }

  loadFlight(id: number, type?: string) {
    if (id === 0) {
      return;
    }
    this.flightForm.form.markAsUntouched();
    this.flightCardActiveTab = 'flight-card-tab-route';
    type === 'arrival' ? this.selectNextFlight = true : this.selectNextFlight = false;
    console.log(id);
    this.deleteQueue = new Set();
    return this.restApi.getFlight(id).subscribe((data: Flight) => {
      console.log(data);
      Object.assign(this.flight, data);
      this.flight.route = [];

      for (const item of data.route) {
        const route = new Route();
        Object.assign(route, item);
        this.flight.route.push(route);
      }

      for (const key in data.processes) {
        if (Object.prototype.hasOwnProperty.call(data.processes, key)) {
          const processes = new Process();
          Object.assign(processes, data.processes[key]);
          if (processes.resources && processes.resources.length == 0) {
            processes.resources.push(new ProcessesResources());
          } else if (processes.resources && processes.resources.length == 1 && processes.resources[0].num == 0) {
            processes.resources[0].num = null;
          }
          this.flight.processes[key] = processes;
        }
      }

      this.restApi.getMessagesForFlight(id).subscribe((data) => {
        this.flight.messages = data;
      });
      this.activeState = 'state-update';

      this.checkTypeFlight();
      console.log(this.flight.blockOff);
      console.log(this.flight.blockOn);

      if (this.typeFlight === 'A') {
        this.selectAircraft(this.flight.aircraftTypeIdA, 'A');
      }
      if (this.typeFlight === 'D') {
        this.selectAircraft(this.flight.aircraftTypeIdD, 'D');
      }
      if (this.typeFlight === 'AD') {
        this.selectAircraft(this.flight.aircraftTypeIdA, 'A');
        this.selectAircraft(this.flight.aircraftTypeIdD, 'D');
      }
      if (this.flight.delaySourceId) {
        this.loadDelaySourceOperators(this.flight.delaySourceId);
      }
      if (this.flight.aircraftTypeIdA === 0) {
        this.flight.aircraftTypeIdA = null;
      }
      if (this.flight.aircraftTypeIdD === 0) {
        this.flight.aircraftTypeIdD = null;
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  // Определение типа рейса
  checkTypeFlight() {
    let arr = false;
    let dep = false;
    for (const route of this.flight.route) {
      if (route.airportId === this.globalSettings.homeAirport && this.flight.route.indexOf(route) !== this.flight.route.length - 1) {
        dep = true;
      }
      if (route.airportId === this.globalSettings.homeAirport && this.flight.route.indexOf(route) !== 0) {
        arr = true;
      }
    }

    if (arr && dep) {
      this.typeFlight = 'AD';
    } else if (arr) {
      this.typeFlight = 'A';
    } else if (dep) {
      this.typeFlight = 'D';
    } else {
      this.typeFlight = '';
    }
  }

  createFlight() {
    this.flightForm.reset();
    this.selectNextFlight = false;
    this.flightCardActiveTab = 'flight-card-tab-route';
    this.flight = new Flight(this.globalSettings.homeAirport);
    this.flight.processes.baggage.resources.push(new ProcessesResources());
    this.flight.processes.checkin.resources.push(new ProcessesResources());
    this.flight.processes.boarding.resources.push(new ProcessesResources());
    this.activeState = 'state-append';
    this.flight.route[0].orderNumber = 0;
    this.flight.route[1].orderNumber = 1;
    this.checkTypeFlight();
    console.log(this.flight);
    console.log(this.typeFlight);
  }

  beforeAddFlight() {
    this.checkTypeFlight();
    if (this.typeFlight === 'D' && ((this.flight.aircraftTypeIdA && this.flight.aircraftTypeIdA !== 0) ||
        (this.flight.tailAId && this.flight.tailAId !== 0) ||
        (this.flight.standAId && this.flight.standAId !== 0) ||
        (this.flight.terminalAId && this.flight.terminalAId !== 0) ||
        (this.flight.geoTypeIdA && this.flight.geoTypeIdA !== 0) || this.flight.blockOn)) {
      this.errorMessage = this.globalI18n.getMessage(ModuleName.Schedule, 'delInfoArrival');
      this.errorType = 'warning';
      this.userAction = 'add_no_route';
    } else if (this.typeFlight === 'A' && ((this.flight.aircraftTypeIdD && this.flight.aircraftTypeIdD !== 0) ||
      (this.flight.tailDId && this.flight.tailDId !== 0) ||
      (this.flight.standDId && this.flight.standDId !== 0) ||
      (this.flight.terminalDId && this.flight.terminalDId !== 0) ||
      (this.flight.geoTypeIdD && this.flight.geoTypeIdD !== 0) || this.flight.blockOff)) {
      this.errorMessage = this.globalI18n.getMessage(ModuleName.Schedule, 'delInfoDeparture');
      this.errorType = 'warning';
      this.userAction = 'add_no_route';
    } else {
      this.addFlight();
    }
  }

  addFlight() {
    this.flightForm.form.markAllAsTouched();
    this.clearInfoByType();
    if (this.flightForm.valid) {
      this.loading = true;
      console.log(JSON.stringify(this.flight));
      this.restApi.addFlight(this.flight).subscribe((data: {}) => {
          this.loadFlights();
          this.flightModalForm.nativeElement.click();
          this.loading = false;
        }, err => {
          if (err.type) {
            this.errorType = err.type;
            this.errorMessage = err.message;
            console.log('Error: ' + err.message + '\ndetail:' + err.detail);
            this.loading = false;
          }
        }
      );
    }
  }

  beforeUpdateFlight(id: number) {
    this.checkTypeFlight();
    if (this.typeFlight === 'D' && ((this.flight.aircraftTypeIdA && this.flight.aircraftTypeIdA !== 0) ||
        (this.flight.tailAId && this.flight.tailAId !== 0) ||
        (this.flight.standAId && this.flight.standAId !== 0) ||
        (this.flight.terminalAId && this.flight.terminalAId !== 0) ||
        (this.flight.geoTypeIdA && this.flight.geoTypeIdA !== 0) || this.flight.blockOn)) {
      this.errorMessage = 'При сохранении информация по прилету будет удалена, поскольку нет точки маршрута на прилет';
      this.errorType = 'warning';
      this.userAction = 'save_no_route';
    } else if (this.typeFlight === 'A' && ((this.flight.aircraftTypeIdD && this.flight.aircraftTypeIdD !== 0) ||
      (this.flight.tailDId && this.flight.tailDId !== 0) ||
      (this.flight.standDId && this.flight.standDId !== 0) ||
      (this.flight.terminalDId && this.flight.terminalDId !== 0) ||
      (this.flight.geoTypeIdD && this.flight.geoTypeIdD !== 0) || this.flight.blockOff)) {
      this.errorMessage = 'При сохранении информация по вылету будет удалена, поскольку нет точки маршрута на вылет';
      this.errorType = 'warning';
      this.userAction = 'save_no_route';
    } else {
      this.updateFlight(id);
    }
  }

  clearInfoByType() {
    this.checkTypeFlight();
    if (this.typeFlight === 'D') {
      this.flight.aircraftTypeIdA = null;
      this.flight.tailAId = null;
      this.flight.standAId = null;
      this.flight.terminalAId = null;
      this.flight.geoTypeIdA = null;
      this.flight.blockOn = null;
      this.errorMessage = '';
      this.errorType = '';
      this.userAction = '';
    } else if (this.typeFlight === 'A') {
      this.flight.aircraftTypeIdD = null;
      this.flight.tailDId = null;
      this.flight.standDId = null;
      this.flight.terminalDId = null;
      this.flight.geoTypeIdD = null;
      this.flight.blockOff = null;
      this.errorMessage = '';
      this.errorType = '';
      this.userAction = '';
    }
  }

  updateFlight(id: number) {
    let i = 0;
    for (const item of this.flight.route) {
      item.orderNumber = i;
      i++;
    }
    this.clearInfoByType();
    console.log('flight update');
    console.log(JSON.stringify(this.flight));
    this.flightForm.form.markAllAsTouched();
    if (this.flightForm.valid) {
      this.restApi.updateFlight(id, this.flight).subscribe(data => {
          this.loadAirlines();
          this.flightModalForm.nativeElement.click();
          if (this.displayCombined) {
            this.loadFlightsCombined();
          } else {
            this.loadFlights();
          }
        }, err => {
          if (err.type) {
            this.errorType = err.type;
            this.errorMessage = err.message;
            console.log('Error: ' + err.message + '\ndetail:' + err.detail);
          }
        }
      );
    }
  }

  addRoutePoint() {
    const num = this.flight.route.length;
    this.flight.route[num] = new Route();
    this.flight.route[num].orderNumber = num;
  }

  addToDeleteQueue(value) {
    if (this.deleteQueue.has(value)) {
      this.deleteQueue.delete(value);
    } else {
      this.deleteQueue.add(value);
    }
  }

  deleteRoute() {
    if (this.flight.route.length < 3) {
      this.errorMessage = 'Route must contain at least 2 points';
    } else {
      const deleteQueue = this.deleteQueue;
      this.flight.route  = this.flight.route.filter((el, i) => {
        if (!deleteQueue.has(i)) {
          return el;
        }
      });
      this.deleteQueue = new Set();
    }

    this.flight.route[0].dtArrivalScheduled = null;
    this.flight.route[0].dtArrivalEstimated = null;
    this.flight.route[0].dtArrivalFact = null;

    this.flight.route[this.flight.route.length - 1].dtDepartureScheduled = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureEstimated = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureFact = null;
    this.checkTypeFlight();
  }

  changeFlightCardTab(event: { target: { id: string; }; }) {
    this.flightCardActiveTab = event.target.id;
  }

  timeNormal(status: string): boolean {
    return status === 'normal';
  }

  timeGood(status: string): boolean {
    return status === 'good';
  }

  timeBad(status: string): boolean {
    return status === 'bad';
  }

  statusPlace(status: string): boolean {
    return status === '1';
  }

  checkHandicapped(value: string): boolean {
    return value !== '0';
  }

  checkAdult(value: number): boolean {
    return value !== 0;
  }

  checkChild(value: number): boolean {
    return value !== 0;
  }

  checkInfant(value: number): boolean {
    return value !== 0;
  }

  checkBaggage(value: number): boolean {
    return value !== 0;
  }

  showFlightCardTab(item: string): boolean {
    return item === this.flightCardActiveTab;
  }

  showButton(item: string): boolean {
    return item === this.activeState;
  }

  loadAirlines() {
    this.referanceAirlinesLoading = true;
    return this.restApi.getAirlines().subscribe((data: ReferanceAirline[]) => {
      this.referanceAirlines = data;
      this.referanceAirlinesLoading = false;
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadAirports() {
    this.referanceAirportsLoading = true;
    return this.restApi.getAirports().subscribe((data: ReferanceAirport[]) => {
      for (const item of data) {
        const airport = new ReferanceAirport();
        Object.assign(airport, item);
        this.referanceAirports = [...this.referanceAirports, airport];
      }
      this.referanceAirportsLoading = false;
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadMovements() {
    this.referanceMovementsLoading = true;
    return this.restApi.getMovements().subscribe((data: ReferanceMovement[]) => {
      this.referanceMovements = data;
      this.referanceMovementsLoading = false;
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadGeoTypes() {
    return this.restApi.getGeoTypes().subscribe((data: ReferanceGeo[]) => {
      for (const item of data) {
        const geo = new ReferanceGeo();
        Object.assign(geo, item);
        this.referanceGeoTypes = [...this.referanceGeoTypes, geo];
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadDelays() {
    return this.restApi.getDelays().subscribe((data: ReferanceDelay[]) => {
      this.referanceDelays = data;
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadDelaySources() {
    return this.restApi.getDelaySources().subscribe((data: ReferanceDelaySource[]) => {
      this.referanceDelaySources = [];
      for (const item of data) {
        const source = new ReferanceDelaySource();
        Object.assign(source, item);
        this.referanceDelaySources = [...this.referanceDelaySources, source];
      }
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadDelaySourceOperators(id: number, drop?: boolean) {
    if (drop) {
      this.flight.delaySourceOperatorId = null;
    }
    return this.restApi.getDelaySourceOperators(id).subscribe((data: ReferanceDelaySourceOperator[]) => {
      this.referanceDelaySourceOperators = [];
      for (const item of data) {
        const source = new ReferanceDelaySourceOperator();
        Object.assign(source, item);
        this.referanceDelaySourceOperators.push(source);
      }
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  // Aircrafts
  loadAircrafts() {
    this.referanceAircraftTypesLoading = true;
    return this.restApi.getAircraftTypes().subscribe((data: ReferanceAircraft[]) => {
      this.referanceAircraftTypes = [];
      for (const item of data) {
        const aircraft = new ReferanceAircraft();
        Object.assign(aircraft, item);
        this.referanceAircraftTypes = [...this.referanceAircraftTypes, aircraft];
      }
      this.referanceAircraftTypesLoading = false;
      console.log('loadAircrafts', data);
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  selectAircraft(aircraftType: any, type: string, reset: boolean = false) {
    let id;
    if (typeof aircraftType === 'number') {
      id = aircraftType;
    } else if (aircraftType) {
      id = aircraftType.id;
    }
    if (type === 'A') {
      if (reset) {
        this.flight.tailAId = null;
      }
      if (!aircraftType) {
        this.referanceTailsA = [];
      } else {
        this.referanceTailsA = this.referanceTails.filter(el => {
          if (el.aircraftTypeId === id) {
            return el;
          }
        });
      }
    }
    if (type === 'D') {
      if (reset) {
        this.flight.tailDId = null;
      }
      if (!aircraftType) {
        this.referanceTailsD = [];
      } else {
        this.referanceTailsD = this.referanceTails.filter(el => {
          if (el.aircraftTypeId === id) {
            return el;
          }
        });
      }
    }
  }

  // Tails
  loadTails() {
    this.referanceTailsLoading = true;
    return this.restApi.getTails().subscribe((data: ReferanceTail[]) => {
      this.referanceTails = [];
      for (const item of data) {
        const tail = new ReferanceTail();
        Object.assign(tail, item);
        this.referanceTails = [...this.referanceTails, tail];
      }
      this.referanceTailsLoading = false;
      console.log('loadTails', data);
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  // Airlines
  async loadStatusesArrival() {
    try {
      const data = await this.restApi.getStatusesArrival();
      this.referanceStatusesArrival = [];
      for (const item of data) {
        const arrival = new ReferanceStatuses();
        Object.assign(arrival, item);
        this.referanceStatusesArrival.push(arrival);
      }
    } catch (err) {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    }
  }

  getStatusesArrivalName(id: number): string {
    const index = this.referanceStatusesArrival.findIndex(i => i.id === id);
    if (index === -1) {
      return '';
    } else {
      return this.referanceStatusesArrival[index].name;
    }
  }

  // Statuses Baggage
  loadStatusesBaggage() {
    return this.restApi.getStatusesBaggage().subscribe((data: ReferanceStatuses[]) => {
      this.referanceStatusesBaggage = [];
      for (const item of data) {
        const baggage = new ReferanceStatuses();
        Object.assign(baggage, item);
        this.referanceStatusesBaggage.push(baggage);
      }
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  // Statuses Checkin
  loadStatusesCheckin() {
    return this.restApi.getStatusesCheckin().subscribe((data: ReferanceStatuses[]) => {
      this.referanceStatusesCheckin = [];
      for (const item of data) {
        const checkin = new ReferanceStatuses();
        Object.assign(checkin, item);
        this.referanceStatusesCheckin.push(checkin);
      }
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  // Statuses Boarding
  loadStatusesBoarding() {
    return this.restApi.getStatusesBoarding().subscribe((data: ReferanceStatuses[]) => {
      this.referanceStatusesBoarding = [];
      for (const item of data) {
        const boarding = new ReferanceStatuses();
        Object.assign(boarding, item);
        this.referanceStatusesBoarding.push(boarding);
      }
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  // Statuses Departure
  async loadStatusesDeparture() {
    return this.restApi.getStatusesDeparture().subscribe((data: ReferanceStatuses[]) => {
      this.referanceStatusesDeparture = [];
      for (const item of data) {
        const departure = new ReferanceStatuses();
        Object.assign(departure, item);
        this.referanceStatusesDeparture.push(departure);
      }
    }, err => {
        if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  getStatusesDepartureName(id: number): string {
    const index = this.referanceStatusesDeparture.findIndex(i => i.id === id);
    if (index === -1) {
      return '';
    } else {
      return this.referanceStatusesDeparture[index].name;
    }
  }

  loadStands() {
    return this.restApi.getStands().subscribe((data: ReferanceStand[]) => {
      for (const item of data) {
        const stand = new ReferanceStand();
        Object.assign(stand, item);
        this.referanceStands = [...this.referanceStands, stand];
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  loadTerminals() {
    return this.restApi.getTerminals().subscribe((data: ReferanceTerminal[]) => {
      for (const item of data) {
        const terminal = new ReferanceTerminal();
        Object.assign(terminal, item);
        this.referanceTerminals = [...this.referanceTerminals, terminal];
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    });
  }

  getById(arr, search) {
    const result = arr.filter(el => el.id === search)[0];
    if (result) {
      return result;
    } else {
      return null;
    }
  }

  parseDate(dateString: string, time?): Date {
    if (time && dateString) {
      return new Date(dateString + 'T' + time);
    } else if (!time && dateString) {
      return new Date(dateString);
    }
    return null;
  }

  parseTime(date, dateString: string): Date {
    if (date.value && date.value + 'T' + dateString) {
      return new Date(date.value + 'T' + dateString);
    }
    return null;
  }

  clearErrorMess() {
    this.errorMessage = '';
  }

  closeWithoutSaving() {
    this.flightModalForm.nativeElement.click();
    this.errorMessage = '';
  }

  addCounter() {
    this.flight.processes.checkin.resources[this.flight.processes.checkin.resources.length] = new ProcessesResources();
  }

  deleteCounter(index: number) {
    this.errorMessage = 'Delete element?';
    this.errorType = 'warning';
    this.userAction = 'delete';
    this.indexDelete = index;
  }

  deleteElement(array: any, index: number) {
    array.splice(index, 1);
    this.errorMessage = this.errorType = this.userAction = '';
  }

  getAirportNameFromIata(iata): string {
    if (this.referanceAirports && iata) {
      const result = this.referanceAirports.find(item => item.iata.toLowerCase() === iata.toLowerCase());
      if (result !== undefined) {
        return result.nameEnglish;
      } else {
        return '';
      }
    }
  }
}
