import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppAuthGuard } from '../app.authguard';
import { AdministrationModuleComponent } from './administration-module.component';
import { AmBalanceComponent } from './am-balance/am-balance.component';
import { AmBoardingControlComponent } from './am-boarding-control/am-boarding-control.component';
import { AmCommunicationComponent } from './am-communication/am-communication.component';
import { AmFlightsComponent } from './am-flights/am-flights.component';
import { AmInformationalComponent } from './am-informational/am-informational.component';
import { AmLostFoundComponent } from './am-lost-found/am-lost-found.component';
import { AmTechprocessesComponent } from './am-techprocesses/am-techprocesses.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdministrationModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_admin']
    },
    children: [
      {
        path: 'communication',
        component: AmCommunicationComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: ['aac_admin']
        },
      },
      {
        path: 'technical',
        component: AmTechprocessesComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: ['aac_admin']
        },
      },
      {
        path: 'informational',
        component: AmInformationalComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: ['aac_admin']
        },
      },
      {
        path: 'boarding-control',
        component: AmBoardingControlComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: ['aac_admin']
        },
      },
      {
        path: 'weight-balance',
        component: AmBalanceComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: ['aac_admin']
        },
      },
      {
        path: 'lost-found',
        component: AmLostFoundComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: ['aac_admin']
        },
      },
      {
        path: 'flights',
        component: AmFlightsComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: ['aac_admin']
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AdministrationRoutingModule { }
