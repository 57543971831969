export class InstallationWorkplace {
  id: number;
  idWorkPlace: number;
  idTerminal: number;
  idGeo: number;
  workplaceName: string;
  terminalName: string;
  geoName: string;
  name: string;
  gate: string;
  timeEnter: string;
  timeInterval: string;
  passportScanner: boolean;
  showTabScanner: boolean;
  showTabWanted: boolean;
  showClearArea: boolean;
  showReport: boolean;
  guid: string;
  showSecondNumber: boolean;
  reReadSec:number;
  aspectRatio4x3: boolean;
  dateStartCurrentFlightsHours: number;
  dateEndCurrentFlightsHours: number;
  reasonBaggageMobile: number;
  autoPass: boolean;
}

export class DictWorkplace {
  id: number;
  name: string;
}

export class DictReasonSolution {
  id: number;
  idWorkplace: number;
  workPlaceName: string;
  passStop: number;
  name:string;
}

export class DictReasonWanted {
  id: number;
  name: string;
  baggage: boolean;
}

export class DictStatusPassenger {
  id: number;
  name: string;
}

export class DictTerminal {
  id: number;
  name: string;
}

export class DictGeo {
  id: number;
  name: string;
}
