import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferencesService } from '../services/references.service';
import { FilterLost } from '../types/filter-lost';
import { References, REFERENCES_LIST } from '../types/types';
import { LostItems } from '../types/lost-items';
import { LostFoundModuleRestApiService } from '../services/lost-found-rest-api.service';
import { GlobalSettings } from '@settings/global-settings';
import { parseDate } from '@shared/functions/dateFunctions';
import { ADMIN_DATA } from '../types/const';

@Component({
  selector: 'lost-items',
  templateUrl: './lost-items.component.html',
  styleUrls: ['./lost-items.component.less']
})
export class LostItemsComponent implements OnInit {

  loading = false;
  references: References = new References();
  parseDate = parseDate;
  ADMIN_DATA = ADMIN_DATA;

  filter = {
    params: new FilterLost(),
    apply: false,
    show: false,
  };

  filterLoadAnimation = {
    name: false
  };
  public addTagClaim: (name) => void;
  // Таймер обновления данных
  interval: any;

  numberOfItemsFromEndBeforeFetchingMore = 10;

  selectLoadAnimation = {
    notifications: false,
    worldTracer: false,
    baggage_statuses_animation: false,
    baggage_brands: false
  };

  buffer = {
    airlines: [],
    airports: [],
    baggage_internals: [],
  };

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    airlines: 50,
    airports: 50,
    baggage_internals: 50,
  };

  statements: Array<LostItems> = [];
  currentSelectedStatement: string = null;

  pagination = {
    countsPerPage: [20, 40, 80],
    perPage: 20,
    pageActive: 1,
    statementsCount: 0,
    counters: null,
  };
  error = {
    errorMessage: '',
    errorType: '',
  };

  constructor(
    public restApi: LostFoundModuleRestApiService,
    private router: Router,
    public referencesService: ReferencesService,
    public globalSettings: GlobalSettings,
    private activatedRoute: ActivatedRoute
  ) {
    this.addTagClaim = (name) => this.addTagFilterFromArray(name, 'name');
  }

  ngOnInit(): void {
    const referencesList = [
      REFERENCES_LIST.airports,
      REFERENCES_LIST.airlines,
      REFERENCES_LIST.baggage_internals
    ];
    this.loading = true;
    Promise.all([
      this.referencesService.loadReferences(referencesList).then(data => {
        this.references = data;
      }),
      this.referencesService.loadStatementTypes(null, [6], this.globalSettings.language).then(data => {
        this.references.statement_types = data;
      }),
      this.referencesService.loadMasterData().then(data => {
        if (data.storages && data.storages.length > 0) {
          this.ADMIN_DATA.storages = data.storages;
        }
      })
    ]).finally(() => {
      this.activatedRoute.params.subscribe(async params => {
        if (params.id) {
          this.currentSelectedStatement = params.id;
        }
      });
      this.loadLostItems();
      this.loading = false;
    });
  }

  loadLostItems(activePage = 1) {
    const xRequestId = this.globalSettings.randomUuid;
    this.pagination.pageActive = activePage;

    this.restApi.getCountLostItems(this.filter.params, xRequestId).then((counters: any) => {
      this.pagination.statementsCount = counters.filtered.all;
      this.pagination.counters = counters;
    });

    this.statements = [];
    this.restApi.getLostItems(this.pagination, this.filter.params, xRequestId).then(data => {
      data.forEach(item => {
        const statement = new LostItems(item);
        this.statements.push(statement);
      });
    });
    return;
  }

  selectStatement(id: string) {
    this.currentSelectedStatement = id;
  }

  openLostItem(url: string) {
    this.router.navigate([url]);
  }

  clearFilterParametr(field: string, event) {
    event.stopPropagation();

    switch (field) {
      case 'name':
        this.filter.params.name = [];
        break;
      case 'airport':
        this.filter.params.airport = [];
        break;
      case 'content':
        this.filter.params.content = [];
        break;
      case 'status':
        this.filter.params.status = null;
        break;
      case 'item':
        this.filter.params.item = '';
        break;
      case 'start':
        this.filter.params.start = null;
        break;
      case 'finish':
        this.filter.params.finish = null;
        break;
    }

    if (this.quickSearchCheck()) {
      this.filter.apply = false;
    }
    this.loadLostItems();
  }

  quickSearchCheck() {
    const newFilter = new FilterLost();
    return JSON.stringify(this.filter.params) === JSON.stringify(newFilter);
  }

  filterApp() {
    this.filter.apply = true;
    this.filter.show = !this.filter.show;
    this.loadLostItems();
  }

  filterSwitch() {
    this.filter.apply = !this.filter.apply;
    this.loadLostItems();
  }

  clearFilter() {
    this.filter.params = new FilterLost();
    this.filter.apply = false;
  }

  filterArchive() {
    this.filter.params.archived = !this.filter.params.archived;
    this.loadLostItems();
  }

  getById(array: Array<any>, id: any) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }
  // Обработка значения, введенного в поле фильтра
  addTagFilterFromArray(names, key) {
    if (!names) {
      return;
    }

    names = names.toUpperCase().split(' ');

    // Скрытие оригинальной строки ng-select, так как
    // не корректно отрабатывает добавление элементов.
    // После скрытия и повторной отрисовки - все отлично.
    // Нюанс работы с компонентом.
    this.filterLoadAnimation[key] = true;
    if (names.length > 1) {
      // Добавление всех элементов в адреса, если выше проверки пройденны
      names.forEach(element => {
        if (element.length !== 0 && !this.filter.params[key].includes(element)) {
          this.filter.params[key] = [...this.filter.params[key], element.trim()];
        }
      });
    } else if (!this.filter.params[key].includes(names[0])) {
      // Если в массиве один элемент, использование стандартного метода добавления
      // единичной записи для ng-select
      this.filter.params[key] = [...this.filter.params[key], names[0].trim()];
    }
    this.interval = setTimeout(() => {
      this.filterLoadAnimation[key] = false;
    }, 250);
  }

  onScrollNgSelect({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].data.slice(len, this.bufferSize[name] + len);
    this.references[name].loading = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.references[name].loading = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param term Строка для поиска введеня пользователем
   * @param item Элемент для поиска
   */
  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
      (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
      (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 5 ?
        (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
        :
        (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }

  onScrollToEndNgSelect(name) {
    this.fetchMore(name);
  }

  async changePerPage(perPage) {
    this.pagination.perPage = perPage;
    this.loadLostItems();
  }

  get baggage_statuses() {
    for (const item of this.references.statement_types.data) {
      if (item.id === 6) {
        return item.baggage_statuses;
      }
    }
    return [];
  }

}
