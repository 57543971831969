import { Flight } from "./statements";

export class Chat {
  constructor(obj?: Chat) {
    this.externalBaggageId = obj?.externalBaggageId || '';
    this.statementBaggageId = obj?.statementBaggageId || '';
    this.statementId = obj?.statementId || '';
    this.name = obj?.name || '';
    if (obj?.baggage) {
      this.baggage = new ChatBaggage(obj.baggage);
    }
  }
  externalBaggageId: string;
  statementBaggageId: string;
  statementId: string;
  name?: string;
  baggage?: ChatBaggage;
}

export class ChatBaggage {
  constructor(obj?: ChatBaggage) {
    if (obj) {
      Object.assign(this, obj);
    } else {
      this.brand = '';
      this.color = '';
      this.description = '';
      this.externals = [];
      this.flights = [];
      this.internals = [];
      this.material = '';
      this.passengerTag = {
        address: '',
        destination: '',
        destinationId: null,
        email: '',
        note: '',
        passenger: '',
        phone: '',
      }
      this.statement = '';
      this.status = [];
      this.statusId = null;
      this.tag = '';
      this.type = '';
      this.weight = null;
    }
  }
  id: string;
  brand: string;
  color: string;
  description: string;
  externals: Array<{code: string}>;
  flights: Array<Flight>;
  internals: Array<{
    category: string;
    element: string;
    elements: Array<string>;
    note: string;
  }>;
  material: string;
  passengerTag: {
    address: string;
    destination: string;
    destinationId: number;
    email: string;
    note: string;
    passenger: string;
    phone: string;
  }
  statement: string;
  status: Array<string>;
  statusId: number;
  tag: string;
  type: string;
  weight: number;

  get strCode() {
    const externals = this.externals.map(el => el.code).join('');
    const material = this.type === '22R' || this.type === '22D' ? '' : this.material;
    return this.color + this.type + material + externals;
  }

  get strInternals() {
    return this.internals.map(el => {
      let note = el.note ? (' (' + el.note + ')') : '';
      return el.category + ': ' + el.element + note;
    }).join(', ');
  }

  get strRoute() {
    if (this.flights && this.flights.length) {
      let res = [this.flights[0].arrival];
      this.flights.forEach(flight => res.push(flight.departure));
      return res.join('-')
    }
    return '';
  }

  get strFlight() {
    if (this.flights && this.flights.length) {
      let res = [];
      this.flights.forEach(flight => res.push(flight.airline + flight.flight));
      return res.join('/')
    }
    return '';
  }
}

export class ChatMessage {
  constructor(text?: string) {
    this.text = text || '';
    this.dt = new Date();
    this.direction = 1;
  }
  id: string;
  statementId: string;
  statementBaggageId: string;
  sender: string;
  senderType: string;
  dt: Date;
  text: string;
  direction: number;
}

export type ChatData = {
  statementBaggageId: string,
  statementId: string,
  statementName?: string,
  externalBaggageId: string,
  externalId?: string,
  externalName?: string,
};

