import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormsService } from 'src/app/lost-found-module/services/forms.service';

@Component({
  selector: 'app-pilferage-modal',
  templateUrl: './pilferage-modal.component.html',
  styleUrls: ['./pilferage-modal.component.less']
})
export class PilferageModalComponent implements OnInit {
  @Input() pilferage: UntypedFormArray;
  @Input() parentForm: UntypedFormGroup;

  constructor(private formsService: FormsService) { }

  ngOnInit(): void {
  }

  removePilferage(index: number) {
    this.pilferage.removeAt(index);
  }

  addPilferage() {
    this.pilferage.push(this.formsService.createPilferageItem());
  }

  closeForm() {
    if (!this.parentForm.valid) {
      this.parentForm.markAllAsTouched();
    }
    else {
      return;
    }
  }

}
