import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LostFoundModuleRoutingModule } from './lost-found-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { DesktopBaggageComponent } from './desktop-baggage/desktop-baggage.component';
import { StatementComponent } from './desktop-baggage/statement/statement.component';
import { GetReferencesService, ReferencesService } from './services/references.service';
import { LogsTabComponent } from './desktop-baggage/statement/logs-tab/logs-tab.component';
import { SharedModule } from '@shared/shared.module';
import { ManualSearchComponent } from './desktop-baggage/manual-search/manual-search.component';
import { LostItemsComponent } from './lost-items/lost-items.component';
import { BaggageTableComponent } from './desktop-baggage/baggage-table/baggage-table.component';
import { LostStatementComponent } from './lost-items/lost-statement/lost-statement.component';
import { BaggageCompareModalComponent } from './desktop-baggage/statement/baggage-compare-modal/baggage-compare-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaggageDamageModalComponent } from './desktop-baggage/statement/baggage-damage-modal/baggage-damage-modal.component';
import { ContentModalComponent } from './content-modal/content-modal.component';
import { ChatsTabComponent } from './desktop-baggage/statement/chats-tab/chats-tab.component';
import { BaggageMessageModalComponent } from './desktop-baggage/statement/baggage-message-modal/baggage-message-modal.component';
import { BaggageItemComponent } from './desktop-baggage/statement/baggage-item/baggage-item.component';
import { RoutesTableComponent } from './desktop-baggage/statement/routes-table/routes-table.component';
import { PilferageModalComponent } from './desktop-baggage/statement/pilferage-modal/pilferage-modal.component';
import { PhotoComponent } from './desktop-baggage/statement/accordeons/photo/photo.component';
import { AttachmentsComponent } from './desktop-baggage/statement/accordeons/attachments/attachments.component';
import { ViewParamsService } from './services/view-lostfound.service';

@NgModule({
  declarations: [
    DesktopBaggageComponent,
    StatementComponent,
    LogsTabComponent,
    ManualSearchComponent,
    LostItemsComponent,
    BaggageTableComponent,
    LostStatementComponent,
    BaggageDamageModalComponent,
    ContentModalComponent,
    ChatsTabComponent,
    BaggageItemComponent,
    RoutesTableComponent,
    BaggageMessageModalComponent,
    BaggageCompareModalComponent,
    PilferageModalComponent,
    PhotoComponent,
    AttachmentsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgxPaginationModule,
    LostFoundModuleRoutingModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule
  ],
  providers: [
    ReferencesService,
    GetReferencesService,
    ViewParamsService,
  ],
  exports: [
    LostFoundModuleRoutingModule
  ]
})
export class LostFoundModuleModule { }
