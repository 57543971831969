export class Slot {

  constructor() {
    this.airlineId = undefined;
    this.flightId = undefined;
    this.flight = '';
    this.movementTypeId = undefined;
    this.routes = [];
    this.routes[0] = new SlotRoutes();
  }

  id: number;
  airlineId: number;
  airline: string;
  flightId: number;
  flight: string;
  movementTypeId: number;
  movementType: string;
  flightOperator: Array<boolean>;
  routes: Array<SlotRoutes>;
  seasonID: number;
  scheduleTypeID: number;
}

export class SlotRoutes {

  constructor() {
    this.route = [];
    this.route.push(new SlotRoute());
    this.periods = [];
    this.periods.push(new SlotPeriod());
  }

  route: Array<SlotRoute>;
  periods: Array<SlotPeriod>;
}

export class SlotRoute {

  constructor() {
    this.airportIdD = undefined;
    this.airportIdA = undefined;
    this.aircraftTypeId = undefined;
    this.geoTypeId = undefined;
  }

  orderNumber: number;
  airportIdD: number;
  airportD: string;
  timeD: string;
  dayD: number;
  airportIdA: number;
  airportA: string;
  timeA: string;
  dayA: number;
  aircraftTypeId: number;
  aircraftType: string;
  geoTypeId: number;
  geoType: string;
  
}


export class SlotPeriod {
  constructor() {
    this.frequency = '.......'
  }

  start: Date;
  finish: Date;
  frequency: string;
  count: number;

}


