/* TODO: Переписать функцию, чтобы вырезалось все кроме точек, запятых и цифр */
export function inputNotLetter(event, type: string = '') {
    const allowed = [
        ',',
        'Backspace',
        '-',
        '.',
        'ArrowRight',
        'ArrowLeft',
        'Tab',
        'Meta',
        'Control',
        'v',
        'c',
        'с',
        'м',
    ];

    if (isNaN(Number(event.key)) && !allowed.includes(event.key)) {
        return false;
    }
    if (type === 'int' && (event.key === ',' || event.key === '.')) {
        return false;
    }
    if (
        type === 'int_positive' &&
        (event.key === '-' || event.key === ',' || event.key === '.')
    ) {
        return false;
    }
}
/**
 * Функция обработки значения из редактируемой таблицы
 * @param value Строка, введеная пользователем
 */
export function toNumber(value: string) {
    if (value === '') {
        return null;
    }
    const num = parseFloat(
        value
            .replace(/<\/?[^>]+(>|$)/g, '')
            .replace(/^(-)|[^0-9.,]+/gi, '$1')
            .replace(/^-+/g, '-')
            .replace(',', '.')
    );
    return num ?? null;
}

export function clearTags(value: string) {
    const str = value.replace(/<\/?[^>]+(>|$)/g, '').replace('&nbsp;', '');
    return str ?? null;
}