import { Component, OnInit } from '@angular/core';
import { MaterialAccountingRestApiService } from '../material-accounting-data/material-accounting-rest-api.service';
import { Aircraft } from '../material-accounting-data/material-accounting';
import { GlobalSettings } from '@settings/global-settings';

@Component({
  selector: 'app-material-accounting',
  templateUrl: './material-accounting.component.html',
  styleUrls: ['./material-accounting.component.less']
})
export class MaterialAccountingComponent implements OnInit {

  // ***** Модуль логики пользовательского интерфейса
  // ***** связывает Веб-форму и запросы к Бэку

  error = {
    errorMessage: '',
    errorType: '',
  };

  // Объявление массива для хранения списка воздушных судов
  aircraftList: Array<Aircraft> = [];

  /**
   * Конструктор инициализации модуля
   *
   * @param restApi Объявление службы отсылающией REST-API запросы на бэк
   * @param globalSettings Объявление службы дающей доступ к глобальным настройкам приложения
   */
  constructor(
    public restApi: MaterialAccountingRestApiService,
    public globalSettings: GlobalSettings
  ) {
    globalSettings.loadDefaultConfig();
  }

  /**
   * Функция вызываемая сразу после создания модуля и инициализации конструктора
   * обычно в ней предварительно загружаются справочники и выполняются другие
   * подгтовительные действия
   */
  ngOnInit(): void {
    // Загрузка справочников
    this.loadReferences();
  }

  // Функция загрузки справочников
  async loadReferences() {
    // Ручное создание записи в массиве Воздушных судов
    let aircraft = new Aircraft;
    aircraft.id = 1;
    aircraft.type = 'A-320';
    aircraft.registrationNumber = 'RA78745';

    // Размещение ВС в глобальном массиве
    this.aircraftList.push(aircraft);

    // Создание уникального идентификатора для запроса, нужно для системы логирования действий
    const xRequestId = this.globalSettings.randomUuid;
    // Запрос справочника из РЕСТа
    await this.restApi.getReference('aircraft', xRequestId).then(data => {
      this.aircraftList = data;
    }, err => {
      this.displayError(err);
    });
  }

  // Функция отображения ошибок
  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message === 'object') {
        if (this.globalSettings.language === 'en') {
          this.error.errorMessage = err.message[0];
        } else {
          this.error.errorMessage = err.message[1];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

}
