export class FilterParams {
  constructor() {
    this.name = [];
    this.tag = [];
    this.passenger = '';
    this.pnr = '';
    this.airport = [];
    this.archived = false;
    this.baggageType = [];
    this.baggageColor = [];
    this.baggageMaterial = [];
    this.baggageExternalElements = [];
    this.airline = [];
    this.flight = '';
    this.status = '';
    this.start = null;
    this.finish = null;
    this.type = [];
    this.archived = false;
    this.statusFlag = '';
    this.baggageTypeFlag = '';
    this.baggageColorFlag = '';
    this.baggageMaterialFlag = '';
    this.baggageExternalElementsFlag = '';
  }
  start: Date | null;
  finish: Date | null;
  status: string;
  statusFlag: string;
  type: Array<number>;
  mark: string;
  name: Array<string>;
  tag: Array<string>;
  passenger: string;
  pnr: string;
  airport: Array<number>;
  airline: Array<number>;
  flight: string;
  baggageTypeFlag: string;
  baggageType: Array<number>;
  baggageColorFlag: string;
  baggageColor: Array<number>;
  baggageMaterialFlag: string;
  baggageMaterial: Array<number>;
  baggageExternalElementsFlag: string;
  baggageExternalElements: Array<number>;
  archived: boolean;
}
