import { Injectable } from '@angular/core';
import { GlobalSettings } from '@settings/global-settings';
import { LostFoundModuleRestApiService } from './lost-found-rest-api.service';
import { GlobalI18n } from '@settings/global-i18n';
import { StatementTypes, Referance } from '../types/statements';
import { References } from '../types/types';
import { Storage } from '../types/references';


@Injectable()
export class GetReferencesService {
  public storages: Array<Storage> = [];
  // notifications: Array<Notification> = [];
  // worldtracer: Array<Worldtracer> = [];

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
    public globalI18n: GlobalI18n,
  ) {
    globalSettings.loadDefaultConfig();
    this.loadReferences();
  }

  loadReferences() {
    const xRequestId = this.globalSettings.randomUuid;
    if (this.storages.length === 0) {
      this.restApi.getAdminData('storages', xRequestId).then(data => {
        this.storages = data;
      });
    }
    // this.restApi.getAdminData('notifications', xRequestId).then(data => {
    //   this.notifications.value = data;
    // });
    // this.restApi.getAdminData('worldtracer', xRequestId).then(data => {
    //   this.worldtracer.value = data;
    // });
  }
}


@Injectable()
export class ReferencesService {

  references: References = new References();
  statementTypesOrder = [1, 5, 7, 8, 3, 2];

  userReferences = {
    airports: {loading: true, data: []},
    airlines: {loading: true, data: []},
  }

  // statementManualOrder: [5, 7, 8];

  public masterData: {
    storages: Array<Storage>;
    // notifications: Array<Notification>;
    // worldtracer: Array<Worldtracer>;
  } = {
      storages: [],
      // notifications: [],
      // worldtracer: [],
    };

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
    public globalI18n: GlobalI18n,
  ) {
    this.globalSettings.loadDefaultConfig();
  }

  getUserAirports() {
    return new Promise((resolve) => {
      if (this.userReferences.airports.data.length > 0) {
        resolve(this.userReferences.airports.data);
      } else {
        this.restApi.getUserAirports().subscribe(data => {
          data.forEach(el => {
            const item = new Referance();
            Object.assign(item, el);
            this.userReferences.airports.data.push(item);
          });
          resolve(this.userReferences.airports.data);
        });
      }
    });
  }

  getUserAirlines() {
    return new Promise((resolve) => {
      if (this.userReferences.airlines.data.length > 0) {
        resolve(this.userReferences.airlines.data);
      } else {
        this.restApi.getUserAirlines().subscribe(data => {
          data.forEach(el => {
            const item = new Referance();
            Object.assign(item, el);
            this.userReferences.airlines.data.push(item);
          });
          resolve(this.userReferences.airlines.data);
        });
      }
    });
  }

  async loadStatementTypes(filterParams = null, statementTypesOrder: Array<number>, language: string = '') {
    const xRequestId = this.globalSettings.randomUuid;
    let indicators;
    this.references.statement_types.loading = true;
    this.references.baggage_statuses.loading = true;
    await this.restApi.getIndicators(xRequestId, filterParams, filterParams?.times).then(data => {
      indicators = data;
    });
    await this.restApi.getReference('statement_types', xRequestId).then(data => {
      this.references.statement_types.data = [];
      statementTypesOrder.forEach(statementId => {
        const newType = new StatementTypes(language);
        const type = data.find(el => el.id === statementId);
        Object.assign(newType, type);
        const statuses = newType.baggage_statuses;
        const referanceStatuses: Array<StatementTypes> = [];
        statuses.forEach(item => {
          const status = new Referance(language);
          Object.assign(status, item);
          referanceStatuses.push(status);
        });
        newType.baggage_statuses = referanceStatuses;
        newType.count = indicators.find(indicator => indicator.statementType === statementId)?.count;
        this.references.statement_types.data.push(newType);
      });
    });
    this.references.statement_types.loading = false;
    this.references.baggage_statuses.loading = false;
    return this.references.statement_types;
  }

  async loadReferences(referencesList?: Array<string>) {
    const xRequestId = this.globalSettings.randomUuid;
    if (referencesList) {
      referencesList.forEach(async referenceItem => {
        if (this.references[referenceItem].data && this.references[referenceItem].data.length === 0) {
          this.references[referenceItem].loading = true;
          await this.loadReference(referenceItem, xRequestId);
        }
      });
    } else {
      for (const key in this.references) {
        if (Object.prototype.hasOwnProperty.call(this.references, key)) {
          if (this.references[key].data && this.references[key].data.length === 0) {
            this.references[key].loading = true;
            await this.loadReference(key, xRequestId);
          }
        }
      }
    }
    return this.references;
  }


  async loadReference(referenceName: string, xRequestId) {
    await this.restApi.getReference(referenceName, xRequestId).then(data => {
      this.references[referenceName].data = [];
      data.forEach(el => {
        const item = new Referance();
        Object.assign(item, el);
        this.references[referenceName].data.push(item);
      });
    });
    this.references[referenceName].loading = false;
  }

  async loadMasterData() {
    const xRequestId = this.globalSettings.randomUuid;
    if (this.masterData.storages.length === 0) {
      await this.restApi.getAdminData('storages', xRequestId).then(data => {
        this.masterData.storages = data;
      });
    }
    // this.restApi.getAdminData('notifications', xRequestId).then(data => {
    //   this.masterData.notifications.value = data;
    // });
    // this.restApi.getAdminData('worldtracer', xRequestId).then(data => {
    //   this.masterData.worldtracer.value = data;
    // });
    return this.masterData;
  }

}
