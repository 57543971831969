export class Filter {
  constructor() {
    this.airlineID = null;
    this.flight = null;
    this.departureAirportID = null;
    this.arrivalAirportID = null;
    this.aircraftTypeID = null;
    this.tail = null;
  }

  airlineID: number;
  flight: string;
  departureAirportID: number;
  arrivalAirportID: number;
  aircraftTypeID: number;
  tail: string;
}

export class WorkspaceDistribution {
  constructor() {
    this.id = null;
    this.rating = null;
    this.workspaceID = null;
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
    this.lastUpdate = null;
    this.filter = new Filter();
  }

  id: number;
  rating: number;
  workspaceID: number;
  filter: Filter;
  dtRange: Array<Date>;
  lastUpdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }
}
