<div class="row">
  <div class="col">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a id="tab-installation-place"
           class="nav-link active"
           data-toggle="tab"
           href="#tab1"
           i18n="adm|Boarding control - tab workplace@@adm.boardControl.tab.workplace">
          Installation of workplaces
        </a>
      </li>
      <!--<li class="nav-item">
        <a id="tab-workplace" class="nav-link" data-toggle="tab" href="#tab2">
          Dict Workplace
        </a>
      </li>-->
      <li class="nav-item">
        <a id="tab-solution"
           class="nav-link"
           data-toggle="tab"
           href="#tab3"
           i18n="adm|Boarding control - tab reason solutions@@adm.boardControl.tab.reasonSolutions">
          Reasons solutions
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-reason-wanted"
           class="nav-link"
           data-toggle="tab"
           href="#tab4"
           i18n="adm|Boarding control - tab reason wanted@@adm.boardControl.tab.reasonWanted">
          Reasons for the wanted
        </a>
      </li>
      <!--<li class="nav-item">
        <a id="tab-status-passenger" class="nav-link" data-toggle="tab" href="#tab5">
          Dict Status Passenger
        </a>
      </li>-->
    </ul>
  </div>
</div>

<div class="tab-content tab-borders p-2">
  <div class="tab-pane fade show active pt-2" id="tab1">
    <table class="table">
      <thead>
      <tr class="text-light type-head table__main-header">
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab workplace header name@@adm.boardControl.table.workplace.header.name">
          Name
        </th>
        <th class="text-uppercase head-border--top text-center"
            i18n="adm|Boarding control - tab workplace header type@@adm.boardControl.table.workplace.header.type">
          Workplace type
        </th>
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab workplace header terminal@@adm.boardControl.table.workplace.header.terminal">
          Terminal
        </th>
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab workplace header geo@@adm.boardControl.table.workplace.header.geo">
          Geo
        </th>
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab workplace header desk and gate@@adm.boardControl.table.workplace.header.deskGate">
          Desk/Gate
        </th>
        <!-- <th class="text-uppercase head-border__top text-center">Time enter</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Time interval</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Passport scanner</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Tab scanner</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Tab wanted</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Tab clear area</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Tab report</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">GUID</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Second number</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Read</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Aspect ratio 4x3</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">-Hour start current flights</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">+Hour end current flights</th> -->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let iPlace of installationWorkplaces"
          class="bg-white"
          data-toggle="modal"
          data-target=".modal-installation-place"
          (click)="editPlace(iPlace.id)">
        <td>{{iPlace.name}}</td>
        <td>{{iPlace.workplaceName}}</td>
        <td>{{iPlace.terminalName}}</td>
        <td>{{iPlace.geoName}}</td>
        <td>{{iPlace.gate}}</td>
        <!-- <td>{{iPlace.timeEnter}}</td> -->
        <!-- <td>{{iPlace.timeInterval}}</td> -->
        <!-- <td *ngIf="iPlace.passportScanner" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!iPlace.passportScanner" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td *ngIf="iPlace.showTabScanner" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!iPlace.showTabScanner" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td *ngIf="iPlace.showTabWanted" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!iPlace.showTabWanted" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td *ngIf="iPlace.showClearArea" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!iPlace.showClearArea" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td *ngIf="iPlace.showReport" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!iPlace.showReport" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td>{{iPlace.guid}}</td> -->
        <!-- <td *ngIf="iPlace.showSecondNumber" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!iPlace.showSecondNumber" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td>{{iPlace.reReadSec}}</td> -->
        <!-- <td *ngIf="iPlace.aspectRatio4x3" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!iPlace.aspectRatio4x3" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td>{{iPlace.dateStartCurrentFlightsHours}}</td> -->
        <!-- <td>{{iPlace.dateEndCurrentFlightsHours}}</td> -->
      </tr>
      </tbody>
    </table>
  </div>

  <!--<div class="tab-pane fade pt-2" id="tab2">
    <button type="button" class="btn-sm mb-2 btn-dark" data-toggle="modal" data-target=".modal-workplace" (click)="newWorkplace()">
      New
    </button>

    <table class="table pl-0">
      <thead>
      <tr class="text-light type-head table__main-header">
        <th class="text-uppercase head-border__top text-center">Name</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let work of dictWorkplaces" class="bg-white" data-toggle="modal" data-target=".modal-workplace" (click)="editWorkplace(work.id)">
        <td>{{work.name}}</td>
      </tr>
      </tbody>
    </table>
  </div>-->

  <div class="tab-pane fade pt-2" id="tab3">
    <button type="button"
            class="btn btn-sm btn-light-gray mb-2"
            data-toggle="modal"
            data-target=".modal-solution"
            (click)="newSolution()">
      <i class="material-icons">add</i>
      <span i18n="adm|Boarding control - solution button new@@adm.boardControl.solutions.button.new">New</span>
    </button>

    <table class="table pl-0">
      <thead>
      <tr class="text-light type-head table__main-header">
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab solution header name@@adm.boardControl.table.solution.header.name">
          Name
        </th>
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab solution header type@@adm.boardControl.table.solution.header.type">
          Workplace type
        </th>
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab solution header deny or allow@@adm.boardControl.table.solution.header.denyAllow">
          Passage into the clean area
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of dictReasonSolutions"
          class="bg-white"
          data-toggle="modal"
          data-target=".modal-solution"
          (click)="editSolution(item.id)">
        <td>{{item.name}}</td>
        <td>{{item.workPlaceName}}</td>
        <td>{{(item.passStop == 1) ? denyStatus : allowStatus}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="tab-pane fade pt-2" id="tab4">
    <button type="button"
            class="btn btn-sm btn-light-gray mb-2"
            data-toggle="modal"
            data-target=".modal-reason-wanted"
            (click)="newReasonWanted()">
      <i class="material-icons">add</i>
      <span i18n="adm|Boarding control - tab reason wanted@@adm.boardControl.reasonWanted.button.new">New</span>
    </button>

    <table class="table pl-0">
      <thead>
      <tr class="text-light type-head table__main-header">
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab reason wanted header type@@adm.boardControl.table.reasonWanted.header.name">
          Name
        </th>
        <th class="text-uppercase head-border__top text-center"
            i18n="adm|Boarding control - tab reason wanted header type@@adm.boardControl.table.reasonWanted.header.baggage">
          On luggage
        </th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dictReasonWanteds"
            class="bg-white"
            data-toggle="modal"
            data-target=".modal-reason-wanted"
            (click)="editReasonWanted(item.id)">
          <td>{{item.name}}</td>
          <td *ngIf="item.baggage" class="text-center"><i class="material-icons text-green">done</i></td>
          <td *ngIf="!item.baggage" class="text-center"><i class="material-icons text-red">clear</i></td>
        </tr>
      </tbody>
    </table>
  </div>

  <!--<div class="tab-pane fade pt-2" id="tab5">
    <button type="button" class="btn-sm mb-2 btn-dark" data-toggle="modal" data-target=".modal-status-passenger" (click)="newStatusPassenger()">
      New
    </button>

    <table class="table pl-0">
      <thead>
      <tr class="text-light type-head table__main-header">
        <th class="text-uppercase head-border__top text-center">Status Passenger</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let sp of dictStatusPassengers" class="bg-white" data-toggle="modal" data-target=".modal-status-passenger" (click)="editStatusPassenger(sp.id)">
        <td>{{sp.name}}</td>
      </tr>
      </tbody>
    </table>
  </div>-->

</div>

<!-- Modal -->
<div #InstallationPlaceModalForm class="modal fade modal-installation-place mw-100" tabindex="-1" role="dialog"  aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl load--max-width">
    <div class="modal-content bg-light-gray">
      <form #InstallationPlaceForm="ngForm">
        <div class="modal-header">
          <h5 class="modal-title" i18n="adm|Boarding control - modal workplace@@adm.boardControl.modal.workplace">Workplace</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
            <div class="row mb-2">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <p class="text-blue m-0" i18n="adm|Boarding control - modal workplace group@@adm.boardControl.modal.workplace.groupWorkplace">Workplace</p>
                    <div class="row">
                      <div class="col-3">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace name@@adm.boardControl.modal.workplace.name">Name</span><span class="required-asterisk">*</span>
                        </label>
                        <input type="text"
                               class="form-control form-control-sm"
                               name="name"
                               id="name"
                               [(ngModel)]="installationWorkplace.name"
                               #name="ngModel"
                               maxlength="100">
                      </div>
                      <div class="col-3">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace type@@adm.boardControl.modal.workplace.type">Type</span><span class="required-asterisk">*</span>
                        </label>
                        <select #workplaceId
                                class="form-control form-control-sm"
                                name="workplaceId"
                                [(ngModel)]="installationWorkplace.idWorkPlace"
                                required>
                          <option *ngFor="let work of dictWorkplaces"
                                  value="{{work.id}}"
                                  [selected]="work.id == installationWorkplace.idWorkPlace">{{work.name}}</option>
                        </select>
                      </div>
                      <div class="col-2">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace terminal@@adm.boardControl.modal.workplace.terminal">Terminal</span><span class="required-asterisk">*</span>
                        </label>
                        <select #terminalId
                                class="form-control form-control-sm"
                                name="terminalId"
                                [(ngModel)]="installationWorkplace.idTerminal"
                                required>
                          <option *ngFor="let term of dictTerminals;"
                                  value="{{term.id}}"
                                  [selected]="term.id == installationWorkplace.idTerminal">{{term.name}}</option>
                        </select>
                      </div>
                      <div class="col-2">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace geo@@adm.boardControl.modal.workplace.geo">Geo</span><span class="required-asterisk">*</span>
                        </label>
                        <select #geoid
                                class="form-control form-control-sm"
                                name="geoid"
                                [(ngModel)]="installationWorkplace.idGeo"
                                required>
                          <option *ngFor="let geo of dictGeos"
                                  value="{{geo.id}}"
                                  [selected]="geo.id == installationWorkplace.idGeo">{{geo.name}}</option>
                        </select>
                      </div>


                      <div class="col-2" *ngIf="installationWorkplace.idWorkPlace == -2">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace desk and gate@@adm.boardControl.modal.workplace.deskGate">Desk/Gate</span><span class="required-asterisk">*</span>
                        </label>
                        <input type="text"
                               class="form-control form-control-sm"
                               name="gate"
                               id="gate"
                               [(ngModel)]="installationWorkplace.gate"
                               #gate="ngModel"
                               maxlength="3">
                      </div>
                    </div>
                    <div class="row"
                         *ngIf="installationWorkplace.idWorkPlace != -2 &&
                                installationWorkplace.idWorkPlace != -3 &&
                                installationWorkplace.idWorkPlace != -4">
                      <div class="col-3">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace delay between scan@@adm.boardControl.modal.workplace.delayBetweenScan">Delay between scans, seconds</span><span class="required-asterisk">*</span>
                        </label>
                        <input type="number"
                               class="form-control form-control-sm"
                               name="reReadSec"
                               id="reReadSec"
                               [(ngModel)]="installationWorkplace.reReadSec"
                               #reReadSec="ngModel"
                               maxlength="2">
                      </div>
                      <div class="col">
                        <label class="col-form-label col-form-label-sm">
                          &nbsp;
                        </label>
                        <div class="form-check form-control-sm">
                          <input class="form-check-input"
                                 type="checkbox"
                                 id="passport-scanner"
                                 name="passport-scanner"
                                 [(ngModel)]="installationWorkplace.passportScanner">
                          <label class="form-check-label"
                                 for="passport-scanner"
                                 i18n="adm|Boarding control - modal workplace passport scaner@@adm.boardControl.modal.workplace.passportScaner">
                            Passport scanner
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <label class="col-form-label col-form-label-sm">
                          &nbsp;
                        </label>
                        <div class="form-check form-control-sm">
                          <input class="form-check-input"
                                 type="checkbox"
                                 id="compact-look"
                                 name="compact-look"
                                 [(ngModel)]="installationWorkplace.aspectRatio4x3">
                          <label class="form-check-label"
                                 for="compact-look"
                                 i18n="adm|Boarding control - modal workplace compact look@@adm.boardControl.modal.workplace.compactLook">
                            Compact look, for small screens (4х3)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <p class="text-blue m-0" i18n="adm|Boarding control - modal workplace type@@adm.boardControl.modal.workplace.group.flightView">Flight view window</p>
                    <div class="row">
                      <div class="col">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace flight hours before@@adm.boardControl.modal.workplace.flight.hoursBefore">Hours, before current time</span><span class="required-asterisk">*</span>
                        </label>
                        <input type="number"
                               class="form-control form-control-sm"
                               name="dateStartCurrentFlightsHours"
                               id="dateStartCurrentFlightsHours"
                               [(ngModel)]="installationWorkplace.dateStartCurrentFlightsHours"
                               #dateStartCurrentFlightsHours="ngModel"
                               maxlength="2"
                               min="0">
                      </div>
                      <div class="col">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace flight hours after@@adm.boardControl.modal.workplace.flight.hoursAfter">Hours, after current time</span><span class="required-asterisk">*</span>
                        </label>
                        <input type="number"
                               class="form-control form-control-sm"
                               name="dateEndCurrentFlightsHours"
                               id="dateEndCurrentFlightsHours"
                               [(ngModel)]="installationWorkplace.dateEndCurrentFlightsHours"
                               #dateEndCurrentFlightsHours="ngModel"
                               maxlength="2"
                               min="0">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2"
                 *ngIf="installationWorkplace.idWorkPlace != -2 &&
                        installationWorkplace.idWorkPlace != -3 &&
                        installationWorkplace.idWorkPlace != -4">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <p class="text-blue m-0" i18n="adm|Boarding control - modal workplace flight hours before@@adm.boardControl.modal.workplace.flight.hoursBefore">Clean area entry interval</p>
                    <div class="row">
                      <div class="col">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace entry before@@adm.boardControl.modal.workplace.entryBefore">Entry starts before scheduled / expected departure time</span><span class="required-asterisk">*</span>
                        </label>
                        <input class="form-control without-ampm"
                               type="time"
                               name="timeEnter"
                               id="timeEnter"
                               [(ngModel)]="installationWorkplace.timeEnter"
                               #timeEnter="ngModel"
                               maxlength="5">
                      </div>
                      <div class="col">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="adm|Boarding control - modal workplace entry deadline@@adm.boardControl.modal.workplace.entryDeadline">Deadline for entry before the scheduled / expected departure time</span><span class="required-asterisk">*</span>
                        </label>
                        <input class="form-control without-ampm"
                               type="time"
                               name="timeInterval"
                               id="timeInterval"
                               [(ngModel)]="installationWorkplace.timeInterval"
                               #timeInterval="ngModel"
                               maxlength="5">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row"
                 *ngIf="installationWorkplace.idWorkPlace != -2 &&
                        installationWorkplace.idWorkPlace != -3 &&
                        installationWorkplace.idWorkPlace != -4">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <p class="text-blue m-0"
                       i18n="adm|Boarding control - modal workplace available sectionse@@adm.boardControl.modal.workplace.availableSections">
                      Available sections
                    </p>
                    <div class="row">
                      <div class="col">
                        <div class="form-check form-control-sm">
                          <input class="form-check-input"
                                 type="checkbox"
                                 id="tab-scanning"
                                 name="tab-scanning"
                                 [(ngModel)]="installationWorkplace.showTabScanner">
                          <label class="form-check-label"
                                 for="tab-scanning"
                                 i18n="adm|Boarding control - modal workplace tab scanning@@adm.boardControl.modal.workplace.tab.scanning">
                            Scanning
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-check form-control-sm">
                          <input class="form-check-input"
                                 type="checkbox"
                                 id="tab-wanted-list"
                                 name="tab-wanted-list"
                                 [(ngModel)]="installationWorkplace.showTabWanted">
                          <label class="form-check-label"
                                 for="tab-wanted-list"
                                 i18n="adm|Boarding control - modal workplace tab wanted list@@adm.boardControl.modal.workplace.tab.wantedList">
                            Wanted list
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-check form-control-sm">
                          <input class="form-check-input"
                                 type="checkbox"
                                 id="tab-passengers-clean-area"
                                 name="tab-passengers-clean-area"
                                 [(ngModel)]="installationWorkplace.showClearArea">
                          <label class="form-check-label"
                                 for="tab-passengers-clean-area"
                                 i18n="adm|Boarding control - modal workplace tab passengers in clean area@@adm.boardControl.modal.workplace.tab.passengersInCleanArea">
                            Passengers in the clean area
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-check form-control-sm">
                          <input class="form-check-input"
                                 type="checkbox"
                                 id="tab-reports"
                                 name="tab-reports"
                                 [(ngModel)]="installationWorkplace.showReport">
                          <label class="form-check-label"
                                 for="tab-reports"
                                 i18n="adm|Boarding control - modal workplace tab reports@@adm.boardControl.modal.workplace.tab.reports">
                            Reports
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="installationWorkplace.idWorkPlace == -2">
              <div class="col">
                <div class="form-check form-control-sm">
                  <input class="form-check-input"
                         type="checkbox"
                         id="auto-pass-gate"
                         name="auto-pass-gate"
                         [(ngModel)]="installationWorkplace.autoPass">
                  <label class="form-check-label"
                         for="auto-pass-gate"
                         i18n="adm|Boarding control - modal workplace tab passengers in clean area@@adm.boardControl.modal.workplace.tab.autoPass">
                    Auto pass
                  </label>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="installationWorkplace.idWorkPlace == -3">
              <div class="col">
                <label class="col-form-label col-form-label-sm">
                  <span i18n="adm|Boarding control - modal workplace wanted by baggage@@adm.boardControl.modal.workplace.wantedByBaggage">Wanted by luggage</span><span class="required-asterisk">*</span>
                </label>
                <select #reasonBaggageId
                        class="form-control form-control-sm"
                        name="reasonBaggageId"
                        [(ngModel)]="installationWorkplace.reasonBaggageMobile"
                        required>
                  <option *ngFor="let drw of dictReasonWanteds"
                          [hidden]="!drw.baggage"
                          value="{{drw.id}}"
                          [selected]="drw.id == installationWorkplace.reasonBaggageMobile">{{drw.name}}</option>
                </select>
              </div>
            </div>
        </div>

        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-red"
                  data-dismiss="modal"
                  *ngIf="!newRecord"
                  (click)="delPlace(installationWorkplace.id)"
                  i18n="adm|Boarding control - modal workplace button delete@@adm.boardControl.modal.workplace.button.delete">
            Delete
          </button>
          <button type="button"
                  class="btn btn-sm btn-blue"
                  *ngIf="!newRecord"
                  (click)="savePlace()"
                  i18n="adm|Boarding control - modal workplace button save@@adm.boardControl.modal.workplace.button.save">
            Save
          </button>
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                  i18n="adm|Boarding control - modal workplace button close@@adm.boardControl.modal.workplace.button.close">
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!--modal workplace-->
<!--<div #WorkplaceModalForm class="modal fade modal-workplace" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content bg-light-gray">
      <form #WorkplacePlaceForm="ngForm">

        <div class="modal-header">
          <h5 class="modal-title">Workplace</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">

          <label class="col-form-label col-form-label-sm"> Workplace <span class="required-asterisk">*</span></label>
          <input type="text" class="form-control form-control-sm" name="idwpname" id="idwpname" [(ngModel)]="dictWorkplace.name" #idwpname="ngModel" maxlength="100" required>

          <div *ngIf="!idwpname?.valid && (idwpname?.dirty || idwpname?.touched)" class="invalid-feedback d-block">
            <div *ngIf="idwpname.errors.required">
              <div> Type workplace is required </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-blue" *ngIf="newRecord" (click)="addWorkplace()">
            <i class="material-icons">add_circle_outline</i>
            Add
          </button>
          <button type="button" class="btn btn-sm btn-blue" *ngIf="!newRecord" (click)="saveWorkplace()">
            <i class="material-icons">save</i>
            Save
          </button>
          &lt;!&ndash;<button type="button" class="btn btn-sm btn-red" data-dismiss="modal" *ngIf="!newRecord" (click)="delWorkplace(dictWorkplace.id)">
            <i class="material-icons">delete</i>
            Delete
          </button>&ndash;&gt;
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">
            <i class="material-icons">cancel</i>
            Close
          </button>
        </div>

      </form>
    </div>
  </div>
</div>-->

<!-- Modal solution -->
<div #SolutionModalForm
     class="modal fade modal-solution mw-100"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myExtraLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-xl load--max-width">
    <div class="modal-content bg-light-gray">
      <form #SolutionPlaceForm="ngForm">
        <div class="modal-header">
          <h5 class="modal-title"
              i18n="adm|Boarding control - modal solution header@@adm.boardControl.modal.solution">
            Solution
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">

          <div class="row mx-auto">
            <div class="col">
              <label class="col-form-label col-form-label-sm">
                <span i18n="adm|Boarding control - modal solution type workplace@@adm.boardControl.modal.typeWorkplace">Type workplace</span><span class="required-asterisk">*</span>
              </label>
              <select #workplaceId
                      class="form-control form-control-sm"
                      name="workplaceId"
                      [(ngModel)]="dictReasonSolution.idWorkplace"
                      required>
                <option *ngFor="let work of dictWorkplaces"
                        value="{{work.id}}"
                        [selected]="dictReasonSolution.idWorkplace == installationWorkplace.idWorkPlace">{{work.name}}</option>
              </select>
            </div>

            <div class="col">
              <label class="col-form-label col-form-label-sm">
                <span i18n="adm|Boarding control - modal solution deny and allow passage to clean zone@@adm.boardControl.modal.solution.denyAllowPassageToCleanZone">Deny/Allow passage to the clean area</span><span class="required-asterisk">*</span>
              </label>
              <!-- TODO deny allow to translate -->
              <select #passStop
                      class="form-control form-control-sm"
                      name="passStop"
                      [(ngModel)]="dictReasonSolution.passStop"
                      required>
                <option value="0">{{denyStatus}}</option>
                <option value="1">{{allowStatus}}</option>
              </select>
            </div>

            <div class="col">
              <label class="col-form-label col-form-label-sm">
                <span i18n="adm|Boarding control - modal solution reason solution@@adm.boardControl.modal.solution.reasonSolution">Reason for the solution</span><span class="required-asterisk">*</span>
              </label>
              <input type="text"
                     class="form-control form-control-sm"
                     name="nameSolution"
                     id="nameSolution"
                     [(ngModel)]="dictReasonSolution.name"
                     #nameSolution="ngModel"
                     maxlength="50"
                     required>

              <div *ngIf="!nameSolution?.valid && (nameSolution?.dirty || nameSolution?.touched)"
                   class="invalid-feedback d-block">
                <div *ngIf="nameSolution.errors.required">
                  <div i18n="adm|Boarding control - modal solution reason solution required@@adm.boardControl.modal.solution.reasonSolution.err.required">Reason for the solution is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-blue"
                  *ngIf="newRecord"
                  (click)="addSolution()"
                  i18n="adm|Boarding control - modal solution button add@@adm.boardControl.modal.solution.button.add">
            Add
          </button>
          <button type="button"
                  class="btn btn-sm btn-red"
                  data-dismiss="modal"
                  *ngIf="!newRecord"
                  (click)="delSolution(dictReasonSolution.id)"
                  i18n="adm|Boarding control - modal solution button delete@@adm.boardControl.modal.solution.button.delete">
            Delete
          </button>
          <button type="button"
                  class="btn btn-sm btn-blue"
                  *ngIf="!newRecord"
                  (click)="saveSolution()"
                  i18n="adm|Boarding control - modal solution button save@@adm.boardControl.modal.solution.button.save">
            Save
          </button>
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                  i18n="adm|Boarding control - modal solution button close@@adm.boardControl.modal.solution.button.close">
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal reason wanted -->
<div #ReasonWantedModalForm class="modal fade modal-reason-wanted" tabindex="-1" role="dialog"  aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content bg-light-gray">
      <form #ReasonWantedForm="ngForm">
        <div class="modal-header">
          <h5 class="modal-title"
              i18n="adm|Boarding control - modal reason wanted@@adm.boardControl.modal.reasonWanted">
            Reason for wanted
          </h5>
          <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <label class="col-form-label col-form-label-sm">
                <span i18n="adm|Boarding control - modal reason wanted name@@adm.boardControl.modal.reasonWanted.name">Reason name</span><span class="required-asterisk">*</span>
              </label>
              <input type="text"
                     class="form-control form-control-sm"
                     name="reason"
                     id="reason"
                     [(ngModel)]="dictReasonWanted.name"
                     #reason="ngModel"
                     maxlength="50"
                     required>
              <div *ngIf="!reason?.valid && (reason?.dirty || reason?.touched)"
                   class="invalid-feedback d-block">
                  <div *ngIf="reason.errors.required">
                    <div i18n="adm|Boarding control - modal reason wanted name required@@adm.boardControl.modal.reasonWanted.name.err.required">Reason name is required</div>
                  </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-check">
                <input class="form-check-input"
                       type="checkbox"
                       id="reasonBaggage"
                       name="reasonBaggage"
                       [(ngModel)]="dictReasonWanted.baggage">
                <label class="form-check-label"
                       for="reasonBaggage"
                       i18n="adm|Boarding control - modal reason wanted reason baggage@@adm.boardControl.modal.reasonWanted.reasonBaggage">
                  The reason for the search for luggage
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-blue"
                  *ngIf="newRecord"
                  (click)="addReasonWanted()"
                  i18n="adm|Boarding control - modal reason wanted button add@@adm.boardControl.modal.reasonWanted.button.add">
            Add
          </button>
          <button *ngIf="dictReasonWanted.id != -1 && !newRecord"
                  type="button"
                  class="btn btn-sm btn-red"
                  data-dismiss="modal"
                  (click)="delReasonWanted(dictReasonWanted.id)"
                  i18n="adm|Boarding control - modal reason wanted button delete@@adm.boardControl.modal.reasonWanted.button.delete">
            Delete
          </button>
          <button type="button"
                  class="btn btn-sm btn-blue"
                  *ngIf="!newRecord"
                  (click)="saveReasonWanted()"
                  i18n="adm|Boarding control - modal reason wanted button save@@adm.boardControl.modal.reasonWanted.button.save">
            Save
          </button>
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                  i18n="adm|Boarding control - modal reason wanted button close@@adm.boardControl.modal.reasonWanted.button.close">
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!--modal status passsenger-->
<!--<div #StatusPassModalForm class="modal fade modal-status-passenger" tabindex="-1" role="dialog"  aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content bg-light-gray">
      <form #StatusPassForm="ngForm">

        <div class="modal-header">
          <h5 class="modal-title">Status Passenger</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">

          <label class="col-form-label col-form-label-sm"> Status Passenger <span class="required-asterisk">*</span></label>
          <input type="text" class="form-control form-control-sm" name="statusPass" id="statusPass" [(ngModel)]="dictStatusPassenger.name"
                 #statusPass="ngModel" maxlength="50" required>

          <div *ngIf="!statusPass?.valid && (statusPass?.dirty || statusPass?.touched)"
               class="invalid-feedback d-block">
            <div *ngIf="statusPass.errors.required">
              <div>Status is required </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">

          <button type="button" class="btn btn-sm btn-blue" *ngIf="newRecord" (click)="addStatusPassenger()">
            <i class="material-icons">add_circle_outline</i>
            Add
          </button>
          <button type="button" class="btn btn-sm btn-blue" *ngIf="!newRecord" (click)="saveStatusPassenger()">
            <i class="material-icons">save</i>
            Save
          </button>
          <button type="button" class="btn btn-sm btn-red" data-dismiss="modal" *ngIf="!newRecord" (click)="delStatusPassenger(dictStatusPassenger.id)">
            <i class="material-icons">delete</i>
            Delete
          </button>
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">
            <i class="material-icons">cancel</i>
            Close
          </button>
        </div>

      </form>
    </div>
  </div>
</div>-->
