import { LANGUAGES } from '@shared/const';

export class ManualStatement {
  constructor(obj?: ManualStatement, public lang?: string) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
  statementId: string;
  baggageId: string;
  airport: string;
  name: string;
  dt: Date;
  type: Array<string>;
  flights: string;
  route: string;
  surname: string;
  phone: string;
  address: string;
  pnr: string;
  tag: string;
  baggage: string;
  brand: string;
  weight: number;
  internals: string;
  description: string;
  status: Array<string>;

  get localTypeName() {
    if (this.lang && this.lang === LANGUAGES.RU && this.type && this.type.length > 1) {
      return this.type[1];
    } else if (this.lang && this.lang === LANGUAGES.EN && this.type && this.type.length > 0) {
      return this.type[0];
    } else if (this.type && this.type.length > 0) {
      return this.type[0];
    }
    return '';
  }

  get localStatusName() {
    if (this.lang && this.lang === LANGUAGES.RU && this.status && this.status.length > 1) {
      return this.status[1];
    } else if (this.lang && this.lang === LANGUAGES.EN && this.status && this.status.length > 0) {
      return this.status[0];
    } else if (this.status && this.status.length > 0) {
      return this.status[0];
    }
    return '';
  }

  get routeString() {
    if (this.route && this.route.length > 0) {
      let routesArray = this.route.split(';');
      let res = [];
      routesArray.forEach((route, i) => {
        let oneRoute = route.split('-');
        if (i === 0) {
          res.push(oneRoute[0].trim());
        }
        res.push(oneRoute[1].trim());
      });
      return res.join(' - ')
    }
    
    if (this.lang && this.lang === LANGUAGES.RU && this.type && this.type.length > 1) {
      return this.type[1];
    } else if (this.lang && this.lang === LANGUAGES.EN && this.type && this.type.length > 0) {
      return this.type[0];
    } else if (this.type && this.type.length > 0) {
      return this.type[0];
    }
    return '';
  }

}

