<div class="mb-2 p-3 aero-top-panel shadow">
  <div class="row align-items-center">
    <div class="col-2">
      <button type="button" class="btn mr-2 btn-ico btn-light-gray" data-toggle="modal"
        data-target=".bd-example-modal-xl" (click)="createFlight()">
        <i class="material-icons">add</i>
        <span i18n="sch|Control panel - add flight@@sch.controlPanel.button.addFlight">Add flight</span>
      </button>
    </div>
    <div class="col-4 pr-1 text-right d-flex align-items-center">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text font-small"
                i18n="sch|Control panel - displayed period@@sch.controlPanel.text.displayedPeriod">
            Displayed period
          </span>
        </div>
        <!--<div class="border bg-light-gray py-2 px-1 font-small">
          <ng-container *ngIf="filterApply && filterParams.start && filterParams.finish; else current">
            {{filterParams.start | date: 'dd-MM-yyyy'}} - {{filterParams.finish | date: 'dd-MM-yyyy'}}
          </ng-container>
          <ng-template #current>
            <span i18n="sch|Control panel - current@@sch.controlPanel.text.current">Current (-24h - +48h)</span>
          </ng-template>
        </div>-->
          <ng-container *ngIf="filterApply && filterParams.start && filterParams.finish; else current">
            <input type="text"
                   value="{{filterParams.start | date: 'dd.MM.yyyy'}} - {{filterParams.finish | date: 'dd.MM.yyyy'}}"
                   class="form-control form-control-sm"
                   disabled>
          </ng-container>
          <ng-template #current>
            <input type="text"
                   value="{{messages['current']}}"
                   class="form-control form-control-sm"
                   disabled>
          </ng-template>
      </div>
      <div class="dropdown ml-2">
        <button class="btn btn-xs btn-ico btn-light-gray dropdown-toggle" type="button"
          id="changeFlightDisplay" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="material-icons" *ngIf="!displayCombined">view_column</i>
          <i class="material-icons" *ngIf="displayCombined">view_stream</i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuExportAirports">
          <button class="dropdown-item" type="button" (click)="selectFlightDisplay(true)">
            <i class="material-icons">view_stream</i>
            Combined view
          </button>
          <button class="dropdown-item" type="button" (click)="selectFlightDisplay(false)">
            <i class="material-icons">view_column</i>
            Not combined view
          </button>
        </div>
      </div>
    </div>
    <div class="col-2 pr-1">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text font-small" i18n="sch|Filter - search@@sch.filter.label.search">
            Search
          </span>
        </div>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="searchFlight">
      </div>
    </div>
    <div class="col-4 pl-0">
      <div class="d-flex">
        <div class="filter-block-wrap mr-2" (click)="showFilter = !showFilter">
          <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.start && filterParams.finish">
              <i class="material-icons mx-1" (click)="clearFilterParametr('date', $event)">close</i>
              <span i18n="sch|Filter - date@@sch.filter.field.date">Date</span>&nbsp;{{filterParams.start | date: 'dd.MM.yyyy'
             }}&nbsp;-&nbsp;{{filterParams.finish | date: 'dd.MM.yyyy'}}
            </div>
            <!--<div class="filter-element mr-2 my-1" *ngIf="filterParams.start!=null">
              Date start {{filterParams.start | date: 'dd.MM.yyyy'}}
              <i class="material-icons ml-2" (click)="clearFilterParametr('start')">close</i>
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.finish!=null">
              Date finish {{filterParams.finish | date: 'dd.MM.yyyy'}}
              <i class="material-icons ml-2" (click)="clearFilterParametr('finish')">close</i>
            </div>-->
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.flight!=null">
                 <i class="material-icons ml-2" (click)="clearFilterParametr('flight', $event)">close</i>
              <span i18n="sch|Filter - date@@sch.filter.field.flight">Flight</span>&nbsp;{{filterParams.flight}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.airport!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('airport', $event)">close</i>
              <span i18n="sch|Filter - airport@@sch.filter.field.airport">Airport</span>&nbsp;{{getById(referanceAirports, filterParams.airport ).iata}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.movement!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('movement', $event)">close</i>
              <span i18n="sch|Filter - movement@@sch.filter.field.movement">Movement</span>&nbsp;{{getById(referanceMovements, filterParams.movement ).name}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.airline!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('airline', $event)">close</i>
              <span i18n="sch|Filter - airline@@sch.filter.field.airline">Airline</span>&nbsp;{{getById(referanceAirlines, filterParams.airline ).iata}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.aircraft!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('aircraft', $event)">close</i>
              <span i18n="sch|Filter - aircraft@@sch.filter.field.aircraft">Aircraft</span>&nbsp;{{getById(referanceAircraftTypes, filterParams.aircraft ).names}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.tail!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('tail', $event)">close</i>
              <span i18n="sch|Filter - tail@@sch.filter.field.tail">Tail</span>&nbsp;{{filterParams.tail}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.terminal!=null">
                 <i class="material-icons ml-2" (click)="clearFilterParametr('terminal', $event)">close</i>
              <span i18n="sch|Filter - terminal@@sch.filter.field.terminal">Terminal</span>&nbsp;{{getById(referanceTerminals, filterParams.terminal ).name}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.stand!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('stand', $event)">close</i>
              <span i18n="sch|Filter - stand@@sch.filter.field.stand">Stand</span>&nbsp;{{getById(referanceStands, filterParams.stand ).name}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.delay!=null">
                 <i class="material-icons ml-2" (click)="clearFilterParametr('delay', $event)">close</i>
              <span i18n="sch|Filter - delay@@sch.filter.field.delay">Delay</span>&nbsp;{{getById(referanceDelays, filterParams.delay ).iataChar}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filterParams.geo!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('geo', $event)">close</i>
              <span i18n="sch|Filter - geo@@sch.filter.field.geo">Geo</span>&nbsp;{{globalSettings.language=='en' ?
                getById(referanceGeoTypes, filterParams.geo ).nameEnglish :
                getById(referanceGeoTypes, filterParams.geo ).nameLocal}}
            </div>
          </div>
          <div class="d-flex align-items-center text-dark-gray">
            <i class="material-icons mx-1">arrow_drop_down</i>
          </div>
        </div>
        <!-- Filter -->
          <div class="filter-block border p-2 bg-white font-small" *ngIf="showFilter">
            <div class="container">
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from"
                         class="mr-2 mb-0"
                         i18n="sch|Filter - date from@@sch.filter.field.dateFrom">
                    Date from
                  </label>
                </div>
                <div class="col-8 px-1">
                  <input type="date"
                         class="form-control"
                         [ngModel]="filterParams.start | date: 'yyyy-MM-dd'"
                         (input)="filterParams.start = parseDate($event.target.value, '00:00')"
                         name="filterParamsStartDate"
                         #start_date>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from"
                         class="mr-2 mb-0"
                         i18n="sch|Filter - date to@@sch.filter.field.dateTo">
                    Date to
                  </label>
                </div>
                <div class="col-8 px-1">
                  <input type="date"
                         class="form-control"
                         [ngModel]="filterParams.finish | date: 'yyyy-MM-dd'"
                         (input)="filterParams.finish = parseDate($event.target.value, '23:59')"
                         name="filterParamsStartDate"
                         #finish_date>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from"
                         class="mr-2 mb-0"
                         i18n="sch|Filter - date@@sch.filter.field.flight">
                    Flight
                  </label>
                </div>
                <div class="col-8 px-1">
                  <input type="text"
                         class="form-control form-control-sm"
                         [(ngModel)]="filterParams.flight"
                         name="filterParamsFlight">
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from" class="mr-2 mb-0"
                         i18n="sch|Filter - airport@@sch.filter.field.airport">
                    Airport
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                            [items]="referanceAirports"
                            bindLabel="iata"
                            bindValue="id"
                            [(ngModel)]="filterParams.airport"
                            #filterParamsAirline="ngModel"
                            name="filterParamsAirline"
                            [virtualScroll]="true"
                            [loading]="referanceAirportsLoading"
                            (scroll)="onScrollReferanceAirports($event)"
                            (scrollToEnd)="onScrollReferanceAirportsToEnd()"
                            [searchFn]="customSelectSearchAirports"
                            appendTo="body">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.names}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from" class="mr-2 mb-0"
                         i18n="sch|Filter - movement@@sch.filter.field.movement">
                    Movement
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                    [items]="referanceMovements"
                    bindLabel="name"
                    bindValue="id"
                    [(ngModel)]="filterParams.movement"
                    name="filterParamsMovement"
                    #filterParamsMovement="ngModel"
                    [virtualScroll]="true"
                    [loading]="referanceMovementsLoading"
                    (scroll)="onScrollReferanceMovements($event)"
                    (scrollToEnd)="onScrollReferanceMovementsToEnd()">
                    <ng-template ng-option-tmp let-item="item">
                      {{item.code}} / {{item.name}}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from" class="mr-2 mb-0"
                         i18n="sch|Filter - airline@@sch.filter.field.airline">
                    Airline
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                    [items]="referanceAirlines"
                    bindLabel="iata"
                    bindValue="id"
                    [(ngModel)]="filterParams.airline"
                    #filterParamsAirline="ngModel"
                    name="filterParamsAirline"
                    [virtualScroll]="true"
                    [loading]="referanceAirlinesLoading"
                    (scroll)="onScrollReferanceAirlines($event)"
                    (scrollToEnd)="onScrollReferanceAirlinesToEnd()">
                    <ng-template ng-option-tmp let-item="item">
                      {{item.code}} / {{item.name}}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from" class="mr-2 mb-0"
                         i18n="sch|Filter - aircraft@@sch.filter.field.aircraft">
                    Aircraft
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                    [items]="referanceAircraftTypes"
                    bindLabel="names"
                    bindValue="id"
                    name="filterParamsAircraft"
                    id="filterParamsAircraft"
                    [(ngModel)]="filterParams.aircraft"
                    (change)="selectAircraft($event, 'A', true)"
                    [virtualScroll]="true"
                    [loading]="referanceAircraftTypesLoading"
                    (scroll)="onScrollReferanceAircraftTypes($event)"
                    (scrollToEnd)="onScrollReferanceAircraftTypesToEnd()">
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filter-date-from" class="mr-2 mb-0"
                         i18n="sch|Filter - tail@@sch.filter.field.tail">
                    Tail
                  </label>
                </div>
                <div class="col-8 px-1">
                  <input type="text"
                         class="form-control form-control-sm"
                         [(ngModel)]="filterParams.tail"
                         name="filterParamsTail">
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filterParamsTerminal" class="mr-2 mb-0"
                         i18n="sch|Filter - terminal@@sch.filter.field.terminal">
                    Terminal
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                    [items]="referanceTerminals"
                    bindLabel="name"
                    bindValue="id"
                    name="filterParamsTerminal"
                    id="filterParamsTerminal"
                    [(ngModel)]="filterParams.terminal">
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filterParamStand" class="mr-2 mb-0"
                         i18n="sch|Filter - stand@@sch.filter.field.stand">
                    Stand
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                    [items]="referanceStands"
                    bindLabel="name"
                    bindValue="id"
                    name="filterParamStand"
                    id="filterParamStand"
                    [(ngModel)]="filterParams.stand">
                </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filterParamStand" class="mr-2 mb-0"
                         i18n="sch|Filter - delay@@sch.filter.field.delay">
                    Delay
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                    [items]="referanceDelays"
                    bindLabel="iataChar"
                    bindValue="id"
                    name="filterParamDelay"
                    id="filterParamDelay"
                    [(ngModel)]="filterParams.delay">
                </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4 px-1">
                  <label for="filterParamGeo" class="mr-2 mb-0"
                         i18n="sch|Filter - geo@@sch.filter.field.geo">
                    Geo
                  </label>
                </div>
                <div class="col-8 px-1">
                  <ng-select class="form-control custom-sm p-0"
                    [items]="referanceGeoTypes"
                    [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                    bindValue="id"
                    name="filterParamGeo"
                    id="filterParamGeo"
                    [(ngModel)]="filterParams.geo">
                    <ng-template ng-option-tmp let-item="item">
                      <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
                    </ng-template>
                </ng-select>
                </div>
              </div>
            </div>
            <hr />
            <button class="btn btn-gray-blue-dark btn-sm float-right ml-2"
                    (click)="showFilter = !showFilter"
                    i18n="sch|Filter - close@@sch.filter.button.close">
              Close
            </button>
            <button class="btn btn-blue btn-sm float-right ml-2"
                    (click)="filterApp()"
                    i18n="sch|Filter - find@@sch.filter.button.find">
              Find
            </button>
            <button class="btn btn-blue btn-sm float-right"
                    (click)="clearFilter()"
                    i18n="sch|Filter - clear all@@sch.filter.button.clearAll">
              Clear all
            </button>
          </div>

        <button type="button"
                class="btn btn-xs btn-ico btn-light-gray mr-2"
                ngbTooltip="Filter on/off"
                i18n-ngbTooltip="cmn|Main buttons - filter on/off@@cmn.main.button.filterOnOff"
                [ngClass]="{'btn-active' : filterApply}"
                (click)="filterSwitch()">
          <i class="material-icons">filter_alt</i>
        </button>
        <!--<button type="button" class="btn btn-xs btn-ico btn-light-gray mr-2 float-right" (click)="showFilter = !showFilter">
          <i class="material-icons">filter_alt</i>
        </button>
        <div class="input-group-prepend">
          <div class="custom-control custom-switch d-flex align-items-center ml-2" style="min-width: fit-content;">
            <input type="checkbox" class="custom-control-input" id="filterSwitch" name="filterSwitch"
              [checked]="filterApply" (click)="filterSwitch()">
            <label class="custom-control-label" for="filterSwitch"
                    i18n="sch|Filter - filter@@sch.filter.button.filter">
              Filter
            </label>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
<div class="shedule-two-tables-wrap" *ngIf="!displayCombined">
  <div class="shedule-two-tables">
    <div class="row m-0">
      <div class="col-6 mp-2 pl-0 pr-1">
        <table class="table table-borderless table-hover">
          <thead>
            <tr class="text-muted detail-head table__mail-header--subhead">
              <th class="text-center text-uppercase"
                  i18n="sch|Table standart view arrival - column arrival@@sch.table.standartView.arrival.column.arrival">
                  ARRIVAL
              </th>
              <th class="text-center text-uppercase"
                  i18n="sch|Table standart view arrival - column status@@sch.table.standartView.arrival.column.status">
                STATUS
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view arrival - column dst@@sch.table.standartView.arrival.column.dst">
                DST
              </th>
              <th class="text-center font-small">
                <span
                      i18n="sch|Table standart view arrival - column aircraft/tail@@sch.table.standartView.arrival.column.aircraftTail.aircraft">Aircraft</span><br><span
                      i18n="sch|Table standart view arrival - column aircraft/tail@@sch.table.standartView.arrival.column.aircraftTail.tail">Tail</span>
              </th>
              <th class="text-center font-small">
                <span
                      i18n="sch|Table standart view arrival - column arrival time/day@@sch.table.standartView.arrival.column.arrivalTimeDay.arrival">Arrival</span><br><span
                      i18n="sch|Table standart view arrival - column arrival time/day@@sch.table.standartView.arrival.column.arrivalTimeDay.timeDay">Time
                  / Day</span>
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view arrival - column terminal@@sch.table.standartView.arrival.column.terminal">
                T
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view arrival - column landing@@sch.table.standartView.arrival.column.landing">
                Landing
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view arrival - column baggage claim@@sch.table.standartView.arrival.column.baggageClaim">
                Baggage claim
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let flight of flights_arrival" class="bg-white" [ngClass]="{'flight-list__row--actual-flight': flight.status == ''
          ,'flight-list__row--expected-flight': flight.statusExpected == 1
          ,'flight-list__row--complated-flight': flight.status == 'Arrived'}" data-toggle="modal"
              data-target=".bd-example-modal-xl" (click)="loadFlight(flight.id, 'arrival')">
              <td class="text-center text-dark font-weight-bold">
                <p class="m-0 column-ico font-medium text-center">
                  <span class="icons">
                    <i class="material-icons text-gray-blue-dark md-18">flight_land</i>
                  </span>
                  {{flight.flight}}
                </p>
                <p class="m-0 column-ico font-medium text-center">
                  <span class="icons">
                    <i class="material-icons text-gray-blue-dark md-18">local_parking</i>
                  </span>
                  {{flight.stand}}
                </p>
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                {{flight.statusName}}
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                <span class="font-weight-bold">{{flight.route[0].iata}}</span>
                <span class="font-small d-block">{{flight.route[0].name}}</span>
                <!--<span class="font-small d-block">{{getAirportNameFromIata(flight.route[0])}}</span>-->
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                <span class="font-weight-normal text-gray-blue-dark"
                      i18n="sch|Table standart view arrival - data aircraft@@sch.table.standartView.arrival.data.aircraft">
                  aircraft 
                </span>&nbsp;{{flight.aircraftType}}
                <br>
                <span class="font-weight-normal text-gray-blue-dark"
                      i18n="sch|Table standart view arrival - data tail@@sch.table.standartView.arrival.data.tail">
                  tail 
                </span>&nbsp;{{flight.tail}}
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                <div class="font-small">
                  <div class="d-flex">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view arrival - data scd@@sch.table.standartView.arrival.data.scd">
                        scd 
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.scheduleDatetime | date: 'HH:mm'}} /
                      {{flight.scheduleDatetime | date: 'dd'}}</div>
                  </div>
                  <div class="d-flex flex-row bd-highlight mb-0">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view arrival - data est@@sch.table.standartView.arrival.data.est">
                        est 
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.estimatedDatetime | date: 'HH:mm'}} /
                      {{flight.estimatedDatetime | date: 'dd'}}</div>
                  </div>
                </div>
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                {{flight.terminal}}
              </td>
              <td class="text-center text-dark">
                <div class="font-small">
                  <div class="d-flex">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view arrival - data touch down@@sch.table.standartView.arrival.data.touchDown">
                        touch down
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.touchDownOrTakeOffDatetime | date: 'HH:mm'}}</div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view arrival - data block on@@sch.table.standartView.arrival.data.blockOn">
                        block on
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.blockOnOrBockOffDatetime | date: 'HH:mm'}}</div>
                  </div>
                </div>
              </td>
              <td class="text-center text-dark">
                <div class="font-small">
                  {{flight.baggageClaimStart | date: 'HH:mm'}}
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table standart view arrival - data baggage to@@sch.table.standartView.arrival.data.baggageTo">
                     to 
                    </span>&nbsp;{{flight.baggageClaimFinish | date: 'HH:mm'}}
                  <br>
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table standart view arrival - data belt@@sch.table.standartView.arrival.data.belt">
                    belt 
                  </span>&nbsp;<span *ngIf="flight.belts && flight.belts!=0">{{flight.belts}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 mp-2 pr-0 pl-1">
        <table class="table table-borderless table-hover">
          <thead>
            <tr class="text-muted detail-head table__mail-header--subhead">
              <th class="text-center text-uppercase"
                  i18n="sch|Table standart view departure - column daparture@@sch.table.standartView.departure.column.departure">
                DEPARTURE
              </th>
              <th class="text-center text-uppercase"
                  i18n="sch|Table standart view departure - column status@@sch.table.standartView.departure.column.status">
                STATUS
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view departure - column desatination@@sch.table.standartView.departure.column.destination">
                DST
              </th>
              <th class="text-center font-small">
                <span
                      i18n="sch|Table standart view departure - column departure time/day@@sch.table.standartView.departure.column.departureTimeDay.departure">Departure</span><br><span
                      i18n="sch|Table standart view departure - column departure time/day@@sch.table.standartView.departure.column.departureTimeDay.timeDay">Time
                  / Day</span>
              </th>
              <th class="text-center font-small">
                <span
                      i18n="sch|Table standart view departure - column aircraft/tail@@sch.table.standartView.departure.column.aircraftTail.aircraft">Aircraft</span><br><span
                      i18n="sch|Table standart view departure - column aircraft/tail@@sch.table.standartView.departure.column.aircraftTail.tail">Tail</span>
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view departure - column terminal@@sch.table.standartView.departure.column.terminal">
                T
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view departure - column registration@@sch.table.standartView.departure.column.registration">
                Registration
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view departure - column boarding@@sch.table.standartView.departure.column.boarding">
                Boarging
              </th>
              <th class="text-center font-small"
                  i18n="sch|Table standart view departure - column sending@@sch.table.standartView.departure.column.sending">
                Sending
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let flight of flights_departure" class="bg-white" [ngClass]="{'flight-list__row--actual-flight': flight.statusActual == 1
                ,'flight-list__row--expected-flight': flight.statusExpected == 1
                ,'flight-list__row--complated-flight': flight.status == 'Arrived'}" data-toggle="modal"
              data-target=".bd-example-modal-xl" (click)="loadFlight(flight.id)">
              <td class="text-center text-dark font-weight-bold">
                <p class="m-0 column-ico font-medium text-center">
                  <span class="icons">
                    <i class="material-icons text-gray-blue-dark md-18">flight_takeoff</i>
                  </span>
                  {{flight.flight}}
                </p>
                <p class="m-0 column-ico font-medium text-center">
                  <span class="icons">
                    <i class="material-icons text-gray-blue-dark md-18">local_parking</i>
                  </span>
                  {{flight.stand}}
                </p>
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                {{flight.statusName}}
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                <span class="font-weight-bold">{{flight.route[0].iata}}</span>
                <span class="font-small d-block">{{flight.route[0].name}}</span>
                <!--<span class="font-small d-block">{{getAirportNameFromIata(flight.route[0])}}</span>-->
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                <div class="font-small">
                  <div class="d-flex">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view departure - data scd@@sch.table.standartView.departure.data.scd">
                        scd
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.scheduleDatetime | date: 'HH:mm'}} /
                      {{flight.scheduleDatetime | date: 'dd'}}</div>
                  </div>
                  <div class="d-flex flex-row bd-highlight mb-0">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view departure - data est@@sch.table.standartView.departure.data.est">
                        est
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.estimatedDatetime | date: 'HH:mm'}} /
                      {{flight.estimatedDatetime | date: 'dd'}}</div>
                  </div>
                </div>
              </td>
              <td class="text-center text-dark body-border__right--gray-normal">
                <span class="font-weight-normal text-gray-blue-dark"
                      i18n="sch|Table standart view departure - data aircraft@@sch.table.standartView.departure.data.aircraft">
                  aircraft 
                </span>&nbsp;{{flight.aircraftType}}
                <br>
                <span class="font-weight-normal text-gray-blue-dark"
                      i18n="sch|Table standart view departure - data tail@@sch.table.standartView.departure.data.tail">
                  tail 
                </span>&nbsp;{{flight.tail}}
              </td>
              <td class="text-center body-border__right--gray-normal">
                {{flight.terminal}}
              </td>
              <td class="text-center text-dark">
                <div class="font-small">
                  {{flight.checkinStart | date: 'HH:mm'}}
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table standart view departure - data reg to@@sch.table.standartView.departure.data.regTo">
                     to 
                  </span>&nbsp;{{flight.checkinFinish | date: 'HH:mm'}}
                  <br>
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table standart view departure - data checkin@@sch.table.standartView.departure.data.checkin">
                    checkin 
                  </span>&nbsp;<span *ngIf="flight.counters && flight.counters!=0">{{flight.counters}}</span>
                </div>
              </td>
              <td class="text-center text-dark">
                <div class="font-small">
                  {{flight.boardingStart | date: 'HH:mm'}}
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table standart view departure - data boarding to@@sch.table.standartView.departure.data.boardingTo">
                     to 
                  </span>&nbsp;{{flight.boardingFinish | date: 'HH:mm'}}
                  <br>
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table standart view departure - data gate@@sch.table.standartView.departure.data.gate">
                    gate 
                  </span>&nbsp;<span *ngIf="flight.gates && flight.gates!=0">{{flight.gates}}</span>
                </div>
              </td>
              <td class="text-center text-dark">
                <div class="font-small">
                  <div class="d-flex">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view departure - data block off@@sch.table.standartView.departure.data.blockOff">
                        block off
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.blockOnOrBockOffDatetime | date: 'HH:mm'}}</div>
                  </div>
                  <div class="d-flex flex-row bd-highlight mb-0">
                    <div class="flex-fill text-left">
                      <span class="font-weight-normal text-gray-blue-dark"
                            i18n="sch|Table standart view departure - data take off@@sch.table.standartView.departure.data.takeOff">
                        take off
                      </span>
                    </div>
                    <div class="flex-fill text-right">{{flight.touchDownOrTakeOffDatetime | date: 'HH:mm'}}</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Combinate table flight -->
<div class="shedule-one-tables-wrap" *ngIf="displayCombined">
  <div class="shedule-one-tables">
    <table class="table table-borderless table-hover">
      <thead>
        <tr class="text-muted detail-head table__mail-header--subhead font-small">
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column flight arrival@@sch.table.combineView.column.flightArrival">
            Flight
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column status arrival@@sch.table.combineView.column.statusArrival">
            Status
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column destination arrival@@sch.table.combineView.column.destinationArrival">
            DST
          </th>
          <th class="text-center text-uppercase">
            <span
                  i18n="sch|Table combined view - column aircraft/tail@@sch.table.combineView.column.aircraftTail.aircraft">Aircraft</span><br><span
                  i18n="sch|Table combined view - column aircraft/tail@@sch.table.combineView.column.aircraftTail.tail">Tail</span>
          </th>
          <th class="text-center text-uppercase">
            <span
                  i18n="sch|Table combined view - column arrival time/day@@sch.table.combineView.column.arrivalTimeDay.arrival">Arrival</span><br><span
                  i18n="sch|Table combined view - column arrival time/day@@sch.table.combineView.column.arrivalTimeDay.timeDay">Time
              / Day</span>
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column terminal arrival@@sch.table.combineView.column.terminalArrival">
            T
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column landing@@sch.table.combineView.column.landing">
            Landing
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column process@@sch.table.combineView.column.process">
            Process
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column loading@@sch.table.combineView.column.loading">
            Loading
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column flight departure@@sch.table.combineView.column.flightDeparture">
            Flight
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column statusDeparture@@sch.table.combineView.column.statusDeparture">
            Status
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column destination departure@@sch.table.combineView.column.destinationDearture">
            DST
          </th>
          <th class="text-center text-uppercase">
            <span
                  i18n="sch|Table combined view - column departure time/day@@sch.table.combineView.column.departureTimeDay.departure">Departure</span><br><span
                  i18n="sch|Table combined view - column departure time/day@@sch.table.combineView.column.departureTimeDay.timeDay">Time / Day</span>
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column terminal departure@@sch.table.combineView.column.terminalDeparture">
            T
          </th>
          <th class="text-center text-uppercase"
              i18n="sch|Table combined view - column sending@@sch.table.combineView.column.sending">
            Sending
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let flight of flights_combined" class="bg-white"
            data-toggle="modal"
            data-target=".bd-example-modal-xl"
            [ngClass]="{'flight-list__row--actual-flight': flight.status == ''
            ,'flight-list__row--expected-flight': flight.status == 1
            ,'flight-list__row--complated-flight': flight.status == 'Arrived'}">
          <td class="text-left text-dark font-weight-bold"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <p class="m-0 column-ico font-medium text-center">
              <span class="icons">
                <i class="material-icons text-gray-blue-dark md-18">flight_land</i>
              </span>
              {{flight.flightA}}
            </p>
            <p class="m-0 column-ico font-medium text-center">
              <span class="icons">
                <i class="material-icons text-gray-blue-dark md-18">local_parking</i>
              </span>
              {{flight.standA}}
            </p>
          </td>
          <td class="text-center body-border__right--gray-normal"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            {{flight.statusAName}}
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <span class="font-weight-bold" *ngIf="flight.routeA">{{flight.routeA[0].iata}}</span>
            <span class="font-small d-block" *ngIf="flight.routeA">{{flight.routeA[0].name}}</span>
            <!--<span class="font-small d-block" *ngIf="flight.routeA">{{getAirportNameFromIata(flight.routeA[0])}}</span>-->
          </td>
          <td class="text-center text-dark body-border__right--gray-normal"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <span class="font-weight-normal text-gray-blue-dark"
                  i18n="sch|Table combined view - data aircraft arrival@@sch.table.combineView.arrival.data.aircraft">
              aircraft 
            </span>&nbsp;{{flight.aircraftType}}
            <br>
            <span class="font-weight-normal text-gray-blue-dark"
                  i18n="sch|Table combined view - data tail arrival@@sch.table.combineView.arrival.data.tail">
              tail 
            </span>&nbsp;{{flight.tail}}
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <div class="font-small" *ngIf="flight.timesA">
              <div class="d-flex">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data schedule arrival@@sch.table.combineView.arrival.data.schedule">
                    scd 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.scheduleDateTimeArrival | date: 'HH:mm'}} /
                  {{flight.scheduleDateTimeArrival | date: 'dd'}}</div>
              </div>
              <div class="d-flex flex-row bd-highlight mb-0">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data estimated arrival@@sch.table.combineView.arrival.data.estimated">
                    est 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.estimatedDateTimeArrival | date: 'HH:mm'}} /
                  {{flight.estimatedDateTimeArrival | date: 'dd'}}</div>
              </div>
            </div>
          </td>
          <td class="text-center text-dark body-border__right--gray-normal"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <!-- TODO нужен ли терминал для прилетного в комбинированном режиме -->
            <!-- {{flight.terminal}} -->
            A
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <div class="font-small">
              <div class="d-flex">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data touch down arrival@@sch.table.combineView.arrival.data.touchDown">
                    touch down 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.touchDownDateTime | date: 'HH:mm'}}</div>
              </div>
              <div class="d-flex flex-row bd-highlight mb-0">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data block on arrival@@sch.table.combineView.arrival.data.blockOn">
                    block on 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.blockOnDateTime | date: 'HH:mm'}}</div>
              </div>
            </div>
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <div class="process-line">
              <div class="process-line-item"></div>
              <div class="process-line-item"></div>
              <div class="process-line-item"></div>
              <div class="process-line-item"></div>
            </div>
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idA ? flight.idA : flight.idD, flight.idA ? 'arrival' : '')">
            <div class="loading-line">
              <div><i class="material-icons text-gray-blue-dark">person</i></div>
              <div><i class="material-icons text-gray-blue-dark">child_care</i></div>
              <div><i class="material-icons text-gray-blue-dark">child_friendly</i></div>
              <div><i class="material-icons text-gray-blue-dark">work</i></div>
            </div>
          </td>
          <td class="text-center font-weight-bold text-dark"
              (click)="loadFlight(flight.idD!=0 ? flight.idD : flight.idA, flight.idD ? '' : 'arrival')">
            <p class="m-0 column-ico font-medium text-center">
              <span class="icons">
                <i class="material-icons text-gray-blue-dark md-18">flight_takeoff</i>
              </span>
              {{flight.flightD}}
            </p>
            <p class="m-0 column-ico font-medium text-center">
              <span class="icons">
                <i class="material-icons text-gray-blue-dark md-18">local_parking</i>
              </span>
              {{flight.standD}}
            </p>
          </td>
          <td class="text-center text-dark body-border__right--gray-normal"
              (click)="loadFlight(flight.idD!=0 ? flight.idD : flight.idA, flight.idD ? '' : 'arrival')">
            {{flight.statusDName}}
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idD!=0 ? flight.idD : flight.idA, flight.idD ? '' : 'arrival')">
            <span class="font-weight-bold" *ngIf="flight.routeD">{{flight.routeD[0].iata}}</span>
            <span class="font-small d-block" *ngIf="flight.routeD">{{flight.routeD[0].name}}</span>
            <!--<span class="font-small d-block" *ngIf="flight.routeD">{{getAirportNameFromIata(flight.routeD[0])}}</span>-->
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idD!=0 ? flight.idD : flight.idA, flight.idD ? '' : 'arrival')">
            <div class="font-small" *ngIf="flight.timesD">
              <div class="d-flex">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data schedule departure@@sch.table.combineView.departure.data.schedule">
                    scd 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.scheduleDateTimeDeparture | date: 'HH:mm'}} /
                  {{flight.scheduleDateTimeDeparture | date: 'dd'}}</div>
              </div>
              <div class="d-flex flex-row bd-highlight mb-0">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data estimated departure@@sch.table.combineView.departure.data.estimated">
                    est 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.estimatedDateTimeDeparture | date: 'HH:mm'}} /
                  {{flight.estimatedDateTimeDeparture | date: 'dd'}}</div>
              </div>
            </div>
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idD!=0 ? flight.idD : flight.idA, flight.idD ? '' : 'arrival')">
            <!-- TODO нужен ли терминал для вылетного в комбинированном режиме -->
            <!-- {{flight.terminalD}} -->
            D
          </td>
          <td class="text-center text-dark"
              (click)="loadFlight(flight.idD!=0 ? flight.idD : flight.idA, flight.idD ? '' : 'arrival')">
            <div class="font-small">
              <div class="d-flex">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data block off departure@@sch.table.combineView.departure.data.blockOff">
                    block off 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.blockOffDateTime | date: 'HH:mm'}}</div>
              </div>
              <div class="d-flex flex-row bd-highlight mb-0">
                <div class="flex-fill text-left">
                  <span class="font-weight-normal text-gray-blue-dark"
                        i18n="sch|Table combined view - data take off departure@@sch.table.combineView.departure.data.takeOff">
                    take off 
                  </span>
                </div>
                <div class="flex-fill text-right">{{flight.takeOffDateTime | date: 'HH:mm'}}</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Modal -->
<div class="modal fade bd-example-modal-xl mw-100" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl load--max-width">
    <div class="modal-content bg-light-gray">
      <form #flightForm="ngForm">
        <div class="loading" *ngIf="loading">
          <div class="cssload-spin-box"></div>
        </div>
        <div class="modal-header">
          <h5 class="modal-title"
              id="exampleModalScrollableTitle">
            <span i18n="sch|Flight card - header name@@sch.flightCard.header.name">Flight card</span>&nbsp;{{flight.flight}}
            <ng-container *ngIf="flight.geoTypeA; else geoTypeD">{{flight.geoTypeA}}</ng-container>
            <ng-template #geoTypeD>{{flight.geoTypeD}}</ng-template>
          </h5>
          <button #flightModalForm type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-nav pl-3 pr-3 pb-1">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a id="flight-card-tab-route" 
                 [ngClass]="{'active': showFlightCardTab('flight-card-tab-route')}"
                 class="nav-link active" 
                 (click)="changeFlightCardTab($event)"
                 i18n="sch|Flight card tabs - route@@sch.flightCard.tabs.route">
                Route
              </a>
            </li>
            <li class="nav-item">
              <a id="flight-card-tab-information"
                 [ngClass]="{'active': showFlightCardTab('flight-card-tab-information')}"
                 class="nav-link"
                 (click)="changeFlightCardTab($event)"
                 i18n="sch|Flight card tabs - information@@sch.flightCard.tabs.information">
                Information
              </a>
            </li>
            <li class="nav-item">
              <a id="flight-card-tab-loading-arrival"
                 [ngClass]="{'active': showFlightCardTab('flight-card-tab-loading-arrival')}"
                 class="nav-link"
                 (click)="changeFlightCardTab($event)"
                 i18n="sch|Flight card tabs - payload arrival@@sch.flightCard.tabs.payloadArrival">
                Payload Arrival
              </a>
            </li>
            <li class="nav-item">
              <a id="flight-card-tab-loading-departure"
                 [ngClass]="{'active': showFlightCardTab('flight-card-tab-loading-departure')}"
                 class="nav-link"
                 (click)="changeFlightCardTab($event)"
                 i18n="sch|Flight card tabs - payload departure@@sch.flightCard.tabs.payloadDeparture">
                Payload Departure
              </a>
            </li>
            <li class="nav-item">
              <a id="flight-card-tab-telegrams"
                 [ngClass]="{'active': showFlightCardTab('flight-card-tab-telegrams')}"
                 class="nav-link"
                 (click)="changeFlightCardTab($event)"
                 i18n="sch|Flight card tabs - telegrams@@sch.flightCard.tabs.telegrams">
                Telegrams
              </a>
            </li>
            <li class="nav-item">
              <a id="flight-card-tab-additional-info"
                 [ngClass]="{'active': showFlightCardTab('flight-card-tab-additional-info')}"
                 class="nav-link"
                 (click)="changeFlightCardTab($event)"
                 i18n="sch|Flight card tabs - additional info@@sch.flightCard.tabs.additionalInfo">
                Additional info
              </a>
            </li>
            <li class="nav-item" *ngIf="displayCombined" class="flight-type-selection">
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn font-small"
                       [ngClass]="{'active bg-blue text-white': selectNextFlight,
                                    'bg-white border': flight.nextFlightId && !selectNextFlight,
                                    'bg-light-gray border': !flight.nextFlightId && !selectNextFlight}">
                  <input type="radio"
                         name="options"
                         id="arrival"
                         [checked]="selectNextFlight"
                         [disabled]="!flight.nextFlightId"
                         i18n="sch|Flight card type flight - arrival@@sch.flightCard.typeflight.arrival"
                         (click)="loadFlight(flight.nextFlightId, 'arrival')">
                    Arrival
                </label>
                <label class="btn font-small"
                       [ngClass]="{'active bg-blue text-white': !selectNextFlight,
                                    'bg-white border': flight.nextFlightId && selectNextFlight,
                                    'bg-light-gray border': !flight.nextFlightId && !selectNextFlight}">
                  <input type="radio"
                         name="options"
                         id="departure"
                         [checked]="selectNextFlight"
                         [disabled]="!flight.nextFlightId"
                         i18n="sch|Flight card type flight - departure@@sch.flightCard.typeflight.departure"
                         (click)="loadFlight(flight.nextFlightId, '')">
                    Departure
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-body">
<!--
 ██████  █████  ██████  ██████      ███    ███ ███████ ███    ██ ██    ██
██      ██   ██ ██   ██ ██   ██     ████  ████ ██      ████   ██ ██    ██
██      ███████ ██████  ██   ██     ██ ████ ██ █████   ██ ██  ██ ██    ██
██      ██   ██ ██   ██ ██   ██     ██  ██  ██ ██      ██  ██ ██ ██    ██
 ██████ ██   ██ ██   ██ ██████      ██      ██ ███████ ██   ████  ██████
-->
          <!-- Tab route -->
          <div class="routes" *ngIf="showFlightCardTab('flight-card-tab-route')">
            <div class="row mb-2">
              <div class="pr-2" [ngClass]="{'col-3': selectNextFlight, 'col-4': !selectNextFlight}">
                <div class="card bg-none">
                  <div class="card-body p-3 bg-white rounded">
                    <p class="m-0 h6 card__header--important">
                      <i class="material-icons">directions</i>
                      <span i18n="sch|Flight card tab route - group movement name@@sch.flightCard.tab.route.groupMovement.name">Movement</span>
                    </p>
                    <div class="row">
                      <div class="col">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="sch|Flight card tab route - group movement type@@sch.flightCard.tab.route.groupMovement.type">Type</span><span class="required-asterisk">*</span>
                        </label>
                        <!-- Movement Type -->
                        <div class="input-group p-0">
                          <ng-select class="form-control custom-sm p-0"
                            [items]="referanceMovements"
                            bindLabel="name"
                            bindValue="id"
                            [(ngModel)]="flight.movementTypeId"
                            name="movementType"
                            #movementType="ngModel"
                            [virtualScroll]="true"
                            [loading]="referanceMovementsLoading"
                            (scroll)="onScrollReferanceMovements($event)"
                            (scrollToEnd)="onScrollReferanceMovementsToEnd()"
                            required>
                            <ng-template ng-option-tmp let-item="item">
                              {{item.code}} / {{item.name}}
                            </ng-template>
                          </ng-select>
                          <div *ngIf="!movementType?.valid && (movementType?.dirty || movementType?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="movementType.errors.required"
                                 i18n="sch|Flight card tab route - group movement type req@@sch.flightCard.tab.route.groupMovement.type.req">
                              Movement type is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-0" [ngClass]="{'col-7 pr-2': selectNextFlight, 'col-8': !selectNextFlight}">
                <div class="card bg-none">
                  <div class="card-body p-3 bg-white rounded">
                    <p class="m-0 h6 card__header--important">
                      <i class="material-icons">apartment</i>
                      <span i18n="sch|Flight card tab route - group airline name@@sch.flightCard.tab.route.groupAirline.name">Airline</span>
                    </p>
                    <div class="row">
                      <div class="col-2 pr-1">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="sch|Flight card tab route - group airline iata@@sch.flightCard.tab.route.groupAirline.iata">IATA</span><span class="required-asterisk">*</span>
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                          [items]="referanceAirlines"
                          bindLabel="iata"
                          bindValue="id"
                          [(ngModel)]="flight.airlineId"
                          #airlineIata="ngModel"
                          name="airlineIata"
                          [virtualScroll]="true"
                          [loading]="referanceAirlinesLoading"
                          (scroll)="onScrollReferanceAirlines($event)"
                          (scrollToEnd)="onScrollReferanceAirlinesToEnd()"
                          required>
                        </ng-select>
                        <div *ngIf="!airlineIata?.valid && (airlineIata?.dirty || airlineIata?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="airlineIata.errors.required"
                               i18n="sch|Flight card tab route - group airline iata req@@sch.flightCard.tab.route.groupAirline.iata.req">
                            IATA is required
                          </div>
                        </div>
                      </div>
                      <div class="col-2 pl-0 pr-1">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="sch|Flight card tab route - group airline icao@@sch.flightCard.tab.route.groupAirline.icao">ICAO</span><span class="required-asterisk">*</span>
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                          [items]="referanceAirlines"
                          bindLabel="icao"
                          bindValue="id"
                          [(ngModel)]="flight.airlineId"
                          name="airlineIcao"
                          #airlineIcao="ngModel"
                          [virtualScroll]="true"
                          [loading]="referanceAirlinesLoading"
                          (scroll)="onScrollReferanceAirlines($event)"
                          (scrollToEnd)="onScrollReferanceAirlinesToEnd()"
                          required>
                        </ng-select>
                        <div *ngIf="!airlineIcao?.valid && (airlineIcao?.dirty || airlineIcao?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="airlineIcao.errors.required"
                               i18n="sch|Flight card tab route - group airline icao req@@sch.flightCard.tab.route.groupAirline.icao.req">
                            ICAO is required
                          </div>
                        </div>
                      </div>
                      <div class="col-5 pl-0 pr-1">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="sch|Flight card tab route - group airline name@@sch.flightCard.tab.route.groupAirline.nameAirline">Name</span><span class="required-asterisk">*</span>
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                          [items]="referanceAirlines"
                          bindLabel="name"
                          bindValue="id"
                          [(ngModel)]="flight.airlineId"
                          name="airlineName"
                          #airlineName="ngModel"
                          [virtualScroll]="true"
                          [loading]="referanceAirlinesLoading"
                          (scroll)="onScrollReferanceAirlines($event)"
                          (scrollToEnd)="onScrollReferanceAirlinesToEnd()"
                          required>
                        </ng-select>
                        <div *ngIf="!airlineName?.valid && (airlineName?.dirty || airlineName?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="airlineName.errors.required"
                               i18n="sch|Flight card tab route - group airline name req@@sch.flightCard.tab.route.groupAirline.name.req">
                            Name is required
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pl-0">
                        <label class="col-form-label col-form-label-sm">
                          <span i18n="sch|Flight card tab route - group airline flight@@sch.flightCard.tab.route.groupAirline.flight">Flight</span><span class="required-asterisk">*</span>
                        </label>
                        <input type="text"
                               class="form-control form-control-sm"
                               name="flightinput"
                               [(ngModel)]="flight.flight"
                               #flightinput="ngModel"
                               pattern="^[/д//Д/a-zA-Z0-9]{0,}$"
                          maxlength="5" required>
                        <div *ngIf="!flightinput?.valid && (flightinput?.dirty || flightinput?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="flightinput.errors.required"
                               i18n="sch|Flight card tab route - group airline flight req@@sch.flightCard.tab.route.groupAirline.flight.req">
                            Flight code is required
                          </div>
                          <div *ngIf="flightinput.errors.pattern"
                               i18n="sch|Flight card tab route - group airline flight en only@@sch.flightCard.tab.route.groupAirline.flight.enOnlyNumber">
                            Flight code is English only and number
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0" *ngIf="selectNextFlight">
                <div class="card bg-none">
                  <div class="card-body p-3 bg-white rounded">
                    <p class="m-0 h6 card__header--important">
                      <i class="material-icons">flight_takeoff</i>
                      <span i18n="sch|Flight card tab route - next flight@@sch.flightCard.tab.route.nextFlight">Next Flight</span>
                    </p>
                    <div class="row">
                      <div class="col">
                        <label class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group departure name@@sch.flightCard.tab.route.nextFlight.field.departure">
                          Departure
                        </label>
                        <div class="input-group p-0">
                          <ng-select class="form-control custom-sm p-0"
                            [items]="flights_departure"
                            bindLabel="flight"
                            bindValue="id"
                            name="nextFlight"
                            id="nextFlight"
                            [(ngModel)]="flight.nextFlightId">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 2 -->
            <div class="row">
              <div class="col-6 pr-2">
                <div class="card bg-none">
                  <div class="card-body p-3 bg-white rounded">
                    <p class="m-0 h6 card__header--important">
                      <i class="material-icons">flight_land</i>
                      <span i18n="sch|Flight card tab route - group arrival name@@sch.flightCard.tab.route.groupArrival.name">Arrival</span>
                    </p>
                    <div class="row">
                      <div class="col-6 pr-1">
                        <label for="arrivalType"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group arrival type@@sch.flightCard.tab.route.groupArrival.type">
                          Type
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceAircraftTypes"
                                   bindLabel="names"
                                   bindValue="id"
                                   name="aircraftTypeIdA"
                                   id="aircraftTypeIdA"
                                   [(ngModel)]="flight.aircraftTypeIdA"
                                   (change)="selectAircraft($event, 'A', true)"
                                   [virtualScroll]="true"
                                   [loading]="referanceAircraftTypesLoading"
                                   (scroll)="onScrollReferanceAircraftTypes($event)"
                                   (scrollToEnd)="onScrollReferanceAircraftTypesToEnd()"
                                   [required]="typeFlight=='A' || typeFlight=='AD'">
                        </ng-select>
                      </div>
                      <div class="col-3 pl-0 pr-1">
                        <label for="arrivalTail"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group arrival tail@@sch.flightCard.tab.route.groupArrival.tail">
                          Tail
                        </label>
                        <ng-select *ngIf="flight.aircraftTypeIdA" class="form-control custom-sm p-0"
                                   [items]="referanceTailsA"
                                   bindLabel="tail"
                                   bindValue="id"
                                   name="tailAId"
                                   id="tailAId"
                                   [(ngModel)]="flight.tailAId"
                                   [virtualScroll]="true">
                        </ng-select>
                        <ng-select *ngIf="!flight.aircraftTypeIdA" class="form-control custom-sm p-0"
                                   [items]="referanceTails"
                                   bindLabel="tail"
                                   bindValue="id"
                                   name="tailAId"
                                   id="tailAId"
                                   [(ngModel)]="flight.tailAId"
                                   [virtualScroll]="true"
                                   [loading]="referanceTailsLoading"
                                   (scroll)="onScrollReferanceTails($event)"
                                   (scrollToEnd)="onScrollReferanceTailsToEnd()">
                        </ng-select>
                      </div>
                      <div class="col-3 pl-0">
                        <label for="arrivalTail"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group arrival geo type@@sch.flightCard.tab.route.groupArrival.geoType">
                               Geo Type
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceGeoTypes"
                                   [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                                   bindValue="id"
                                   name="geoTypeIdA"
                                   id="geoTypeIdA"
                                   [(ngModel)]="flight.geoTypeIdA">
                          <ng-template ng-option-tmp let-item="item">
                            <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
                          </ng-template>
                        </ng-select>
                      </div>
                      <div class="col-3 pr-1">
                        <label for="arrivalStand"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group arrival stand@@sch.flightCard.tab.route.groupArrival.stand">
                          Stand
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceStands"
                                   bindLabel="name"
                                   bindValue="id"
                                   name="arrivalStand"
                                   id="arrivalStand"
                                   [(ngModel)]="flight.standAId">
                        </ng-select>
                      </div>
                      <div class="col-3 pl-0 pr-1">
                        <label for="arrivalTail"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group arrival terminal@@sch.flightCard.tab.route.groupArrival.terminal">
                            Terminal
                          </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceTerminals"
                                   bindLabel="name"
                                   bindValue="id"
                                   name="terminalAId"
                                   [(ngModel)]="flight.terminalAId">
                        </ng-select>
                      </div>
                      <div class="col-6 pl-0">
                        <label for="blockOn"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group departure blockOn@@sch.flightCard.tab.route.groupDeparture.blockOn">
                          Block on
                        </label>
                        <div class="input-group input-group-sm">
                          <input type="time"
                                 class="form-control mr-1"
                                 value="{{flight.blockOn | date: 'HH:mm'}}"
                                 (blur)="flight.blockOn = parseTime(blockOnDate, $event.target.value)"
                                 name="blockOnTime"
                                 #blockOnTime>
                          <input type="date"
                                 class="form-control"
                                 value="{{flight.blockOn | date: 'yyyy-MM-dd'}}"
                                 (blur)="flight.blockOn = parseDate($event.target.value, blockOnTime.value)"
                                 name="blockOnDate"
                                 #blockOnDate>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 pl-0">
                <div class="card bg-none">
                  <div class="card-body p-3 bg-white rounded">
                    <p class="m-0 h6 card__header--important">
                      <i class="material-icons">flight_takeoff</i>
                      <span i18n="sch|Flight card tab route - group departure name@@sch.flightCard.tab.route.groupDeparture.name">Departure</span>
                    </p>
                    <div class="row">
                      <div class="col-6 pr-1">
                        <label for="departureType"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group departure type@@sch.flightCard.tab.route.groupDeparture.type">
                          Type
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceAircraftTypes"
                                   bindLabel="names"
                                   bindValue="id"
                                   name="aircraftTypeIdD"
                                   id="aircraftTypeIdD"
                                   [(ngModel)]="flight.aircraftTypeIdD"
                                   (change)="selectAircraft($event, 'D', true)"
                                   [virtualScroll]="true"
                                   [loading]="referanceAircraftTypesLoading"
                                   (scroll)="onScrollReferanceAircraftTypes($event)"
                                   (scrollToEnd)="onScrollReferanceAircraftTypesToEnd()"
                                   [required]="typeFlight=='D' || typeFlight=='AD'">
                        </ng-select>
                      </div>
                      <div class="col-3 pl-0 pr-1">
                        <label for="departureTail"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group departure tail@@sch.flightCard.tab.route.groupDeparture.tail">
                          Tail
                        </label>
                        <ng-select *ngIf="flight.aircraftTypeIdD" class="form-control custom-sm p-0"
                                   [items]="referanceTailsD"
                                   bindLabel="tail"
                                   bindValue="id"
                                   name="tailDId"
                                   id="tailDId"
                                   [(ngModel)]="flight.tailDId"
                                   [virtualScroll]="true">
                        </ng-select>
                        <ng-select *ngIf="!flight.aircraftTypeIdD" class="form-control custom-sm p-0"
                                   [items]="referanceTails"
                                   bindLabel="tail"
                                   bindValue="id"
                                   name="tailDId"
                                   id="tailDId"
                                   [(ngModel)]="flight.tailDId"
                                   [virtualScroll]="true"
                                   [loading]="referanceTailsLoading"
                                   (scroll)="onScrollReferanceTails($event)"
                                   (scrollToEnd)="onScrollReferanceTailsToEnd()">
                        </ng-select>
                      </div>
                      <div class="col-3 pl-0">
                        <label for="arrivalTail"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group arrival geo type@@sch.flightCard.tab.route.groupArrival.geoType">
                               Geo Type
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceGeoTypes"
                                   [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                                   bindValue="id"
                                   name="geoTypeIdD"
                                   id="geoTypeIdD"
                                   [(ngModel)]="flight.geoTypeIdD">
                          <ng-template ng-option-tmp let-item="item">
                            <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
                          </ng-template>
                        </ng-select>
                      </div>
                      <div class="col-3 pr-1">
                        <label for="departureStand"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group departure stand@@sch.flightCard.tab.route.groupDeparture.stand">
                          Stand
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceStands"
                                   bindLabel="name"
                                   bindValue="id"
                                   name="departureStand"
                                   id="departureStand"
                                   [(ngModel)]="flight.standDId">
                        </ng-select>
                      </div>
                      <div class="col-3 pl-0 pr-1">
                        <label for="arrivalTail"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group departure terminal@@sch.flightCard.tab.route.groupDeparture.terminal">
                          Terminal
                        </label>
                        <ng-select class="form-control custom-sm p-0"
                                   [items]="referanceTerminals"
                                   bindLabel="name"
                                   bindValue="id"
                                   name="terminalDId"
                                   [(ngModel)]="flight.terminalDId">
                        </ng-select>
                      </div>
                      <div class="col-6 pl-0">
                        <label for="blockOff"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab route - group departure blockOff@@sch.flightCard.tab.route.groupDeparture.blockOff">
                          Block off
                        </label>
                        <div class="input-group input-group-sm">
                          <input type="time"
                                 class="form-control form-control-sm mr-1"
                                 value="{{flight.blockOff | date: 'HH:mm'}}"
                                 (blur)="flight.blockOff = parseTime(blockOffDate, $event.target.value)"
                                 name="blockOffTime"
                                 #blockOffTime>
                          <input type="date"
                                 class="form-control form-control-sm"
                                 value="{{flight.blockOff | date: 'yyyy-MM-dd'}}"
                                 (blur)="flight.blockOff = parseDate($event.target.value, blockOffTime.value)"
                                 name="blockOffDate"
                                 #blockOffDate>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-1 p-0 float-right">
              <button type="button"
                      class="btn btn-sm btn-blue mr-1"
                      (click)="addRoutePoint()">
                <i class="material-icons">add_circle_outline</i>
                <span i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.button.addRoutePoint">
                  Add route point
                </span>
              </button>
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="deleteRoute()"
                      [disabled]="deleteQueue.size==0">
                <i class="material-icons">delete</i>
                <span i18n="sch|Flight card tab route - button delete route point@@sch.flightCard.tab.route.button.deleteRoutePoint">
                  Delete
                </span>
              </button>
            </div>
            <table class="table table-borderless table-hover mt-3">
              <thead>
                <tr class="text-light type-head table__main-header">
                  <th scope="col"
                      colspan="4"
                      class="text-center font-large head-border-right-bold"
                      style="border-bottom-left-radius: 0;"
                      i18n="sch|Flight card tab route - route table column arrival@@sch.flightCard.tab.route.table.column.arrival">
                    Arrival
                  </th>
                  <th scope="col"
                      class="text-center font-large align-middle head-border-right-bold"
                      i18n="sch|Flight card tab route - route table column destination@@sch.flightCard.tab.route.table.column.destination">
                    Destination
                  </th>
                  <th scope="col"
                      colspan="4"
                      class="text-center font-large"
                      style="border-bottom-right-radius: 0;"
                      i18n="sch|Flight card tab route - route table column departure@@sch.flightCard.tab.route.table.column.departure">
                    Departure
                  </th>
                </tr>
                <tr class="text-muted detail-head table__mail-header--subhead">
                  <th class="font-small text-center text-uppercase head-border-top" style="border-top-left-radius: 0;">
                  </th>
                  <th class="font-small text-center text-uppercase head-border-top">
                    <span i18n="sch|Flight card tab route - route table column schedule time arrival@@sch.flightCard.tab.route.table.column.scheduleTimeArrival">STA</span><span class="required-asterisk">*</span></th>
                  <th class="font-small text-center text-uppercase head-border-top"
                      i18n="sch|Flight card tab route - route table column estimated time arrival@@sch.flightCard.tab.route.table.column.estimatedTimeArrival">
                    ETA
                  </th>
                  <th class="font-small text-center text-uppercase head-border-top head-border-right-bold"
                      i18n="sch|Flight card tab route - route table column actual time arrival@@sch.flightCard.tab.route.table.column.actualTimeArrival">
                    ATA
                  </th>
                  <th class="font-small text-center text-uppercase head-border-top head-border-right-bold"
                      i18n="sch|Flight card tab route - route table column city\airport@@sch.flightCard.tab.route.table.column.cityAirport">
                    City\Airport
                  </th>
                  <th class="font-small text-center text-uppercase head-border-top">
                    <span i18n="sch|Flight card tab route - route table column schedule time departure@@sch.flightCard.tab.route.table.column.scheduleTimeDeparture">STD</span><span class="required-asterisk">*</span></th>
                  <th class="font-small text-center text-uppercase head-border-top"
                      i18n="sch|Flight card tab route - route table column estimated time departure@@sch.flightCard.tab.route.table.column.estimatedTimeDeparture">
                    ETD
                  </th>
                  <th class="font-small text-center text-uppercase head-border-top"
                      i18n="sch|Flight card tab route - route table column actual time departure@@sch.flightCard.tab.route.table.column.actualTimeDeparture">
                    ATD
                  </th>
                  <th class="font-small text-center text-uppercase head-border-top"
                      style="border-top-right-radius: 0;"
                      i18n="sch|Flight card tab route - route table column delay@@sch.flightCard.tab.route.table.column.delay">
                    Delay
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let route of flight.route; let i = index" class="route-date">
                  <td class="text-center">
                    <div class="input-group-xs">
                      <input type="checkbox" name="checkboxRoute" value="{{i}}" (click)="addToDeleteQueue(i)"
                        aria-label="Checkbox for following text input">
                    </div>
                  </td>
                  <td class="text-center body-border__right--gray-normal">
                    <div class="input-group-sm">
                      <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalScheduled | date: 'HH:mm'"
                        (input)="route.dtArrivalScheduled = parseTime(Arrival_date_0, $event.target.value)"
                        name="dtArrivalScheduledTime{{i}}" #Arrival_time_0 [disabled]="i===0"
                        #dtArrivalScheduledTime{{i}}="ngModel" [required]="i!==0">
                      <input type="date" class="form-control my-1"
                        [ngModel]="route.dtArrivalScheduled | date: 'yyyy-MM-dd'"
                        (input)="route.dtArrivalScheduled = parseDate($event.target.value, Arrival_time_0.value)"
                        name="dtArrivalScheduledDate{{i}}" #Arrival_date_0 [disabled]="i===0"
                        #dtArrivalScheduledDate{{i}}="ngModel" [required]="i!==0">
                    </div>
                  </td>
                  <td class="text-center body-border__right--gray-normal">
                    <div class="input-group-sm">
                      <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalEstimated | date: 'HH:mm'"
                        (input)="route.dtArrivalEstimated = parseTime(Arrival_date_1, $event.target.value)"
                        name="dtArrivalEstimatedTime{{i}}" #Arrival_time_1 [disabled]="i===0">
                      <input type="date" class="form-control my-1"
                        [ngModel]="route.dtArrivalEstimated | date: 'yyyy-MM-dd'"
                        (input)="route.dtArrivalEstimated = parseDate($event.target.value, Arrival_time_1.value)"
                        name="dtArrivalEstimatedDate{{i}}" #Arrival_date_1 [disabled]="i===0">
                    </div>
                  </td>
                  <td class="text-center body-border__right--gray-normal">
                    <div class="input-group-sm">
                      <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalFact | date: 'HH:mm'"
                        (input)="route.dtArrivalFact = parseTime(Arrival_date_2, $event.target.value)"
                        name="dtArrivalFactTime{{i}}" #Arrival_time_2 [disabled]="i===0">
                      <input type="date" class="form-control my-1" [ngModel]="route.dtArrivalFact | date: 'yyyy-MM-dd'"
                        (input)="route.dtArrivalFact = parseDate($event.target.value, Arrival_time_2.value)"
                        name="dtArrivalFactDate{{i}}" #Arrival_date_2 [disabled]="i===0">
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="">
                      <ng-select class="form-control airports-long p-0"
                                 [items]="referanceAirports"
                                 bindLabel="iata"
                                 bindValue="id"
                                 [(ngModel)]="route.airportId"
                                 #airlineId{{i}}="ngModel"
                                 name="airlineId{{i}}"
                                 [virtualScroll]="true"
                                 [loading]="referanceAirportsLoading"
                                 (change)="checkTypeFlight()"
                                 (scroll)="onScrollReferanceAirports($event)"
                                 (scrollToEnd)="onScrollReferanceAirportsToEnd()"
                                 [searchFn]="customSelectSearchAirports"
                                 appendTo="body"
                                 required>
                        <ng-template ng-option-tmp let-item="item">
                          <div class="font-small">
                            <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.names}}
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  </td>
                  <td class="text-center body-border__right--gray-normal">
                    <div class="input-group-sm">
                      <input type="time" class="form-control my-1"
                        [ngModel]="route.dtDepartureScheduled | date: 'HH:mm'"
                        (input)="route.dtDepartureScheduled = parseTime(Departure_date_0, $event.target.value)"
                        name="dtDepartureScheduledTime{{i}}" #Departure_time_0 [disabled]="i===flight.route.length-1"
                        #dtDepartureScheduledTime{{i}}="ngModel" [required]="i!==flight.route.length-1">
                      <input type="date" class="form-control my-1"
                        [ngModel]="route.dtDepartureScheduled | date: 'yyyy-MM-dd'"
                        (input)="route.dtDepartureScheduled = parseDate($event.target.value, Departure_time_0.value)"
                        name="dtDepartureScheduledDate{{i}}" #Departure_date_0 [disabled]="i===flight.route.length-1"
                        #dtDepartureScheduledDate{{i}}="ngModel" [required]="i!==flight.route.length-1">
                    </div>
                  </td>
                  <td class="text-center body-border__right--gray-normal">
                    <div class="input-group-sm">
                      <input type="time" class="form-control my-1"
                        [ngModel]="route.dtDepartureEstimated | date: 'HH:mm'"
                        (input)="route.dtDepartureEstimated = parseTime(Departure_date_1, $event.target.value)"
                        name="dtDepartureEstimatedTime{{i}}" #Departure_time_1 [disabled]="i===flight.route.length-1">
                      <input type="date" class="form-control my-1"
                        [ngModel]="route.dtDepartureEstimated | date: 'yyyy-MM-dd'"
                        (input)="route.dtDepartureEstimated = parseDate($event.target.value, Departure_time_1.value)"
                        name="dtDepartureEstimatedDate{{i}}" #Departure_date_1 [disabled]="i===flight.route.length-1">
                    </div>
                  </td>
                  <td class="text-center body-border__right--gray-normal">
                    <div class="input-group-sm">
                      <input type="time" class="form-control my-1" [ngModel]="route.dtDepartureFact | date: 'HH:mm'"
                        (input)="route.dtDepartureFact = parseTime(Departure_date_2, $event.target.value)"
                        name="dtDepartureFactTime{{i}}" #Departure_time_2 [disabled]="i===flight.route.length-1">
                      <input type="date" class="form-control my-1"
                        [ngModel]="route.dtDepartureFact | date: 'yyyy-MM-dd'"
                        (input)="route.dtDepartureFact = parseDate($event.target.value, Departure_time_2.value)"
                        name="dtDepartureFactDate{{i}}" #Departure_date_2 [disabled]="i===flight.route.length-1">
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="input-group-sm">
                      <ng-select class="form-control custom-sm p-0"
                        [items]="referanceDelays"
                        bindLabel="iataChar"
                        bindValue="id"
                        name="dtDelay_1_{{i}}"
                        id="dtDelay_1_{{i}}"
                        [(ngModel)]="route.delayId"
                        [disabled]="i===flight.route.length-1">
                      </ng-select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Tab information-->
          <div *ngIf="showFlightCardTab('flight-card-tab-information')">
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input mb-2"
                         name="flighShow"
                         type="checkbox"
                         id="informationShowOnDisplay"
                         [(ngModel)]="flight.show">
                  <label class="form-check-label"
                         for="informationShowOnDisplay"
                         i18n="sch|Flight card tab information - field show on display@@sch.flightCard.tab.information.field.showOnDisplay">
                    Show on display
                  </label>
                </div>
              </div>
            </div>
            <hr>
            <div class="row py-2">
              <div class="col-6 pr-0">
                <div class="card bg-none">
                  <div class="card-body p-3 bg-white rounded">
                    <p class="m-0 h6 card__header--important">
                      <i class="material-icons">flight_land</i>
                      <span i18n="sch|Flight card tab information - group arrival name@@sch.flightCard.tab.information.groupArrival.name">Arrival</span>
                    </p>
                    <div class="row">
                      <div class="col-6 pr-1">
                        <div class="">
                          <label for="inputGroupSelect01"
                                 class="col-form-label col-form-label-sm font-weight-bold"
                                 i18n="sch|Flight card tab information - group arrival status@@sch.flightCard.tab.information.groupArrival.status">
                            Status
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="referanceStatusesArrival"
                            [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="arrivalStatus"
                            id="arrivalStatus"
                            [(ngModel)]="flight.processes.arrival.statusId">
                            <ng-template ng-option-tmp let-item="item">
                              {{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <hr class="my-2">
                    <div class="row">
                      <div class="col-6 pr-1">
                        <div class="">
                          <label for="inputGroupSelect02"
                                 class="col-form-label col-form-label-sm font-weight-bold"
                                 i18n="sch|Flight card tab information - group arrival baggage@@sch.flightCard.tab.information.groupArrival.baggage">
                            Baggage
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="referanceStatusesBaggage"
                            [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="arrivalBaggage"
                            id="arrivalBaggage"
                            [(ngModel)]="flight.processes.baggage.statusId">
                            <ng-template ng-option-tmp let-item="item">
                              {{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6 pl-0">
                        <label for="arrivalBelt"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group arrival belt@@sch.flightCard.tab.information.groupArrival.belt">
                          Belt
                        </label>
                        <input id="arrivalBelt"
                               type="number"
                               class="form-control form-control-sm"
                               name="arrivalBelt"
                               [(ngModel)]="flight.processes.baggage.resources[0].num"
                               placeholder="Number"
                               i18n-placeholder="sch|Flight card tab information - group arrival caorusel number@@sch.flightCard.tab.information.groupArrival.caoruselNumber">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 pr-1">
                        <label for="arrivalBaggageStartTime"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group arrival baggage start@@sch.flightCard.tab.information.groupArrival.baggageStart">
                          Start
                        </label>
                        <input type="time"
                               class="form-control my-1"
                               [ngModel]="flight.processes.baggage.dtStart | date: 'HH:mm'"
                               (blur)="flight.processes.baggage.dtStart = parseTime(arrivalBaggageStartDate, $event.target.value)"
                               name="arrivalBaggageStartTime"
                               #arrivalBaggageStartTime="ngModel">
                        <input type="date"
                               class="form-control my-1"
                               [ngModel]="flight.processes.baggage.dtStart | date: 'yyyy-MM-dd'"
                               (blur)="flight.processes.baggage.dtStart = parseDate($event.target.value, arrivalBaggageStartTime.value)"
                               name="arrivalBaggageStartDate"
                               #arrivalBaggageStartDate="ngModel">
                      </div>
                      <div class="col-6 pl-0">
                        <label for="arrivalBaggageaggageFinishTime"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group arrival baggage finish@@sch.flightCard.tab.information.groupArrival.baggageFinish">
                          Finish
                        </label>
                        <input type="time"
                               class="form-control my-1"
                               [ngModel]="flight.processes.baggage.dtFinish | date: 'HH:mm'"
                               (blur)="flight.processes.baggage.dtFinish = parseTime(arrivalBaggageFinishDate, $event.target.value)"
                               name="arrivalBaggageFinishTime"
                               #arrivalBaggageFinishTime="ngModel">
                        <input type="date"
                               class="form-control my-1"
                               [ngModel]="flight.processes.baggage.dtFinish | date: 'yyyy-MM-dd'"
                               (blur)="flight.processes.baggage.dtFinish = parseDate($event.target.value, arrivalBaggageFinishTime.value)"
                               name="arrivalBaggageFinishDate"
                               #arrivalBaggageFinishDate="ngModel">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 pl-2">
                <div class="card bg-none">
                  <div class="card-body p-3 bg-white rounded">
                    <p class="m-0 h6 card__header--important">
                      <i class="material-icons">flight_takeoff</i>
                      <span i18n="sch|Flight card tab information - group departure name@@sch.flightCard.tab.information.groupDeparture.name">Departure</span>
                    </p>
                    <div class="row">
                      <div class="col-6 pr-1">
                        <div class="">
                          <label for="departureCheckIn"
                                 class="col-form-label col-form-label-sm font-weight-bold"
                                 i18n="sch|Flight card tab information - group departure check in@@sch.flightCard.tab.information.groupDeparture.checkIn">
                            Check in
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="referanceStatusesCheckin"
                            [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="departureCheckIn"
                            id="departureCheckIn"
                            [(ngModel)]="flight.processes.checkin.statusId">
                            <ng-template ng-option-tmp let-item="item">
                              {{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6 pl-0">
                        <label for="departureCounters"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group departure counters@@sch.flightCard.tab.information.groupDeparture.counters">
                          Counters
                        </label>
                        <div class="input-group input-group-sm" style="height: 31px;">
                          <div class="border py-1 pl-3 input-group-text form-control-sm w-75 h-100">
                            <ng-container *ngFor="let item of flight.processes.checkin.resources; let i_num = index">
                              {{item.num}}
                            <ng-container *ngIf="flight.processes.checkin.resources.length-1 != i_num">,</ng-container>
                            </ng-container>
                          </div>
                          <div class="input-group-append w-25">
                            <button class="btn btn-xs btn-blue w-100 h-100"
                                    type="button"
                                    (click)="showModalCounters=!showModalCounters"
                                    i18n="sch|Flight card tab information - group departure button choose@@sch.flightCard.tab.information.groupDeparture.button.choose">
                              Choose
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 pr-1">
                        <label for="departureCheckInStartTime"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group departure check in start@@sch.flightCard.tab.information.groupDeparture.checkInStart">
                          Start
                        </label>
                        <input type="time"
                               class="form-control my-1"
                               [ngModel]="flight.processes.checkin.dtStart | date: 'HH:mm'"
                               (blur)="flight.processes.checkin.dtStart = parseTime(arrivalCheckinStartDate, $event.target.value)"
                               name="arrivalCheckinStartTime"
                               #arrivalCheckinStartTime="ngModel">
                        <input type="date"
                               class="form-control my-1"
                               [ngModel]="flight.processes.checkin.dtStart | date: 'yyyy-MM-dd'"
                               (blur)="flight.processes.checkin.dtStart = parseDate($event.target.value, arrivalCheckinStartTime.value)"
                               name="arrivalCheckinStartDate"
                               #arrivalCheckinStartDate="ngModel">
                      </div>
                      <div class="col-6 pl-0">
                        <label for="departureCheckInFinishTime"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group departure check in finish@@sch.flightCard.tab.information.groupDeparture.checkInFinish">
                          Finish
                        </label>
                        <input type="time"
                               class="form-control my-1"
                               [ngModel]="flight.processes.checkin.dtFinish | date: 'HH:mm'"
                               (blur)="flight.processes.checkin.dtFinish = parseTime(arrivalCheckinFinishDate, $event.target.value)"
                               name="arrivalCheckinFinishTime"
                               #arrivalCheckinFinishTime="ngModel">
                        <input type="date"
                               class="form-control my-1"
                               [ngModel]="flight.processes.checkin.dtFinish | date: 'yyyy-MM-dd'"
                               (blur)="flight.processes.checkin.dtFinish = parseDate($event.target.value, arrivalCheckinFinishTime.value)"
                               name="arrivalCheckinFinishDate"
                               #arrivalCheckinFinishDate="ngModel">
                      </div>
                    </div>
                    <hr class="my-2">
                    <div class="row">
                      <div class="col-6 pr-1">
                        <div class="">
                          <label for="departureBoarding"
                                 class="col-form-label col-form-label-sm font-weight-bold"
                                 i18n="sch|Flight card tab information - group departure boarding@@sch.flightCard.tab.information.groupDeparture.boarding">
                            Boarding
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="referanceStatusesBoarding"
                            [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="departureBoarding"
                            id="departureBoarding"
                            [(ngModel)]="flight.processes.boarding.statusId">
                            <ng-template ng-option-tmp let-item="item">
                              {{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6 pl-0">
                        <label for="departureGate"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group departure gate@@sch.flightCard.tab.information.groupDeparture.gate">
                          Gate
                        </label>
                        <input id="departureGate"
                               type="number"
                               class="form-control form-control-sm"
                               name="departureGate"
                               [(ngModel)]="flight.processes.boarding.resources[0].num"
                               placeholder="Number"
                               i18n-placeholder="sch|Flight card tab information - group departure gate number@@sch.flightCard.tab.information.groupDeparture.gateNumber">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 pr-1">
                        <label for="departureGateStartTime"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group departure boarding start@@sch.flightCard.tab.information.groupDeparture.boardingStart">
                          Start
                        </label>
                        <input type="time"
                               class="form-control my-1"
                               [ngModel]="flight.processes.boarding.dtStart | date: 'HH:mm'"
                               (blur)="flight.processes.boarding.dtStart = parseTime(arrivalBoardingStartDate, $event.target.value)"
                               name="arrivalBoardingStartTime"
                               #arrivalBoardingStartTime="ngModel">
                        <input type="date"
                               class="form-control my-1"
                               [ngModel]="flight.processes.boarding.dtStart | date: 'yyyy-MM-dd'"
                               (blur)="flight.processes.boarding.dtStart = parseDate($event.target.value, arrivalBoardingStartTime.value)"
                               name="arrivalBoardingStartDate"
                               #arrivalBoardingStartDate="ngModel">
                      </div>
                      <div class="col-6 pl-0">
                        <label for="departureGateFinishTime"
                               class="col-form-label col-form-label-sm"
                               i18n="sch|Flight card tab information - group departure boarding finish@@sch.flightCard.tab.information.groupDeparture.boardingFinish">
                          Finish
                        </label>
                        <input type="time"
                               class="form-control my-1"
                               [ngModel]="flight.processes.boarding.dtFinish | date: 'HH:mm'"
                               (blur)="flight.processes.boarding.dtFinish = parseTime(arrivalBoardingFinishDate, $event.target.value)"
                               name="arrivalBoardingFinishTime"
                               #arrivalBoardingFinishTime="ngModel">
                        <input type="date"
                               class="form-control my-1"
                               [ngModel]="flight.processes.boarding.dtFinish | date: 'yyyy-MM-dd'"
                               (blur)="flight.processes.boarding.dtFinish = parseDate($event.target.value, arrivalBoardingFinishTime.value)"
                               name="arrivalBoardingFinishDate"
                               #arrivalBoardingFinishDate="ngModel">
                      </div>
                    </div>
                    <hr class="my-2">
                    <div class="row">
                      <div class="col-6">
                        <div class="">
                          <label for="tst1"
                                 class="col-form-label col-form-label-sm font-weight-bold"
                                 i18n="sch|Flight card tab information - group departure status@@sch.flightCard.tab.information.groupDeparture.departure">
                            Departure
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="referanceStatusesDeparture"
                            [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="departure"
                            id="departure"
                            [(ngModel)]="flight.processes.departure.statusId">
                            <ng-template ng-option-tmp let-item="item">
                              {{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="showFlightCardTab('flight-card-tab-loading-arrival')">
            <div>
              <div>
                <div class="card mb-2">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="input-group-sm col-2 pr-1">
                        <label class="col-form-label col-form-label-sm">
                          <span
                                i18n="sch|Flight card tab loading - loading departure lmg@@sch.flightCard.tab.loading.departure.lmg">LMG</span>&nbsp;<span
                                class="prompt"
                                placement="right"
                                ngbTooltip="{{messages['lmgPrompt']}}">?</span>
                        </label>
                        <input type="number"
                              class="form-control my-1"
                              name="Departurelmg"
                              placeholder=""
                              [(ngModel)]="flight.lmg[1]">
                      </div>
                      <div class="col-1 pr-1">
                        <label class="col-form-label col-form-label-sm">
                          &nbsp;
                        </label>
                        <div class="form-check pt-2">
                          <input class="form-check-input"
                                 type="checkbox"
                                 id="weaponArrivial"
                                 name="weaponArrivial"
                                 [(ngModel)]="flight.weapon[0]"
                                 [checked]="flight.weapon[0]">
                          <label class="form-check-label"
                                 for="weaponArrivial"
                                 i18n="sch|Flight card tab loading - arrival weapon@@sch.flightCard.tab.loading.weapon">
                            Weapon
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table class="table table-borderless table-hover">
                  <thead>
                    <tr class="text-light type-head table__main-header">
                      <th scope="col"
                          colspan=""
                          class="head-border-right-bold font-large text-center"
                          style="border-bottom-left-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading column destination@@sch.flightCard.tab.loading.table.loading.column.destination">
                        Destination
                      </th>
                      <th scope="col"
                          colspan="6"
                          class="head-border-right-bold font-large text-center"
                          i18n="sch|Flight card tab loading - table loading column passangers@@sch.flightCard.tab.loading.table.loading.column.passangers">
                        Passengers
                      </th>
                      <th scope="col"
                          colspan="3"
                          class="head-border-right-bold font-large text-center"
                          i18n="sch|Flight card tab loading - table loading column luggage@@sch.flightCard.tab.loading.table.loading.column.luggage">
                        Luggage
                      </th>
                      <th scope="col"
                          colspan="0"
                          class="head-border-right-bold font-large text-center"
                          i18n="sch|Flight card tab loading - table loading column cargo@@sch.flightCard.tab.loading.table.loading.column.cargo">
                        Cargo
                      </th>
                      <th scope="col"
                          class="font-large text-center"
                          style="border-bottom-right-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading column mail@@sch.flightCard.tab.loading.table.loading.column.mail">
                        Mail
                      </th>
                    </tr>
                    <tr class="text-muted detail-head table__mail-header--subhead head-border-top">
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                          style="border-top-left-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading column city/airport@@sch.flightCard.tab.loading.table.loading.column.cityAirport">
                        CITY\AIRPORT
                      </th>
                      <th colspan="2"
                          class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading column adult@@sch.flightCard.tab.loading.table.loading.column.adult">
                        Adl
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading column child@@sch.flightCard.tab.loading.table.loading.column.child">
                        Chd
                      </th>
                      <th class="text-center text-uppercase head-font-smalll"
                          i18n="sch|Flight card tab loading - table loading column infant@@sch.flightCard.tab.loading.table.loading.column.infant">
                        Inf
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading column busines@@sch.flightCard.tab.loading.table.loading.column.busines">
                        Business
                      </th>
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                          i18n="sch|Flight card tab loading - table loading column economy@@sch.flightCard.tab.loading.table.loading.column.economy">
                        Economy
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading column all@@sch.flightCard.tab.loading.table.loading.column.all">
                        All, kg
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading column paid@@sch.flightCard.tab.loading.table.loading.column.paid">
                        Paid, kg
                      </th>
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                          i18n="sch|Flight card tab loading - table loading column units@@sch.flightCard.tab.loading.table.loading.column.units">
                        Amount
                      </th>
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                          i18n="sch|Flight card tab loading - table loading column weight\kg@@sch.flightCard.tab.loading.table.loading.column.weightKg">
                        Weight, kg
                      </th>
                      <th class="text-center text-uppercase font-small"
                          style="border-top-right-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading column weight\kg2@@sch.flightCard.tab.loading.table.loading.column.weightKg2">
                        Weight, kg
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let route of flight.route; let i = index">
                      <td class="text-center tbody-border-right-bold"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__airport" *ngIf="route.airportId">
                          {{getById(referanceAirports, route.airportId).iata}} /
                          {{getById(referanceAirports, route.airportId).name[1]}}
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="">
                            <label class="col-form-label col-form-label-sm mr-2">
                              &nbsp;<span i18n="sch|Flight card tab loading - table loading column transit@@sch.flightCard.tab.loading.table.loading.column.transit">TR</span>
                            </label>
                          </div>
                          <div class="">
                            <label class="col-form-label col-form-label-sm mr-2">
                              &nbsp;<span i18n="sch|Flight card tab loading - table loading column transfer@@sch.flightCard.tab.loading.table.loading.column.transfer">TF</span>
                            </label>
                          </div>
                          <div class="">
                            <label class="col-form-label col-form-label-sm mr-2">
                              &nbsp;<span i18n="sch|Flight card tab loading - table loading column supply@@sch.flightCard.tab.loading.table.loading.column.supply">SP</span>
                            </label>
                          </div>
                          <div class="">
                            <label class="col-form-label col-form-label-sm mr-2"
                                   i18n="sch|Flight card tab loading - table loading column self@@sch.flightCard.tab.loading.table.loading.column.self">
                              SLF
                            </label>
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialAdult0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialAdult1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialAdult2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialAdult3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialChild0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialChild1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialChild2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialChild3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialInfant0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialInfant1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialInfant2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialInfant3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialBusiness0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialBusiness1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialBusiness2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialBusiness3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialEconomy0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialEconomy1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialEconomy2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialEconomy3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageWeight0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageWeight[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageWeight1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageWeight[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageWeight2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageWeight[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageWeight3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageWeight[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggagePayed0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggagePayed1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggagePayed2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggagePayed3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageUnit0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageUnit1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageUnit2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageUnit3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageCargo0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageCargo1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageCargo2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageCargo3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal"
                        *ngIf="route.airportId!=1 && flight.isArrivial(route.airportId)">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageMail0" 
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageMail1" 
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageMail2" 
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="ArrivialeBaggageMail3" 
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[3]">
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div *ngIf="showFlightCardTab('flight-card-tab-loading-departure')">
            <div>
              <div>
                <div class="row">
                  <div class="col-5 mb-2 pr-2">
                    <div class="card">
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="input-group-sm col-3 pr-1">
                            <label for="tst1"
                                   class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure fuel@@sch.flightCard.tab.loading.departure.fuel">
                              Fuel
                            </label>
                            <input type="number"
                                   class="form-control my-1"
                                   name="DepartureFuel"
                                   placeholder=""
                                   [(ngModel)]="flight.fuel[0]">
                          </div>
                          <div class="input-group-sm col-3 pl-0 pr-1">
                            <label for="tst1"
                                   class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure taxi@@sch.flightCard.tab.loading.departure.taxi">
                              Taxi
                            </label>
                            <input type="number"
                                   class="form-control my-1"
                                   name="DepartureTaxi"
                                   placeholder=""
                                   [(ngModel)]="flight.fuel[1]">
                          </div>
                          <div class="input-group-sm col-3 pl-0 pr-1">
                            <label for="tst1"
                                   class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure mtow@@sch.flightCard.tab.loading.departure.mtow">
                              MTOW
                            </label>
                            <input type="text"
                                   class="form-control my-1"
                                   name="DepartureMtow"
                                   placeholder=""
                                   value={{showMTOWForDepartureFlight}}
                                   disabled>
                          </div>
                          <div class="input-group-sm col-3 pl-0">
                            <label for="tst1"
                                   class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure atow@@sch.flightCard.tab.loading.departure.atow">
                              ATOW
                            </label>
                            <input type="number"
                                   class="form-control my-1"
                                   name="DepartureAtow"
                                   placeholder=""
                                   [(ngModel)]="flight.atow">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-7 pl-0">
                    <div class="card">
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="input-group-sm col pr-1">
                            <label class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure captain@@sch.flightCard.tab.loading.departure.captain">
                              Captain
                            </label>
                            <input type="text"
                                   class="form-control my-1"
                                   name="departureCaptain"
                                   placeholder=""
                                   [(ngModel)]="flight.captain">
                          </div>
                          <div class="input-group-sm col pl-0 pr-1">
                            <label class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure pilots@@sch.flightCard.tab.loading.departure.pilots">
                              Pilots
                            </label>
                            <input type="number"
                                   class="form-control my-1"
                                   name="DeparturePilots"
                                   placeholder=""
                                   [(ngModel)]="flight.crew[0]">
                          </div>
                          <div class="input-group-sm col-auto pl-0 pr-1">
                            <label class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure air steward@@sch.flightCard.tab.loading.departure.airSteward">
                              Air steward
                            </label>
                            <input type="number"
                                   class="form-control my-1"
                                   name="DepartureSteward"
                                   placeholder=""
                              [(ngModel)]="flight.crew[1]">
                          </div>
                          <div class="col input-group-sm col pl-0 pr-1">
                            <label class="col-form-label col-form-label-sm"
                                   i18n="sch|Flight card tab loading - loading departure catering@@sch.flightCard.tab.loading.departure.catering">
                              Catering
                            </label>
                            <input type="number"
                                   class="form-control my-1"
                                   name="DepartureCatering"
                                   [(ngModel)]="flight.cateringWeight"
                                   placeholder="">
                          </div>
                          <div class="input-group-sm col pl-0 pr-1">
                            <label class="col-form-label col-form-label-sm">
                              <span
                                    i18n="sch|Flight card tab loading - loading departure lmg@@sch.flightCard.tab.loading.departure.lmg">Lmg</span>&nbsp;<span
                                    class="prompt"
                                    placement="right"
                                    ngbTooltip="{{messages['lmg_prompt']}}">?</span>
                            </label>
                            <input type="number"
                                   class="form-control my-1"
                                   name="Departurelmg"
                                   placeholder=""
                                   [(ngModel)]="flight.lmg[0]">
                          </div>
                          <div class="col-auto pl-0">
                            <label class="col-form-label col-form-label-sm">
                              &nbsp;
                            </label>
                            <div class="form-check pt-2">
                              <input class="form-check-input"
                                     type="checkbox"
                                     id="departureWeapon"
                                     name="departureWeapon"
                                     [(ngModel)]="flight.weapon[1]"
                                     [checked]="flight.weapon[1]">
                              <label class="form-check-label"
                                     for="departureWeapon"
                                     i18n="sch|Flight card tab loading - loading departure weapon@@sch.flightCard.tab.loading.departure.weapon">
                                Weapon
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table class="table table-borderless table-hover">
                  <thead>
                    <tr class="text-light type-head table__main-header">
                      <th scope="col"
                          colspan=""
                          class="head-border-right-bold font-large text-center"
                          style="border-bottom-left-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading dep column destination@@sch.flightCard.tab.loading.table.loadingDeparture.column.destination">
                        Destination
                      </th>
                      <th scope="col"
                          colspan="6"
                          class="head-border-right-bold font-large text-center"
                          i18n="sch|Flight card tab loading - table loading dep column passengers@@sch.flightCard.tab.loading.table.loading.loadingDeparture.passengers">
                        Passengers
                      </th>
                      <th scope="col"
                          colspan="3"
                          class="head-border-right-bold font-large text-center"
                          i18n="sch|Flight card tab loading - table loading dep column leggage@@sch.flightCard.tab.loading.table.loading.loadingDeparture.luggage">
                        Luggage
                      </th>
                      <th scope="col"
                          colspan="0"
                          class="head-border-right-bold font-large text-center"
                          i18n="sch|Flight card tab loading - table loading dep column cargo@@sch.flightCard.tab.loading.table.loading.loadingDeparture.cargo">
                        Cargo
                      </th>
                      <th scope="col"
                          class="font-large text-center"
                          style="border-bottom-right-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading dep column mail@@sch.flightCard.tab.loading.table.loading.loadingDeparture.mail">
                        Mail
                      </th>
                    </tr>
                    <tr class="text-muted detail-head table__mail-header--subhead head-border-top">
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                        style="border-top-left-radius: 0;"
                        i18n="sch|Flight card tab loading - table loading dep column city\airport@@sch.flightCard.tab.loading.table.loading.loadingDeparture.cityAirport">
                        CITY\AIRPORT
                      </th>

                      <th colspan="2"
                          class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading dep column adult@@sch.flightCard.tab.loading.table.loading.loadingDeparture.adult">
                        Adl
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading dep column child@@sch.flightCard.tab.loading.table.loading.loadingDeparture.child">
                        Chd
                      </th>
                      <th class="text-center text-uppercase head-font-smalll"
                          i18n="sch|Flight card tab loading - table loading dep column infant@@sch.flightCard.tab.loading.table.loading.loadingDeparture.infant">
                        Inf
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading dep column business@@sch.flightCard.tab.loading.table.loading.loadingDeparture.business">
                        Business
                      </th>
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                          i18n="sch|Flight card tab loading - table loading dep column economy@@sch.flightCard.tab.loading.table.loading.loadingDeparture.economy">
                        Economy
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading dep column all@@sch.flightCard.tab.loading.table.loading.loadingDeparture.all">
                        All, kg
                      </th>
                      <th class="text-center text-uppercase font-small"
                          i18n="sch|Flight card tab loading - table loading dep column paid@@sch.flightCard.tab.loading.table.loading.loadingDeparture.paid">
                        Paid, kg
                      </th>
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                          i18n="sch|Flight card tab loading - table loading dep column units@@sch.flightCard.tab.loading.table.loading.loadingDeparture.units">
                        Amount
                      </th>
                      <th class="text-center text-uppercase font-small head-border-right-bold"
                          i18n="sch|Flight card tab loading - table loading dep column weight kg@@sch.flightCard.tab.loading.table.loading.loadingDeparture.weightKg">
                        Weight/kg
                      </th>
                      <th class="text-center text-uppercase font-small"
                          style="border-top-right-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading dep column weight kg 2@@sch.flightCard.tab.loading.table.loading.loadingDeparture.weightKg2">
                        Weight/kg
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let route of flight.route; let i = index">
                    <tr *ngIf="(route.airportId==1 || flight.isDeparture(route.airportId)) && i!=flight.route.length-1">
                      <td class="text-center tbody-border-right-bold">
                        <div class="input-group-sm load__airport" *ngIf="route.airportId">
                          {{getById(referanceAirports, route.airportId).iata}} /
                          {{getById(referanceAirports, route.airportId).name[1]}}
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="">
                            <label for=""
                                   class="col-form-label col-form-label-sm mr-2">
                              &nbsp;<span i18n="sch|Flight card tab loading - table loading dep column transit@@sch.flightCard.tab.loading.table.loading.loadingDeparture.transit">TR</span>
                            </label>
                          </div>
                          <div class="">
                            <label for=""
                                   class="col-form-label col-form-label-sm mr-2">
                              &nbsp;<span i18n="sch|Flight card tab loading - table loading dep column transfer@@sch.flightCard.tab.loading.table.loading.loadingDeparture.transfer">TF</span>
                            </label>
                          </div>
                          <div class="">
                            <label for=""
                                   class="col-form-label col-form-label-sm mr-2">
                              &nbsp;<span i18n="sch|Flight card tab loading - table loading dep column supply@@sch.flightCard.tab.loading.table.loading.loadingDeparture.supply">SP</span>
                            </label>
                          </div>
                          <div class="">
                            <label for=""
                                   class="col-form-label col-form-label-sm mr-2"
                                   i18n="sch|Flight card tab loading - table loading dep column selft@@sch.flightCard.tab.loading.table.loading.loadingDeparture.self">
                              SLF
                            </label>
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureAdult0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureAdult1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureAdult2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureAdult3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.adult[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureChild0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureChild1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureChild2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureChild3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.child[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureInfant0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureInfant1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureInfant2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureInfant3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.infant[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBusiness0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBusiness1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBusiness2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBusiness3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.business[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureEconomy0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureEconomy1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureEconomy2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureEconomy3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.economy[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                              name="DepartureBaggageWeight0"
                              class="form-control"
                              placeholder=""
                              [(ngModel)]="route.baggageWeight[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                              name="DepartureBaggageWeight1"
                              class="form-control"
                              placeholder=""
                              [(ngModel)]="route.baggageWeight[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                              name="DepartureBaggageWeight2"
                              class="form-control"
                              placeholder=""
                              [(ngModel)]="route.baggageWeight[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                              name="DepartureBaggageWeight3"
                              class="form-control"
                              placeholder=""
                              [(ngModel)]="route.baggageWeight[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggagePayed0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggagePayed1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggagePayed2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggagePayed3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggagePayed[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggageUnit0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggageUnit1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggageUnit2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureBaggageUnit3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.baggageUnit[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureCargo0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureCargo1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureCargo2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureCargo3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.cargo[3]">
                          </div>
                        </div>
                      </td>
                      <td class="text-center body-border__right--gray-normal">
                        <div class="input-group-sm load__poeple-count-l">
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureMail0"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[0]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureMail1"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[1]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureMail2"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[2]">
                          </div>
                          <div class="input-group-sm d-flex align-items-center my-1">
                            <input type="number"
                                   name="DepartureMail3"
                                   class="form-control"
                                   placeholder=""
                                   [(ngModel)]="route.mail[3]">
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Tab telegrams -->
          <div *ngIf="showFlightCardTab('flight-card-tab-telegrams')">
            <div class="card p-3 mb-3" *ngFor="let message of flight.messages">
              <div class="font-weight-bold mb-3">{{message.dtMessage | date: 'dd.MM.yyyy HH:mm'}} {{message.type}}
              </div>
              <div class="message-text-wrapping" [innerHTML]="message.message"></div>
            </div>
          </div>

          <!-- Tab Additional info -->
          <div *ngIf="showFlightCardTab('flight-card-tab-additional-info')">
            <div class="row">
              <div class="col input-group-sm col-3">
                <label class="col-form-label col-form-label-sm"
                       i18n="sch|Flight card tab additional info - source delay@@sch.flightCard.tab.additionalInfo.delay">
                  Source of delay
                </label>
                <ng-select class="form-control custom-sm p-0"
                          [items]="referanceDelaySources"
                          [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                          bindValue="id"
                          name="sourceDelay"
                          id="sourceDelay"
                          [(ngModel)]="flight.delaySourceId">
                  <ng-template ng-option-tmp let-item="item">
                    {{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="col input-group-sm col-3">
                <label class="col-form-label col-form-label-sm"
                       i18n="sch|Flight card tab additional info - service operator@@sch.flightCard.tab.additionalInfo.serviceOperator">
                  Service/operator
                </label>
                <ng-select class="form-control custom-sm p-0"
                          [items]="referanceDelaySourceOperators"
                          [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                          bindValue="id"
                          name="delay"
                          id="delay"
                          [(ngModel)]="flight.delaySourceOperatorId">
                  <ng-template ng-option-tmp let-item="item">
                    {{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col input-group-sm">
                <label class="col-form-label col-form-label-sm"
                       i18n="sch|Flight card tab additional info - note@@sch.flightCard.tab.additionalInfo.note">
                  Note
                </label>
                <textarea class="form-control w-100"
                          id="validationTextarea"
                          placeholder="Text message"
                          rows="5"
                          i18n-placeholder="sch|Flight card tab additional info - note text messagey@@sch.flightCard.tab.additionalInfo.noteTextMessage">
                </textarea>
              </div>
            </div>
          </div>

        </div>
        <!-- Кнопки карточки рейса -->
        <div class="modal-footer">
          <div *ngIf="showButton('state-append')">
            <button type="button"
                    class="btn btn-sm btn-blue"
                    (click)="beforeAddFlight()">
              <span i18n="sch|Flight card - button append@@sch.flightCard.button.append">Append</span>
            </button>
          </div>
          <div *ngIf="showButton('state-update')">
            <button type="button"
                    class="btn btn-sm btn-blue"
                    (click)="beforeUpdateFlight(flight.id)">
              <span i18n="sch|Flight card - button save@@sch.flightCard.button.save">Save</span>
            </button>
          </div>
          <button type="button"
                  class="btn btn-sm btn-gray-blue-dark"
                  data-dismiss="modal">
            <span i18n="sch|Flight card - button close@@sch.flightCard.button.close">Close</span>
          </button>
        </div>
        <!-- Модальное окно добавления/редатирования стоек регистрации -->
        <div *ngIf="showModalCounters" class="ms-modal-message">
          <div class="modal-content w-50">
            <div class="modal-header">
              <h5 class="modal-title"
                  i18n="sch|Flight card modal counters - header name@@sch.flightCard.modal.counters.header.name">
                Select counters
              </h5>
              <!-- <button type="button" class="close" (click)="showModalCounters=!showModalCounters" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
            </div>
            <div class="modal-body">
              <div class="card p-3">
                <table class="table table-hover w-100">
                  <thead>
                    <tr>
                      <th class="text-center"
                        i18n="sch|Flight card modal counters - field header check-in number@@sch.flightCard.modal.counters.header.CheckInNumber">
                        Check-in number
                      </th>
                      <th class="text-center"
                        i18n="sch|Flight card modal counters - field header branded@@sch.flightCard.modal.counters.header.branded">
                        Branded
                      </th>
                      <th class="text-center"
                        i18n="sch|Flight card modal counters - field header registration time@@sch.flightCard.modal.counters.header.registrationTime">
                        By registration time
                      </th>
                      <th class="text-center"
                        i18n="sch|Flight card modal counters - button add delet conter@@sch.flightCard.modal.counters.button.deletConter">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let counter of flight.processes.checkin.resources; let i = index" class="text-center">
                      <td>
                        <input type="number" class="form-control form-control-sm" name="num{{i}}" id="num{{i}}" [(ngModel)]="counter.num">
                      </td>
                      <td>
                        <input type="checkbox" name="priority{{i}}" id="priority{{i}}" [(ngModel)]="counter.priority" class="mr-2 mt-1">
                      </td>
                      <td>
                        <input type="checkbox" name="fullTime{{i}}" id="fullTime{{i}}" [(ngModel)]="counter.fullTime" class="mr-2 mt-1">
                      </td>
                      <td>
                        <button type="button" class="btn btn-outline-danger btn-sm m-auto" (click)="deleteCounter(i)">
                          <i class="material-icons m-0">clear</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-12 mt-2">
                    <button type="button" class="btn btn-sm btn-blue float-right" (click)="addCounter()">
                      <i class="material-icons m-0">add</i>
                      <span i18n="sch|Flight card modal counters - button add counter@@sch.flightCard.modal.counters.button.addCounter">Add counter</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer text-right bg-light-gray">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="showModalCounters=!showModalCounters">
                <span i18n="sch|Flight card modal counters - button save@@sch.flightCard.modal.counters.button.save">Save</span>
              </button>
            </div>
          </div>
        </div>
        <!-- Сообщение об ошибке -->
        <div *ngIf="errorMessage !== ''" class="ms-modal-message">
          <div class="modal-content w-75">
            <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
              <h5 *ngIf="errorType == 'info'"
                  class="modal-title"
                  i18n="sch|Modal error - header information@@sch.modal.errMes.header.inf">
                Information
              </h5>
              <h5 *ngIf="errorType == 'warning'"
                  class="modal-title"
                  i18n="sch|Modal error - header warning@@sch.modal.errMes.header.warn">
                Warning
              </h5>
              <h5 *ngIf="errorType == 'error'"
                  class="modal-title"
                  i18n="sch|Modal error - header error@@sch.modal.errMes.header.err">
                Error
              </h5>
              <button type="button"
                      class="close"
                      (click)="clearErrorMess()"
                      aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{errorMessage}}
            </div>
            <div class="modal-footer">
              <button type="button"
                      *ngIf="userAction == 'save_no_route'"
                      class="btn btn-sm btn-blue"
                      (click)="updateFlight(flight.id)"
                      i18n="sch|Modal error - button edit@@sch.modal.errMes.button.ok">
                OK
              </button>
              <button type="button"
                      *ngIf="userAction == 'add_no_route'"
                      class="btn btn-sm btn-blue"
                      (click)="addFlight()"
                      i18n="sch|Modal error - button edit@@sch.modal.errMes.button.ok">
                OK
              </button>
              <button type="button"
                      *ngIf="userAction != 'delete'"
                      class="btn btn-sm btn-blue"
                      (click)="clearErrorMess()"
                      i18n="sch|Modal error - button edit@@sch.modal.errMes.button.edit">
                Edit
              </button>
              <button type="button"
                      *ngIf="userAction != 'delete' && userAction != 'save_no_route' && userAction != 'add_no_route'"
                      class="btn btn-sm btn-secondary"
                      (click)="closeWithoutSaving()"
                      i18n="sch|Modal error - button close@@sch.modal.errMes.button.close">
                Close without saving
              </button>
              <button type="button"
                      *ngIf="userAction == 'delete'"
                      class="btn btn-sm btn-blue"
                      (click)="deleteElement(flight.processes.checkin.resources, indexDelete)"
                      i18n="sch|Modal error - button yes@@sch.modal.errMes.button.yes">
                Yes
              </button>
              <button type="button"
                      *ngIf="userAction == 'delete'"
                      class="btn btn-sm btn-secondary"
                      (click)="clearErrorMess()"
                      i18n="sch|Modal error - button no@@sch.modal.errMes.button.no">
                No
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>