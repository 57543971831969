export class FilterManualParams {
  constructor(statementType?: number) {
    const today = new Date().setDate(1); // new Date().setDate(new Date().getDate());
    this.start = new Date(today);
    this.finish = new Date();
    this.pnr = '';
    this.airport = '';
    this.airline = '';
    this.flight = '';
    this.surname = '';
    this.address = '';
    this.phone = '';
    this.route = [];
    this.tag = '';
    this.color = '';
    this.type = '';
    this.material = '';
    this.brand = '';
    this.externals = [];
    this.internals = [];
    this.weightFrom = null;
    this.weightTo = null;
    this.passtagSurname = '';
    this.passtagAddress = '';
    this.passtagPhone = '';
    this.passtagEmail = '';
    this.passtagNote = '';
    this.export = '';
    this.description = '';

    if (statementType) {
      switch (statementType) {
        case 1:
          this.statementTypes = [5, 4, 7];
          this.baggageStatuses = [1];
          break;
        case 5:
        case 7:
        case 8:
          this.statementTypes = [1];
          this.baggageStatuses = [1];
          break;
        default:
          this.statementTypes = [1, 5, 7, 8];
          break;
      }
    } else {
      this.statementTypes = [1, 5, 7, 8];
      this.baggageStatuses = [];
    }
  }
  start: Date | null;
  finish: Date | null;
  pnr: string;
  airport: string;
  airline: string;
  flight: string;
  surname: string;
  address: string;
  phone: string;
  route: Array<string>;
  tag: string;
  color: string;
  type: string;
  material: string;
  brand: string;
  externals: Array<string>;
  internals: Array<string>;
  weightFrom: number | null;
  weightTo: number | null;
  passtagSurname: string;
  passtagAddress: string;
  passtagPhone: string;
  passtagEmail: string;
  passtagNote: string;
  export: string;
  description: string;
  statementTypes: Array<number>;
  baggageStatuses: Array<number>;
}
