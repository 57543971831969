import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Flight } from './flight';
import { Passenger } from './passenger';
import { Baggage } from './baggage';
import { ReferanceAirport, ReferanceAirline } from '../referance-module-data/referance';

import { CurrentFlights } from './current-flights'
import { InstallationWorkplace } from './installation-workplace';
import { DictReasonSolutionPass } from './dict-reason-solution-pass';
import { Passengers } from './passengers';
import { WantedList } from './wanted-list';
import { DictReasonWanted } from './dict-reason-wanted';
import { FindPassenger } from './find-passenger';

import { DictGeo } from './dict-geo';
import { DictTerminal } from '../administration-module/types/boardingcontrol';
import { ActiveFlightsFirstWorkplace } from './active-flights-first-workplace';
import { ShowHistory } from './show-histroy';
import { DictReasonSolutionStop } from './dict-reason-solution-stop';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { NGXLogger } from 'ngx-logger';
import { ECSEvent, EventProvider } from '@settings/logger-monitor';
import {Passport} from './passport';

@Injectable({
  providedIn: 'root'
})
export class BoardingControlRestApiService {

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings,
              private logger: NGXLogger) {
    globalSettings.loadDefaultConfig();
  }

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  // Airports
  getAirports(xRequestId?): Observable<ReferanceAirport[]> {
    this.logger.trace('getAirports',
                     new ECSEvent(ModuleName.BoardingControl,
                                  'rest.getAirports',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiReferanceURL +
                                  '/airports'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ReferanceAirport[]>(this.globalSettings.apiReferanceURL +
                                             '/airports',
                                             httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAirport(id: number, xRequestId?): Observable<ReferanceAirport> {
    this.logger.trace('getAirport',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getAirport',
                                    'get',
                                    'id:' + id,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiReferanceURL +
                                    '/airports/' + id));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ReferanceAirport>(this.globalSettings.apiReferanceURL +
                                           '/airports/' + id,
                                           httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Airlines
  getAirlines(xRequestId?): Observable<ReferanceAirline[]> {
    this.logger.trace('getAirlines',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getAirlines',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiReferanceURL +
                                    '/airlines'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ReferanceAirline[]>(this.globalSettings.apiReferanceURL +
                                             '/airlines',
                                             httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGeo(xRequestId?): Observable<DictGeo[]> {
    this.logger.trace('getGeo',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getGeo',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getgeos'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictGeo[]>(this.globalSettings.apiBoardingControlURL +
                                    '?getgeos', httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setEveryone(selectflightid: number, xRequestId?): Observable<DictTerminal> {
    this.logger.trace('setEveryone',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.setEveryone',
                                    'post',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?seteveryoneout=' + selectflightid));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<DictTerminal>(this.globalSettings.apiBoardingControlURL +
                                        '?seteveryoneout=' + selectflightid,
                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteWanted(id: number, xRequestId?): Observable<WantedList> {
    this.logger.trace('deleteWanted',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.deleteWanted',
                                    'post',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?deletewanted=' + id));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<WantedList>(this.globalSettings.apiBoardingControlURL +
                                      '?deletewanted=' + id,
                                      httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setFirstWorkplaceActiveWanted(fwp: any, xRequestId?): Observable<any> {
    this.logger.trace('setFirstWorkplaceActiveWanted',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.setFirstWorkplaceActiveWanted',
                                    'post',
                                    'workPlace: ' + fwp,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?setfirstworkplacewanted'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<any>(this.globalSettings.apiBoardingControlURL +
                               '?setfirstworkplacewanted', JSON.stringify(fwp),
                               httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /*setExitClearArea(id: number, user: string, idwp: number, xRequestId?): Observable<any> {

    const httpOptions = this.setDefaultHttpHeader();

    console.log('ID EXIT ');

    return this.http.post<any>(this.globalSettings.apiBoardingControlURL +'?exitcleararea=' + id + '&user=' + user + '&idwp=' + idwp, httpOptions);
  }*/

  setExitClearArea(id: number, user: string, idwp: number, xRequestId?): Observable<any> {
    this.logger.trace('setExitClearArea',
      new ECSEvent(ModuleName.BoardingControl,
        'rest.setExitClearArea',
        'post',
        'id: ' + id + 'user: ' + user + 'idwp: ' + idwp,
        EventProvider.APPLICATION,
        '',
        xRequestId,
        this.globalSettings.apiBoardingControlURL +
        '?exitcleararea=' + id +
        '&user=' + user +
        '&idwp=' + idwp));
    const httpOptions = this.setDefaultHttpHeader();

    console.log('ID EXIT ');

    return this.http.post<any>(this.globalSettings.apiBoardingControlURL +
      '?exitcleararea=' + id +
      '&user=' + user +
      '&idwp=' + idwp,httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  // Получение из справочник решений
  getReasonSolutionPass(idw: number, xRequestId?): Observable<DictReasonSolutionPass[]> {
    this.logger.trace('getReasonSolutionPass',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getReasonSolutionPass',
                                    'get',
                                    'idw: ' + idw,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getsolutionpass=' + idw));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictReasonSolutionPass[]>(this.globalSettings.apiBoardingControlURL +
                                                   '?getsolutionpass=' + idw,
                                                   httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getReasonSolutionStop(idw: number, xRequestId?): Observable<DictReasonSolutionStop[]> {
    this.logger.trace('getReasonSolutionStop',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getReasonSolutionStop',
                                    'get',
                                    'idw: ' + idw,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getsolutionstop=' + idw));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictReasonSolutionStop[]>(this.globalSettings.apiBoardingControlURL +
                                                   '?getsolutionstop=' + idw,
                                                   httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получение из справочник причина задержания
  getReasonWanted(xRequestId?): Observable<DictReasonWanted[]> {
    this.logger.trace('getReasonWanted',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getReasonWanted',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getreasonwanted'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictReasonWanted[]>(this.globalSettings.apiBoardingControlURL +
                                             '?getreasonwanted',
                                             httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setFirstWorkplaceActive(actFlights: any, xRequestId): Observable<any> {
    this.logger.trace('setFirstWorkplaceActive',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.setFirstWorkplaceActive',
                                    'post',
                                    'actFlights:' + actFlights,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?updateactivefirstworkplace'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<any>(this.globalSettings.apiBoardingControlURL +
                               '?updateactivefirstworkplace',
                               JSON.stringify(actFlights),
                               httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Активные рейсы в чистой зоне
  getActiveFlights(xRequestId?): Observable<ActiveFlightsFirstWorkplace[]> {
    this.logger.trace('getActiveFlights',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getActiveFlights',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getactiveflights'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ActiveFlightsFirstWorkplace[]>(this.globalSettings.apiBoardingControlURL +
                                                        '?getactiveflights',
                                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получение паспортных данных
  getPassportData(flight_id: number, passenge_name: string, pnr: string, xRequestId?) {
    /*this.logger.trace('getInfoWorkplace',
      new ECSEvent(ModuleName.BoardingControl,
        'rest.getInfoWorkplace',
        'get',
        'guid:' + guid + ', lang:' + lang,
        EventProvider.APPLICATION,
        '',
        xRequestId,
        this.globalSettings.apiBoardingControlURL +
        '?getinfo=' + guid +
        '&lang=' + lang));*/
    const httpOptions = this.setDefaultHttpHeader();

    //console.log('loadPassportData?getpassportadlpnl=' + flight_id + '&passenge_name=' + passenge_name+ '&pnr=' + pnr);

    return this.http.get<Passport>(this.globalSettings.apiBoardingControlURL + '?getpassportadlpnl=' + flight_id + '&passenge_name=' + passenge_name+ '&pnr=' + pnr, httpOptions)
    //return this.http.get<Passport>('http://localhost/monitorsoft/monitorsoft/get.php?getpassportadlpnl=' + flight_id + '&passenge_name=' + passenge_name+ '&pnr=' + pnr, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Получение список розыска
  getWantedLists(xRequestId?): Observable<WantedList[]> {
    this.logger.trace('getWantedLists',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getWantedLists',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getwantedlist'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<WantedList[]>(this.globalSettings.apiBoardingControlURL +
                                       '?getwantedlist',
                                       httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получение инфо о рабочем месте
  getInfoWorkplace(guid: string, lang: string, xRequestId?) {
    this.logger.trace('getInfoWorkplace',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getInfoWorkplace',
                                    'get',
                                    'guid:' + guid + ', lang:' + lang,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getinfo=' + guid +
                                    '&lang=' + lang));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<InstallationWorkplace>(this.globalSettings.apiBoardingControlURL +
                                                '?getinfo=' + guid +
                                                '&lang=' + lang,
                                                httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получаем из базы данные для сравнение с баркодом
  getCurrentFlights(lang: string, minus: string, plus: string, xRequestId?) {
    this.logger.trace('getCurrentFlights',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getCurrentFlights',
                                    'get',
                                    'lang:' + lang + ', minus:' + minus + ',plus:' + plus,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?getcurrentflights=' + lang +
                                    '&minus=' + minus +
                                    '&plus=' + plus));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<CurrentFlights>(this.globalSettings.apiBoardingControlURL +
                                         '?getcurrentflights=' + lang +
                                         '&minus=' + minus +
                                         '&plus=' + plus,
                                         httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Фильтр
  findPassengerInClearArea(filter: string, idFlight?: number, xRequestId?) {
    let param = '';
    if ((idFlight != 0) && (idFlight != undefined)) {
      param = '?filterpass=' + filter + '&idflight=' + idFlight;
    } else {
      param = '?filterpass=' + filter;
    }

    this.logger.trace('findPassengerInClearArea',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.findPassengerInClearArea',
                                    'get',
                                    'param:' + param,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    param));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<FindPassenger>(this.globalSettings.apiBoardingControlURL +
                                        param,
                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // список пассажиров на 3 вкладке при клике на рейс
  getPassengerInClearArea(idFlight: number, filter?: string, xRequestId?) {
    let param = '';
    if ((filter != '') && (filter != undefined)) {
      param = '?filtergetpassengers=' + idFlight + '&filter=' + filter;
    } else {
      param = '?filtergetpassengers=' + idFlight;
    }

    this.logger.trace('getPassengerInClearArea',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getPassengerInClearArea',
                                    'get',
                                    'param:' + param,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    param));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<FindPassenger>(this.globalSettings.apiBoardingControlURL +
                                        param,
                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getHistroy(bar: string, xRequestId?): Observable<ShowHistory> {
    this.logger.trace('getHistroy',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getHistroy',
                                    'post',
                                    'bar:' + bar,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?showhistory=' + bar));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<ShowHistory>(this.globalSettings.apiBoardingControlURL +
                                       '?showhistory=' + bar,
                                       httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Ищем дубликат
  getDuplicate(seat: string, idflight: number, xRequestId?): Observable<HttpResponse<boolean>> {
    const param = '?getduplicate&seat=' + seat + '&idflight=' + idflight;
    this.logger.trace('getDuplicate',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getDuplicate',
                                    'post',
                                    'param:' + param,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    param));
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiBoardingControlURL +
                                             param,
                                             {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем считанный баркод в таблицу
  insertBarcode(barcode: any, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('insertBarcode',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.insertBarcode',
                                    'post',
                                    'barcode:' + barcode,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?setbarcode'));
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiBoardingControlURL +
                                             '?setbarcode',
                                             JSON.stringify(barcode),
                                             {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Ищем есть ли такой баркод уже ?
  haveBarcodeTable(barcode: any, xRequestId?): Observable<any> {
    this.logger.trace('haveBarcodeTable',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.haveBarcodeTable',
                                    'post',
                                    'barcode:' + barcode,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?havebarcode=' + barcode));
    return this.http.post<any>(this.globalSettings.apiBoardingControlURL +
                               '?havebarcode=' + barcode,
                               {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  insertWantedList(wanted: any, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('insertWantedList',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.insertWantedList',
                                    'post',
                                    'wanted:' + wanted,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?insertwantedlist'));
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiBoardingControlURL +
                                             '?insertwantedlist',
                                             JSON.stringify(wanted),
                                             {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем в таблицу first-workplace
  insertPassengers(passenger: any, xRequestId?): Observable<Passengers> {
    this.logger.trace('insertPassengers',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.insertPassengers',
                                    'post',
                                    'passenger:' + passenger,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?setpassenger'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<Passengers>(this.globalSettings.apiBoardingControlURL +
                                      '?setpassenger',
                                      JSON.stringify(passenger),
                                      httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем в таблицу incorrect
  insertDictIncorrect(incorrect: any, xRequestId?): Observable<Passengers> {
    this.logger.trace('insertDictIncorrect',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.insertDictIncorrect',
                                    'post',
                                    'incorrect:' + incorrect,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?insertincorrect'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<Passengers>(this.globalSettings.apiBoardingControlURL +
                                      '?insertincorrect',
                                      JSON.stringify(incorrect),
                                      httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем в таблицу installation place
  insertInstallationPlace(guid: any, xRequestId?): Observable<any> {
    this.logger.trace('insertInstallationPlace',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.insertInstallationPlace',
                                    'post',
                                    'guid:' + guid,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '?insertnewinstallation=' + guid +
                                    '&idw=-1'));
    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<any>(this.globalSettings.apiBoardingControlURL +
                               '?insertnewinstallation=' + guid +
                               '&idw=-1',
                               httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAirline(id: number, xRequestId?): Observable<ReferanceAirline> {
    this.logger.trace('getAirline',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getAirline',
                                    'get',
                                    'id:' + id,
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiReferanceURL +
                                    '/airlines/' + id));
    return this.http.get<ReferanceAirline>(this.globalSettings.apiReferanceURL +
                                           '/airlines/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFlights(xRequestId?): Observable<Flight[]> {
    this.logger.trace('getFlights',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getFlights',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                   '/flightsv1/'));
    return this.http.get<Flight[]>(this.globalSettings.apiBoardingControlURL +
                                   '/flightsv1/')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPassengers(xRequestId?): Observable<Passenger[]> {
    this.logger.trace('getPassengers',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getPassengers',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '/passengers/'));
    return this.http.get<Passenger[]>(this.globalSettings.apiBoardingControlURL +
                                      '/passengers/')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getBaggages(xRequestId?): Observable<Baggage[]> {
    this.logger.trace('getBaggages',
                      new ECSEvent(ModuleName.BoardingControl,
                                    'rest.getBaggages',
                                    'get',
                                    '',
                                    EventProvider.APPLICATION,
                                    '',
                                    xRequestId,
                                    this.globalSettings.apiBoardingControlURL +
                                    '/baggages/'));
    return this.http.get<Baggage[]>(this.globalSettings.apiBoardingControlURL +
                                    '/baggages/')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
    // console.warn(JSON.stringify(error));
    if (this.logger) {
      this.logger.error('error',
                      new ECSEvent(ModuleName.BoardingControl,
                                  'rest.handleError',
                                  'error',
                                  error,
                                  EventProvider.APPLICATION));
    }

    let errorMessage = '';
    let errorDetail: any = null;
    // if (error.error instanceof ErrorEvent) {
    //   errorDetail = error.error;
    //   errorDetail.status = error.status;
    //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    // } else {
    //   console.warn('Catch unsupported error');
    //   console.warn(error);
    // }

    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
