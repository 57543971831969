export class WantedList {
  id: number;
  idAirline : number;
  idFlight: number;
  idReason: number;
  idGeo: number;
  idInstallationWorkplace: number;
  akName: string;
  numFlight: string;
  reasonName: string;
  geoName: string;
  installationName: string;
  userLogin: string;
  passenger: string;
  tagNumber: string;
  prn:string;
  seq:string;
  dateTimeAdded: string;
  find: boolean;
  dateTimeEntrance: string;
  dateTimeBoarding: string;
  active: boolean;

  terminalName: string;
  departureTime: string;
}
