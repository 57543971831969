// Транслитерация адресов
export let mtk2Ru = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ь', 'Ы', 'Я'];
export let mtk2Lat = ['A', 'B', 'W', 'G', 'D', 'E', 'V', 'Z', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'F', 'H', 'C', 'X', 'Y', 'Q'];
export let arrRu = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ь', 'Ы', 'Ъ', 'Э', 'Ю', 'Я'];
export let arrLat = ['A', 'B', 'W', 'G', 'D', 'E', 'E', 'V', 'Z', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'F', 'H', 'C', 'CH', 'SH', 'Q', 'X', 'Y', 'X', 'E', 'IU', 'IA'];


export function transliterationRuEn(text): string {
  if (text) {
    text = text.toUpperCase();
    const messageArray = text.split('');
    let transliterationMessage = '';
    let arrayIndex = -1;
    messageArray.forEach(element => {
      arrayIndex = mtk2Ru.indexOf(element);
      if (arrayIndex === -1) {
        transliterationMessage += element;
      } else {
        transliterationMessage += mtk2Lat[arrayIndex];
      }
    });
    return transliterationMessage;
  } else return '';
}

export function transliterationEnRu(text): string {
  if (text && text.length !== 0) {
    text = text.toUpperCase();
    const messageArray = text.split('');
    let transliterationMessage = '';
    let arrayIndex = -1;
    messageArray.forEach(element => {
      arrayIndex = mtk2Lat.indexOf(element);
      if (arrayIndex === -1) {
        transliterationMessage += element;
      } else {
        transliterationMessage += mtk2Ru[arrayIndex];
      }
    });
    return transliterationMessage;
  } else return '';
}

export function transliterationRuEnMessage(text) {
  if (text) {
    text = text.toUpperCase();
    const messageArray = text.split('');
    let transliterationMessage = '';
    let arrayIndex = -1;
    messageArray.forEach(element => {
      arrayIndex = arrRu.indexOf(element);
      if (arrayIndex === -1) {
        transliterationMessage += element;
      } else {
        transliterationMessage += arrLat[arrayIndex];
      }
    });
    return transliterationMessage;
  } else {
    return '';
  }
}

export function isCyrillic(text) {
  return /[а-я]/i.test(text);
}

export function isLatin(text) {
  return /[a-z]/i.test(text);
}
