import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '@settings/global-settings';
import { Maintenance, Operation, Resource } from './reference';
import { Flight } from '../communication-module-data/reference';

@Injectable({
  providedIn: 'root'
})
export class WorkflowModuleRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getFlights(): Observable<Flight[]> {
    return this.http.get<Flight[]>(this.globalSettings.apiWorkflowURL + '/flights')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  async getOperations(id): Promise<Operation[]> {
    return this.http.get<Operation[]>(this.globalSettings.apiWorkflowURL + '/flights/' + id + '/operations')
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  addOperation(id, operation) {
    return this.http.post<Operation>(this.globalSettings.apiTechProcessesURL + '/flights/' + id + '/operations',
                                     JSON.stringify(operation),
                                     this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getMaintenances(): Observable<Maintenance[]> {
    return this.http.get<Maintenance[]>(this.globalSettings.apiWorkflowURL +
                                        '/maintenance')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMaintenance(id): Observable<Maintenance> {
    return this.http.get<Maintenance>(this.globalSettings.apiWorkflowURL +
                                   '/maintenance/' +
                                   id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateMaintenance(id: number, data: Maintenance): Observable<Maintenance> {
    return this.http.put<Maintenance>(this.globalSettings.apiTechProcessesURL
                                          + '/maintenance/' + id,
                                          JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Operations
  getReferenceOperations(): Observable<Operation[]> {
    return this.http.get<Operation[]>(this.globalSettings.apiTechProcessesURL + '/admin/operations')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Resource
  getReferenceResources(): Observable<Resource[]> {
    return this.http.get<Resource[]>(this.globalSettings.apiTechProcessesURL + '/admin/resources')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = 'Error Code: ${error.status}\nMessage: ${error.message}';
     }
     window.alert(errorMessage);
     return throwError(errorMessage);
  }
}
