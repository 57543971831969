export class ReferanceTail {
    constructor() {
      this.tail = '';
      this.aircraftTypeId = 0;
      this.mtow = null;
      this.dtRange = [null, null];
      this.lastupdate = undefined;
    }

    id: number;
    tail: string;
    mtow: number;
    aircraftTypeId: number;
    aircraftTypeIata: string;
    aircraftTypeIcao: string;
    aircraftTypeName: Array<string>;
    private dtRange: Array<Date>;
    lastupdate: string;

    /**
     * Функции чтения/записи времени актуальности записи
     */

    get dtRangeStart(): Date {
      if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null ) {
        return new Date(this.dtRange[0]);
      } else {
        return null;
      }
    }

    set dtRangeStart(value: Date) {
      this.dtRange[0] = value;
    }

    get dtRangeFinish(): Date {
      if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
        return new Date(this.dtRange[1]);
      } else {
        return null;
      }
    }

    set dtRangeFinish(value: Date) {
      this.dtRange[1] = value;
    }
  }
