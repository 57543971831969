export class ReferanceSeason {
  constructor() {
    this.dtRange = [null, null];
    this.name = undefined;
    this.type = 0;
    this.range = [null, null];
    this.lastupdate = undefined;
  }

  id: number;
  private dtRange: Array<Date>;
  name: string;
  type: number;
  private range: Array<Date>;
  lastupdate: string;

  /**
   * Функции чтения/записи времени актуальности записи
   */

  get seasonName(): string {
    if (this.type==0)
      return 'Winter';
    else if (this.type==1)
      return 'Summer';
    else return null
  }

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !==null ) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }

  get dtSeasonStart(): Date {
    if ((this.range) && (this.range.length >= 1) && this.range[0] !==null) {
      return new Date(this.range[0]);
    } else {
      return null;
    }
  }

  set dtSeasonStart(value: Date) {
    this.range[0] = value;
  }

  get dtSeasonFinish(): Date {
    if ((this.range) && (this.range.length === 2) && this.range[1] !==null) {
      return new Date(this.range[1]);
    } else {
      return null;
    }
  }

  set dtSeasonFinish(value: Date) {
    this.range[1] = value;
  }
}
