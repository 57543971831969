<div class="modal-dialog modal-xl">
  <div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content bg-light-gray">
    <form #filterForm="ngForm">
      <div class="modal-header">
        <h5 class="modal-title">
          <span i18n="laf|Message modal@@laf.modal.message.historyAndNotifications">
            History and notifications
          </span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table-striped table-border w-100 font-small">
          <thead class="bg-blue text-center color-white">
            <tr>
              <th i18n="laf|Message modal@@laf.modal.message.status">Status</th>
              <th i18n="laf|Message modal@@laf.modal.message.text">Text</th>
              <th i18n="laf|Message modal@@laf.modal.message.method">Method</th>
              <th i18n="laf|Message modal@@laf.modal.message.sent">Sent</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
        <hr>
        <div class="d-flex align-items-center justify-content-between">
          <p class="m-0 font-weight-bold text-gray-blue-dark" i18n="laf|Message modal@@laf.modal.message.message">Message</p>
          <div class="d-flex align-items-center">
            <div class="form-check mr-3">
              <input class="form-check-input" type="checkbox" id="smsCheckbox" disabled
                    [checked]="channels.includes(2)"
                    (click)="toggleChannel(2)">
              <label class="form-check-label" for="smsCheckbox">
                SMS
              </label>
            </div>
            <div class="form-check mr-3">
              <input class="form-check-input" type="checkbox"
                    id="emailCheckbox" [checked]="channels.includes(1)"
                    (click)="toggleChannel(1)">
              <label class="form-check-label" for="emailCheckbox">
                Email
              </label>
            </div>
            <button type="button" class="btn btn-sm btn-blue"
              i18n="laf|Message modal@@laf.modal.message.button.send"
              (click)="sendMessage()" [disabled]="channels.length === 0">
              Send
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text font-small"
                  i18n="laf|Message modal@@laf.modal.message.status">
                  Status
                </span>
              </div>
              <input type="text" class="form-control form-control-sm" value="Новая заявка" disabled>
            </div>
          </div>
          <div class="col-4">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text font-small"
                  i18n="laf|Message modal@@laf.modal.message.sended">
                  Sended
                </span>
              </div>
              <input type="text" class="form-control form-control-sm" [value]="message?.dtInsert | date: 'dd.MM.yyyy HH:mm'" name="dtInsert" disabled>
            </div>
          </div>
          <div class="col-4">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text font-small"
                  i18n="laf|Message modal@@laf.modal.message.language">
                  Language
                </span>
              </div>
              <input type="text" class="form-control form-control-sm" value="Русский" disabled>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="input-group">
              <div class="input-group-prepend custom-sm">
                <span class="input-group-text font-small py-0">
                  <span i18n="laf|Message modal@@laf.modal.message.text">Text</span>&nbsp;
                </span>
              </div>
              <textarea class="form-control form-control-sm" [(ngModel)]="message.message" rows="2" name="message">{{ message.message }}</textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal"
          i18n="laf|Message modal@@laf.modal.message.button.close">
          Close
        </button>
      </div>
    </form>
  </div>
  </div>
</div>
