import { Component, Input, OnInit } from '@angular/core';
import { LostFoundModuleRestApiService } from 'src/app/lost-found-module/services/lost-found-rest-api.service';
import { Message, MessageForSent, Recipient } from '../../../types/messages';

@Component({
  selector: 'message-modal',
  templateUrl: './baggage-message-modal.component.html',
  styleUrls: ['./baggage-message-modal.component.less']
})
export class BaggageMessageModalComponent implements OnInit {
  @Input() recipientEmail: string;
  channels = [1];

  messages: Array<Message> = [];
  message: MessageForSent = new MessageForSent();

  statementId: string;
  baggageId: string;

  constructor(
    public restApi: LostFoundModuleRestApiService,
  ) { }

  ngOnInit(): void {
  }

  loadMessages(statementId: string, baggageId: string) {
    this.statementId = statementId;
    this.baggageId = baggageId;
    this.restApi.getMessagesPassenger(this.statementId, this.baggageId).then(data => {
      console.log(data);
    });
  }

  toggleChannel(channelId: number) {
    if (this.channels.includes(channelId)) {
      this.channels = this.channels.filter(el => el !== channelId);
    } else {
      this.channels.push(channelId);
    }
  }

  sendMessage() {
    this.channels.forEach(channel => {
      if (channel === 1) {
        const recipient = new Recipient(this.recipientEmail, channel);
        this.message.recipients.push(recipient);
      }
    });
    this.restApi.sendMessagePassenger(this.statementId, this.baggageId, this.message);
  }

}
