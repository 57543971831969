export enum USER_ACTIONS {
    ADD_CALCULATION,
    ADD_CALCULATION_MANUAL,
    ADD_CALCULATION_AHM,
    EDIT_CALCULATION,
    EDIT_CALCULATION_MANUAL,
    EDIT_CALCULATION_AHM,
    SEND_DOC,
    SEND_TLG,
    ADD,
    EDIT,
}

export enum LANGUAGES {
  RU = 'ru',
  EN = 'en'
}

export const DOC_TYPES = {
    ahm: [
        {
          id: 1,
          type: 'lir',
          name: 'Loading instruction',
          url: '/loading_instruction',
        }, {
          id: 2,
          type: 'loadsheet',
          name: 'Loadsheet',
          url: '/loadsheet',
        }, {
          id: 3,
          type: 'notoc',
          name: 'Notoc',
          url: '/notoc',
        },
    ],
    manual: [
        {
          id: 1,
          type: 'Loadsheet',
          name: 'Loadsheet',
          url: '/loadsheet',
        }
    ]
}

export const TLG_TYPES = ['ldm', 'cpm', 'ucm'];

export const CHANNELS = [
  {id: 1, name: 'AFTN'},
  {id: 2, name: 'SITATEX'},
  {id: 3, name: 'EMAIL'}
];

export const CREW_ADDITIONAL = {
  type: [
    {id: 0, value: 'cabin',  name: 'Cabin crew'},
    {id: 1, value: 'cockpit',  name: 'Cockpit crew'},
  ],
  role: [
    {id: 0, value: 'crew',  name: 'Crew'},
    {id: 1, value: 'dhc',  name: 'DHC'},
  ]
}
