export class ReferanceImport {
  constructor() {
    this.fieldsImpotrtFromFile = [];
    this.fieldsImpotrtFromDB = [];
    this.fieldsForImpotrt = [];
  }

  // Массив заголовков колонок из файла данных для импорта,
  // если колонки не именованные, будет пустым
  fieldsImpotrtFromFile: Array<string> = [];
  // Массив полей из базы данных, каждое поле описывается как
  // наименование и празник является ли оно обязательным
  // true - обязательное, иначе false
  fieldsImpotrtFromDB: Array<[string, boolean]> = [];
  // Массив связных записей, где указывается соответствие полей БД и файла
  fieldsForImpotrt: Array<string> = [];
  // Массив импортируемых данных
  dataImport: Array<any> = [];
  // Массив состояния валидации данных перед импортом,
  // содержит состояние проверки формата для каждой импортируемой записи
  dataValidationResult: Array<any> = [];
  // Количестов записей прошедших валидацию
  dataValidationGoodSum = 0;
  // Количество записй не прошедших валидацию
  dataValidationBadSum = 0;
  // Результат импорта данных,
  // содержит состояние ok/error для каждой записи
  dataImportResult: Array<any> = [];
  // Количестов записей прошедших валидацию
  dataImportGoodSum = 0;
  // Количество записй не прошедших валидацию
  dataImportBadSum = 0;
  // Наименование справочника для которого осуществляется загрузка
  activeReferanceName: string;
  // Оригинальный текст файла с данными
  originalText: string | ArrayBuffer | null;
  headerLimitMessage = 'Header over 50 columns, make sure the correct file parsing settings';

  private dataFromFile: any[];
  private fileHeader: string[];
  private importingHeaderFile: string[];
  private importingHeaderDb: string[];
  private dataImporting: any[];

  databaseHeader(): string[] {
    console.log('databaseHeader');
    let data = [];
    for (let item of this.fieldsImpotrtFromDB) {
      data.push(item[0].toLowerCase());
    }
    return data;
  }

  makeFileHeader() {
    console.log('makeFileHeader');
    if (this.fieldsImpotrtFromFile) {
      // При некоторых настройках парсинга файла, когда указан некоректный
      // знак разбиения строк, все данные станут заголовками, это серьезно
      // замедлит отрисовку таблицы в UI, введено ограничения
      if (this.fieldsImpotrtFromFile.length > 50) {
        this.fileHeader = [this.headerLimitMessage, ''];
      } else {
        this.fileHeader = this.fieldsImpotrtFromFile;
      }
    } else {
      if (this.dataImport.length !== 0) {
        console.log(this.dataImport[0].length);
        if (this.dataImport[0].length > 50) {
          this.fileHeader = [this.headerLimitMessage, ''];
        } else {
          let columnNumber: string[] = [];
          for (const i in this.dataImport[0]) {
            columnNumber.push(i);
          }
          this.fileHeader = columnNumber;
        }
      }
    }
  }

  getFileHeader(): string[] {
    return this.fileHeader;
  }

  unmapedField(currentStep: number, fieldName: string): boolean {
    console.log('unmapedField');
    if (currentStep === 3) {
      if (this.fieldsImpotrtFromDB.length > 0 && Object.keys(this.fieldsForImpotrt).length > 0) {
        // Проверка признака "обязательное поле", если да, то true
        for (let item of this.fieldsImpotrtFromDB) {
          if (item[0] === fieldName && item[1]) {
            if (this.fieldsForImpotrt[fieldName] === undefined) {
              // Первый элемент добляется без перевода строки
              return true;
            }
          }
        }
      } else {
        return true;
      }
    }
    return false;
  }

  unmapedFields(): string {
    console.log('unmapedFields');
    let message = '';
    if (this.fieldsImpotrtFromDB) {
      // Перебор полей загруженных из базы данных
      for (let item of this.fieldsImpotrtFromDB) {
        // Проверка признака "обязательное поле", если да, то true
        if (item[1]) {
          // Если элемент не найден в списке приязанных полей для импорта,
          // его нужно добавить в сообщение об ошибке
          if (this.fieldsForImpotrt[item[0]] === undefined) {
            // Первый элемент добляется без перевода строки
            if (message.length === 0) {
              message += item[0];
            } else {
              message += ', ' + item[0];
            }
          }
        }
      }
    }
    return message;
  }

  clear() {
    console.log('clear');
    this.fieldsImpotrtFromFile = [];
    this.fieldsForImpotrt = [];
    this.dataImport = [];
    this.originalText = '';
  }

  //  Функция генерации заголовков определенных в импортируемом файле
  makeDataFromFile() {
    console.log('data');
    if (this.fieldsImpotrtFromFile) {
      this.dataFromFile = this.dataImport.map(item => {
        let itemHeader = Object.keys(item);
        let element = [];
        for (let i of itemHeader) {
          element.push(item[i]);
        }
        return element;
      });
    } else {
      this.dataFromFile = this.dataImport;
    }
  }

  //  Функция возвращает заголовки определенные в импортируемом файле
  getDataFromFile(): any[] {
    console.log('data');
    return this.dataFromFile;
  }

  makeImportingHeaderFile() {
    if (Object.keys(this.fieldsForImpotrt).length !== 0) {
      console.log('makeImportingHeaderFile');

      // let headers = Object.keys(this.fieldsForImpotrt);
      // console.log(Object.keys(this.dataImport[0]));
      // console.log(headers);
      // let data = [];
      // if (this.dataImport[0]) {
      //   let dataHeaders = Object.keys(this.dataImport[0]);
      //   for (let i of dataHeaders) {
      //     for (let item of headers) {
      //       console.log(i + ' === ' + this.fieldsForImpotrt[item]);
      //       if (i === this.fieldsForImpotrt[item]) {
      //         data.push(item);
      //         break;
      //       }
      //     }
      //   }
      // }
      this.importingHeaderFile = Object.keys(this.fieldsForImpotrt);
    } else {
      this.importingHeaderFile = [];
    }
  }

  getImportingHeaderFile(): string[] {
    return this.importingHeaderFile;
  }

  makeImportingHeaderDb() {
    console.log('dataImportingHeaderDb');
    if (Object.keys(this.fieldsForImpotrt).length !== 0) {
      this.importingHeaderDb = Object.keys(this.fieldsForImpotrt);
    } else {
      this.importingHeaderDb = [];
    }
  }

  getImportingHeaderDb(index: number): string {
    return this.importingHeaderDb[index];
  }

  automaticBindingImportedFieldsByName() {
    console.log('automaticBindingImportedFieldsByName');
    // Автоматическая привязка работает только при условии
    // наличия именованных заголовков стобцов
    // console.log(this.fieldsImpotrtFromFile);
    if (this.fieldsForImpotrt) {
      const databseHeader = this.databaseHeader();
      for (const item of this.fieldsImpotrtFromFile) {
        const i = databseHeader.indexOf(item.toLowerCase());
        if (i !== -1) {
          this.fieldsForImpotrt[this.fieldsImpotrtFromDB[i][0]] = item;
        }
      }
    }
  }

  dataValidationResultIsGood(index: number): boolean {
    if (this.dataValidationResult.length !== 0) {
      if (this.dataValidationResult[index]['type'].toLowerCase() === 'ok') {
        return true;
      } else if (this.dataValidationResult[index]['type'].toLowerCase() === 'error') {
        return false;
      }
    }
    return false;
  }

  dataImportResultIsGood(index: number): boolean {
    if (this.dataImportResult.length !== 0) {
      if (this.dataImportResult[index]['type'].toLowerCase() === 'ok') {
        return true;
      } else if (this.dataImportResult[index]['type'].toLowerCase() === 'error') {
        return false;
      }
    }
    return false;
  }

  dataImportResultErrorMessage(): string {
    if (this.dataImportResult.length !== 0) {
      let message = '';
      for (let i in this.dataImportResult) {
        if (!this.dataImportResultIsGood(+i)) {
          message += JSON.stringify(this.dataImporting[i]) + '\r\n' + 'Error: ' +
            this.dataImportResult[i]['message'] + '\r\n\r\n';
        }
      }
      return message;
    }
    return '';
  }

  dataImportingValidationResultCalculate() {
    if (this.dataValidationResult.length > 0) {
      console.log(this.dataValidationResult);
      this.dataValidationGoodSum = 0;
      this.dataValidationBadSum = 0;
      for (const item of this.dataValidationResult) {
        console.log(item);
        if (item['type'].toLowerCase() === 'ok') {
          this.dataValidationGoodSum += 1;
        } else if (item['type'].toLowerCase() === 'error') {
          this.dataValidationBadSum += 1;
        }
      }
    }
  }

  dataImportResultCalculate() {
    if (this.dataValidationResult.length > 0) {
      console.log(this.dataImportResult);
      this.dataImportGoodSum = 0;
      this.dataImportBadSum = 0;
      for (const item of this.dataImportResult) {
        console.log(item);
        if (item['type'].toLowerCase() === 'ok') {
          this.dataImportGoodSum += 1;
        } else if (item['type'].toLowerCase() === 'error') {
          this.dataImportBadSum += 1;
        }
      }
    }
  }

  updateImportDataFieldById(row: number, column: string, value: string) {
    console.log('updateImportDataFieldById');
    console.log(row);
    console.log(column);
    console.log(value);
    if (this.fieldsImpotrtFromFile) {
      if (Object.keys(this.fieldsForImpotrt).length !== 0) {
        let itemHeader = Object.keys(this.dataImport[row]);
        let importHeader = Object.entries(this.fieldsForImpotrt);
        console.log('itemHeader');
        console.log(itemHeader);
        console.log('importHeader');
        console.log(importHeader);
        console.log('this.fieldsForImpotrt');
        console.log(this.fieldsForImpotrt);
        for (let i of itemHeader) {
          console.log(i + ' === ' + importHeader[column][1]);

          if (importHeader[column][1] === i) {
            this.dataImport[row][i] = value;
          }
        }
      }
    } else {
      console.log('updateImportDataFieldByIdV2');
      console.log(this.dataImport[row]);
      if (Object.keys(this.fieldsForImpotrt).length !== 0) {
        this.dataImport[row][column] = value;
      }
    }
  }

  makeDataImporting() {
    if (this.fieldsImpotrtFromFile) {
      if (Object.keys(this.fieldsForImpotrt).length !== 0) {
        let importHeader = Object.keys(this.fieldsForImpotrt);
        let data = this.dataImport.map((item) => {
          let itemHeader = Object.keys(item);
          let element = [];
          for (let j of importHeader) {
            for (let i of itemHeader) {
              if (this.fieldsForImpotrt[j] === i) {
                element.push(item[i]);
              }
            }
          }
          return element;
        });
        this.dataImporting = data;
      } else {
        this.dataImporting = [];
      }
    } else {
      if (Object.keys(this.fieldsForImpotrt).length !== 0) {
        let importHeader = Object.keys(this.fieldsForImpotrt);
        let data = [];
        for (let item of this.dataImport) {
          let element = [];
          for (let i of importHeader) {
            element.push(item[this.fieldsForImpotrt[i]]);
          }
          data.push(element);
        }
        this.dataImporting = data;
      } else {
        this.dataImporting = [];
      }
    }
  }

  // TODO оптимизировать поиск по массиву полей, так как сейчас перебор
  // подумать над работой этой функции
  getDataImporting(): any[] {
    return this.dataImporting;
  }

  get dataImportingJSON(): any[] {
    console.log('dataImportingJSON');
    if (this.fieldsImpotrtFromFile) {
      if (Object.keys(this.fieldsForImpotrt).length !== 0) {
        let importHeader = Object.keys(this.fieldsForImpotrt);
        let data = this.dataImport.map((item) => {
          let itemHeader = Object.keys(item);
          let element = {};
          for (let i of itemHeader) {
            for (let j of importHeader) {
              if (this.fieldsForImpotrt[j] === i) {
                // element.push(item[i]);
                element[j] = item[i];
              }
            }
          }
          return element;
        });
        return data;
      } else {
        return [];
      }
    } else {
      console.log('dataImportingJSONV2');
      console.log(Object.keys(this.fieldsForImpotrt));
      if (Object.keys(this.fieldsForImpotrt).length !== 0) {
        let importHeader = Object.keys(this.fieldsForImpotrt);
        let data = [];
        for (let item of this.dataImport) {
          let element = {};
          for (let i of importHeader) {
            element[i] = item[this.fieldsForImpotrt[i]];
          }
          data.push(element);
        }
        return data;
      } else {
        return [];
      }
    }
  }

  // Функция вывода демонтрационных данных для связных полей при импорте
  dataExample(header: string): string {
    console.log('dataExample');
    if (this.dataImport[0]) {
      if (this.fieldsImpotrtFromFile) {
        return this.dataImport[0][this.fieldsForImpotrt[header]];
      } else {
        return this.dataImport[0][this.fieldsForImpotrt[header]];
      }
    }
  }
}
