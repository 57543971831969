import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { LostFoundModuleRestApiService } from '../../services/lost-found-rest-api.service';
import { FilterParams } from '../../types/filter-params';
import { Statement, StatementData } from '../../types/statements';
import { LANGUAGES } from '@shared/const';
import { ViewParamsService } from '../../services/view-lostfound.service';
import { GlobalI18n } from '@settings/global-i18n';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';

@Component({
  selector: 'baggage-table',
  templateUrl: './baggage-table.component.html',
  styleUrls: ['./baggage-table.component.less']
})
export class BaggageTableComponent implements OnInit {

  @Input() references;
  @Input() marks;
  @ViewChild(ModalMessageComponent) modalError: ModalMessageComponent;

  statements: Array<Statement>;
  statement: StatementData = new StatementData();

  error = {
    errorMessage: '',
    errorType: '',
  };

  statementOfList: number;

  loading = true;

  userRole: Array<string> = [];

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
    public router: Router,
    public viewParams: ViewParamsService,
    public globalI18n: GlobalI18n,
  ) {
    globalSettings.loadDefaultConfig();
  }

  async ngOnInit(): Promise<void> {
    this.loadStatements();
    await this.globalSettings.loadComplete();
    console.log('keycloakUserProfile', this.globalSettings.getUserDetail());
    this.userRole = this.globalSettings.getUserDetail().keycloakUserProfile['attributes']['usertype'] || [];
  }

  characteristicCodeString(baggage) {
    const baggageType = baggage.baggageType.code;
    const elements = baggage.externalElements.map(el => this.getById(this.references.baggage_elements?.data, el)?.code).join('');
    return (baggage.color.code || 'XX')
      + (baggageType || 'XX')
      + (baggageType !== '22D' && baggageType !== '22R' ? (baggage.material.code || 'X') : '')
      + elements;
  }

  async loadStatements(activePage = 1) {
    this.loading = true;
    const xRequestId = this.globalSettings.randomUuid;
    this.viewParams.filterParams.type = this.viewParams.showedStatement.statementsAll ? [] : this.viewParams.showedStatement.statements;

    const paramsStatements: FilterParams = new FilterParams();
    if (this.viewParams.filterApply) {
      // Дублируем. чтобы не менять исходный
      Object.assign(paramsStatements, this.viewParams.filterParams);
    }

    paramsStatements.mark = this.viewParams.filterParams.mark;
    paramsStatements.type = this.viewParams.filterParams.type;
    paramsStatements.archived = this.viewParams.filterParams.archived;

    this.restApi.getCountStatements(paramsStatements, this.viewParams.showedStatement.times, xRequestId).then((counters: any) => {
      this.viewParams.pagination.statementsCount = counters.filtered.all;
      this.viewParams.pagination.counters = counters;
    });

    this.viewParams.pagination.pageActive = activePage;

    const data = await this.restApi.getStatements(this.viewParams.pagination, paramsStatements, this.viewParams.showedStatement.times, xRequestId);
    this.statements = [];
    data.forEach(el => {
      const statement = new Statement(el, this.globalSettings.language);
      this.statements.push(statement);
    });
    this.loading = false;
    this.statement = new StatementData();
    this.statementOfList = null;
  }

  async loadStatement(id: string, index?) {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getStatement(id, xRequestId);
    this.statement = new StatementData(data);
    this.checkBaggageStatus();
    this.statementOfList = index;
  }

  // TODO Временная заглушка, пока не сделаны механизмы проверки обязательных
  // полей
  checkBaggageStatus() {
    this.statement.baggage.forEach(baggage => {
      const status = this.baggage_statuses.find(el => baggage.statusId);
      if (!baggage.statusId || baggage.statusId === 0 || !status) {
        if (this.baggage_statuses.length > 0) {
          baggage.statusId = this.baggage_statuses[0].id;
        }
      }
    });
  }

  get baggage_statuses() {
    for (const item of this.references.statement_types.data) {
      if (item.id === this.statement.typeId) {
        return item.baggage_statuses;
      }
    }
    return [];
  }

  openStatement(url: string) {
    this.router.navigate([url]);
  }

  extractFlight(value: string) {
    let flightList = [];
    let returnValue = '';
    if (value) {
      flightList = value.split(';');
    }

    flightList.pop();
    let dateOld = '';

    if (flightList) {
      for (const item of flightList) {
        const flightItems = item.split('/');
        const dateStr = flightItems[1].replace(/(\d{2})/g, '$1,').split(',');
        const date = new Date(20 + dateStr[2], dateStr[1] - 1, dateStr[0]);
        const dateReturn = dateStr[0] + date.toLocaleDateString("en-US", { month: "short" });
        if (dateOld === '') {
          dateOld = dateReturn;
        }

        if (flightItems) {
          returnValue += (dateOld !== dateReturn ? ('/' + dateOld.toUpperCase()) : '') + ' / ' + flightItems[0];
        }

        dateOld = dateReturn;
      }
      returnValue += '/' + dateOld.toUpperCase();
    }

    return returnValue.slice(3);
  }

  extractRoute(value: string) {
    let flightList = [];
    let returnValue = '';
    if (value) {
      flightList = value.split(';');
    }

    flightList.pop();
    if (flightList && flightList.length > 0) {
      returnValue = flightList[0].split('/')[2].split('-')[0];
      for (const item of flightList) {
        const flightItems = item.split('/');
        if (flightItems) {
          returnValue += '<wbr>/<wbr>' + flightItems[2].split('-')[1];
        }
      }
    }

    return returnValue.trim();
  }

  async saveStatement() {
    const xRequestId = this.globalSettings.randomUuid;
    await this.restApi.updateStatement(this.statement, xRequestId).catch( err => {
        const errorMessage = `${this.globalI18n.getMessage(ModuleName.LostFound, 'claimNotSaved')} ${err.detail}`;
        const errorType = 'error';
        this.modalError.showErrorMess(errorMessage, errorType);
        return false;
    });
  }

  async sendToArchive() {
    this.loading = true;
    this.statement.archived = true;
    await this.saveStatement();
    this.loadStatements();
    this.loading = false;
  }

  async restoreFromArchive() {
    this.loading = true;
    this.statement.archived = false;
    await this.saveStatement();
    this.loadStatements();
    this.loading = false;
  }

  async changePerPage(perPage) {
    this.viewParams.changePerPage(perPage);
    this.loadStatements();
  }

  setMark(id: string, color: string) {
    const xRequestId = this.globalSettings.randomUuid;
    return this.restApi.setMark(id, color, xRequestId).subscribe(data => {
      this.statement.mark = color;
      this.loadStatements();
      this.loadStatement(id);
    }, err => this.displayError(err));
  }

  unsetMark(id: string) {
    const xRequestId = this.globalSettings.randomUuid;
    return this.restApi.unsetMark(id, xRequestId).subscribe(data => {
      this.statement.mark = '';
      this.loadStatements();
      this.loadStatement(id);
    }, err => this.displayError(err));
  }

  getBaggageByTag(tag: string) {
    const a = this.statement.baggage.find(el => el.tag === tag);
    return a;
  }

  passengerFullName(passenger) {
    let fullName = '';
    fullName = passenger.surname;
    if (passenger.name) {
      fullName += '/' + passenger.name;
    }

    if (passenger.middleName) {
      fullName += ' ' + passenger.middleName;
    }
    return fullName;
  }

  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message === 'object') {
        if (this.globalSettings.language === LANGUAGES.RU && err.message && err.message[1]) {
          this.error.errorMessage = err.message[1];
        } else if (err.message && err.message[1]) {
          this.error.errorMessage = err.message[0];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

  getById(array: Array<any>, id: any) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

}
