import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { LostItems } from "../types/lost-items";
import {
  Baggage,
  Damage,
  Flight,
  PilferageItem,
  Referance,
  StatementData,
} from "../types/statements";
import {
  References,
  STATEMENTS,
  STATUSES,
  REFERENCES_LIST,
} from "../types/types";
import { ReferencesService } from "./references.service";

@Injectable({
  providedIn: "root",
})
export class FormsService {
  STATEMENTS = STATEMENTS;
  STATUSES = STATUSES;

  references: References = new References();

  constructor(
    private fb: UntypedFormBuilder,
    public referencesService: ReferencesService
  ) {}

  async ngOnInit(): Promise<void> {
    const referencesList = [REFERENCES_LIST.irregularity_codes];
    this.referencesService.loadReferences(referencesList).then((data) => {
      this.references = data;
    });
  }

  showedBlock(statementType: number) {
    const showed = {
      departure: false,
      arrival: false,
      storage: false,
      delivery: false,
      worldTracer: false,
      photo: true,
      attachments: true,
      damage: false,
      pilferage: false,
      baggageCount: false, // Registration baggage Count and Weight
      actualBaggage: false, // Actual baggage Count and Weight
      route: false, // Маршрут пассажира
      ticketNumber: false, // номер билета
      middleName: false, // отчество
      contactsAndAddress: false, // контакты и адрес
      additionalInfo: false,
      temporaryStay: false, // временное пребывание
      additionalPassengers: false,
      contactsInBaggage: false,
      excessBaggage: false, // сверхнормативный багаж
      contentBaggage: false, // соержимое багажа
      airline: true, // Авиакомпания поле
      search: true, // результаты поиска
      noTag: false,
      possibleTransfer: false,
      copyRoute: false,
      instructions: false,
    };
    switch (statementType) {
      case this.STATEMENTS.Lost: // Неприбытие
        showed.departure = true;
        showed.arrival = true;
        showed.storage = true;
        showed.delivery = true;
        showed.worldTracer = true;
        showed.baggageCount = true;
        showed.route = true;
        showed.ticketNumber = true;
        showed.middleName = true;
        showed.contactsAndAddress = true;
        showed.additionalInfo = true;
        showed.temporaryStay = true;
        showed.additionalPassengers = true;
        showed.excessBaggage = true;
        // showed.airline = true;
        showed.noTag = true;
        showed.contentBaggage = true;
        showed.possibleTransfer = true;
        showed.actualBaggage = true;
        showed.instructions = true;
        break;
      case this.STATEMENTS.Pilferage: // Недостача
      case this.STATEMENTS.Damage: // Повреждение
        showed.damage = true;
        showed.pilferage = true;
        // showed.airline = true;
        showed.middleName = true;
        showed.route = true;
        showed.contactsAndAddress = true;
        showed.additionalInfo = true;
        showed.search = false;
        showed.noTag = true;
        break;
      // case this.STATEMENTS.Delayed: // Задержанный
      //   break;
      case this.STATEMENTS.OnHand: // Невостребованный
        showed.departure = true;
        showed.storage = true;
        showed.worldTracer = true;
        showed.contactsInBaggage = true;
        // showed.airline = true;
        showed.noTag = true;
        showed.contentBaggage = true;
        showed.copyRoute = true;
        break;
      case this.STATEMENTS.Forward: // Засланный
        showed.departure = true;
        showed.storage = true;
        showed.contactsInBaggage = true;
        showed.worldTracer = true;
        showed.contentBaggage = true;
        showed.copyRoute = true;
        break;
      case this.STATEMENTS.Hold: // Задержанный
        showed.departure = true;
        showed.storage = true;
        showed.worldTracer = true;
        showed.contactsInBaggage = true;
        // showed.airline = true;
        showed.contentBaggage = true;
        showed.copyRoute = true;
        break;
      default:
        break;
    }
    return showed;
  }

  async createStatementForm(
    statement: StatementData,
    cancelled: boolean = false
  ) {
    const statementForm = this.fb.group({
      id: [null],
      dtInsert: [null],
      airportId: [null],
      airlineId: [null],
      typeId: [null],
      pnr: [""],
      name: [""],
      username: [""],
      commentary: [""],
      notes: this.fb.group({
        instructions: [""],
      }),
      passenger: this.fb.group({
        baggage: this.fb.group({
          amount: [null],
          weight: [null],
        }),
        actualBaggage: this.fb.group({
          amount: [null],
          weight: [null],
        }),
        passenger: this.fb.group({
          surname: [null],
          name: [null],
          middleName: [null],
          title: [null],
        }),
        additionalInfo: this.fb.group({
          class: [null],
          ffp: [null],
          status: [null],
          gender: [null],
          language: [null],
          amount: [null],
          passengers: this.fb.array([
            this.fb.group({
              surname: [null],
              name: [null],
              middleName: [null],
            }),
            this.fb.group({
              surname: [null],
              name: [null],
              middleName: [null],
            }),
          ]),
          temporary: this.fb.group({
            address: [null],
            phone: [
              null,
              [Validators.pattern(/^\d{1}\s\(\d{3}\)\s\d{3}-\d{4}$/)],
            ],
            dueDate: [null],
          }),
        }),
        pnr: [null],
        ticket: [null],
        phone1: [null, [Validators.pattern(/^\d{1}\s\(\d{3}\)\s\d{3}-\d{4}$/)]],
        email: [null],
        address: [null],
        notification: [[]],
      }),
      route: this.fb.group({
        full: this.fb.group({
          flights: new UntypedFormArray([]),
        }),
      }),
      baggage: this.fb.array([]),
      messages: this.fb.group({
        incoming: [null],
        outgoing: [null],
      }),
      archived: [false],
    });

    if (!cancelled) {
      statementForm.get("airportId").setValidators([Validators.required]);
      statementForm.get("airlineId").setValidators([Validators.required]);
      statementForm.get("typeId").setValidators([Validators.required]);
      switch (statement.typeId) {
        case this.STATEMENTS.Lost: // Неприбытие
          if (statement.route.full.flights.length === 0) {
            statement.route.full.flights.push(new Flight());
          }
          statementForm
            .get("passenger")
            .get("phone1")
            .addValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("address")
            .setValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("passenger")
            .get("surname")
            .setValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("passenger")
            .get("name")
            .setValidators([Validators.required]);
          break;
        case this.STATEMENTS.Pilferage: // Недостача, хищение
          if (statement.route.full.flights.length === 0) {
            statement.route.full.flights.push(new Flight());
          }
          statementForm
            .get("passenger")
            .get("passenger")
            .get("surname")
            .setValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("passenger")
            .get("name")
            .setValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("phone1")
            .addValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("address")
            .setValidators([Validators.required]);
          break;
        case this.STATEMENTS.Damage: // Повреждение
          if (statement.route.full.flights.length === 0) {
            statement.route.full.flights.push(new Flight());
          }
          statementForm
            .get("passenger")
            .get("passenger")
            .get("surname")
            .setValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("passenger")
            .get("name")
            .setValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("phone1")
            .addValidators([Validators.required]);
          statementForm
            .get("passenger")
            .get("address")
            .setValidators([Validators.required]);
          break;
        // case this.STATEMENTS.Delayed: // Задержанный
        //   break;
        case this.STATEMENTS.OnHand: // Невостребованный
          break;
        case this.STATEMENTS.Forward: // Засланный
          break;
        case this.STATEMENTS.Hold: // Задержанный
          break;
        default:
          break;
      }
    }

    statementForm.patchValue(statement);
    statement.route.full.flights.forEach((flight) => {
      (
        statementForm
          .get("route")
          .get("full")
          .get("flights") as UntypedFormArray
      ).push(this.createFlightToStatementForm(flight, cancelled));
    });
    statement.baggage.forEach((baggage) => {
      (statementForm.get("baggage") as UntypedFormArray).push(
        this.createBaggageToStatementForm(baggage, statement.typeId)
      );
    });
    statementForm.updateValueAndValidity();
    return statementForm;
  }

  async createStatementLostForm(statement: LostItems) {
    const statementForm = this.fb.group({
      id: [null],
      airport: [null],
      airportId: [null, [Validators.required]],
      dt: [null],
      statusId: [null],
      weight: [null],
      name: [null],
      notes: [null],
      internalElements: this.fb.array([]),
      found: this.fb.group({
        name: [null],
        airlineId: [null],
        flight: [null],
        acReg: [null],
        agent: [null],
        dt: [null],
        itemName: [null],
        witness: [null],
      }),
      storage: this.fb.group({
        id: [null],
        name: [null],
        dtStart: [null],
        dtFinish: [null],
      }),
      finish: this.fb.group({
        owner: [null],
        airlineId: [null],
        airportId: [null],
        flight: [null],
        dtSent: [null],
        act: [null],
        dtAct: [null],
      }),
      archived: [null],
    });
    statementForm.patchValue(statement);
    statement.internalElements.forEach((element) => {
      (statementForm.get("internalElements") as UntypedFormArray).push(
        this.createInternalElements(element)
      );
    });
    statementForm.updateValueAndValidity();
    return statementForm;
  }

  createFlightToStatementForm(flight?: Flight, cancelled?: boolean) {
    const validators = !cancelled ? [Validators.required] : [];

    const group = this.fb.group({
      airlineId: [flight?.airlineId || null, validators],
      airline: [flight?.airline || null],
      flight: [flight?.flight || "", validators], // Validators.pattern(/^\d+$/)
      dt: [null, validators],
      departureId: [flight?.departureId || null, validators],
      departure: [flight?.departure || null],
      arrivalId: [flight?.arrivalId || null, validators],
      arrival: [flight?.arrival || null],
    });
    if (flight && flight.dt) {
      group.patchValue({ dt: new Date(flight.dt).toISOString() });
    }
    return group;
  }

  createInternalElementsInternals(value) {
    return this.fb.group({
      id: [value.id || null],
      name: this.fb.array(value.name || []),
      description: [value.description || null],
    });
  }

  createInternalElements(element) {
    const group = this.fb.group({
      categoryId: [null],
      categoryName: this.fb.array(element.categoryName || []),
      internals: this.fb.array([]),
    });
    group.patchValue(element);
    element.internals.forEach((el) => {
      const internalElements = group.get("internals") as UntypedFormArray;
      internalElements.push(this.createInternalElementsInternals(el));
    });
    return group;
  }

  createDamagesToBaggage(value: Damage) {
    return this.fb.group({
      levelId: [value.levelId || null],
      level: [value.level || ""],
      description: [value.description || null],
      claim: [value.claim || null],
      typeId: [value.typeId || null],
      type: [value.type || ""],
      side: [value.side || null],
    });
  }

  createPilferageItem(value?: PilferageItem) {
    return this.fb.group({
      claim: [value?.claim || "", [Validators.required]],
      note: [value?.note || ""],
      declaredValue: [value?.declaredValue || null],
    });
  }

  /* validators */
  damagesIsEmpty(damages: UntypedFormArray) {
    let error = { noValue: true };
    damages.controls.forEach((damage: UntypedFormGroup) => {
      if (
        damage.get("side").value &&
        damage.get("typeId").value &&
        damage.get("levelId").value
      ) {
        error = null;
      }
    });
    return error;
  }

  checkFaultStation(
    irregularity_codes: Array<Referance>,
    baggageForm: UntypedFormGroup
  ): ValidatorFn {
    return (control: AbstractControl) => {
      const faultStationId = control.value;
      const irregularityCodeId = baggageForm.get("irregularityCode1Id").value;
      const code = irregularity_codes.find(
        (el) => el.id === irregularityCodeId
      )?.code;
      const departureCodes = [11, 12, 13, 15, 16, 17, 18, 21, 23, 25, 26, 27, 31, 32, 33, 35];
      const arrivalCodes = [41, 42, 43];
      const routes = baggageForm.get("route") as UntypedFormArray;
      let checkRoute = false;
      if (faultStationId) {
        // проверим, что такой аэропорт есть в маршруте
        for (let i = 0; i < routes.controls.length; i++) {
          const route = routes.at(i);
          if (
            route.get("departureId").value === faultStationId ||
            route.get("arrivalId").value === faultStationId
          ) {
            checkRoute = true;
          }
        }
        if (!checkRoute) {
          return { missingFaultAirport: true };
        }
      }
      if (irregularityCodeId && faultStationId) {
        if (departureCodes.includes(+code)) {
          const departureId = routes.at(0).get("departureId").value;
          if (departureId !== faultStationId) {
            return { faultAirportDoesNotMatch: true };
          }
        } else if (arrivalCodes.includes(+code)) {
          const arrivalId = routes
            .at(routes.controls.length - 1)
            .get("arrivalId").value;
          if (arrivalId !== faultStationId) {
            return { faultAirportDoesNotMatch: true };
          }
        }
      }
      return null;
    };
  }
  /* end validators */

  createBaggageToStatementForm(baggage?: Baggage, statementType?: number) {
    const cancelled = baggage?.statusId === this.STATUSES.Cancelled || false;

    const group = this.fb.group({
      id: [null],
      tag: [
        null,
        [
          Validators.pattern(
            /^([A-Za-z]{1})([A-Za-z]{1})|^([A-Za-z0-9]{1})([A-Za-z]{1})|^([A-Za-z]{1})([A-Za-z0-9]{1})/
          ),
        ],
      ],
      noTag: [null],
      weight: this.fb.group({
        expected: [null],
        actual: [null],
        pilferage: [null],
      }),
      airportId: [null],
      irregularityCode1Id: [null],
      irregularityCode2Id: [null],
      irregularityInfo: [null],
      statusId: [null],
      characteristics: this.fb.group({
        categoryId: [baggage?.characteristics?.categoryId || null],
        typeId: [baggage?.characteristics?.typeId || null],
        materialId: [baggage?.characteristics?.materialId || null],
        colorId: [baggage?.characteristics?.colorId || null],
        externalElements: this.fb.array([]),
        internalElements: this.fb.array([]),
        lockCode: [null],
        description: [null],
        brand: [null],
      }),
      worldTracer: this.fb.group({
        typeId: [null],
        type: [null],
        statement: [null],
        statusId: [null],
        status: [null],
      }),
      forwarding: this.fb.group({
        arrival: this.fb.group({
          tag: [null],
          flights: this.fb.array([]),
        }),
        departure: this.fb.group({
          tag: [null],
          flights: this.fb.array([]),
        }),
      }),
      delivery: this.fb.group({
        enabled: [null],
        dt: [null],
        actualDt: [null],
        address: [null],
        note: [null],
        courier: [null],
      }),
      storage: this.fb.group({
        id: [null],
        name: [null],
        dtStart: [null],
        dtFinish: [null],
        note: [null],
        opened: [null],
      }),
      passengerTag: this.fb.group({
        destinationId: [null],
        destination: [null],
        passenger: [null],
        phone: [null],
        email: [null],
        address: [null],
        note: [null],
      }),
      route: this.fb.array([]),
      routeExtendsFlight: [baggage?.routeExtendsFlight ? true : false],
      damage: this.fb.group({
        declaredValue: [null],
        note: [null],
        damages: this.fb.array([]),
      }),
      pilferage: this.fb.group({
        items: this.fb.array([]),
      }),
      additionalInfo: this.fb.group({
        excessBaggage: [null],
        limitationOfLiability: [null],
        insurance: [null],
        customsInfo: [null],
      }),
      dtClosed: [null],
      possibleTransfer: this.fb.group({
        airports: this.fb.array([]),
      }),
    });

    if (statementType && !cancelled) {
      if (!baggage.noTag) {
        group.get("tag").addValidators([Validators.required]);
      }
      group.get("statusId").addValidators([Validators.required]);
      group
        .get("characteristics")
        .get("typeId")
        .setValidators([Validators.required]);
      group
        .get("characteristics")
        .get("colorId")
        .setValidators([Validators.required]);
    }

    group.patchValue(baggage);

    if (!cancelled) {
      switch (statementType) {
        case this.STATEMENTS.Lost: // Неприбытие
          break;
        case this.STATEMENTS.Pilferage: // Недостача, хищение
          group
            .get("weight")
            .get("expected")
            .setValidators([Validators.required]);
          group
            .get("weight")
            .get("actual")
            .setValidators([Validators.required]);
          group
            .get("pilferage")
            .get("items")
            .setValidators([Validators.required]);
          break;
        case this.STATEMENTS.Damage: // Повреждение
          group
            .get("weight")
            .get("expected")
            .setValidators([Validators.required]);
          group
            .get("weight")
            .get("actual")
            .setValidators([Validators.required]);
          group
            .get("damage")
            .get("damages")
            .setValidators([Validators.required, this.damagesIsEmpty]);
          break;
        // case this.STATEMENTS.Delayed: // Задержанный
        //   break;
        case this.STATEMENTS.OnHand: // Невостребованный
          group.get("routeExtendsFlight").patchValue(false);
          break;
        case this.STATEMENTS.Forward: // Засланный
          group.get("routeExtendsFlight").patchValue(false);
          break;
        case this.STATEMENTS.Hold: // Задержанный
          group.get("routeExtendsFlight").patchValue(false);
          break;
      }
    }

    if (baggage?.statusId) {
      switch (baggage.statusId) {
        case this.STATUSES.IssuedCourier: // Выдан курьеру
        case this.STATUSES.Issued: // Выдан
        case this.STATUSES.Sent: // Отправлен
        case this.STATUSES.Closed: // Закрыт
          group.get("airportId").setValidators([Validators.required]);
          group.get("irregularityCode1Id").setValidators([Validators.required]);
          break;
      }
    }

    if (baggage) {
      const externalElements = group
        .get("characteristics")
        .get("externalElements") as UntypedFormArray;
      baggage.characteristics.externalElements.forEach((el) => {
        externalElements.push(new UntypedFormControl(el));
      });

      const internalElements = group
        .get("characteristics")
        .get("internalElements") as UntypedFormArray;
      baggage.characteristics.internalElements.forEach((el) => {
        internalElements.push(this.createInternalElements(el));
      });

      const arrivalFlights = group
        .get("forwarding")
        .get("arrival")
        .get("flights") as UntypedFormArray;
      baggage.forwarding.arrival.flights.forEach((flight: Flight) => {
        arrivalFlights.push(
          this.createFlightToStatementForm(flight, cancelled)
        );
      });

      const departureFlights = group
        .get("forwarding")
        .get("departure")
        .get("flights") as UntypedFormArray;
      baggage.forwarding.departure.flights.forEach((flight: Flight) => {
        departureFlights.push(
          this.createFlightToStatementForm(flight, cancelled)
        );
      });

      const routes = group.get("route") as UntypedFormArray;
      baggage.route.forEach((flight: Flight) => {
        const newFlight = this.createFlightToStatementForm(flight, cancelled);
        routes.push(newFlight);
      });

      const damages = group.get("damage").get("damages") as UntypedFormArray;
      baggage.damage.damages.forEach((damage: Damage) => {
        damages.push(this.createDamagesToBaggage(damage));
      });

      const pilferage = group.get("pilferage").get("items") as UntypedFormArray;
      baggage.pilferage.items.forEach((item: PilferageItem) => {
        pilferage.push(this.createPilferageItem(item));
      });

      const possibleTransferAirports = group
        .get("possibleTransfer")
        .get("airports") as UntypedFormArray;
      baggage.possibleTransfer.airports.forEach((airport: number) => {
        possibleTransferAirports.push(new FormControl(airport));
      });
    }

    return group;
  }
}
