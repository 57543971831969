export const ADMIN_DATA = {
  storages: [
    { id: 1, name: 'МВЛ' },
    { id: 2, name: 'ВВЛ' },
    { id: 3, name: 'A_ВВЛ' },
    { id: 4, name: 'A_МВЛ' },
    { id: 5, name: 'В_ВВЛ' },
    { id: 6, name: 'В_МВЛ' },
    { id: 7, name: 'C' },
    { id: 8, name: 'D' },
    { id: 9, name: 'E' },
    { id: 9, name: 'F' }
  ],
  notifications: [{ id: 1, name: 'Email' }, { id: 2, name: 'SMS' }],
  worldTracer: [{ id: 1, name: 'AHL' }, { id: 2, name: 'AH' }],
};

export const REASONS = [
  { id: 1, name: 'Неприбытие багажа' },
  { id: 2, name: 'Недостача багажа' },
  { id: 3, name: 'Повреждение багажа' },
  { id: 4, name: 'Утрата багажа' },
  { id: 5, name: 'Невостребованный багаж' },
  { id: 6, name: 'Засланный багаж' },
];

export const LOCAL_REFERENCES = {
  times: [
    { value: 5, nameEnglish: '5 days', nameLocal: '5 дней' },
    { value: 21, nameEnglish: '21 days', nameLocal: '21 день' },
  ],
  storage: [
    { value: 180, nameEnglish: '6 month', nameLocal: '6 месяцев' }
  ],
  gender: [
    { nameEnglish: 'Male', nameLocal: 'Мужской' },
    { nameEnglish: 'Female', nameLocal: 'Женский' }
  ],
  passenger_status: {
    // [
    //   { nameEnglish: 'Bonus', nameLocal: 'Bonus' },
    //   { nameEnglish: 'Silver', nameLocal: 'Silver' },
    //   { nameEnglish: 'Gold', nameLocal: 'Gold' },
    //   { nameEnglish: 'Platinum', nameLocal: 'Platinum' }
    // ],
    S7: ['Classic.Junior', 'Classic.Master', 'Classic.Expert', 'Classic.Top', 'Silver', 'Gold', 'Platinum'],
    U6: ['Синий', 'Серебрянный', 'Золотой'],
    SU: ['Basic', 'Silver', 'Gold', 'Platinum', 'Elite', 'Silver Elite', 'Gold Elite', 'Platinum Elite', 'Elite+', 'Gold Elite+', 'Platinum Elite+'],
    FV: ['Basic', 'Silver', 'Gold', 'Platinum', 'Elite', 'Silver Elite', 'Gold Elite', 'Platinum Elite', 'Elite+', 'Gold Elite+', 'Platinum Elite+'],
    A4: ['Bonus', 'Silver', 'Gold', 'Platinum'],
    UT: ['Basic', 'Bronze', 'Silver', 'Gold'],
    Y7: ['Базовый', 'Никелевый', 'Золотой', 'Платиновый'],
    R3: ['Standart', 'Silver', 'Gold', 'Diamond', 'VIP'],
  },
  languages: [
    { nameEnglish: 'Russian', nameLocal: 'Русский' },
    { nameEnglish: 'English', nameLocal: 'Английский' },
    { nameEnglish: 'Chinese', nameLocal: 'Китайский' },
    { nameEnglish: 'German', nameLocal: 'Немецкий' },
    { nameEnglish: 'French', nameLocal: 'Французский' },
    { nameEnglish: 'Spanish', nameLocal: 'Испанский' }
  ],
  classes: [
    { nameEnglish: 'Economy', nameLocal: 'Эконом' },
    { nameEnglish: 'Business', nameLocal: 'Бизнес' },
    { nameEnglish: 'Comfort', nameLocal: 'Комфорт' },
    { nameEnglish: '1 class', nameLocal: '1 класс' },
  ],
  address_types: [
    { nameEnglish: 'Registration address', nameLocal: 'Адрес регистрации' },
    { nameEnglish: 'Temporary address', nameLocal: 'Временный адрес' },
  ],
};

export enum DAMAGES {
  SIDE1 = 'side1',
  SIDE2 = 'side2',
  END1 = 'end1',
  END2 = 'end2',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export const DamagesList = [
  {id: 'side1', nameEn: 'Side 1', nameRu: 'Сторона 1'},
  {id: 'side2', nameEn: 'Side 2', nameRu: 'Сторона 2'},
  {id: 'end1', nameEn: 'End 1', nameRu: 'Бок 1'},
  {id: 'end2', nameEn: 'End 2', nameRu: 'Бок 2'},
  {id: 'top', nameEn: 'Top', nameRu: 'Верх'},
  {id: 'bottom', nameEn: 'Bottom', nameRu: 'Низ'},
]