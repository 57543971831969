export class Passengers {
  id: number;
  idFlight: number;
  idBoardingpass: number;
  solutionEntrance: boolean;
  idReasonSolutionEntrance: number;
  dateTimeReasonSolutionEntrance: string;
  entranceNote: string;
  solutionBoarding: boolean;
  idReasonSolutionBoarding: number;
  dateTimeReasonSolutionBoarding: string;
  boardingNote: string;
  idWantedList: number;
  baggage: string;
  active: boolean;
  idWorkplaceEntrance: number;
  dateTimeWorkplaceEntrance: string;
  entranceMark: boolean;
  idWorkplaceBoarding: number;
  dateTimeWorkplaceBoarding: string;
  boardingMark: boolean;
  idStatusPassengers: number; // 1 "Зашел в чистую зону"  2 "Вышел из чистой зоны"  3 "Вышел на посадку"  4 "Найден в розыске"  5 "Сняли с рейса"  6 "Нет в чистой зоне"
  userName: string;
}
