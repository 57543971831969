import { Component, OnInit } from '@angular/core';
import { Maintenance, Flight, Operation, Resource, OperationResource } from '../workflow-module-data/reference';
import { WorkflowModuleRestApiService } from '../workflow-module-data/workflow-module-rest-api.service';

@Component({
  selector: 'app-workflow-module',
  templateUrl: './workflow-module.component.html',
  styleUrls: ['./workflow-module.component.less']
})
export class WorkflowModuleComponent implements OnInit {

  maintenances: Maintenance[];
  maintenance: Maintenance;
  flights: Flight[] = [];
  referenceOperations: Operation[] = [];
  referenceResource: Resource[] = [];
  operationsForFlight: Operation[] = [];
  flight: Flight = new Flight();
  currentOperation: Operation = new Operation;
  currentResource: Resource = new Resource;

  viewParams = {
    rightPanel: 'flight',
    flights: 'table', // grid or table
    showFilter: false,
    filterSwitch: false,
    activeTab: 'tab-flight-info',
    showDetail: true,
  };

  error = {
    errorMessage: '',
    errorType: '',
  }

  constructor(
    public restApi: WorkflowModuleRestApiService
  ) { }

  ngOnInit() {
    this.loadFlights();
    // this.loadProcesses();
    this.loadOperations();
    this.loadResources();
  }

  loadFlights() {
    console.log('load flights');
    return this.restApi.getFlights().subscribe(data => {
      console.log('flights data ok');
      this.flights = [];
      data.forEach(el => {
        let flight = new Flight();
        Object.assign(flight, el);
        console.log('Get operations:', el.id);
        this.restApi.getOperations(el.id).then(data => {
          console.log('Data operations for Flight:', data);
          if (data) {
            data.forEach(element => {
              this.operationsForFlight.push(element);
            });
          }
        });
        this.flights.push(flight);
      });
      if (this.flights.length > 0) {
        // TODO заглушка для тестов работы с 1 рейсом
        this.flight = this.flights[0];
      }
      console.log(this.flights);
    });
  }

  loadMaintenances() {
    console.log('load resources');
    return this.restApi.getMaintenances().subscribe((data: Maintenance[]) => {
      this.maintenances = data;
      console.log(this.maintenances);
    });
  }

  loadMaintenance(id: number) {
    console.log('load processes');
    return this.restApi.getMaintenance(id).subscribe((data: Maintenance) => {
      this.maintenance = data;
    });
  }

  updateMaintenance(id: number) {
    this.restApi.updateMaintenance(id, this.maintenance).subscribe(data => {
      this.loadMaintenances();
    });
  }

  checkEmployment(item): boolean {
    if (item === '') {
      return false;
    } else {
      return true;
    }
  }

  changeView(param, value) {
    this.viewParams[param] = value;
  }

  parseDate(date, time?): Date {
    if (time && date) {
      return new Date(date + 'T' + time);
    } else if (!time && date) {
      return new Date(date);
    }
    return null;
  }

  changeTab(event: { target: { id: string; }; }) {
    if (this.viewParams.activeTab === event.target.id) {
      return;
    }
    this.viewParams.activeTab = event.target.id;
    switch (this.viewParams.activeTab) {
      case 'tab-flight-info': {
        break;
      }
      case 'tab-operations-info': {
        break;
      }
    }
  }

  loadOperations() {
    this.restApi.getReferenceOperations().subscribe(data => {
      this.referenceOperations = data;
    });
  }

  loadResources() {
    this.restApi.getReferenceResources().subscribe(data => {
      this.referenceResource = data;
      console.log('resources:', this.referenceResource);
    });
  }

  addOperation() {
    return this.restApi.addOperation(this.flight.id, this.currentOperation).subscribe(data => {
      console.log('Add operation OK');
    }, err => {
      this.displayError(err);
    });
  }

  addResource() {
    this.currentOperation.resources.push(new OperationResource);
  }

  resourceDelete(index) {
    let removeItem = this.currentOperation.resources.splice(index, 1);
    console.log('remove resource:', removeItem);
  }

  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      this.error.errorMessage = err.message;
    } else {
      this.error.errorMessage = err;
    }
  }

  clearErrorMess() {
    this.error.errorMessage = '';
    this.error.errorType = '';
  }
}
