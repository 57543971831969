<div class="row border-bottom py-2 justify-content-between font-small">
  <div class="col-auto">
    <div class="d-flex align-items-center font-small">
      <button type="button" class="btn btn-sm btn-ico btn-light-gray mr-2"
        [routerLink]="selectedStatement.id !== null ? ['/lostfound/' +  selectedStatement.id] : ['/lostfound/']">
        <i class="material-icons mr-2">arrow_back_ios</i>
        <span i18n="laf|Main - control panel@@laf.manualSearch.controlPanel.button.claims">Claims</span>
      </button>
      <button type="button" class="btn btn-sm btn-ico btn-light-gray mr-2" (click)="exportStatements()">
        <span i18n="laf|Main - control panel@@laf.manualSearch.controlPanel.button.exportToExcel">Export to Excel</span>
      </button>
      <button type="button" class="btn btn-sm btn-ico btn-light-gray mr-2"
        (click)="makeBaggageSelected(selectedStatementOfTable.baggageId)"
        [disabled]="!selectedStatementOfTable.statementId || !selectedStatement.id">
        <i class="material-icons mr-2">done</i>
        <span i18n="laf|Main - control panel@@laf.manualSearch.controlPanel.button.selected">Selected</span>
      </button>
      <button type="button" class="btn btn-sm btn-ico btn-light-gray mr-2"
        [disabled]="!selectedStatementOfTable.statementId || !selectedStatement.id" (click)="startChat()">
        <i class="material-icons mr-2">question_answer</i>
        <span i18n="laf|Main - control panel@@laf.manualSearch.controlPanel.button.chat">Chat</span>
      </button>
    </div>
  </div>
  <div class="col-7 pl-0">
    <div class="d-flex w-100">
      <div class="filter-block-wrap mr-2 w-100" (click)="filter.show = !filter.show">
        <div class="input-group-prepend">
          <span class="input-group-text border-0 rounded-0 py-1"
            i18n="laf|Main - control panel@@laf.manualSearch.controlPanel.search">Search</span>
          <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.start && filter.params.finish">
              <i class="material-icons mx-1" (click)="clearFilterParameter('start', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.fromTo">From - to:</span>&nbsp;
              {{ filter.params.start | date: 'dd.MM.yyyy' }} - {{ filter.params.finish | date: 'dd.MM.yyyy' }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.pnr">
              <i class="material-icons ml-2" (click)="clearFilterParameter('pnr', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.pnr">PNR:</span>&nbsp;
              {{ filter.params.pnr }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.airport">
              <i class="material-icons ml-2" (click)="clearFilterParameter('airport', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.airport">Airport:</span>&nbsp;
              {{ filter.params.airport }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.airline">
              <i class="material-icons ml-2" (click)="clearFilterParameter('airline', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.airline">Airline:</span>&nbsp;
              {{ filter.params.airline }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.flight">
              <i class="material-icons ml-2" (click)="clearFilterParameter('flight', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.flight">Flight:</span>&nbsp;
              {{ filter.params.flight }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.route.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParameter('route', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.route">Route:</span>&nbsp;
              {{ filter.params.route }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.surname">
              <i class="material-icons ml-2" (click)="clearFilterParameter('surname', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.surname">Surname:</span>&nbsp;
              {{ filter.params.surname }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.address">
              <i class="material-icons ml-2" (click)="clearFilterParameter('address', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.address">Address:</span>&nbsp;
              {{ filter.params.address }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.phone">
              <i class="material-icons ml-2" (click)="clearFilterParameter('phone', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.phone">Phone:</span>&nbsp;
              {{ filter.params.phone }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.tag">
              <i class="material-icons ml-2" (click)="clearFilterParameter('tag', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.tag">Tag:</span>&nbsp;
              {{ filter.params.tag }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.color">
              <i class="material-icons ml-2" (click)="clearFilterParameter('color', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.color">Color:</span>&nbsp;
              {{ filter.params.color }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.type">
              <i class="material-icons ml-2" (click)="clearFilterParameter('type', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.type">Type:</span>&nbsp;
              {{ filter.params.type }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.material">
              <i class="material-icons ml-2" (click)="clearFilterParameter('material', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.material">Material:</span>&nbsp;
              {{ filter.params.material }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.brand">
              <i class="material-icons ml-2" (click)="clearFilterParameter('brand', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.brand">Brand:</span>&nbsp;
              {{ filter.params.brand }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.externals.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParameter('externals', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.elements">Elements:</span>&nbsp;
              {{ filter.params.externals.join(', ') }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.internals.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParameter('internals', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.content">Content:</span>&nbsp;
              {{ filter.params.internals.join(', ') }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.description.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParameter('description', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.note">Note:</span>&nbsp;
              {{ filter.params.description }}
            </div>

            <div class="filter-element mr-2 my-1" *ngIf="filter.params.weightFrom">
              <i class="material-icons ml-2" (click)="clearFilterParameter('weightFrom', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.weightFrom">Weight from:</span>&nbsp;
              {{ filter.params.weightFrom }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.weightTo">
              <i class="material-icons ml-2" (click)="clearFilterParameter('weightTo', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.weightTo">Weight to:</span>&nbsp;
              {{ filter.params.weightTo }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.passtagSurname">
              <i class="material-icons ml-2" (click)="clearFilterParameter('passtagSurname', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.surname">Surname:</span>&nbsp;
              {{ filter.params.passtagSurname }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.passtagAddress">
              <i class="material-icons ml-2" (click)="clearFilterParameter('passtagAddress', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.address">Address:</span>&nbsp;
              {{ filter.params.passtagAddress }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.passtagPhone">
              <i class="material-icons ml-2" (click)="clearFilterParameter('passtagPhone', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.phone">Phone:</span>&nbsp;
              {{ filter.params.passtagPhone }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.passtagEmail">
              <i class="material-icons ml-2" (click)="clearFilterParameter('passtagEmail', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.email">Email:</span>&nbsp;
              {{ filter.params.passtagEmail }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.passtagNote">
              <i class="material-icons ml-2" (click)="clearFilterParameter('passtagNote', $event)">close</i>
              <span i18n="laf|Filter@@laf.manualSearch.filter.note">Note:</span>&nbsp;
              {{ filter.params.passtagNote }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center text-dark-gray">
          <i class="material-icons mx-1">arrow_drop_down</i>
        </div>
      </div>
      <!-- Filter -->
      <div class="filter-block border p-2 bg-white font-small" *ngIf="filter.show">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-pnr" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.statementTypes">
                    Statement types
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" [items]="references.statement_types?.data" [multiple]="true"
                    [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'" bindValue="id"
                    [virtualScroll]="true" [loading]="references.statement_types.loading"
                    [(ngModel)]="filter.params.statementTypes" [searchFn]="customSelectSearch" [selectOnTab]="true">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-pnr" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.baggageStatuses">
                    Baggage statuses
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" [items]="references.baggage_statuses?.data" [multiple]="true"
                    [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'" bindValue="id"
                    [virtualScroll]="true" [loading]="references.baggage_statuses.loading"
                    [(ngModel)]="filter.params.baggageStatuses" [searchFn]="customSelectSearch" [selectOnTab]="true">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <p class="upper-case text-blue font-weight-bold" i18n="laf|Filter@@laf.manualSearch.filter.flight">
                Flight
              </p>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filterStartDate" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.dateFromTo">
                    Date from/to
                  </label>
                </div>
                <div class="col-4 pr-0">
                  <input type="date" class="form-control custom-sm" [ngModel]="filter.params.start | date: 'yyyy-MM-dd'"
                    (blur)="filter.params.start = parseDate($event.target.value, '00:00')" name="filterStartDate">
                </div>
                <div class="col-4 pl-0">
                  <input type="date" class="form-control custom-sm"
                    [ngModel]="filter.params.finish | date: 'yyyy-MM-dd'"
                    (blur)="filter.params.finish = parseDate($event.target.value, '23:59')" name="filterFinishDate">
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-pnr" class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.airport">
                    Airport
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" [items]="references.airports?.data" bindLabel="iata"
                    bindValue="iata" [virtualScroll]="true" [loading]="references.airports.loading"
                    [(ngModel)]="filter.params.airport" (scroll)="onScrollNgSelect($event, 'airports')"
                    (scrollToEnd)="onScrollToEndNgSelect('airports')" [searchFn]="customSelectSearch"
                    [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-pnr" class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.airline">
                    Airline
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" [items]="references.airlines?.data" bindLabel="iata"
                    bindValue="iata" [virtualScroll]="true" [(ngModel)]="filter.params.airline"
                    [loading]="references.airlines.loading" (scroll)="onScrollNgSelect($event, 'airlines')"
                    (scrollToEnd)="onScrollToEndNgSelect('airlines')" [searchFn]="customSelectSearch"
                    [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-flight-name" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.flight">
                    Flight
                  </label>
                </div>
                <div class="col-8">
                  <input type="text" id="filter-flight-name" [(ngModel)]="filter.params.flight"
                    class="form-control form-control-sm" name="filter-flight-name" />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-route-name" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.route">
                    Route
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" [items]="references.airports?.data" [multiple]="true"
                    bindLabel="iata" bindValue="iata" [virtualScroll]="true" [loading]="references.airports.loading"
                    [(ngModel)]="filter.params.route" (scroll)="onScrollNgSelect($event, 'airports')"
                    (scrollToEnd)="onScrollToEndNgSelect('airports')" [searchFn]="customSelectSearch"
                    [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                  <!-- <input type="text" id="filter-route-name" [(ngModel)]="filter.params.route"
                    class="form-control form-control-sm" name="filter-route-name" /> -->
                </div>
              </div>
              <hr />
              <p class="upper-case text-blue font-weight-bold" i18n="laf|Filter@@laf.manualSearch.filter.passenger">
                Passenger
              </p>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-surname" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.surname">
                    Surname
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-surname" class="form-control form-control-sm" [(ngModel)]="filter.params.surname"
                    name="filter-surname" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-pnr" class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.pnr">
                    PNR
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-pnr" class="form-control form-control-sm" [(ngModel)]="filter.params.pnr"
                    name="filter-pnr" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-phone" class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.phone">
                    Phone
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-phone" class="form-control form-control-sm" [(ngModel)]="filter.params.phone"
                    name="filter-phone" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-address" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.address">
                    Address
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-address" class="form-control form-control-sm" [(ngModel)]="filter.params.address"
                    name="filter-address" appUpperCase />
                </div>
              </div>
            </div>
            <div class="col-4 pl-0">
              <p class="upper-case text-blue font-weight-bold" i18n="laf|Filter@@laf.manualSearch.filter.baggage">
                Baggage
              </p>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-tag" class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.tag">
                    Tag
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-tag" class="form-control form-control-sm" [(ngModel)]="filter.params.tag"
                    name="filter-tag" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-weightFrom" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.weightFromTo">
                    Weight from/to
                  </label>
                </div>
                <div class="col-4 pr-0">
                  <input type="number" id="filter-weightFrom" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.weightFrom" name="filter-weightFrom" />
                </div>
                <div class="col-4 pl-0">
                  <input type="number" id="filter-weightTo" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.weightTo" name="filter-weightTo" />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.color">
                    Color
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" bindValue="code"
                    [bindLabel]="globalSettings.language === LANGUAGES.EN ? 'nameEnglish' : 'nameLocal'"
                    [(ngModel)]="filter.params.color" [loading]="references.baggage_colors.loading"
                    [items]="references.baggage_colors?.data" [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.code}}</span>&nbsp;
                        {{ globalSettings.language === LANGUAGES.EN ? item.nameEnglish : item.nameLocal }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.type">
                    Type
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" bindValue="code"
                    [bindLabel]="globalSettings.language === LANGUAGES.EN ? 'nameEnglish' : 'nameLocal'"
                    [(ngModel)]="filter.params.type" [loading]="references.baggage_types.loading"
                    [items]="references.baggage_types?.data" [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.code}}</span>&nbsp;
                        {{ globalSettings.language === LANGUAGES.EN ? item.nameEnglish : item.nameLocal }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.material">
                    Material
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" bindValue="code"
                    [bindLabel]="globalSettings.language === LANGUAGES.EN ? 'nameEnglish' : 'nameLocal'"
                    [(ngModel)]="filter.params.material" [loading]="references.baggage_materials.loading"
                    [items]="references.baggage_materials?.data" [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.code}}</span>&nbsp;
                        {{ globalSettings.language === LANGUAGES.EN ? item.nameEnglish : item.nameLocal }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.baggageElements">
                    Elements
                  </label>
                </div>
                <div class="col-8">
                  <!-- appendTo="body"  -->
                  <ng-select class="w-100 custom-sm p-0 select-long" bindValue="code"
                    [bindLabel]="globalSettings.language === LANGUAGES.EN ? 'nameEnglish' : 'nameLocal'"
                    [(ngModel)]="filter.params.externals" [loading]="references.baggage_elements.loading"
                    [items]="references.baggage_elements?.data" [selectOnTab]="true" [multiple]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.code}}</span>&nbsp;
                        {{ globalSettings.language === LANGUAGES.EN ? item.nameEnglish : item.nameLocal }}
                      </div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.code}}</span>&nbsp;
                        {{ globalSettings.language === LANGUAGES.EN ? item.nameEnglish : item.nameLocal }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label class="mr-2 mb-0" i18n="laf|Filter@@laf.manualSearch.filter.field.brand">
                    Brand
                  </label>
                </div>
                <div class="col-8">
                  <ng-select class="w-100 custom-sm p-0" bindValue="code" bindLabel="code"
                    [(ngModel)]="filter.params.brand" [loading]="references.baggage_brands.loading"
                    [items]="references.baggage_brands?.data" [selectOnTab]="true" [addTag]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small"> {{item.code}} </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-description" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.description">
                    Description
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-description" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.description" name="filter-description" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-content" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.content">
                    Content
                  </label>
                </div>
                <div class="col-8">
                  <!-- [bindValue]="globalSettings.language === LANGUAGES.EN ? 'nameEnglish' : 'nameLocal'" -->
                  <!-- [bindLabel]="globalSettings.language === LANGUAGES.EN ? 'nameEnglish' : 'nameLocal'" -->
                  <ng-select class="w-100 custom-sm p-0" bindValue="namesString" bindLabel="namesString"
                    [(ngModel)]="filter.params.internals" [loading]="references.baggage_internals.loading"
                    [items]="references.baggage_internals?.data" [selectOnTab]="true" [addTag]="true" [multiple]="true">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        {{ item.namesString }}
                        <!-- {{ globalSettings.language === LANGUAGES.EN ? item.nameEnglish : item.nameLocal }} -->
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-3 pl-0">
              <p class="upper-case text-blue font-weight-bold" i18n="laf|Filter@@laf.manualSearch.filter.passengerTag">
                Passenger tag
              </p>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-passtagSurname" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.surname">
                    Surname
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-passtagSurname" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.passtagSurname" name="filter-passtagSurname" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-passtagAddress" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.address">
                    Address
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-passtagAddress" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.passtagAddress" name="filter-passtagAddress" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-passtagEmail" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.email">
                    Email
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-passtagEmail" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.passtagEmail" name="filter-passtagEmail" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-passtagPhone" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.phone">
                    Phone
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-passtagPhone" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.passtagPhone" name="filter-passtagPhone" appUpperCase />
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-4">
                  <label for="filter-passtagNote" class="mr-2 mb-0"
                    i18n="laf|Filter@@laf.manualSearch.filter.field.note">
                    Note
                  </label>
                </div>
                <div class="col-8">
                  <input id="filter-passtagNote" class="form-control form-control-sm"
                    [(ngModel)]="filter.params.passtagNote" name="filter-passtagNote" appUpperCase />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <button type="button" class="btn btn-gray-blue-dark btn-sm float-right ml-2" (click)="filter.show = !filter.show"
          i18n="laf|Filter@@laf.manualSearch.filter.button.close">
          Close
        </button>
        <button type="button" class="btn btn-blue btn-sm float-right ml-2" (click)="filterApp()"
          i18n="laf|Filter@@laf.manualSearch.filter.button.fing">
          Find
        </button>
        <button type="button" class="btn btn-blue btn-sm float-right" (click)="clearFilter()"
          i18n="laf|Filter@@laf.manualSearch.filter.button.clearAll">
          Clear all
        </button>
      </div>
      <button type="button" class="btn btn-xs btn-ico btn-light-gray mr-2" ngbTooltip="Filter on/off"
        i18n-ngbTooltip="laf|Filter@@laf.manualSearch.filter.button.filterOnOff"
        [ngClass]="{'btn-active' : filter.apply}" (click)="filterSwitch()">
        <i class="material-icons">filter_alt</i>
      </button>
    </div>
  </div>
</div>

<div class="position-relative">
  <div class="loading" *ngIf="viewParametrs.loading">
    <div class="cssload-spin-box"></div>
  </div>
  <h5 class="font-weight-bold text-blue h5 mb-3 mt-3" i18n="laf|Statement tabs@@laf.manualSearch">
    Manual search
  </h5>
  <div class="overflow-hidden position-relative">
    <table class="w-100 table-border contenteditable">
      <thead>
        <tr class="bg-blue color-white font-weight-bold">
          <td width="3%" i18n="laf|Statement tabs@@laf.manualSearch.airport">Airport</td>
          <td width="10%">
            <span i18n="laf|Statement tabs@@laf.manualSearch.claim">Claim</span><br>
            <span i18n="laf|Statement tabs@@laf.manualSearch.dateofClaim">Date of claim</span>
          </td>
          <td width="5%">
            <span i18n="laf|Statement tabs@@laf.manualSearch.defectType">Defect type</span><br>
            <span i18n="laf|Statement tabs@@laf.manualSearch.status">Status</span>
          </td>
          <td width="10%">
            <span i18n="laf|Statement tabs@@laf.manualSearch.flightDate">Flight/Date</span><br>
            <span i18n="laf|Statement tabs@@laf.manualSearch.route">Route</span>
          </td>
          <td width="10%" i18n="laf|Statement tabs@@laf.manualSearch.fullName">Full name</td>
          <td width="10%">
            <span i18n="laf|Statement tabs@@laf.manualSearch.address">Address</span><br>
            <span i18n="laf|Statement tabs@@laf.manualSearch.phone">Phone</span>
          </td>
          <td width="10%" i18n="laf|Statement tabs@@laf.manualSearch.pnr">PNR</td>
          <td width="10%">
            <span i18n="laf|Statement tabs@@laf.manualSearch.baggageWeight">Baggage/weight</span><br>
            <span i18n="laf|Statement tabs@@laf.manualSearch.baggageTag">Baggage tag</span>
          </td>
          <td width="3%" i18n="laf|Statement tabs@@laf.manualSearch.brand">Brand</td>
          <td width="3%" i18n="laf|Statement tabs@@laf.manualSearch.content">Content</td>
          <td width="15%" i18n="laf|Statement tabs@@laf.manualSearch.description" width="18%">Description</td>
        </tr>
        <!-- </thead> -->
        <!-- <tbody *ngIf="selectedBaggage.id"> -->
        <tr>
          <td colspan="11" class="font-weight-bold bg-light-yellow"
            i18n="laf|Statement tabs@@laf.manualSearch.searchBagParameters">Lost baggage information</td>
        </tr>
        <tr class="bg-light text-dark">
          <td>{{ getById(references.airports?.data, selectedStatement.airportId)?.iata }}</td>
          <td>
            {{ selectedStatement.name }}<br>
            {{ selectedStatement.dtInsert | date: 'dd.MM.yyyy' : 'UTC+0' }}
          </td>
          <td>
            {{ getById(references.statement_types?.data, selectedStatement.typeId)?.nameLocal }}<br>
            {{ getById(references.baggage_statuses?.data, selectedBaggage.statusId)?.nameLocal }}
          </td>
          <td>
            {{ selectedBaggage.fullFlight }}<br>
            {{ selectedBaggage.fullRoute }}
          </td>
          <td>{{ selectedStatement.passenger.passenger.surname }}</td>
          <td>
            {{ selectedStatement.passenger.address }}<br>
            {{ selectedStatement.passenger.phone1 }} {{ selectedStatement.passenger.phone2 }}
          </td>
          <td>{{ selectedStatement.passenger.pnr }}</td>
          <td>
            {{ getCharacteristics(selectedBaggage) }} / {{ selectedBaggage.weight.expected }}<br>
            {{ selectedBaggage.tag }}
          </td>
          <td>
            <span class="material-icons pointer" *ngIf="selectedBaggage.characteristics.brand"
              [ngbPopover]="selectedBaggage.characteristics.brand" placement="start" triggers="click"
              [autoClose]="'inside'" placement="top">done</span>
          </td>
          <td>
            <span class="material-icons pointer"
              *ngIf="selectedBaggage.characteristics.internalElements && selectedBaggage.characteristics.internalElements.length > 0"
              [ngbPopover]="popContent" placement="end" triggers="click" [autoClose]="'inside'" placement="top"
              placement="end">done</span>
            <ng-template #popContent>
              <div *ngFor="let element of getInternalElements(selectedBaggage.characteristics.internalElements)">
                <div class="font-weight-bold text-gray-blue-dark">{{element.name}}</div>
                <div *ngFor="let item of element.items" class="mb-2">
                  <div class="text-gray-blue-dark">{{item.name}}</div>
                  <div>{{item.description}}</div>
                </div>
              </div>
            </ng-template>
          </td>
          <td>{{selectedBaggage.characteristics.description}}</td>
        </tr>
        <tr>
          <td colspan="11" class="font-weight-bold bg-light-yellow"
            i18n="laf|Statement tabs@@laf.manualSearch.searchingResults">
            Searching results
          </td>
        </tr>
        <!-- </tbody> -->
      </thead>
      <tr></tr>
      <tbody>
        <ng-container class="37456395">
          <tr class="bg-light"
            [ngClass]="{'bg-gray-blue-dark text-white': 
              selectedStatementOfTable.statementId === statement.statementId
              && selectedStatementOfTable.baggageId === statement.baggageId}"
            *ngFor="let statement of manualStatements; let i = index" (click)="selectStatement(statement)">
            <td>{{ statement.airport }}</td>
            <td>
              {{ statement.name }}<br>
              {{ statement.dt | date: 'dd.MM.yyyy' : 'UTC+0' }}
            </td>
            <td>
              {{ statement.localTypeName }}<br>
              {{ statement.localStatusName }}
            </td>
            <td>
              {{ statement.flights }}<br>
              {{ statement.route }}
            </td>
            <td>
              {{ statement.surname }}
            </td>
            <td>
              {{ statement.address }}<br>
              {{ statement.phone }}
            </td>
            <td>{{ statement.pnr }}</td>
            <td>
              {{ statement.baggage }} / {{ statement.weight }}<br>
              {{ statement.tag }}
            </td>
            <td class="position-relative">
              <span class="material-icons pointer" *ngIf="statement.brand" [ngbPopover]="statement.brand"
                placement="start" triggers="mouseenter:mouseleave" container="body">done</span>
            </td>
            <td>
              <span class="material-icons pointer" *ngIf="statement.internals" [ngbPopover]="popContent" placement="end"
                triggers="mouseenter:mouseleave" container="body">done</span>
              <ng-template #popContent>
                <div *ngFor="let element of getArrayInternalElements(statement.internals)">
                  <div class="font-weight-bold text-gray-blue-dark">{{element.name}}</div>
                  <div *ngFor="let item of element.items" class="mb-2">
                    <div class="text-gray-blue-dark">{{item.name}}</div>
                    <div>{{item.description}}</div>
                  </div>
                </div>
              </ng-template>
            </td>
            <td>{{ statement.description }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>