export class InstallationWorkplace {
  id: number;
  idWorkPlace: number;
  idTerminal: number;
  idGeo: number;
  workplaceName: string;
  terminalName: string;
  geoName: string;
  name: string;
  gate: string;
  timeEnter: string;
  timeInterval: string;
  passportScanner: boolean;
  showTabScanner: boolean;
  showTabWanted: boolean;
  showClearArea: boolean;
  showReport: boolean;
  guid: string;
  showSecondNumber: boolean;
  reReadSec:number;
  aspectRatio4x3: boolean;
  dateStartCurrentFlightsHours: string;
  dateEndCurrentFlightsHours: string;
}
