// Описание структур данных для хранения и обработки данных

/**
 * Класс учета материала на воздушных судах
 */
export class MaterialAccounting {
  constructor() {
    this.id = null;
    this.aircraft = null;
    this.material = null;
  }

  id: number;
  aircraft: Aircraft;
  material: Material;
}

/**
 * Класс описания единичного предмета учета
 */
export class Material {
  constructor() {
    this.id = null;
    this.name = null;
    this.shortName = null;
    this.itemNumber = null;
    this.status = null;
  }

  id: number;
  name: string;
  shortName: string;
  itemNumber: string;
  status: string;
}

/**
 * Класс описания воздушного судна
 */
export class Aircraft {
  constructor() {
    this.id = null;
    this.type = null;
    this.registrationNumber = null;
  }

  id: number;
  type: string;
  registrationNumber: string;
}
