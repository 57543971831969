<table class="w-100 contenteditable text-center mb-3">
    <thead>
        <tr class="font-weight-bold">
            <td width="35px"></td>
            <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.location">
                Location
            </td>
            <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.maxSeats">
                Max seats
            </td>
            <td> +/- </td>
            <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.indexPerWeightUnit">
                Index per weight unit
            </td>
            <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.remark">
                Remark
            </td>
            <td width="35px"></td>
        </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event, seats)">
        <tr *ngFor="let cabin of seats; let i = index" cdkDrag>
            <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
            <td contenteditable="true" [innerHTML]="cabin.location" (blur)="cabin.location = $event.target.innerHTML">
            </td>
            <td contenteditable="true" [innerHTML]="cabin.maxSeats" (keydown)="inputNotLetter($event)"
                (blur)="cabin.maxSeats = toNumber($event.target.innerHTML)"></td>
            <td (click)="cabin.index = -cabin.index" class="font-weight-bold h5"
                [ngClass]="{'text-red': cabin.index < 0, 'text-green': cabin.index > 0}">
                {{ cabin.index < 0 ? '-' : '+' }} </td>
            <td contenteditable="true" [innerHTML]="cabin.index < 0 ? -cabin.index : cabin.index"
                (keydown)="inputNotLetter($event)"
                (blur)="cabin.index = toNumber(cabin.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
            <td contenteditable="true"
                [innerHTML]="cabin.remark"
                (blur)="cabin.remark = clearTags($event.target.innerHTML)"></td>
            <td>
                <i class="material-icons m-0 text-red font-weight-bold pointer"
                    (click)="deleteElement(i)">clear</i>
            </td>
        </tr>
    </tbody>
</table>