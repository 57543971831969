import { Component, OnInit } from '@angular/core';
import { GlobalSettings } from '@settings/global-settings';
import { ManualStatement } from '../../types/manual-statement';
import { FilterManualParams } from '../../types/filter-manual';
import { LostFoundModuleRestApiService } from '../../services/lost-found-rest-api.service';
import { References, REFERENCES_LIST } from '../../types/types';
import { ReferencesService } from '../../services/references.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Baggage, StatementData } from '../../types/statements';
import { parseDate } from '@shared/functions/dateFunctions';
import { FileSaverService } from 'ngx-filesaver';
import { getCharacteristicCode } from '../../functions/characteristics';
import { LANGUAGES } from '@shared/const';

@Component({
  selector: 'app-manual-search',
  templateUrl: './manual-search.component.html',
  styleUrls: ['./manual-search.component.less']
})
export class ManualSearchComponent implements OnInit {

  manualStatements: Array<ManualStatement>;
  manualSearchStatement: ManualStatement = new ManualStatement();
  // массив добавленных в сравнение
  baggageCompareArray = [];

  parseDate = parseDate;
  getCharacteristicCode = getCharacteristicCode;
  LANGUAGES = LANGUAGES;

  selectedBaggage: Baggage = new Baggage();
  selectedStatement: StatementData = new StatementData();
  baggageId = '';

  selectedStatementOfTable: ManualStatement = new ManualStatement();

  references: References = new References();

  statementOfList: number;

  filterParamsManual = {
    statementsAll: false,
    statements: [5, 7, 8],
    timesAll: false,
    times: [],
    storageAll: false,
    storage: []
  };

  selectLoadAnimation = {
    notifications: false,
    worldTracer: false,
    baggage_statuses: false,
    baggage_brands: false,
    statement_types: false
  };

  buffer = {
    airlines: [],
    airports: [],
  };

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    airlines: 50,
    airports: 50,
  };
  numberOfItemsFromEndBeforeFetchingMore = 10;

  // Таймер обновления данных
  interval: any;
  filterLoadAnimation = {
    name: false
  };

  viewParametrs = {
    loading: true,
    // 1 Неприбытие, 5 Невостребованный, 7 Засланный, 8 Задержанный, 3 Поврежденный, 2 Недостача
    // statementManualOrder: [5, 7, 8]
  };

  statementManualTypes = {
    loading: false,
    data: [],
  };

  filter = {
    params: new FilterManualParams(),
    apply: false,
    show: false,
  };

  constructor(
    public globalSettings: GlobalSettings,
    public restApi: LostFoundModuleRestApiService,
    public referencesService: ReferencesService,
    private activatedRoute: ActivatedRoute,
    private fileSaverService: FileSaverService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const referencesList = [
      REFERENCES_LIST.airlines,
      REFERENCES_LIST.airports,
      REFERENCES_LIST.baggage_types,
      REFERENCES_LIST.baggage_colors,
      REFERENCES_LIST.baggage_materials,
      REFERENCES_LIST.baggage_elements,
      REFERENCES_LIST.baggage_statuses,
      REFERENCES_LIST.baggage_brands,
      REFERENCES_LIST.baggage_internals,
      REFERENCES_LIST.statement_types
    ];
    // 1 Неприбытие, 5 Невостребованный, 7 Засланный, 8 Задержанный, 3 Поврежденный, 2 Недостача
    const statementTypesOrder = [1, 5, 7, 8];
    Promise.all([
      this.referencesService.loadReferences(referencesList).then(data => {
        this.references = data;
      }),
      this.referencesService.loadStatementTypes(this.filter.params, statementTypesOrder, this.globalSettings.language).then(data => {
        this.references.statement_types = data;
      }),
    ]).then(() => {
      // отфильтровать статусы багажа, нужны только В розыске, Приостановлен, Досылка и Не найден
      this.references.baggage_statuses.loading = true;
      this.references.baggage_statuses.data = this.references.baggage_statuses.data.filter(el => [1, 2, 3, 5].includes(el.id));
      this.references.baggage_statuses.loading = false;

      this.activatedRoute.queryParams.subscribe(params => {
        if (params.statementId && params.baggageId) {
          this.getBaggage(params.statementId, params.baggageId).then(() => {
            this.filter.params = new FilterManualParams(this.selectedStatement.typeId);
            this.viewParametrs.loading = false;
            this.loadManualStatements();
          });
        } else {
          this.loadManualStatements();
        }
      });
    });
  }

  getCharacteristics(baggage: Baggage) {
    const references = {
      baggage_colors: this.references.baggage_colors,
      baggage_types: this.references.baggage_types,
      baggage_materials: this.references.baggage_materials,
      baggage_elements: this.references.baggage_elements,
    }
    return this.getCharacteristicCode(baggage, references);
  }

  async getBaggage(statementId: string, baggageId?) {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getStatement(statementId, xRequestId);
    this.selectedStatement = new StatementData(data);
    this.selectedBaggage = this.selectedStatement.baggage.find(item => item.id === baggageId);
  }

  async loadManualStatements() {
    const xRequestId = this.globalSettings.randomUuid;
    let newFilterParams = new FilterManualParams(this.selectedStatement.typeId);
    if (this.filter.apply) {
      newFilterParams = this.filter.params;
    }
    const data = await this.restApi.getManualStatements(newFilterParams, this.filterParamsManual.times, xRequestId);
    this.manualStatements = [];
    data.forEach(el => {
      const statement = new ManualStatement(el, this.globalSettings.language);
      this.manualStatements.push(statement);
    });
    this.viewParametrs.loading = false;
    this.statementOfList = null;
    this.baggageCompareArray = [];
  }

  exportStatements() {
    const xRequestId = this.globalSettings.randomUuid;
    return this.restApi.downloadManualStatements(this.filter.params, this.filterParamsManual.times).subscribe(data => {
      const blob = new Blob([data], { type: 'application' });
      this.fileSaverService.save(blob, 'statements.xlsx');
    });
  }

  selectStatement(statement: ManualStatement) {
    this.selectedStatementOfTable = new ManualStatement(statement);
  }

  startChat() {
    const data = {
      statementId: this.selectedStatement.id,
      statementBaggageId: this.selectedBaggage.id,
      externalBaggageId: this.selectedStatementOfTable.baggageId,
      externalName: this.selectedStatementOfTable.name
    };
    this.router.navigate(['/lostfound/', this.selectedStatement.id], { queryParams: data });
  }

  getById(array: Array<any>, id: any) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

  getInternalElements(array) {
    return array.map(item => {
      return {
        // name: this.globalSettings.language === 'en' ? item.categoryName[0] : item.categoryName[1],
        name: item.categoryName[0],
        items: item.internals.map(el => {
          return { name: el.name[0], description: el.description };
        })
      };
    });
  }

  getArrayInternalElements(value: string) {
    const array = value.split(';');
    let internalElements = [];

    array.forEach(el => {
      const item = el.split('/');
      let categoryName = item[0];
      let name = item[1];
      let description = item[2];

      let finded = internalElements.find(arrayEl => arrayEl.name === categoryName)
      if (finded) {
        // если уже есть такая категория, то добавим в нее
        finded.items.push({ name, description })
      } else {
        // иначе создаем
        internalElements.push({ name: categoryName, items: [{ name, description }] })
      }
    });

    return internalElements;
  }

  clearFilterParameter(field: string, event) {
    event.stopPropagation();
    if (field === 'start' || field === 'finish') {
      const date = new Date().setDate(1);
      this.filter.params.start = new Date(date);
      this.filter.params.finish = new Date();
    } else if (typeof this.filter.params[field] === 'string') {
      this.filter.params[field] = '';
    } else if (typeof this.filter.params[field] === 'number') {
      this.filter.params[field] = null;
    } else if (typeof this.filter.params[field] === 'object') {
      this.filter.params[field] = [];
    }

    if (this.quickSearchCheck()) {
      this.filter.apply = false;
    }
    this.loadManualStatements();
  }

  quickSearchCheck() {
    const newFilter = new FilterManualParams();
    const date = new Date().setDate(new Date().getDate());
    newFilter.start = new Date(date);
    newFilter.finish = new Date();
    return JSON.stringify(this.filter.params) === JSON.stringify(newFilter);
  }

  filterApp() {
    this.filter.apply = true;
    this.filter.show = !this.filter.show;
    this.loadManualStatements();
  }

  filterSwitch() {
    this.filter.apply = !this.filter.apply;
    this.loadManualStatements();
  }

  clearFilter() {
    this.filter.params = new FilterManualParams();
    this.filter.apply = false;
  }

  addTagFilterFromArray(names, key) {
    if (!names) {
      return;
    }

    names = names.toUpperCase().split(' ');

    // Скрытие оригинальной строки ng-select, так как
    // не корректно отрабатывает добавление элементов.
    // После скрытия и повторной отрисовки - все отлично.
    // Нюанс работы с компонентом.
    this.filterLoadAnimation[key] = true;
    if (names.length > 1) {
      // Добавление всех элементов в адреса, если выше проверки пройденны
      names.forEach(element => {
        if (element.length !== 0 && !this.filter.params[key].includes(element)) {
          this.filter.params[key] = [...this.filter.params[key], element.trim()];
        }
      });
    } else if (!this.filter.params[key].includes(names[0])) {
      // Если в массиве один элемент, использование стандартного метода добавления
      // единичной записи для ng-select
      this.filter.params[key] = [...this.filter.params[key], names[0].trim()];
    }
    this.interval = setTimeout(() => {
      this.filterLoadAnimation[key] = false;
    }, 250);
  }

  onScrollToEndNgSelect(name) {
    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].data.slice(len, this.bufferSize[name] + len);
    this.references[name].loading = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.references[name].loading = false;
  }

  async makeBaggageSelected(selectBaggage: string) {
    const xRequestId = this.globalSettings.randomUuid;
    await this.restApi.makeBaggageSelected(this.selectedBaggage.id, selectBaggage, xRequestId);
    this.loadManualStatements();
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param term Строка для поиска введеня пользователем
   * @param item Элемент для поиска
   */

  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
      (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
      (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 5 ?
        (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
        :
        (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }

}
