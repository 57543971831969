import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BagData, Baggage, Flight, Referance, StatementData } from 'src/app/lost-found-module/types/statements';
import { BaggageMessageModalComponent } from '../baggage-message-modal/baggage-message-modal.component';
import { References, STATEMENTS, STATUSES } from '../../../types/types';
import { NgSelectFunctions } from '@shared/functions/ngSelectFunctions';
import { Clipboard } from '@angular/cdk/clipboard';
import { parseDate, parseDateReactiveForm } from '@shared/functions/dateFunctions';
import { ADMIN_DATA, LOCAL_REFERENCES } from 'src/app/lost-found-module/types/const';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { ChatData } from 'src/app/lost-found-module/types/chats';
import { AbstractControl, FormControl, UntypedFormArray, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormsService } from '../../../services/forms.service';
import { distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { EMPTY, Subscription } from 'rxjs';
import { GlobalI18n } from '@settings/global-i18n';
import { LANGUAGES } from '@shared/const';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';

@Component({
  selector: 'app-baggage-item',
  templateUrl: './baggage-item.component.html',
  styleUrls: ['./baggage-item.component.less']
})
export class BaggageItemComponent implements OnInit, OnDestroy {
  @ViewChild(BaggageMessageModalComponent) messagesModal: BaggageMessageModalComponent;
  @ViewChild(ModalMessageComponent) modalError: ModalMessageComponent;

  @Input() statement: StatementData;
  @Input() baggageForm: UntypedFormGroup;
  @Input() statementForm: UntypedFormGroup;
  @Input() baggageItem: Baggage;
  @Input() references: References;
  @Input() baggageIndex: number;

  @Output() openChatEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeBaggageEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() setBaggageStatus = new EventEmitter<void>();
  @Output() setCancelledStatus = new EventEmitter<void>();
  @Output('copyRoute') copyRouteEmit = new EventEmitter<number>();

  classes = {
    baggage: Baggage,
    flight: Flight,
  };
  ADMIN_DATA = ADMIN_DATA;
  LOCAL_REFERENCES = LOCAL_REFERENCES;
  STATEMENTS = STATEMENTS;
  LANGUEGES = LANGUAGES;
  STATUSES = STATUSES;

  parseDate = parseDate;

  selectFuc = new NgSelectFunctions();
  changeRouteExtendsFlight: Subscription;

  showedBlock;
  photoAvailable: boolean = false;
  attachmentsAvailable: boolean = false;
  public bagData: Array<BagData> = [];
  previousStatusId: number;

  constructor(
    public globalSettings: GlobalSettings,
    private clipboard: Clipboard,
    private formsService: FormsService,
    public globalI18n: GlobalI18n,
  ) { }

  ngOnInit(): void {
    this.showedBlock = this.formsService.showedBlock(this.statementForm.get('typeId').value);
    // подписываемся на изменение routeExtendsFlight, чтобы обновлять маршрут в багаже
    const cancelled = this.baggageItem?.statusId === STATUSES.Cancelled || false;
    this.changeRouteExtendsFlight = this.baggageForm.get('routeExtendsFlight')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(routeExtendsFlight => {
          if (routeExtendsFlight) {
            return this.statementForm.get('route').get('full').get('flights')
              .valueChanges
              .pipe(
                startWith(this.statementForm.get('route').get('full').get('flights').value),
                tap(value => {
                  this.clearFormArray(this.routes);
                  value.forEach(flight => {
                    this.routes.push(this.formsService.createFlightToStatementForm(flight, cancelled));
                  });
                }
                )
              );
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe();
    // дергаем чекбокс, чтобы при первичном запуске сработала подписка
    if (this.baggageForm.get('routeExtendsFlight').value) {
      this.baggageForm.get('routeExtendsFlight').setValue(true);
    }
    this.previousStatusId = this.baggageForm.get('statusId').value;
    setTimeout(() => {
      if (this.statementForm.get('typeId').value === this.STATEMENTS.Lost) {
        this.baggageForm.get('airportId')
          .setValidators([
            this.formsService.checkFaultStation(this.references.irregularity_codes.data, this.baggageForm)
          ]);
        this.baggageForm.get('irregularityCode1Id').valueChanges.subscribe(() => {
          this.baggageForm.get('airportId').updateValueAndValidity();
        });
        this.changeBaggageType(this.baggageForm.get('characteristics').get('typeId').value);
      }
    });
  }

  parseDateReactiveForm(control: AbstractControl, date: string, time?: string) {
    if (time) {
      parseDateReactiveForm(control, date, time);
    } else {
      parseDateReactiveForm(control, date);
    }
    this.baggageForm.markAsDirty();
  }

  getBagData(data: Array<BagData>) {
    this.bagData = data;
  }

  ngOnDestroy() {
    this.changeRouteExtendsFlight.unsubscribe();
  }

  get baggageId() {
    return this.baggageForm.get('id').value || this.baggageIndex;
  }

  get lostWeight() {
    const weight = this.baggageForm.get('weight').get('expected').value - this.baggageForm.get('weight').get('actual').value;
    return weight % 1 === 0 ? weight : weight.toFixed(1);
  }

  get routesForwardingDeparture() {
    return this.baggageForm.get('forwarding').get('departure').get('flights') as UntypedFormArray;
  }

  get routesForwardingArrival() {
    return this.baggageForm.get('forwarding').get('arrival').get('flights') as UntypedFormArray;
  }

  get externalElements() {
    return this.baggageForm.get('characteristics').get('externalElements') as UntypedFormArray;
  }

  get routes() {
    return this.baggageForm.get('route') as UntypedFormArray;
  }

  get internalElements() {
    return this.baggageForm.get('characteristics').get('internalElements') as UntypedFormArray;
  }

  get damages() {
    return this.baggageForm.get('damage').get('damages') as UntypedFormArray;
  }

  get pilferageItems() {
    return this.baggageForm.get('pilferage').get('items') as UntypedFormArray;
  }

  get airportId() {
    return this.baggageForm.get('airportId');
  }

  get damagesCodesString() {
    const res = [];
    this.damages.controls.forEach(element => {
      if (element.get('side').value || element.get('type').value || element.get('level').value) {
        res.push((element.get('side')?.value?.replace(' ', '') + element.get('type').value + element.get('level').value).toUpperCase());
      }
    });
    return res;
  }

  get possibleTransferAirports() {
    return this.baggageForm.get('possibleTransfer').get('airports') as UntypedFormArray;
  }

  changeBaggageType(id: number) {
    const material = this.baggageForm.get('characteristics').get('materialId');
    if (id === 13 || id === 14) {
      material.setValue(null);
      material.disable();
    } else {
      material.enable();
    }
  }

  changeBaggageWeight() {
    const expected = this.baggageForm.get('weight').get('expected').value;
    const actual = this.baggageForm.get('weight').get('actual').value;
    if (expected > -1 && actual > -1) {
      let weight = expected - actual;
      weight = weight % 1 === 0 ? weight : +weight.toFixed(1);
      this.baggageForm.get('weight').get('pilferage').setValue(weight);
    }
  }

  addPossibleTransfer() {
    this.possibleTransferAirports.push(new FormControl(null));
  }

  removePossibleTransfer(i_possible) {
    this.possibleTransferAirports.removeAt(i_possible);
  }

  async changeStatus(statusId: number) {
    if (statusId === STATUSES.Cancelled) {
      const mess = this.globalI18n.getMessage(ModuleName.LostFound, 'setStatusCancelled');
      const errType = 'warning';
      await this.modalError.waitAnswer(mess, errType).then(async res => {
        if (res) {
          this.setCancelledStatus.emit();
          this.previousStatusId = statusId;
        } else {
          this.baggageForm.get('statusId').setValue(this.previousStatusId);
        }
      });
    } else {
      this.setBaggageStatus.emit();
      this.previousStatusId = statusId;
    }
  }

  changeNoTag() {
    if (this.baggageForm.get('noTag').value) {
      this.baggageForm.get('tag').removeValidators([Validators.required]);
    } else {
      this.baggageForm.get('tag').setValidators([Validators.required]);
    }
    this.baggageForm.get('tag').updateValueAndValidity();
    this.baggageForm.get('tag').markAsUntouched();
  }

  getCharacteristicCode(index?: number) {
    let baggage: UntypedFormGroup;
    if (!index) {
      baggage = this.baggageForm.get('characteristics') as UntypedFormGroup;
    } else {
      baggage = (this.statementForm.get('baggage') as UntypedFormArray).at(index).get('characteristics') as UntypedFormGroup;
    }
    const codeId = baggage.get('colorId').value;
    const colorCode = this.getById(this.references.baggage_colors.data, codeId)?.code;
    const typeId = baggage.get('typeId').value;
    const typeCode = this.getById(this.references.baggage_types.data, typeId)?.code;
    let materialCode = '';
    if (typeCode !== '22D' && typeCode !== '22R') {
      const materialId = baggage.get('materialId').value;
      materialCode = this.getById(this.references.baggage_materials.data, materialId)?.code;
    }
    const elem = this.externalElements.controls.reduce((acc, el) => {
      return acc += this.getById(this.references.baggage_elements.data, el.value)?.code;
    }, '');
    return (colorCode ?? 'XX') + (typeCode ?? 'XX') + (materialCode ?? 'X') + elem;
  }

  getCharacteristicText(index?: number) {
    let baggage: UntypedFormGroup;
    if (!index) {
      baggage = this.baggageForm.get('characteristics') as UntypedFormGroup;
    } else {
      baggage = (this.statementForm.get('baggage') as UntypedFormArray).at(index).get('characteristics') as UntypedFormGroup;
    }
    const indexName = this.globalSettings.language === this.LANGUEGES.EN ? 0 : 1;

    const codeId = baggage.get('colorId').value;
    const colorName = codeId ? this.getById(this.references.baggage_colors.data, codeId)?.name[indexName] : '';
    const typeId = baggage.get('typeId').value;
    const typeCode = typeId ? this.getById(this.references.baggage_types.data, typeId)?.code : '';
    const typeName = typeId ? this.getById(this.references.baggage_types.data, typeId)?.name[indexName] + ' / ' : '';
    let materialName = '';
    if (typeCode !== '22D' && typeCode !== '22R') {
      const materialId = baggage.get('materialId').value;
      materialName = materialId ? this.getById(this.references.baggage_materials.data, materialId)?.name[indexName] + ' / ' : '';
    }
    const elem = this.externalElements.controls.reduce((acc, el) => {
      return acc += this.getById(this.references.baggage_elements.data, el.value)?.name[indexName];
    }, '');
    return (typeName ?? '') + (materialName ?? '') + (colorName ?? '');
  }

  getCharacteristicFromCode(code: string) {
    code = code.toUpperCase();

    // Длина кода по IATA всегда равна 7 символам
    if (code.length > 7) {
      code = code.slice(0, 7);
    }

    const color = this.references.baggage_colors.data.find(el => el.code === (code[0] + code[1]));
    this.baggageForm.get('characteristics').get('colorId').patchValue(color?.id || null);

    // для корректной отработки исключения, когда тип - 3 символа
    let codeType = code[2] + code[3] + code[4];

    if (codeType === '22D' || codeType === '22R') {
    } else {
      codeType = code[2] + code[3];
    }

    const type = this.references.baggage_types.data.find(el => el.code === (codeType));
    this.baggageForm.get('characteristics').get('typeId').patchValue(type?.id || null);

    const material = this.references.baggage_materials.data.find(el => el.code === code[4]);
    this.baggageForm.get('characteristics').get('materialId').patchValue(material?.id || null);

    const elemArray = [];
    for (let i = 5; i < code.length; i++) {
      const elem = this.references.baggage_elements.data.find(el => el.code === code[i]);
      if (elem) {
        elemArray.push(elem?.id);
      } else {
        elemArray.push(StatementData.BAG_CHARACTERISTIC_X_ELEMENT);
      }
    }
    if (elemArray.length > 0) {
      this.externalElements.patchValue(elemArray);
    } else {
      this.externalElements.patchValue([
        StatementData.BAG_CHARACTERISTIC_X_ELEMENT,
        StatementData.BAG_CHARACTERISTIC_X_ELEMENT
      ]);
    }
  }

  parseDateInStatementForm(index: number, date?: string): void {
    const value = this.routes.at(index);
    if (value) {
      if (date) {
        value.patchValue({ dt: new Date(date).toISOString() });
      } else {
        value.patchValue({ dt: null });
      }
    }
  }

  removeFlight(routes: UntypedFormArray, index: number) {
    routes.removeAt(index);
  }

  addFlight(routes: UntypedFormArray) {
    routes.push(this.formsService.createFlightToStatementForm());
  }

  clearFormArray(formArray: UntypedFormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  setStorageBeginDate(input, value) {
    if (value) {
      input.setValue((new Date()).toISOString());
    }
  }

  checkRequiredInput(input) {
    return input.hasValidator(Validators.required);
  }

  getStrFlight(routes: UntypedFormArray) {
    const res = [];
    if (routes.controls.length > 0) {
      res.push(routes.at(0).get('departure').value);
      routes.controls.forEach((route: UntypedFormGroup) => {
        res.push(route.get('arrival').value);
      });
    }
    return res.join(' - ');
  }

  removeBaggage() {
    this.removeBaggageEmit.emit();
  }

  loadMessages(statementId: string, baggageId: string) {
    this.messagesModal.loadMessages(statementId, baggageId);
  }

  /**
   * Функция подсчета вложений для отображения в аккордеоне
   * @param internalElements Массив вложений
   * @returns Число зафиксированных вложений
   */
  internalElementsCount(internalElements: UntypedFormArray): number {
    if (!internalElements || internalElements === undefined) {
      return 0;
    } else {
      let count = 0;
      // Перебор всех групп и подсчет всех перечислений вложений  каждой группе
      for (const item of internalElements.controls) {
        if (item.get('internals')) {
          count += (item.get('internals') as UntypedFormArray).length;
        }
      }
      return count;
    }
  }


  /**
   * Функция добавления нового элемента в массив вложений
   * каждый Элемент принадлежит к Категории, все Элементы нужно помещать внутрь
   * нужной Категории для дальнейшего правильно вывода пользователю
   * @param id Идентификатор места багажа
   */
  addBaggageInternalElement({ internalElement, category, baggageInternalDescription, baggageInternalCategoryId }) {
    // Место багажа
    const baggage = this.baggageItem;
    // Флаг проверки существования Категории в текущем наборе данных
    let categoryIsExist = false;
    // Поиск существующей Категории элементов для добавления нового Элемента
    if (baggage.characteristics.internalElements) {
      for (const element of baggage.characteristics.internalElements) {
        // Если есть группы с нужной Категорией, добавление элемент в данную группу
        if (element.categoryId === internalElement.categoryId) {
          // Проверка на существование данного Элемента, что бы избежать дублирование
          if (element.internals.find(el => el.id === internalElement.id) === undefined) {
            // Добавление элемента в виде объекта
            element.internals.push({
              id: internalElement.id,
              name: internalElement.name,
              description: baggageInternalDescription
            });
          }
          // Установка флага что Категория найдена
          categoryIsExist = true;
          break;
        }
      }
    } else {
      // Если раздела не существует, происходит его создание
      baggage.characteristics.internalElements = [];
    }

    // Если Категории не было, ее необходимо создать и записать туда Элемент
    if (!categoryIsExist) {
      baggage.characteristics.internalElements.push({
        categoryId: baggageInternalCategoryId,
        categoryName: category.name,
        internals: [{ id: internalElement.id, name: internalElement.name, description: baggageInternalDescription }]
      });
    }
  }

  openChatFromSearch(baggageId: string, bagData: BagData) {

    const ids: ChatData = {
      statementId: this.statementForm.get('id').value, // this.statement.id,
      statementBaggageId: baggageId,
      externalBaggageId: bagData.baggage.id,
      externalName: bagData.statement.name,
    };
    this.openChatEmit.emit(ids);
  }

  openChat(ids) {
    this.openChatEmit.emit(ids);
  }

  getById(array, id) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

  get baggage_statuses() {
    for (const item of this.references.statement_types.data) {
      if (item.id === this.statementForm.get('typeId').value) { //this.statement.typeId
        return item.baggage_statuses;
      }
    }
    return [];
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  setAttribute(array, item, val) {
    array[item] = val;
  }

  addItem(array, type) {
    if (array !== null) {
      const item = new type();
      array.push(item);
    }
  }

  removeItem(array, item) {
    array.splice(item, 1);
  }

  getInternalsAsArray(index: number): UntypedFormArray {
    return this.internalElements.at(index).get('internals') as UntypedFormArray;
  }

  getLocalName(element: UntypedFormArray) {
    return this.globalSettings.language === LANGUAGES.EN ? element.at(0).value : element.at(1).value;
  }

  internalElementList(group: UntypedFormArray) {
    const array = group.controls.map(el => {
      const nameArray = el.get('name') as UntypedFormArray;
      const name = this.globalSettings.language === LANGUAGES.EN ? nameArray.at(0).value : nameArray.at(1).value;
      const description = el.get('description').value;
      const descriptionText = description ? ' (' + description + ')' : '';
      // return name + el.get('description').value ? (' (' + el.get('description').value + ')') : '';
      return name + descriptionText;
    });
    return array.join(', ');
  }

  copyRoute(index: number) {
    this.copyRouteEmit.emit(index);
  }

}
