<div *ngIf="error.errorMessage" class="ms-modal-message">
  <div class="modal-content w-50">
    <div class="modal-header" [ngClass]="{'bg-info': error.errorType == 'info',
                                      'bg-warning': error.errorType == 'warning',
                                      'bg-danger': error.errorType == 'error'}">
      <h5 *ngIf="error.errorType == 'info'" class="modal-title"
        i18n="shared|Modal message error - header information@@shared.modalMessage.header.inf">
        Information
      </h5>
      <h5 *ngIf="error.errorType == 'warning'" class="modal-title"
        i18n="shared|Modal message error - header watning@@shared.modalMessage.header.warn">
        Warning
      </h5>
      <h5 *ngIf="error.errorType == 'error'" class="modal-title"
        i18n="shared|Modal message error - header error@@shared.modalMessage.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="white-space: pre-line">
      {{error.errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-blue" (click)="userAnswer = true"
        i18n="shared|Modal message error - button yes@@shared.modalMessage.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="userAnswer = false" i18n="shared|Modal message error - button no@@shared.modalMessage.button.no">
        No
      </button>
      <button type="button" *ngIf="modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="error.errorMessage = null"
        i18n="shared|Modal create message error - button close@@shared.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>