import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ResourceType,
  Operation,
  OperationVersion,
  OperationResource,
  Reference,
  Modification,
  ModificationModifiers,
  AllReference,
  ServiceSchedule,
  ServiceSchedulesProcesses,
  Resource,
  Division
} from '../types/techprocesses';
import { TechProcessesRestApiService } from '../services/techprocesses-rest-api.service';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { NgForm } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';

@Component({
  selector: 'app-am-techprocesses',
  templateUrl: './am-techprocesses.component.html',
  styleUrls: ['./am-techprocesses.component.less']
})
export class AmTechprocessesComponent implements OnInit {

  @ViewChild('resourceTypesForm') resourceTypesForm: NgForm;
  @ViewChild('operationForm') operationForm: NgForm;
  @ViewChild('modificationForm') modificationForm: NgForm;
  @ViewChild('serviceShedulesForm') serviceShedulesForm: NgForm;
  @ViewChild('resourceForm') resourceForm: NgForm;
  @ViewChild('divisionForm') divisionForm: NgForm;
  @ViewChild('closebutton') closebutton: any;

  baseURL: string;

  resourceTypes: Array<ResourceType> = [];
  resourceType: ResourceType = new ResourceType();
  resources: Array<Resource> = [];
  resource: Resource = new Resource();
  operations: Array<Operation> = [];
  operation: Operation = new Operation();
  modifications: Array<Modification> = [];
  modification: Modification = new Modification();
  serviceSchedules: Array<ServiceSchedule> = [];
  serviceSchedule: ServiceSchedule = new ServiceSchedule();
  versions: Array<OperationVersion> = [];
  divisions: Array<Division> = [];
  division: Division = new Division();

  // Индикатор загрузки
  loading = false;

  // Справочники
  modifiers: Array<any> = [];
  references: AllReference = new AllReference();
  serviceScheduleTypes: Array<Reference> = [];
  myData: Array<Array<any>> = [];

  executableFunction;
  removableId = null;

  @ViewChild('modalAddResource') modalAddResource: any;
  @ViewChild('modalAddOperation') modalAddOperation: any;

  activeTab = 'tab-resource-types';
  activeState = 'action-append';
  errorMessage = '';
  errorType = '';
  userAction = '';

  // Очередь на удаление
  deleteQueue = new Set();

  linkReferences = {
    1: 'airports',
    2: 'airlines',
    3: 'regularities',
    4: 'movement_types',
    5: 'geo_types',
    6: 'countries',
    7: 'ladder_types',
    8: 'stand_types',
    9: 'aircraft_types',
    10: 'aircraft_classes',
    11: 'aircraft_kinds',
    12: 'resource_classes',
    // cities
    // delay_sources
    // delays
    // modifiers
    // regions
    // tails
  };

  timeline: Array<any>;
  // Добавление класса для "прилипания" графика в техпроцессах
  stickClass = -1;

  constructor(
    public restApi: TechProcessesRestApiService,
    private globalI18n: GlobalI18n,
    public globalSettings: GlobalSettings
  ) {
    globalSettings.loadDefaultConfig();
    this.baseURL = this.globalSettings.apiInformationalURL;
  }

  ngOnInit() {
    this.loadResourceTypes();
    // this.loadModifiers();
    // this.loadReference();
  }

  // Resource Types
  loadResourceTypes() {
    return this.restApi.getResourceTypes().subscribe((data: ResourceType[]) => {
      this.resourceTypes = [];
      for (const item of data) {
        const type = new ResourceType();
        Object.assign(type, item);
        this.resourceTypes.push(type);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      } else {
        this.errorMessage = err;
      }
    });
  }

  loadResourceType(id: number) {
    this.resourceType = new ResourceType();
    this.loading = true;
    return this.restApi.getResourceType(id).subscribe((data: ResourceType) => {
      this.activeState = 'action-update';
      Object.assign(this.resourceType, data);
      this.loading = false;
    });
  }

  createResourceType() {
    this.activeState = 'action-append';
    this.resourceType = new ResourceType();
  }

  addResourceType() {
    this.resourceTypesForm.form.markAllAsTouched();
    if (this.resourceTypesForm.valid) {
      return this.restApi.addResourceType(this.resourceType).subscribe((data: {}) => {
        this.loadResourceTypes();
        this.closebutton.nativeElement.click();
      });
    }
  }

  updateResourceType(id: number) {
    this.resourceTypesForm.form.markAllAsTouched();
    if (this.resourceTypesForm.valid) {
      this.restApi.updateResourceType(id, this.resourceType).subscribe(data => {
        this.closebutton.nativeElement.click();
        this.loadResourceTypes();
      });
    }
  }

  deleteResourceType(id: number) {
    this.resourceType.dtRangeFinish = new Date();
    this.loading = true;
    return this.restApi.updateResourceType(id, this.resourceType).subscribe(data => {
      this.closebutton.nativeElement.click();
      this.clearErrorMess();
      this.loadResourceTypes();
      this.loading = false;
    }, err => this.displayError(err));
  }

  // Operations
  loadOperations() {
    return this.restApi.getOperations().subscribe((data: Operation[]) => {
      this.operations = [];
      for (const item of data) {
        const operation = new Operation();
        Object.assign(operation, item);
        this.operations.push(operation);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      } else {
        this.errorMessage = err;
      }
    });
  }

  loadOperation(id: number) {
    this.loading = true;
    return this.restApi.getOperation(id).subscribe((data: Operation) => {
      this.activeState = 'action-update';
      Object.assign(this.operation, data);
      this.deleteQueue = new Set();
      this.loading = false;
    });
  }

  createOperation() {
    this.activeState = 'action-append';
    this.operation = new Operation();
    this.deleteQueue = new Set();
  }

  addOperation() {
    this.operationForm.form.markAllAsTouched();
    if (this.operationForm.valid) {
      return this.restApi.addOperation(this.operation).subscribe((data: {}) => {
        this.closebutton.nativeElement.click();
        this.loadOperations();
      });
    }
  }

  updateOperation(id: number) {
    this.operationForm.form.markAllAsTouched();
    if (this.operationForm.valid) {
      this.restApi.updateOperation(id, this.operation).subscribe(data => {
        this.closebutton.nativeElement.click();
        this.loadOperations();
      });
    }
  }

  deleteOperation(id: number) {
    this.operation.dtRangeFinish = new Date();
    this.loading = true;
    return this.restApi.updateOperation(id, this.operation).subscribe(data => {
      this.closebutton.nativeElement.click();
      this.clearErrorMess();
      this.loadOperations();
      this.loading = false;
    }, err => this.displayError(err));
  }

  addVersion() {
    const num = this.operation.versions.length;
    this.operation.versions[num] = new OperationVersion();
  }

  addToDeleteQueue(value) {
    if (this.deleteQueue.has(value)) {
      this.deleteQueue.delete(value);
    } else {
      this.deleteQueue.add(value);
    }
  }

  deleteVersion() {
    if (this.operation.versions.length < 1) {
      this.errorMessage = 'Operation must contain at least 1 versions';
    } else {
      for (const item of this.deleteQueue) {
        this.operation.versions.splice(+item, 1);
        this.deleteQueue.delete(item);
      }
    }
  }

  addResourceItem(verI: number) {
    this.operation.versions[verI].resources.push(new OperationResource());
  }

  /*deleteResource(verI: number, resI: number) {
    this.operation.versions[verI].resources.splice(resI, 1);
  }*/

  // Modifications
  loadModifications() {
    return this.restApi.getModifications().subscribe((data: Modification[]) => {
      this.modifications = [];
      for (const item of data) {
        const modification = new Modification();
        Object.assign(modification, item);
        this.modifications.push(modification);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      } else {
        this.errorMessage = err;
      }
    });
  }

  loadModification(id: number) {
    this.loading = true;
    return this.restApi.getModification(id).subscribe((data: Modification) => {
      this.activeState = 'action-update';
      Object.assign(this.modification, data);
      this.deleteQueue = new Set();
      this.loading = false;
    });
  }

  createModification() {
    this.activeState = 'action-append';
    this.modification = new Modification();
    this.deleteQueue = new Set();
  }

  addModification() {
    this.modificationForm.form.markAllAsTouched();
    if (this.modificationForm.valid) {
      return this.restApi.addModification(this.modification).subscribe((data: {}) => {
        this.closebutton.nativeElement.click();
        this.loadModifications();
      });
    }
  }

  updateModification(id: number) {
    this.modificationForm.form.markAllAsTouched();
    if (this.modificationForm.valid) {
      this.restApi.updateModification(id, this.modification).subscribe(data => {
        this.closebutton.nativeElement.click();
        this.loadModifications();
      });
    }
  }

  deleteModification(id: number) {
    this.modification.dtRangeFinish = new Date();
    this.loading = true;
    return this.restApi.updateModification(id, this.modification).subscribe(data => {
      this.closebutton.nativeElement.click();
      this.clearErrorMess();
      this.loadModifications();
      this.loading = false;
    }, err => this.displayError(err));
  }

  // Service schedules
  loadServiceSchedules() {
    return this.restApi.getServiceSchedules().subscribe((data: ServiceSchedule[]) => {
      this.serviceSchedules = [];
      for (const item of data) {
        const schedule = new ServiceSchedule();
        Object.assign(schedule, item);
        this.serviceSchedules.push(schedule);

        const modifiers = [];
        for (const itemMod of item.modification.modifiers) {
          const modifier = new ModificationModifiers();
          Object.assign(modifier, itemMod);
          modifiers.push(modifier);
        }
        item.modification.modifiers = modifiers;
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      } else {
        this.errorMessage = err;
      }
    });
  }

  loadServiceSchedule(id: number) {
    this.loading = true;
    return this.restApi.getServiceSchedule(id).subscribe((data: ServiceSchedule) => {
      this.activeState = 'action-update';
      this.serviceSchedule = new ServiceSchedule();
      Object.assign(this.serviceSchedule, data);

      const modifiers = [];
      for (const item of data.modification.modifiers) {
        const modifier = new ModificationModifiers();
        Object.assign(modifier, item);
        modifiers.push(modifier);
      }
      this.serviceSchedule.modification.modifiers = modifiers;

      const processes = [];
      if (data.processes) {
        for (const item of data.processes) {
          const process = new ServiceSchedulesProcesses();
          Object.assign(process, item);
          // const opration = this.getById(this.operations, process.id);
          // process.operation = this.getById(opration.versions, process.operation.id);
          processes.push(process);
        }
      }
      this.serviceSchedule.processes = processes;

      this.createDiagram();
      this.deleteQueue = new Set();
      let i = -10;
      this.timeline = new Array(Math.floor(this.serviceSchedule.time / 10))
        .fill('')
        .map( () => {
          return i += 10;
        });
      this.loading = false;
    });
  }

  createServiceSchedule() {
    this.activeState = 'action-append';
    this.serviceSchedule = new ServiceSchedule();
    this.deleteQueue = new Set();
  }

  addServiceSchedule() {
    this.serviceShedulesForm.form.markAllAsTouched();
    if (this.serviceShedulesForm.valid) {
      this.serviceSchedule.modification = this.getById(this.modifications, this.serviceSchedule.modification.id);
      this.serviceSchedule.processes.forEach(element => {
        const opration = this.getById(this.operations, element.id);
        element.name = opration.name;
        // element.operation = this.getById(opration.versions, element.operation.id);
      });

      return this.restApi.addServiceSchedule(this.serviceSchedule).subscribe((data: {}) => {
        this.closebutton.nativeElement.click();
        this.loadServiceSchedules();
      });
    }
  }

  updateServiceSchedule(id: number) {
    this.serviceShedulesForm.form.markAllAsTouched();
    if (this.serviceShedulesForm.valid) {
      this.serviceSchedule.modification = this.getById(this.modifications, this.serviceSchedule.modification.id);
      this.serviceSchedule.processes.forEach(element => {
        const opration = this.getById(this.operations, element.id);
        element.name = opration.name;
        element.id = opration.id;
        // element.operation = this.getById(opration.versions, element.operation.id);
      });
      this.restApi.updateServiceSchedule(id, this.serviceSchedule).subscribe(data => {
        this.closebutton.nativeElement.click();
        this.loadServiceSchedules();
      });
    }
  }

  deleteServiceSchedule(id: number) {
    this.serviceSchedule.dtRangeFinish = new Date();
    this.loading = true;
    return this.restApi.updateServiceSchedule(id, this.serviceSchedule).subscribe(data => {
      this.closebutton.nativeElement.click();
      this.clearErrorMess();
      this.loadServiceSchedules();
      this.loading = false;
    }, err => this.displayError(err));
  }

  selectTime(value) {
    this.serviceSchedule.time = value;
    let i = -10;
    this.timeline = new Array(Math.floor(value / 10))
      .fill('')
      .map(() => { return i += 10; });
  }

  // Resource
  loadResources() {
    return this.restApi.getResources().subscribe((data: Resource[]) => {
      this.resources = [];
      for (const item of data) {
        const type = new Resource();
        Object.assign(type, item);
        this.resources.push(type);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      } else {
        this.errorMessage = err;
      }
    });
  }

  loadResource(id: number) {
    this.loading = true;
    return this.restApi.getResource(id).subscribe((data: Resource) => {
      this.resource = new Resource();
      this.activeState = 'action-update';
      Object.assign(this.resource, data);
      this.loading = false;
    });
  }

  createResource() {
    this.activeState = 'action-append';
    this.resource = new Resource();
  }

  addResource() {
    this.resourceForm.form.markAllAsTouched();
    if (this.resourceForm.valid) {
      this.resource.classId = this.getById(this.resourceTypes, this.resource.typeId).classId;
      return this.restApi.addResource(this.resource).subscribe((data: {}) => {
        this.closebutton.nativeElement.click();
        this.loadResources();
      });
    }
  }

  updateResource(id: number) {
    this.resourceForm.form.markAllAsTouched();
    if (this.resourceForm.valid) {
      this.restApi.updateResource(id, this.resource).subscribe(data => {
        this.closebutton.nativeElement.click();
        this.loadResources();
      });
    }
  }

  deleteResource(id: number) {
    this.resource.dtRangeFinish = new Date();
    this.loading = true;
    return this.restApi.updateResource(id, this.resource).subscribe(data => {
      this.closebutton.nativeElement.click();
      this.clearErrorMess();
      this.loadResources();
      this.loading = false;
    }, err => this.displayError(err));
  }

  // Division
  loadDivisions() {
    return this.restApi.getDivisions().subscribe((data: Division[]) => {
      this.divisions = [];
      for (const item of data) {
        const division = new Division();
        Object.assign(division, item);
        this.divisions.push(division);
      }
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      } else {
        this.errorMessage = err;
      }
    });
  }

  loadDivision(id: number) {
    this.loading = true;
    return this.restApi.getDivision(id).subscribe((data: Division) => {
      this.activeState = 'action-update';
      Object.assign(this.division, data);
      this.loading = false;
    });
  }

  createDivision() {
    this.activeState = 'action-append';
    this.division = new Division();
  }

  addDivision() {
    this.divisionForm.form.markAllAsTouched();
    if (this.divisionForm.valid) {
      return this.restApi.addDivision(this.division).subscribe((data: {}) => {
        this.closebutton.nativeElement.click();
        this.loadDivisions();
      });
    }
  }

  updateDivision(id: number) {
    this.divisionForm.form.markAllAsTouched();
    if (this.divisionForm.valid) {
      this.restApi.updateDivision(id, this.division).subscribe(data => {
        this.closebutton.nativeElement.click();
        this.loadDivisions();
      });
    }
  }

  deleteDivision(id: number) {
    this.division.dtRangeFinish = new Date();
    this.loading = true;
    return this.restApi.updateDivision(id, this.division).subscribe(data => {
      this.closebutton.nativeElement.click();
      this.clearErrorMess();
      this.loadDivisions();
      this.loading = false;
    }, err => this.displayError(err));
  }


  createDiagram(operation?: any) {
    this.myData = [];
    if (operation && operation.finish && operation.finish < operation.start) {
      operation.finish = operation.start + 1;
    }
    this.serviceSchedule.processes.forEach(item => {
      if (!item.name) {
        item.name = this.getById(this.operations, item.id).name;
      }
      if (item.start < item.finish) {
        this.myData.push([item.name, new Date(2000, 1, 1, 0, item.start), new Date(2000, 1, 1, 0, item.finish)]);
      } else {
        this.myData.push([item.name, new Date(2000, 1, 1, 0, item.finish), new Date(2000, 1, 1, 0, item.start)]);
      }
    });
  }


  addModificationModifiers(element) {
    const num = element.length;
    element[num] = new ModificationModifiers();
  }

  addOperationServiceSchedule(element) {
    const num = element.length || 0;
    element[num] = new ServiceSchedulesProcesses();
  }

  deleteElement(array, id) {
    array.splice(id, 1);
  }

  // loadModifiers() {
  //   return this.restApi.getModifiers().then((data: Reference[]) => {
  //     this.modifiers = [];
  //     for (const item of data) {
  //       const modifier = new Reference();
  //       Object.assign(modifier, item);
  //       this.modifiers.push(modifier);
  //     }
  //   });
  // }

  loadReference() {
    for (const key in this.linkReferences) {
      if (Object.prototype.hasOwnProperty.call(this.linkReferences, key)) {
        const link = this.linkReferences[key];
        this.restApi.getReference(link).then((data: Reference[]) => {
          this.references[link] = [];
          for (const item of data) {
            const reference = new Reference();
            Object.assign(reference, item);
            this.references[link].push(reference);
          }
        });
      }
    }
  }

  loadServiceScheduleTypes() {
    return this.restApi.getServiceScheduleTypes().then((data: Reference[]) => {
      this.serviceScheduleTypes = [];
      for (const item of data) {
        const type = new Reference();
        Object.assign(type, item);
        this.serviceScheduleTypes.push(type);
      }
    });
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    this.activeState = 'action-append';
    switch (this.activeTab) {
      case 'tab-resource-types': {
        this.loadResourceTypes();
        break;
      }
      case 'tab-operations': {
        this.loadOperations();
        break;
      }
      case 'tab-modifications': {
        this.loadModifications();
        break;
      }
      case 'tab-service-schedules': {
        this.loadServiceScheduleTypes();
        this.loadOperations();
        this.loadModifications();
        this.loadServiceSchedules();
        break;
      }
      case 'tab-resources': {
        this.loadResources();
        this.loadDivisions();
        break;
      }
      case 'tab-divisions': {
        this.loadDivisions();
        break;
      }
    }
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  clearErrorMess() {
    this.errorMessage = '';
  }

  getVersions(array, id: number) {
    array.forEach(element => {
      if (element.id === id) {
        this.versions = element.versions;
      }
    });
  }

  getById(array, id: number) {
    if (id) {
      return array.filter( el => el.id === id)[0];
    }
  }

  selectModifer(el, id) {
    el.id = id;
    el.value = null;
    el.name = this.getById(this.modifiers, el.id).name;
  }

  dragEnd($event: CdkDragEnd, index: number) {
    const step = $event.source.element.nativeElement.parentElement.offsetWidth / this.serviceSchedule.time;
    const newPosition = $event.source.element.nativeElement.offsetLeft + $event.source.getFreeDragPosition().x;
    const len = this.serviceSchedule.processes[index].finish - this.serviceSchedule.processes[index].start;
    const start = Math.round(newPosition / step);
    this.serviceSchedule.processes[index].finish = start + len;
    this.serviceSchedule.processes[index].start = start;
    this.stickClass = -1;
    $event.source.reset();
  }

  dragMoved($event: CdkDragEnd, index: number) {
    const step = $event.source.element.nativeElement.parentElement.offsetWidth / this.serviceSchedule.time;
    const newPosition = $event.source.element.nativeElement.offsetLeft + $event.source.getFreeDragPosition().x;
    const start = Math.round(newPosition / step);
    if (!this.serviceSchedule.processes.find( el => {
      if (el.finish === start && this.serviceSchedule.processes.indexOf(el) !== index) {
        this.stickClass = this.serviceSchedule.processes.indexOf(el);
        return el;
      }
    })) {
      this.stickClass = -1;
    }
  }

  selectWidth($event, el, index: number) {
    const step = $event.source.element.nativeElement.parentElement.offsetWidth / this.serviceSchedule.time;
    const newPosition = $event.source.element.nativeElement.offsetLeft + $event.source.getFreeDragPosition().x + 2;
    const start = Math.round(newPosition / step);
    const operation = this.serviceSchedule.processes[index];
    operation.finish = start;
    /*if (operation.finish - operation.start < operation.operation.duration) {
      operation.finish = operation.start + operation.operation.duration;
    }*/
    if (operation.finish < operation.start) {
      operation.finish = operation.start + 1;
    }
    $event.source.reset();
  }

  selectDuration(operation, id) {
    if (id) {
      const versions = this.getById(this.operations, +operation.id).versions;
      const version = this.getById(versions, id);
      operation.operation = version;
      /*if (operation.finish - operation.start < operation.operation.duration) {
        operation.finish = operation.start + operation.operation.duration;
      }*/
      if (operation.finish < operation.start) {
        operation.finish = operation.start + 1;
      }
    }
  }

  displayError(err) {
    this.loading = false;
    if (err.status === 409) {
      this.errorMessage = err.message;
      this.errorType = 'warning';
      this.userAction = 'update_sender';
    } else if (err.type) {
      this.errorType = err.type;
      this.errorMessage = err.message;
      console.log('Error: ' + err.message + '\ndetail:' + err.detail);
    } else {
      this.errorMessage = err;
    }
  }

  confirmAction(func: Function, id: number) {
    this.userAction = 'deleteItem';
    this.errorType = 'warning';
    this.errorMessage = this.globalI18n.getMessage(ModuleName.Workflow, 'deleteItem'); // 'Delete?';
    this.executableFunction = func;
    this.removableId = id;
  }

}
