<ul class="nav nav-tabs">
  <li class="nav-item">
    <a id="tab-stands" [ngClass]="{'active': showTab('tab-stands')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Flights@@adm.infMod.tab.stands">
      Stands
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-terminals" [ngClass]="{'active': showTab('tab-terminals')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Flights@@adm.infMod.tab.terminals">
      Terminals
    </a>
  </li>
</ul>

<!-- Tab stands -->
<div *ngIf="showTab('tab-stands')" class="bg-white p-3">
  <div class="row my-2">
    <div class="col-6 pl-0">
      <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="createStand()">
        <i class="material-icons">add</i>
        <span i18n="adm|Flights@@adm.Flights.tab.stands.button.new">New</span>
      </button>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead>
      <tr>
        <th class="text-center text-uppercase" i18n="adm|Flights@@adm.Flights.tab.stands.table.column.name">
          Name
        </th>
        <th class="text-center text-uppercase" i18n="adm|Flights@@adm.Flights.tab.stands.table.column.standTypeName">
          Stand type
        </th>
        <th class="text-center text-uppercase" i18n="adm|Flights@@adm.Flights.tab.stands.table.column.lastupdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let stand of stands" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadStand(stand.id)">
        <td>{{ stand.name }}</td>
        <td>{{ stand.standTypeNames }}</td>
        <td>{{ stand.lastupdate | date: 'yyyy-MM-dd' : 'UTC+0' }}</td>
      </tr>
    </tbody>
  </table>

  <form #standForm="ngForm">
    <div class="modal fade bd-example-modal-xl new-folder" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState=='action-append'"
                class="modal-title"
                id="exampleModalScrollableTitle">
              <span i18n="adm|Flights Modal@@adm.Flights.modal.stand.header.add">Add new stand</span>&nbsp;{{stand.name}}
            </h5>
            <h5 *ngIf="activeState=='action-update'"
                class="modal-title"
                id="exampleModalScrollableTitle">
              <span i18n="adm|Flights Modal@@adm.Flights.modal.stand.header.edit">Edit stand</span>&nbsp;{{stand.name}}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-4">
                <label for="standName" i18n="adm|Flights Modal@@adm.Flights.modal.stand.field.name">
                  Name
                </label>
                <input id="standName"
                       type="text"
                       class="form-control form-control-sm"
                       name="standName"
                      [(ngModel)]="stand.name"
                      #standName="ngModel"
                      required>
                  <div *ngIf="!standName?.valid && (standName?.dirty || standName?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="standName.errors.required"
                          i18n="adm|Flights Modal@@adm.Flights.modal.filters.err.nameReq">
                      Name is required
                    </div>
                  </div>
              </div>
              <div class="col-4">
                <label i18n="adm|Flights Modal@@adm.Flights.modal.stand.field.dtStart">
                  Date range start
                </label>
                <input type="date"
                      class="form-control"
                      name="standStart"
                      [ngModel]="stand.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                      (blur)="stand.dtRangeStart = parseDate($event.target.value)"
                      #standStart="ngModel">
                  <div *ngIf="!standStart?.valid && (standStart?.dirty || standStart?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="standStart.errors.required"
                          i18n="adm|Flights Modal@@adm.Flights.modal.stand.err.dtRangeStart">
                      Date range start is required
                    </div>
                  </div>
              </div>
              <div class="col-4">
                <label i18n="adm|Flights Modal@@adm.Flights.modal.stand.field.dtFinish">
                  Date range finish
                </label>
                <input type="date"
                      class="form-control"
                      name="standFinish"
                      [ngModel]="stand.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                      (blur)="stand.dtRangeFinish = parseDate($event.target.value)"
                      #standFinish="ngModel">
                  <div *ngIf="!standFinish?.valid && (standFinish?.dirty || standFinish?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="standFinish.errors.required"
                          i18n="adm|Flights Modal@@adm.Flights.modal.stand.err.dtRangeFinish">
                      Date range finish is required
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState=='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="addStand()"
                      i18n="adm|Flights Modal@@adm.Flights.modal.stand.button.create">
                Create
              </button>
            </div>
            <div *ngIf="activeState=='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="deleteStand(stand.id)"
                      i18n="adm|Flights Modal@@adm.Flights.modal.stand.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState=='action-update'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="updateStand(stand.id)"
                      i18n="adm|Flights Modal@@adm.Flights.modal.stand.button.save">
                Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-gray-blue-dark"
                    data-dismiss="modal"
                    i18n="adm|Flights Modal@@adm.Flights.modal.stand.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>

<!-- Tab terminals -->
<div *ngIf="showTab('tab-terminals')" class="bg-white p-3">
  <div class="row my-2">
    <div class="col-6 pl-0">
      <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="createTerminal()">
        <i class="material-icons">add</i>
        <span i18n="adm|Flights@@adm.Flights.tab.terminals.button.new">New</span>
      </button>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead>
      <tr>
        <th class="text-center text-uppercase" i18n="adm|Flights@@adm.Flights.tab.terminal.table.column.name">
          Name
        </th>
        <th class="text-center text-uppercase" i18n="adm|Flights@@adm.Flights.tab.terminal.table.column.lastupdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let terminal of terminals" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadTerminal(terminal.id)">
        <td>{{ terminal.name }}</td>
        <td>{{ terminal.lastupdate | date: 'yyyy-MM-dd' : 'UTC+0' }}</td>
      </tr>
    </tbody>
  </table>

  <form #terminalForm="ngForm">
    <div class="modal fade bd-example-modal-xl new-folder" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState=='action-append'"
                class="modal-title"
                id="exampleModalScrollableTitle">
              <span i18n="adm|Flights Modal@@adm.Flights.modal.terminal.header.add">Add new terminal</span>&nbsp;{{terminal.name}}
            </h5>
            <h5 *ngIf="activeState=='action-update'"
                class="modal-title"
                id="exampleModalScrollableTitle">
              <span i18n="adm|Flights Modal@@adm.Flights.modal.terminal.header.edit">Edit terminal</span>&nbsp;{{terminal.name}}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-4">
                <label for="terminalName" i18n="adm|Flights Modal@@adm.Flights.modal.terminal.field.name">
                  Name
                </label>
                <input id="terminalName"
                       type="text"
                       class="form-control form-control-sm"
                       name="terminalName"
                      [(ngModel)]="terminal.name"
                      #terminalName="ngModel"
                      required>
                  <div *ngIf="!terminalName?.valid && (terminalName?.dirty || terminalName?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="terminalName.errors.required"
                          i18n="adm|Flights Modal@@adm.Flights.modal.filters.err.nameReq">
                      Name is required
                    </div>
                  </div>
              </div>
              <div class="col-4">
                <label i18n="adm|Flights Modal@@adm.Flights.modal.terminal.field.dtStart">
                  Date range start
                </label>
                <input type="date"
                      class="form-control"
                      name="terminalStart"
                      [ngModel]="terminal.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                      (blur)="terminal.dtRangeStart = parseDate($event.target.value)"
                      #terminalStart="ngModel">
                  <div *ngIf="!terminalStart?.valid && (terminalStart?.dirty || terminalStart?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="terminalStart.errors.required"
                          i18n="adm|Flights Modal@@adm.Flights.modal.stand.err.dtRangeStart">
                      Date range start is required
                    </div>
                  </div>
              </div>
              <div class="col-4">
                <label i18n="adm|Flights Modal@@adm.Flights.modal.stand.field.dtFinish">
                  Date range finish
                </label>
                <input type="date"
                      class="form-control"
                      name="terminalFinish"
                      [ngModel]="terminal.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                      (blur)="terminal.dtRangeFinish = parseDate($event.target.value)"
                      #terminalFinish="ngModel">
                  <div *ngIf="!terminalFinish?.valid && (terminalFinish?.dirty || terminalFinish?.touched)"
                      class="invalid-feedback d-block">
                    <div *ngIf="terminalFinish.errors.required"
                          i18n="adm|Flights Modal@@adm.Flights.modal.terminal.err.dtRangeFinish">
                      Date range finish is required
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState=='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="addTerminal()"
                      i18n="adm|Flights Modal@@adm.Flights.modal.terminal.button.create">
                Create
              </button>
            </div>
            <div *ngIf="activeState=='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="deleteTerminal(terminal.id)"
                      i18n="adm|Flights Modal@@adm.Flights.modal.terminal.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState=='action-update'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="updateTerminal(terminal.id)"
                      i18n="adm|Flights Modal@@adm.Flights.modal.terminal.button.save">
                Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-gray-blue-dark"
                    data-dismiss="modal"
                    i18n="adm|Flights Modal@@adm.Flights.modal.terminal.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>

<div *ngIf="errorMessage" class="ms-modal-message">
  <div class="modal-content w-25">
    <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                    'bg-warning': errorType == 'warning',
                                    'bg-danger': errorType == 'error'}">
      <h5 *ngIf="errorType == 'info'" class="modal-title"
        i18n="adm|Flights@@adm.flights.main.modalMessage.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="errorType == 'warning'" class="modal-title"
        i18n="adm|Flights@@adm.flights.main.modalMessage.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="errorType == 'error'" class="modal-title"
        i18n="adm|Flights@@adm.flights.main.modalMessage.errMes.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-blue" (click)="userAnswer = true"
        i18n="adm|Flights@@adm.flights.main.modalMessage.errMes.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="userAnswer = false" i18n="Flights@@adm.flights.main.modalMessage.errMes.button.no">
        No
      </button>
      <button type="button" *ngIf="modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="errorMessage = null"
        i18n="adm|Flights@@adm.flights.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>
