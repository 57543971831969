export class ReferanceAirport {
  constructor() {
    this.iata = '';
    this.icao = '';
    this.name = [null, null];
    this.code = [null, null];
    this.cityId = null;
    this.tzOffset = [null, null];
    this.dtRange = [null, null];
    this.lastupdate = undefined;
  }

  id: number;
  iata: string;
  icao: string;
  code: Array<string>;
  private name: Array<string>;
  private tzOffset: Array<number>;
  private dtRange: Array<Date>;
  cityId: number;
  cityIata: string;
  cityIcao: string;
  city: Array<string>;
  regionId: number;
  regionIata: string;
  regionIcao: string;
  region: Array<string>;
  countryId: number;
  countryIata: string;
  countryIcao: string;
  country: Array<string>;
  localRegionId: number;
  localRegionName: Array<string>;
  lastupdate: string;

  /**
   * Функции чтения/записи времени актуальности записи
   */

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    //this.dtRange[0] = value;
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }

  /**
   * Функции чтения/записи смещения времени
   * относительно UTC в зимний и летний период
   */

  get tzOffsetWinter(): number {
    if ((this.tzOffset) && this.tzOffset.length >= 1) {
      return this.tzOffset[0];
    }
  }

  set tzOffsetWinter(value: number) {
    this.tzOffset[0] = value;
  }

  get tzOffsetSummer(): number {
    if ((this.tzOffset) && this.tzOffset.length === 2) {
      return this.tzOffset[1];
    }
  }

  set tzOffsetSummer(value: number) {
    this.tzOffset[1] = value;
  }

  /**
   * Функции чтения/записи наименований аэропорта
   * на английском и локальном языке
   */

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  set nameEnglish(value: string) {
    this.name[0] = value;
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  set nameLocal(value: string) {
    this.name[1] = value;
  }

  /**
   * Функции чтения/записи кодов аэропорта
   * на английском и локальном языке
   */

  get codeEnglish(): string {
    if ((this.code) && (this.code.length >= 1)) {
      return this.code[0];
    } else {
      return null;
    }
  }

  set codeEnglish(value: string) {
    this.code[0] = value;
  }

  get codeLocal(): string {
    if ((this.code) && (this.code.length === 2)) {
      return this.code[1];
    } else {
      return null;
    }
  }

  set codeLocal(value: string) {
    this.code[1] = value;
  }

  /**
   * Функция вывода наименований аэропорта в строку
   */
  get names(): string {
    if ((this.name) && (this.name.length > 0)) {
      return this.name.join(',  ');
    } else {
      return null;
    }
  }

  get shortCode(): string {
    if (this.iata !== '') {
      return this.iata;
    } else if (this.icao !== '') {
      return this.icao;
    } else {
      return this.codeEnglish;
    }
  }
}
