import { Component, Input, OnInit } from '@angular/core';
import { GlobalSettings } from '@settings/global-settings';
import { LostFoundModuleRestApiService } from '../../../services/lost-found-rest-api.service';

@Component({
  selector: 'logs-tab',
  templateUrl: './logs-tab.component.html',
  styleUrls: ['./logs-tab.component.less']
})
export class LogsTabComponent implements OnInit {
  @Input() statementId: string;
  logs: Array<{dt: Date, id: number, text: string}> = [];

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
  ) { }

  ngOnInit(): void {
    this.loadLogs();
    this.readLogs();
  }

  async loadLogs() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getLogs(this.statementId, xRequestId);
    this.logs = data;
  }

  async readLogs() {
    const xRequestId = this.globalSettings.randomUuid;
    await this.restApi.readLogs(this.statementId, xRequestId);
  }

}
