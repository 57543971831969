import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { checkValidAddress } from '../functions/validationAddresses';

@Directive({
  selector: '[addressValidator]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: AddressValidatorDirective, multi: true}
  ]
})
export class AddressValidatorDirective implements Validator {

  @Input('addressValidator') channelId: number;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && !checkValidAddress(control.value, +this.channelId, 'LAT')) {
      return null;
    } else {
      return {pattern: {value: control.value}};
    }
  }

}
