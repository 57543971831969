import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { CloseReason, Flight, Parking } from '../parking-places-data/parking-places';

@Component({
  selector: 'app-parking-places',
  templateUrl: './parking-places.component.html',
  styleUrls: ['./parking-places.component.less']
})
export class ParkingPlacesComponent implements OnInit {

  parkings: Parking[] = [];
  parking: Parking = new Parking();
  
  flights: Flight[] = [];
  flight: Flight = new Flight();

  closeReasons: CloseReason[] = [];

  mapView: boolean = true; // Вид
  sizeMap: number = 0; // Множитель увеличения

  action: string = ''; // Действие: add, edit
  userRole = 'user'; // Роль: admin, user

  selectedFlight: number = null;
  selectedParking: number = null;

  errorMessage = '';
  errorType = '';

  todayDate = new Date();

  constructor() { }

  ngOnInit(): void {
    this.getCloaseReason();
    this.getFlights();
    this.getParkings();
    setInterval( () => this.todayDate = new Date(), 10000 )
  }

  selectSizeMap() {
    console.log(this.sizeMap);
  }

  createParking() {
    this.action = 'add';
    this.parking = new Parking();
  }

  getParkings() {
    let data = [{
      id: 1, name: '1', positionX: 280, positionY: 45,
      width: 120, height: 150,
      information: 'Parking size: width 54m, height 70m',
      warning: 'Taxing of A-321, B-737 and lighter aircraft is allowed',
      closeFrom: null, closeTo: null, closeReasonId: null,
      flightId: 3
    }, {
      id: 2, name: '2', positionX: 405, positionY: 45,
      width: 120, height: 150,
      information: '', warning: '',
      closeFrom: null, closeTo: null, closeReasonId: null,
      flightId: 1
    }, {
      id: 3, name: '3', positionX: 530, positionY: 45,
      width: 120, height: 140,
      information: '', warning: '',
      closeFrom: null, closeTo: null, closeReasonId: null,
      flightId: null
    }, {
      id: 4, name: '4', positionX: 655, positionY: 45,
      width: 120, height: 140,
      information: '', warning: '',
      closeFrom: new Date('2020-10-14T12:25'), closeTo: new Date('2020-12-18T17:25'), closeReasonId: 1,
      closeReasonName: ['Fuel spilled', 'Разлито топливо'],
      flightId: null
    }];
    this.parkings = [];
    for (const item of data) {
      const parking = new Parking();
      Object.assign(parking, item);
      if (parking.flightId) {
        let flight = this.flights.filter( el => el.id==parking.flightId )[0];
        console.log(flight)
        parking.flightInfo = {...flight};
      }
      this.parkings.push(parking);
    }
  }

  addParking(id: number) {
    this.parking.id = id;
    this.parkings.push(this.parking);
    this.action = '';
    console.log(this.parkings);
  }

  editParking(id: number) {
    this.parking = this.parkings.filter( el => el.id==id)[0];
    console.log(this.parking);
    this.action = 'edit';
    this.selectedParking = id;
    //this.selectedFlight = this.parking.flightId;
  }

  deleteParking(id: number) {
    this.parkings = this.parkings.filter( el => el.id !=id);
    console.log(this.parkings);
  }

  getCloaseReason() {
    let data = [
      {id: 1, name: ['Fuel spilled', 'Разлито топливо']},
      {id: 2, name: ['Coating repair', 'Ремонт покрытия']},
      {id: 3, name: ['Snow cleaning', 'Очистка снега']}
    ];
    this.closeReasons = [];
    for (const item of data) {
      const reason = new CloseReason();
      Object.assign(reason, item);
      this.closeReasons.push(reason);
    }
  }

  getFlights() {
    let data = [
      {id: 1, arrival: 'S7-1234', plane: 'FPLK', departure: 'S7-1234', arrivalTime: new Date('2020-10-14T04:25'), departureTime: new Date('2020-10-14T06:25'), parkingName: '2', parkingId: 2, aircraft: 'A-318'},
      {id: 2, arrival: 'S7-1524', plane: 'KLJR', departure: 'S7-1524', arrivalTime: new Date('2020-10-14T14:25'), departureTime: new Date('2020-10-14T15:25'), parkingName: '', parkingId: null, aircraft: 'A-596'},
      {id: 3, arrival: 'S7-8854', plane: 'HGYM', departure: 'S7-8854', arrivalTime: new Date('2020-10-14T01:25'), departureTime: new Date('2020-10-14T09:25'), parkingName: '1', parkingId: 1, aircraft: 'A-524'},
      {id: 4, arrival: 'S7-6542', plane: 'JYUF', departure: 'S7-6542', arrivalTime: new Date('2020-10-14T05:25'), departureTime: new Date('2020-10-14T23:25'), parkingName: '', parkingId: null, aircraft: 'A-358'},
    ];
    this.flights = [];
    for (const item of data) {
      const flight = new Flight();
      Object.assign(flight, item);
      this.flights.push(flight);
    }
  }

  towingPlane(value) {
    value = +value;
    this.parking.flightId = null;
    this.parking.flightInfo = null;
    this.parking = this.parkings.filter( el=>el.id==value )[0];
    this.parking.flightId = value;
    let flight = this.flights.filter( el=>el.id==value )[0];
    console.log(flight);
    flight.parkingId = value;
    flight.parkingName = this.parking.name;
    //this.parking.flightInfo = new Flight();
    this.parking.flightInfo = {...flight};
  }

  closeParking(value) {
  }

  openParking(id) {
    this.parking.closeFrom = null;
    this.parking.closeTo = null;
    this.parking.closeReasonId = null;
  }

  parseDate(dateString: string, time?): Date {
    if (time && dateString) {
      return new Date(dateString + 'T' + time);
    } else if (!time && dateString) {
      return new Date(dateString);
    }
    return null;
  }

  dragEnd($event: CdkDragEnd, id: number) {
    if (this.userRole=='admin') {
      this.editParking(id);
      const size = 100 + this.sizeMap * 10;
      this.parking.positionX += Math.round($event.source.getFreeDragPosition().x * 100 / size);
      this.parking.positionY +=  Math.round($event.source.getFreeDragPosition().y * 100 / size);
      $event.source.reset();
    } else {
      $event.source._dragRef.reset();
    }
  }

  dragStart() {}

  dragMoved($event: CdkDragEnd) {
  }

  selectFlight(id: number) {
    this.selectedFlight = id;
    this.flight = this.getById(this.flights, id);
  }

  setPlane() {
    let flight = this.getById(this.flights, this.selectedFlight);
    if (flight) {
      flight.parkingName = this.parking.name;
      flight.parkingId = this.selectedParking;
    }
    this.parking.flightId = this.selectedFlight;
    this.parking.flightInfo = {...flight};
  }

  remotePlane() {
    this.flight.parkingId = null;
    this.flight.parkingName = null;
    let parking = this.parkings.filter(el => el.flightId === this.selectedFlight)[0];
    parking.flightId = null;
    parking.flightInfo = null;
  }

  clearParking() {
    let flight = this.getById(this.flights, this.parking.flightId);
    flight.parkingId = null;
    flight.parkingName = null;
    this.parking.flightId = null;
    this.parking.flightInfo = null;
    this.parking = new Parking();
  }

  getById(array, id: number) {
    let elem = array.filter( el => el.id===id);
    if (elem) {
      return elem[0];
    } else {
      return null;
    }
  }

  clearErrorMess() {
    this.errorType = '';
    this.errorMessage = '';
  }

}
