<ul class="nav nav-tabs mt-2">
  <li class="nav-item">
    <a id="tab-comman" [ngClass]="{'active': showTab('tab-comman')}" class="nav-link" (click)="changeTab($event)">
      Comman
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-barcode" [ngClass]="{'active': showTab('tab-barcode')}" class="nav-link" (click)="changeTab($event)">
      Barcode generator
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-balance" [ngClass]="{'active': showTab('tab-balance')}" class="nav-link" (click)="changeTab($event)">
      Weight balance
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-lost" [ngClass]="{'active': showTab('tab-lost')}" class="nav-link" (click)="changeTab($event)">
      Lost and Found
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-reference" [ngClass]="{'active': showTab('tab-reference')}" class="nav-link" (click)="changeTab($event)">
      References
    </a>
  </li>
</ul>

<div *ngIf="showTab('tab-comman')" class="bg-white p-3">
  <div class="row">
    <div class="col-12 mb-4">
      <label>Channel</label>
      <select id="channel" class="form-control" name="channel" [value]="channel"
        (input)="channel = $event.target.value">
        <option value="aftn" selected>aftn</option>
        <option value="sitatex">sitatex</option>
      </select>
    </div>
    <div class="col-12 mb-4">
      <label>Text message (header + body)</label>
      <textarea name="message" id="message" class="form-control" [(ngModel)]="message" cols="30" rows="10"></textarea>
    </div>
    <div class="col-12">
      <button class="btn btn-primary" (click)="sendMessage(channel, message)">Отправить</button>
    </div>
  </div>

</div>

<div *ngIf="showTab('tab-barcode')" class="bg-white p-3">
  <div class="row">
    <div class="col-12 mb-4">
      <label>Select flight</label>
      <select id="flight" class="form-control" name="flight" [value]="selectedFlightId"
        (input)="generateBarcodeForFlight(+$event.target.value)">
        <option *ngFor="let item of flights" value="{{item.id}}">
          <span class="font-weight-bold">Flight: </span>{{item.flight}} <span class="font-weight-bold">To:
          </span>{{item.route[0].iata}} ({{item.route[0].name}}) <span class="font-weight-bold">Sch:
          </span>{{item.times[0]}}
        </option>
      </select>
    </div>
    <div class="col-12 mb-4">
      <button class="btn btn-primary mr-2" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPassanger(1)">
        Passanger 1
      </button>
      <button class="btn btn-primary mx-2" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPassanger(2)">
        Passanger 2
      </button>
      <button class="btn btn-primary mx-2" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPassanger(3)">
        Passanger 3
      </button>
      <button class="btn btn-primary mx-2" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPassanger(4)">
        Passanger 4
      </button>
      <button class="btn btn-primary mx-2" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPassanger(6)">
        Infant SEAT: INF
      </button>
      <button class="btn btn-primary mx-2" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPassanger(7)">
        Infant SEAT: Empty
      </button>
      <button class="btn btn-primary mx-2" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPassanger(5)">
        Random Passanger
      </button>
    </div>
  </div>
</div>

<div *ngIf="showTab('tab-barcode')">
  <div #airportFormFade class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 class="modal-title">
            Passanger board pass
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mb-2">
            <div class="col-12">
              <p class="h4 text-dark">Edit field and press "Enter" to generate new Barcode</p>
            </div>
          </div>
          <hr>
          <div class="row mb-2">
            <div class="col-2">
              <label class="w-100">
                Format Code
              </label>
              <input type="text" class="form-control form-control-sm" name="formatCode" #formatCode="ngModel"
                [ngModel]="referanceBoardPass.formatCode" minlength="2" maxlength="2" disabled>
            </div>
            <div class="col-4">
              <label class="w-100">
                Passenger Name
              </label>
              <input type="text" class="form-control form-control-sm" name="name" #name="ngModel"
                [ngModel]="referanceBoardPass.name" (keyup.enter)="addName($event.target.value)" minlength="0"
                maxlength="20">
            </div>
            <div class=" col-3">
              <label class="w-100">
                Ticket Type
              </label>
              <input type="text" class="form-control form-control-sm" name="ticketType" #ticketType="ngModel"
                [ngModel]="referanceBoardPass.ticketType" (keyup.enter)="addTicket($event.target.value)" minlength="1"
                maxlength="1">
            </div>
            <div class=" col-3">
              <label class="w-100">
                PNR Code
              </label>
              <input type="text" class="form-control form-control-sm" name="pnr" #pnr="ngModel"
                [ngModel]="referanceBoardPass.pnr" (keyup.enter)="addPNR($event.target.value)" minlength="0"
                maxlength="7">
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-2">
              <label class="w-100">
                From Airport
              </label>
              <input type="text" class="form-control form-control-sm" name="from" #from="ngModel"
                [ngModel]="referanceBoardPass.from" minlength="3" maxlength="3" disabled>
            </div>
            <div class="col-2">
              <label class="w-100">
                To Airport
              </label>
              <input type="text" class="form-control form-control-sm" name="to" #to="ngModel"
                [ngModel]="referanceBoardPass.to" minlength="3" maxlength="3" disabled>
            </div>
            <div class="col-2">
              <label class="w-100">
                Airline
              </label>
              <input type="text" class="form-control form-control-sm" name="airline" #airline="ngModel"
                [ngModel]="referanceBoardPass.airline" minlength="2" maxlength="3" disabled>
            </div>
            <div class="col-2">
              <label class="w-100">
                Flight
              </label>
              <input type="text" class="form-control form-control-sm" name="flight" #flight="ngModel"
                [ngModel]="referanceBoardPass.flight" minlength="0" maxlength="5" disabled>
            </div>
            <div class="col-2">
              <label class="w-100">
                Day of Flight
              </label>
              <input type="text" class="form-control form-control-sm" name="date" #date="ngModel"
                [ngModel]="referanceBoardPass.date" minlength="0" maxlength="3" disabled>
            </div>
            <div class="col-2">
              <label class="w-100">
                Date of Flight
              </label>
              <input type="text" class="form-control form-control-sm" name="date" #date="ngModel"
                [ngModel]="currentFlightPlanDateTime | date: 'dd.MM.yyyy'" disabled>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-2">
              <label class="w-100">
                Compartment Code
              </label>
              <input type="text" class="form-control form-control-sm" name="compartmentCode" #compartmentCode="ngModel"
                [ngModel]="referanceBoardPass.compartmentCode" disabled>
            </div>
            <div class="col-3">
              <label class="w-100">
                Seat Number
              </label>
              <input type="text" class="form-control form-control-sm" name="seatNumber" #seatNumber="ngModel"
                [ngModel]="referanceBoardPass.seatNumber" (keyup.enter)="addSeatNumber($event.target.value)"
                minlength="0" maxlength="4">
            </div>
            <div class="col-3">
              <label class="w-100">
                Sequence Number
              </label>
              <input type="text" class="form-control form-control-sm" name="sequenceNumber" #sequenceNumber="ngModel"
                [ngModel]="referanceBoardPass.sequenceNumber" (keyup.enter)="addSequenceNumber($event.target.value)"
                minlength="0" maxlength="5">
            </div>
            <div class="col-2">
              <label class="w-100">
                Passenger Status
              </label>
              <input type="text" class="form-control form-control-sm" name="pasangerStatus" #pasangerStatus="ngModel"
                [ngModel]="referanceBoardPass.pasangerStatus" disabled>
            </div>
            <div class="col-2">
              <label class="w-100">
                Field Size
              </label>
              <input type="text" class="form-control form-control-sm" name="fieldSize" #fieldSize="ngModel"
                [ngModel]="referanceBoardPass.fieldSize" disabled>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 pb-4">
              <label class="w-100">
                Barcode PDF417 text
              </label>
              <input type="text" class="form-control form-control-sm" name="compartmentCode" #compartmentCode="ngModel"
                [(ngModel)]="barCodeText">
            </div>
            <div class="col-12 pt-1 bg-white d-flex justify-content-center">
              <pdf417barcode [text]="barCodeText" [opts]="{ pxw: 2, pxh: 2, errlvl: -1 }">
              </pdf417barcode>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button *ngIf="passangerId == 5" type="button" class="btn btn-primary btn-sm" (click)="loadPassanger(5)">
            Random
          </button>
          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showTab('tab-balance')" class="bg-white p-3">
  <div class="row">
    <div class="col-6 mb-4">
      <label>Select flight</label>
      <select id="flight" class="form-control" name="flight" [value]="selectedFlightId"
        (input)="setFlightIdWB(+$event.target.value)" (change)="setDefaultTelegramText()">
        <option *ngFor="let flight of flightsWB" value="{{flight.id}}">
          <span class="font-weight-bold">{{flight.airlineIata}} {{flight.flightNumber}}</span> {{ flight.homeRoute ?
          flight.homeRoute.displayName : '' }} - {{ flight.nextRoute ? flight.nextRoute.displayName : '' }} / {{
          flight.homeRoute ? (flight.homeRoute.dtDepartureShow | date: 'dd.MM.yyyy HH:mm') : '' }}
        </option>
      </select>
    </div>
    <div class="col-6 mb-4">
      <label>Type telegram</label>
      <select id="typeTelegram" class="form-control" name="typeTelegram" [value]="balance.typeTelegram"
        (input)="balance.typeTelegram = $event.target.value" (change)="setDefaultTelegramText()">
        <option value="wgtmsg" selected>Fuel message</option>
        <option value="uws">UWS Unit Weight Statement message</option>
        <option value="pnl_adl">PNL/ADL Passengers list message</option>
      </select>
    </div>
    <div class="col-12 mb-4">
      <label>Text message</label>
      <textarea name="textTelegram" id="textTelegram" class="form-control" [(ngModel)]="balance.textTelegram" cols="30"
        rows="10">
        {{ balance.textTelegram }}
      </textarea>
    </div>
    <div class="col-12">
      <button class="btn btn-primary" (click)="sendBalanceTlg()">Отправить</button>
    </div>
  </div>

  <hr>
  <div class="row">
    <div class="col-4">
      <div class="">
        <button class="btn btn-primary mb-2" (click)="loadAhms()">Загрузить список АХМ</button>
        <button class="btn btn-primary mb-2 ml-2" (click)="exportAhm()">Экспорт AHM в файл</button>
      </div>
      <table class="contenteditable w-100">
        <thead class="bg-blue color-white">
          <tr>
            <th></th>
            <th i18n="adm|Weight and balance - table airline@@adm.wb.main.table.airline">Airline</th>
            <th i18n="adm|Weight and balance - table aircraft@@adm.wb.main.table.aircraft">Aircraft</th>
            <th i18n="adm|Weight and balance - table revision@@adm.wb.main.table.revision">Revision</th>
            <th i18n="adm|Weight and balance - table release date@@adm.wb.main.table.releaseDate">Release date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of ahms" (click)="selectAhm(item.id)" [ngClass]="{'bg-gray': item.id == ahm.id}"
            class="cursor-default">
            <td>
              <div class="form-check p-0">
                <input class="form-check-input m-0 position-relative" type="checkbox" value="{{item.id}}"
                  [checked]="item.selected" (change)="item.selected = !item.selected">
              </div>
            </td>
            <td>{{ item.airlineName }}</td>
            <td>{{ item.aircraftTypeName }}</td>
            <td>{{ item.revision }}</td>
            <td>{{ item.dtRangeStart | date: 'dd.MM.yyyy' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-3">
      <button class="btn btn-primary mb-2" (click)="selectTails()">Выбрать все</button>
      <table class="contenteditable w-100" *ngIf="ahmsData.length > 0">
        <thead class="bg-blue color-white">
          <tr>
            <th></th>
            <th>Id</th>
            <th i18n="adm|Weight and balance - table ac registration@@adm.wb.main.table.acRegistration">A/C registration
            </th>
            <th i18n="adm|Weight and balance - table last update@@adm.wb.main.table.lastUpdate">Last update</th>
          </tr>
        </thead>
        <tbody>
          <tr (click)="selectedAhmDataId = ahm.id" *ngFor="let ahm of ahmsData" class="cursor-default"
            [ngClass]="{'bg-gray': selectedAhmDataId == ahm.id}">
            <td>
              <div class="form-check p-0">
                <input class="form-check-input m-0 position-relative" type="checkbox" value="{{ahm.id}}"
                  [checked]="ahm.selected" (change)="ahm.selected = !ahm.selected">
              </div>
            </td>
            <td>{{ahm.id}}</td>
            <td>{{ ahm.tailName }}</td>
            <td>{{ ahm.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-5">
      <h5>Статус</h5>
      <p>
        Выбран AHM:<span class="font-weight-bold pl-2">{{ ahm.id }}</span><br>
        Выбранные записи в AHM:<span class="font-weight-bold pl-2">{{ selectedTailsAhmRowId.join(', ') }}</span><br>
        Идентификаторы Бортовых:<span class="font-weight-bold pl-2">{{ selectedTailsId.join(', ') }}</span>
      </p>
      <p>Результат:<span class="font-weight-bold pl-2" style="white-space: pre-line;">{{weightAndBalanceResult}}</span>
      </p>

      <hr>
      <h5>Копирование AHM</h5>
      <p><small>Для быстрого переноса АХМ между базами</small></p>

      <p>1 Выбрать AHM и Бортовые номер (поставить галочки)<br>
        2 Cкопировать AHM в формате JSON, вставить в текстовое поле ниже<br>
        3 Нажать кнопку "Оновить данные в AHM"<br>
        4 Все выбранные Бортовые номера будут обновленны</p>
      <div class="mb-3">
        <label for="ahmdDataText">JSON для обновления AHM</label>
        <textarea id="ahmdDataText" class="form-control" id="validationTextarea"
          [(ngModel)]="ahmDataText">{{ ahmDataText }}</textarea>
      </div>
      <button class="btn btn-primary mb-2" (click)="uploadAhmData()">Обновить данные в AHM</button>

      <hr>
      <h5>Расчет геометрии багажников</h5>
      <p><small>Функция расчитает размеры всех заведенных позиций по центрам. Центра рассчитаются от индексов.</small>
      </p>

      <p>1 Выбрать AHM и Бортовые номер (поставить галочки)<br>
        2 Нажать кнопку "Расчет геометрии"<br>
        3 Все выбранные Бортовые номера будут обновленны</p>
      <button class="btn btn-primary mb-2" (click)="calculateGeometry()">Расчет геометрии</button>

      <hr>
      <h5>Пересохранение справочника Бортовых номеров</h5>
      <p><small>Если бортовые отсутствуют на какой либо площадке, их надо пересохранить на DEV в Мастер Дате, что бы они
          долетели по шине данных</small></p>
      <p>1 Выбрать AHM и Бортовые номер (поставить галочки)<br>
        2 Нажать кнопку "Пересохранить бортовые номера"<br>
        3 Все выбранные Бортовые номера будут пересохранены<br>
        4 Проверить наличие бортовых на площадке, где их небыло</p>
      <button class="btn btn-primary mb-2" (click)="resaveTails()">Пересохранить бортовые номера</button>

      <hr>
      <h5>Импорт AHM из файла</h5>
      <p><small>На конечной площадки должны присутствовать записи АХМ и Бортовых, функция только обновляет, не создает
          записи</small></p>
      <p>1 Выбрать файл<br>
        2 Нажать кнопку "Начать"<br>
        3 Просмотреть результаты, где "НЕТ БОРТА", создать записи для Бортовых и повторить процедуру</p>
      <div class="input-group">
        <div class="custom-file">
          <input #ahmFileUpload type="file" class="custom-file-input" id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01" accept=".txt">
          <label class="custom-file-label" for="inputGroupFile01">Жми для выбора файла</label>
        </div>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" id="inputGroupFileAddon01"
            (click)="importAhm(ahmFileUpload.files)">Начать</button>
        </div>
      </div>

      <hr>
      <h5>Обновление Багажников и Экипажа</h5>
      <p><small>Багажники обновляются полностью, в Экипаже остаются ранее введенные DOW/DOI</small></p>

      <p>1 Выбрать AHM и Бортовые номер (поставить галочки)<br>
        2 Cкопировать Багажники и Экипаж из AHM в формате JSON, вставить в текстовое поле ниже<br>
        3 Нажать кнопку "Оновить данные в AHM"<br>
        4 Все выбранные Бортовые номера будут обновленны</p>
      <div class="mb-3">
        <label for="ahmdDataText">JSON для обновления AHM</label>
        <textarea id="ahmdDataText" class="form-control" id="validationTextarea"
          [(ngModel)]="ahmDataTextCompartmentsCrews">{{ ahmDataTextCompartmentsCrews }}</textarea>
      </div>
      <button class="btn btn-primary mb-2" (click)="uploadAhmDataCompartmentsCrews()">Обновить данные в AHM</button>
    </div>
  </div>

</div>

<div *ngIf="showTab('tab-lost')" class="bg-white p-3">
  <button class="btn btn-primary mr-2" data-toggle="modal" data-target=".statement-status"
    (click)="createStatementStatusForm()">
    Wanted baggage status
  </button>
  <div #airportFormFade class="modal fade bd-example-modal-l statement-status" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-l">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 class="modal-title">
            Wanted baggage status
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form *ngIf="!statementSearchResult; else: searchResult" [formGroup]="statementStatusForm">
            <div class="row mb-2">
              <div class="col-6">
                <label class="w-100">
                  Surname
                </label>
                <input type="text" class="form-control form-control-sm" formControlName="surname">
              </div>
              <div class="col-6">
                <label class="w-100">
                  File
                </label>
                <input type="text" class="form-control form-control-sm" formControlName="file">
              </div>
            </div>
          </form>
          <ng-container *ngIf="statementSearchResult === false">
            <p>Nothing found. Check that the information you entered is correct and try again.</p>
          </ng-container>
          <ng-template #searchResult>
              <p>Claim number <strong>{{ statementSearchStatus.statementName }}</strong> dated <strong>
                {{ statementSearchStatus.statementDt | date : 'dd.MM.yyyy' }}</strong></p>
              <div class="h4">Baggages:</div>
              <div *ngFor="let baggage of statementSearchStatus.baggages">
                <hr />
                <div>Status: <strong>{{ getStatusName(baggage.status) }}</strong></div>
                <div>Color: <strong>{{ baggage.colorNameLocal }}</strong></div>
                <div>Type: <strong>{{ baggage.typeNameLocal }}</strong></div>
                <div>Tag: <strong>{{ baggage.tag }}</strong></div>
                <div *ngIf="baggage.forwardingDt"><strong>{{ baggage.forwardingDt | date : 'dd.MM.yyyy' }}</strong></div>
                <div *ngIf="baggage.forwardingArrivalDt"><strong>{{ baggage.forwardingArrivalDt | date : 'dd.MM.yyyy' }}</strong></div>
              </div>
          </ng-template>
        </div>
        <div class="modal-footer">
          <button type="button" *ngIf="!statementSearchResult" class="btn btn-primary btn-sm"
            (click)="searchStatementStatus()">
            Search
          </button>
          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showTab('tab-reference')" class="bg-white p-3 d-flex flex-column">
  <p>Результат:<span class="font-weight-bold pl-2">{{referenceAndBalanceResult}}</span></p>
  <button class="btn btn-primary mb-2" (click)="resaveAirports()">Пересохранить справочник Аэропортов</button>
  <button class="btn btn-primary mb-2" (click)="resaveAirline()">Пересохранить справочник Авиакомпаний</button>
</div>