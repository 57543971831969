import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value): string {
    return value ? value.toLocaleString('ru-RU') : '';
  }

}
