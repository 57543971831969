import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BoardingControlRestApiService } from '../boarding-control-data/boarding-control-rest-api.service';
import { GlobalI18n } from '@settings/global-i18n';
import { ModuleName } from '@settings/global-settings';
import moment from 'moment';
import {ReferanceAircraftType, ReferanceAirport} from '../referance-module-data/referance';
import { DictIncorrect } from '../boarding-control-data/dict-incorrect';
import { ListError } from '../boarding-control-data/list-error';

import { DictReasonWanted } from '../boarding-control-data/dict-reason-wanted';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { DictGeo } from '../boarding-control-data/dict-geo';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Список рейсов
// Данные из баркода
import { DataFromBarCode } from '../boarding-control-data/data-from-bar-code';
// Списко розыска
import { WantedList } from '../boarding-control-data/wanted-list';
// Кнопка инфо
import { InstallationWorkplace } from '../boarding-control-data/installation-workplace';
// Поиск пассажира
import { FindPassenger } from '../boarding-control-data/find-passenger';
// Справочник решений
import { DictReasonSolutionPass } from '../boarding-control-data/dict-reason-solution-pass';
// Данные для сравнение с баркодом
import { CurrentFlights } from '../boarding-control-data/current-flights';
// Записываем данные работы первого рабочего места
import { Passengers } from '../boarding-control-data/passengers';
// Глобальные настройки приложения
import { GlobalSettings } from '@settings/global-settings';
// Websocket
import { WebsocketService } from '../boarding-control-data/websocket.service';
import { ChatService } from '../boarding-control-data/chat.service';
import { ShowHistory } from '../boarding-control-data/show-histroy';
import { Passport } from '../boarding-control-data/passport';
import { DictReasonSolutionStop } from '../boarding-control-data/dict-reason-solution-stop';

@Component({
  selector: 'app-boarding-control-module',
  templateUrl: './boarding-control-module.component.html',
  styleUrls: ['./boarding-control-module.component.less'],
  providers: [WebsocketService, ChatService],
})
export class BoardingControlModuleComponent implements OnInit {
  private privateAirports: Array<ReferanceAirport> = [];

  installationWorkplace: InstallationWorkplace = new InstallationWorkplace();
  passengers: Passengers = new Passengers();
  dataFromBarCode: DataFromBarCode = new DataFromBarCode();
  passport: Passport = new Passport();
  wantedLists: Array<WantedList> = [];
  wantedList: WantedList = new WantedList();
  dictIncorrect: Array<DictIncorrect> = [];
  listError: Array<ListError> = [];
  currentFlights: Array<CurrentFlights> = [];
  findPassenger: FindPassenger[] = [];

  dictReasonSolutionsPass: Array<DictReasonSolutionPass> = [];
  dictReasonSolutionsStop: Array<DictReasonSolutionStop> = [];
  showHistoryPassenger: Array<ShowHistory> = [];

  dictReasonWanteds = [];
  dictGeos: DictGeo[] = [];

  // Вермя сканирования
  timeScan;
  // Читать или не читать билет со сканера
  getBoardingPass: boolean;
  // GUID раб места
  GUID = '';

  // Аэропорт отправления
  fromAirport = '';
  // Id аэропорта отправления
  fromAirportId = 0;

  // Индекс рейса для баркода из списка рейсов
  indexCurrentFlights = 0;
  // Последний внесенный баркод ид
  lastInsertBarcodeId = 0;

  // Зпоминаем состойние правой стороны перед показом всякизх решений
  previousRightSide = 0;
  // 0 = first window , 1 = problem list, 2 = reason stopped, 3 = reason passed
  rightSide = 0;
  // 0 = passport data not found, 1 = passport data available, 2 = passport show
  passportData = 0;
  buttonExit = false;
  // 0 = ready to scan, 1 = show data, 2 = error list 3 = reason passing, 4 = reason stopped
  aspect4status = 0;
  statusDescription = 0;

  // Обновляем поле актив если только первое место
  refreshUpdateActive: any;
  scannerOff = false;
  serverOff = false;
  prnCodeInfant = '';

  // Обновляем список рейсов в чистой зоне
  refreshFlightClearArea: any;

  // Выбранные рейс на 3 вкладке
  selectedFlight = '';
  // Кол-во пассажиров на ресе выбранном на 3 вкладке
  countPassenger = 0;
  // Id выбранного рейса
  selectedFlightId = 0;
  // Фильтр на 3 вкладке
  filterText = '';

  //indexWantedSelectFlight = 0;

  // Время обновления списка рейсов и списка розыска
  refreshTime = 60000;
  reasonBaggageWanted = '';
  closeResult: string;

  private message = {
    typeMessage: '',
    message: '',
  };

  private sendConfigWSS = {
    lang: '',
    passportScanner: '',
  };

  @ViewChild('wantedModalFormContent') wantedModalFormContent: any;
  @ViewChild('wantedModalForm') wantedModalForm: any;

  @ViewChild('addBarcodePlaceForm') addBarcodePlaceForm: any;
  @ViewChild('addBarcodeModalForm') addBarcodeModalForm: any;

  @ViewChild('modalQuestionExitClearArea')
  private modalQuestionExitClearArea: TemplateRef<Object>;

  constructor(
    public restApi: BoardingControlRestApiService,
    private globalI18n: GlobalI18n,
    private chatService: ChatService,
    private wSS: WebsocketService,
    private globalSettings: GlobalSettings,
    private keycloakService: KeycloakService,
    private modalService: NgbModal
  ) {
    chatService.messages.subscribe((msg) => {
      console.log('Response from chatclient: ' + JSON.stringify(msg));

      // Пришел баркод
      if (msg.typeMessage == 'barcode') {
        console.log('Пришел баркод');
        if (this.timeScan != null) {
          if (this.getBoardingPass) {
            const scantime: moment.Moment = moment();
            scantime.subtract(this.installationWorkplace.reReadSec, 'seconds');

            if (moment(scantime).isAfter(this.timeScan)) {
              this.preParceBarcode(msg.message);
            }

            this.timeScan = scantime;
          }
        } else {
          const scantime: moment.Moment = moment();
          this.timeScan = scantime;
          this.preParceBarcode(msg.message);
        }
      }

      // Пришел паспорт
      /*if (msg.typeMessage == 'passport') {
        this.parcePassport(msg.message);
      }*/

      // Ошибка в программе
      if (msg.typeMessage == 'error') {
        if (msg.message == 'statusScannerBarcodeOff') {
          this.scannerOff = true;
        }
        this.clearAll();
        //this.dataFromBarCode.status = 4;
        //this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(ModuleName.BoardingControl, msg.message);

        this.addError(
          4,
          this.globalI18n.getMessage(ModuleName.BoardingControl, msg.message)
        );
      }

      // Инфо
      if (msg.typeMessage == 'info') {
        if (msg.message == 'statusWaiting') {
          this.scannerOff = false;
        }
        this.dataFromBarCode.status = 0;
        this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          msg.message
        );

        this.clearAll();
      }

      // Запрос на получение параметров от сервера
      if (msg.typeMessage == 'getparam') {
        this.GUID = msg.message;
        this.sendWSSConfig();
      }

      // Закрыли программу
      if (msg.typeMessage == 'disconnect') {
        this.serverOff = true;
        this.clearAll();
        //this.dataFromBarCode.status = 5;
        //this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(ModuleName.BoardingControl, msg.message);

        this.addError(
          5,
          this.globalI18n.getMessage(ModuleName.BoardingControl, msg.message)
        );
        wSS.disconnect();
      }
    });
  }

  userDetails: KeycloakProfile;

  async ngOnInit(): Promise<void> {
    await this.globalSettings.loadComplete();

    // По умолчанию сервер типа офф
    /*this.dataFromBarCode.status = 5;
    this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
      ModuleName.BoardingControl,
      'statusWSSClosed'
    );
    this.addError(
      5,
      this.globalI18n.getMessage(ModuleName.BoardingControl, 'statusWSSClosed')
    );*/

    this.getBoardingPass = true;

    this.loadAirports();
    this.loadDictGeo();
    this.loadDictReasonWanted();
    this.loadWantedList();

    // Данные пользователя
    if (await this.keycloakService.isLoggedIn()) {
      this.userDetails = await this.keycloakService.loadUserProfile();
      this.wantedList.userLogin = this.userDetails.username;
      this.passengers.userName = this.userDetails.username;
    }

    // Обновление 3 вкладки
    this.refreshFlightClearArea = setInterval(() => {
      this.loadCurrentFlights();
      this.loadWantedList();
    }, this.refreshTime);
  }

  /*async refreshFirstWorkPlaceActive() {
    await this.updateFirstWorkplaceActive();
  }

  refreshPage() {
    window.location.reload();
  }*/

  // для 4 х 3
  // 0 = ready to scan, 1 = show data, 2 = error list 3 = reason passing, 4 = reason stopped
  setPass3x4() {
    this.previousRightSide = this.aspect4status;
    if (this.dataFromBarCode.status == 1) {
      this.insertPass(true);
    } else {
      this.aspect4status = 3;
    }
  }

  setStop3x4() {
    this.previousRightSide = this.aspect4status;
    this.passengers.solutionEntrance = false;
    this.passengers.active = false;
    this.aspect4status = 4;
  }

  showErrorList3x4() {
    this.previousRightSide = this.aspect4status;
    this.aspect4status = 2;
  }

  hideErrorList3x4() {
    this.aspect4status = this.previousRightSide;
  }

  closeStopSolution3x4() {
    this.aspect4status = this.previousRightSide;
    this.passengers.idReasonSolutionEntrance = 0;
    this.passengers.solutionEntrance = true;
    this.passengers.active = true;
  }

  closeSkipSolution3x4() {
    this.aspect4status = this.previousRightSide;
  }
  // для 4 х 3

  // для 16 х 9
  // 0 = first window , 1 = problem list, 2 = reason stopped, 3 = reason passed
  setPass() {
    this.previousRightSide = this.rightSide;
    if (this.dataFromBarCode.status == 1) {
      this.insertPass(true);
    } else {
      this.rightSide = 3;
    }
  }

  setStop() {
    this.previousRightSide = this.rightSide;
    this.passengers.solutionEntrance = false;
    this.passengers.active = false;
    this.rightSide = 2;
  }

  showErrorList() {
    this.previousRightSide = this.rightSide;
    this.rightSide = 1;
  }

  hideErrorList() {
    this.rightSide = this.previousRightSide;
  }

  closeStopSolution() {
    this.rightSide = this.previousRightSide;
    this.passengers.idReasonSolutionEntrance = 0;
    this.passengers.solutionEntrance = true;
    this.passengers.active = true;
  }

  closeSkipSolution() {
    this.rightSide = this.previousRightSide;
  }
  // для 16 х 9

  // Ищем текущий аэропорт
  loadAirports() {
    return this.restApi.getAirports().subscribe((data: ReferanceAirport[]) => {
      this.privateAirports.length = 0;
      for (const item of data) {
        const airport = new ReferanceAirport();
        Object.assign(airport, item);
        this.privateAirports.push(airport);
        if (airport.id == this.globalSettings.homeAirport) {
          this.fromAirportId = this.globalSettings.homeAirport;
          this.fromAirport = airport.iata;
        }
      }
    });
  }

  // Загрузка из справоника решений
  loadDictReasonSolutionPass() {
    return this.restApi
      .getReasonSolutionPass(this.installationWorkplace.idWorkPlace)
      .subscribe((data: DictReasonSolutionPass[]) => {
        Object.assign(this.dictReasonSolutionsPass, data);
        console.log(this.dictReasonSolutionsPass);
      });
  }

  loadDictReasonSolutionStop() {
    return this.restApi
      .getReasonSolutionStop(this.installationWorkplace.idWorkPlace)
      .subscribe((data: DictReasonSolutionStop[]) => {
        Object.assign(this.dictReasonSolutionsStop, data);
        console.log(this.dictReasonSolutionsStop);
      });
  }

  loadDictGeo() {
    return this.restApi.getGeo().subscribe((data: DictGeo[]) => {
      Object.assign(this.dictGeos, data);
    });
  }

  // загрузка из причина задержания
  loadDictReasonWanted() {
    return this.restApi
      .getReasonWanted()
      .subscribe((data: DictReasonWanted[]) => {
        data.forEach(item => {
          let dictReasonWanteds = new DictReasonWanted();
          Object.assign(dictReasonWanteds, item);
          this.dictReasonWanteds = [...this.dictReasonWanteds, dictReasonWanteds];
        });
        // this.wantedList.idReason = this.dictReasonWanteds[0].id;
        // this.wantedList.reasonName = this.dictReasonWanteds[0].name;
      });
  }

  // Список розыска
  loadWantedList() {
    console.log('loadWantedList');
    //this.wantedList = null;
    this.wantedLists = [];
    return this.restApi.getWantedLists().subscribe((data: WantedList[]) => {
      if (data) {
        Object.assign(this.wantedLists, data);
        //console.log('WANTED' + JSON.stringify(this.wantedLists));
      } else {
        console.log('ERROR LOAD WANTED');
      }
    });
  }

  // Список текущих рейсов
  async loadCurrentFlights() {
    console.log(
      'loadCurrentFlights ' +
        this.installationWorkplace.dateStartCurrentFlightsHours +
        this.installationWorkplace.geoName
    );
    return await this.restApi
      .getCurrentFlights(
        this.globalSettings.language,
        this.installationWorkplace.dateStartCurrentFlightsHours,
        this.installationWorkplace.dateEndCurrentFlightsHours
      )
      .toPromise()
      .then((data) => {
        if (data) {
          //console.log(JSON.stringify(data));
          Object.assign(this.currentFlights, data);
        }
      });
  }

  // Посылка конфига WSS серверу
  async sendWSSConfig() {
    await this.loadInfoWorkplace();
    this.loadDictReasonSolutionPass();
    this.loadDictReasonSolutionStop();

    //if (this.installationWorkplace.showSecondNumber == false) {
      // Обновление активе если только первый номер
      /*this.refreshUpdateActive = setInterval(() => {
        this.refreshFirstWorkPlaceActive();
      }, 60000);*/
    //}

    this.sendConfigWSS.lang = this.globalSettings.language;
    if (this.installationWorkplace.passportScanner) {
      this.sendConfigWSS.passportScanner = 'on';
    } else {
      this.sendConfigWSS.passportScanner = 'off';
    }
    this.chatService.configWSS.next(this.sendConfigWSS);
  }

  // Инфо
  async loadInfoWorkplace() {
    console.log('loadInfoWorkplace');
    this.dataFromBarCode.status = 5;
    this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
      ModuleName.BoardingControl,
      'statusWSSClosed'
    );
    this.addError(
      5,
      this.globalI18n.getMessage(ModuleName.BoardingControl, 'statusWSSClosed')
    );

    return await this.restApi
      .getInfoWorkplace(this.GUID, this.globalSettings.language)
      .toPromise()
      .then((data) => {
        const obj = JSON.parse(JSON.stringify(data));
        if (obj.hasOwnProperty('error')) {
          this.dataFromBarCode.status = 4;
          this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusNewInstallationPlace'
          );
          this.addError(
            4,
            this.globalI18n.getMessage(
              ModuleName.BoardingControl,
              'statusNewInstallationPlace'
            )
          );
          this.insertNewInstallationPlace();
        } else {
          Object.assign(this.installationWorkplace, data);
          //console.log('INFO ' + JSON.stringify(this.installationWorkplace));
          this.loadCurrentFlights();
          this.wantedList.idInstallationWorkplace = this.installationWorkplace.id;
          this.dataFromBarCode.status = 0;
          this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusWaiting'
          );
        }
      });
  }

  // Паспортные данные
  async loadPassportData(flight_id: number, passenge_name: string, pnr: string) {
    console.log('loadPassportData' + flight_id + passenge_name + pnr );
    return await this.restApi.getPassportData(flight_id, passenge_name, pnr).toPromise().then((data) => {
      if (data) {
        console.log(JSON.stringify(data));
        Object.assign(this.passport, data);
        this.passportData = 2;
      } else {
        console.log('loadPassportData ERROR');
      }
    });
  }

  passportDataTypeShow(show: boolean) {
    if (show) {
      this.passportData = 2;
    } else {
      this.passportData = 1;
    }
  }

  delWanted(id: number, passenger: string) {
    this.wantedList.id = id;
    this.wantedList.passenger = passenger;
  }

  deleteWanted(id: number) {
    this.restApi.deleteWanted(id).subscribe(
      (data) => {
        for (let i = 0; i < this.wantedLists.length; i++) {
          if (this.wantedLists[i].id == this.wantedList.id) {
            this.wantedLists.splice(i, 1);
            break;
          }
        }
      },
      (err) => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  // Добавляем в таблицу wanted-list
  insertWanted() {
    this.wantedModalFormContent.form.markAllAsTouched();
    if (this.wantedModalFormContent.valid) {
      this.restApi.insertWantedList(this.wantedList).subscribe(
        (data) => {
          const obj = JSON.parse(JSON.stringify(data));
          const wl = new WantedList();

          wl.id = obj.body.id;
          wl.idAirline = this.wantedList.idAirline;
          wl.idFlight = this.wantedList.idFlight;
          wl.idReason = this.wantedList.idReason;
          wl.idGeo = this.wantedList.idGeo;
          wl.idInstallationWorkplace = this.wantedList.idInstallationWorkplace;
          wl.akName = this.wantedList.akName;
          wl.numFlight = this.wantedList.numFlight;
          wl.reasonName = this.wantedList.reasonName;
          wl.geoName = this.wantedList.geoName;
          wl.installationName = this.wantedList.installationName;
          wl.userLogin = this.wantedList.userLogin;
          wl.passenger = this.wantedList.passenger;
          wl.tagNumber = this.wantedList.tagNumber;
          wl.prn = this.wantedList.prn;
          wl.seq = this.wantedList.seq;
          wl.dateTimeAdded = obj.body.added;
          wl.find = false;
          wl.dateTimeEntrance = '';
          wl.dateTimeBoarding = '';
          wl.active = false;

          this.wantedLists.push(wl);
          this.wantedModalForm.nativeElement.click();

          this.wantedList.id = -1;
          this.wantedList.idAirline = -1;
          this.wantedList.idFlight = -1;
          this.wantedList.idReason = this.wantedLists[0].idReason;
          this.wantedList.reasonName = this.wantedLists[0].reasonName;
          this.wantedList.idGeo = -1;
          //this.wantedList.idInstallationWorkplace: number;
          this.wantedList.akName = '';
          this.wantedList.numFlight = '';
          this.wantedList.geoName = '';
          //this.wantedList.installationName: string;
          //this.wantedList.userLogin: string;
          this.wantedList.passenger = '';
          this.wantedList.tagNumber = '';
          this.wantedList.prn = '';
          this.wantedList.seq = '';
          this.wantedList.dateTimeAdded = '';
          this.wantedList.find = false;
          this.wantedList.dateTimeEntrance = '';
          this.wantedList.dateTimeBoarding = '';
          this.wantedList.active = false;

          this.wantedList.terminalName = '';
          this.wantedList.departureTime = '';
        },
        (err) => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  showHistory(bar: string) {
    this.restApi.getHistroy(bar).subscribe(
      (data) => {
        this.showHistoryPassenger = [];
        Object.assign(this.showHistoryPassenger, data);
      },
      (err) => {
        this.showHistoryPassenger = [];
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  // Выбор рейса при поиске разыскиваемого
  selectFlight(flight: string, count: number, idFlight: number) {
    this.selectedFlight = flight;
    this.countPassenger = count;
    this.selectedFlightId = idFlight;

    if (this.filterText != '') {
      this.restApi
        .getPassengerInClearArea(idFlight, this.filterText.toUpperCase())
        .subscribe(
          (data) => {
            this.findPassenger = [];
            Object.assign(this.findPassenger, data);
          },
          (err) => {
            this.findPassenger = [];
            console.log('Error: ' + err.message + '\ndetail:' + err.detail);
          }
        );
    } else {
      this.restApi.getPassengerInClearArea(idFlight).subscribe(
        (data) => {
          this.findPassenger = [];
          Object.assign(this.findPassenger, data);
        },
        (err) => {
          this.findPassenger = [];
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  // Выпустить всех из 3 вкладки
  setEveryoneOut() {
    this.restApi.setEveryone(this.selectedFlightId).subscribe(
      (data) => {
        this.findPassenger = [];
      },
      (err) => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  // Фильтр
  filterShow() {
    if (this.filterText != '') {
      this.filterApp();
    } else {
      this.findPassenger = [];
    }
  }

  filterApp() {
    this.restApi
      .findPassengerInClearArea(
        this.filterText.toUpperCase(),
        this.selectedFlightId
      )
      .subscribe(
        (data) => {
          this.findPassenger = [];
          Object.assign(this.findPassenger, data);
        },
        (err) => {
          this.findPassenger = [];
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
  }

  wantedFlightOnChange(event): void {
    if (event.target.value != -1) {
      for (let i = 0; i < this.currentFlights.length; i++) {
        if (this.currentFlights[i].idFlight == event.target.value) {
          //this.indexWantedSelectFlight = i;
          this.wantedList.idFlight = this.currentFlights[i].idFlight
          this.wantedList.akName = this.currentFlights[i].ak;
          this.wantedList.idAirline = this.currentFlights[i].idAk;
          this.wantedList.numFlight = this.currentFlights[i].numFlight;
          this.wantedList.idGeo = this.currentFlights[i].idGeo;
          this.wantedList.geoName = this.currentFlights[i].geoName;

          this.wantedList.terminalName = this.currentFlights[i].terminalName;
          this.wantedList.departureTime = this.currentFlights[i].departureTime;

          break;
        }
      }
    } else {
      //this.indexWantedSelectFlight = -1;
      this.wantedList.idFlight = -1;
      this.wantedList.akName = '';
      this.wantedList.idAirline = -1;
      this.wantedList.numFlight = '';
      this.wantedList.idGeo = -1;
      this.wantedList.geoName = '';

      this.wantedList.terminalName = '';
      this.wantedList.departureTime = '';
    }
  }

  wantedReasonOnChange(event): void {
    if (event) {
      if (event.id != -1) {
        for (let i = 0; i < this.dictReasonWanteds.length; i++) {
          if (this.dictReasonWanteds[i].id == event.id) {
            this.wantedList.idReason = this.dictReasonWanteds[i].id
            this.wantedList.reasonName = this.dictReasonWanteds[i].name;
            break;
          }
        }
      } else {
        this.wantedList.idReason = - 1
        this.wantedList.reasonName = '';
      }
    }
  }

  // Чистим баркод и все остальное
  clearAll() {

    this.passportData = 0;

    this.passport.id = 0;
    this.passport.passportId = '';
    this.passport.validity = '';
    this.passport.country = '';
    this.passport.birthDay = '';
    this.passport.sex = '';

    this.listError = [];
    this.dictIncorrect = [];
    this.getBoardingPass = true;
    // this.timeToEndBoarding = "null";
    this.indexCurrentFlights = -1;
    this.buttonExit = false;
    this.previousRightSide = 0;
    this.rightSide = 0;
    this.aspect4status = 0;
    this.reasonBaggageWanted = '';

    this.dataFromBarCode.status = 0;
    this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
      ModuleName.BoardingControl,
      'statusWaiting'
    );

    if (this.scannerOff) {
      this.dataFromBarCode.status = 4;
      this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
        ModuleName.BoardingControl,
        'statusScannerBarcodeOff'
      );
    }

    if (this.serverOff) {
      this.dataFromBarCode.status = 5;
      this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
        ModuleName.BoardingControl,
        'statusWSSClosed'
      );
    }

    this.dataFromBarCode.idFlight = 0;
    this.dataFromBarCode.idPassport = 0;
    this.dataFromBarCode.passengerName[0] = '';
    this.dataFromBarCode.passengerName[1] = false;
    this.dataFromBarCode.dateFlight[0] = '';
    this.dataFromBarCode.dateFlight[1] = false;
    this.dataFromBarCode.airlineCode[0] = '';
    this.dataFromBarCode.airlineCode[1] = false;
    this.dataFromBarCode.numFlight[0] = '';
    this.dataFromBarCode.numFlight[1] = false;
    this.dataFromBarCode.cityDeparture[0] = '';
    this.dataFromBarCode.cityDeparture[1] = false;
    this.dataFromBarCode.cityArrival[0] = '';
    this.dataFromBarCode.cityArrival[1] = false;
    this.dataFromBarCode.seatNumber[0] = '';
    this.dataFromBarCode.seatNumber[1] = false;
    this.dataFromBarCode.className[0] = '';
    this.dataFromBarCode.className[1] = false;
    this.dataFromBarCode.barcode = '';
    this.dataFromBarCode.dateTimeFormat = '';
    this.dataFromBarCode.barcodeGuid = '';
    this.dataFromBarCode.prnCode[0] = '';
    this.dataFromBarCode.prnCode[1] = false;
    this.dataFromBarCode.infant = false;
    this.dataFromBarCode.manualInput = false;
    this.dataFromBarCode.code15field = '';
    this.dataFromBarCode.terminalName[0] = '';
    this.dataFromBarCode.terminalName[1] = false;
    this.dataFromBarCode.geoName[0] = '';
    this.dataFromBarCode.geoName[1] = false;

    this.dataFromBarCode.airlineName = '';
    this.dataFromBarCode.airportDepartureName = '';
    this.dataFromBarCode.airportArrivalName = '';
    this.dataFromBarCode.statusFlight = '';
    this.dataFromBarCode.delayTime = '';

    this.dataFromBarCode.haveBarcode = 0;
    this.dataFromBarCode.dateCompare = '';

    this.passengers.id = 0;
    this.passengers.idFlight = 0;
    this.passengers.idBoardingpass = 0;
    this.passengers.solutionEntrance = true;
    this.passengers.idReasonSolutionEntrance = 0;
    this.passengers.dateTimeReasonSolutionEntrance = '';
    this.passengers.entranceNote = '';
    this.passengers.idWantedList = 0;
    this.passengers.baggage = '';
    this.passengers.active = true;
    this.passengers.idWorkplaceEntrance = this.installationWorkplace.id;
    this.passengers.dateTimeWorkplaceEntrance = '';
    this.passengers.entranceMark = true;
    // 1 "Зашел в чистую зону"  2 "Вышел из чистой зоны"  3 "Вышел на посадку"
    // 4 "Найден в розыске"  5 "Сняли с рейса"  6 "Нет в чистой зоне"
    this.passengers.idStatusPassengers = 1;
  }

  // Список рейсов чистой зоны
  /*loadListFlightsClearArea() {
    return this.restApi.getListFlights(this.globalSettings.language, this.infoWorkplace.idGeo).subscribe((data: ListFlights[]) => {
      Object.assign(this.listFlights, data);
      //console.log('11111111' + JSON.stringify(this.listFlights));

      let yes = false;

      for (let i = 0; i < this.listFlights.length; i++) {
        if (this.selectedFlightId == this.listFlights[i].idFlight) {
          yes = true;
          break;
        }
      }

      if (!yes) {
        this.selectedFlight = '()';
        this.countPassenger = 0;
        this.selectedFlightId = 0;
      }
      this.filterApp();
    });
  }*/

  /*updateFirstWorkplaceActiveFlights(actFlights: any) {
    this.restApi.setFirstWorkplaceActive(actFlights).subscribe(data => {

      },
      err => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }*/

  // Список рейсов активных ресов из first_workplace
  /*loadActiveFlightsFirstWorkPlace() {
    return this.restApi.getActiveFlights().subscribe((data: ActiveFlightsFirstWorkplace[]) => {

      const arrayFlightsDeparted = [];
      let flightDeparted = false;

      Object.assign(this.activeFlights, data);

      for (let i = 0; i < this.activeFlights.length; i++) {
        for (let j = 0; j < this.currentFlights.length; j++) {
          if (this.activeFlights[i].id_flight == this.currentFlights[j].idFlight) {
            flightDeparted = true;
            break;
          }
        }

        if (flightDeparted) {
          flightDeparted = false;
        } else {
          arrayFlightsDeparted.push(this.activeFlights[i].id_flight);
        }
      }

      if (arrayFlightsDeparted.length > 0) {
        this.updateFirstWorkplaceActiveFlights(arrayFlightsDeparted);
        console.log('FIND ' + JSON.stringify(arrayFlightsDeparted));
      }
    });
  }*/

  /*async updateFirstWorkplaceActive() {
    await this.loadCurrentFlights();
    await this.loadActiveFlightsFirstWorkPlace();
  }*/

  // Разбор баркода
  async preParceBarcode(bar: string) {
    this.clearAll();

    this.aspect4status = 1; // для соотношения 4x3
    this.getBoardingPass = false;
    this.dataFromBarCode.status = 8;
    this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
      ModuleName.BoardingControl,
      'statusProcessing'
    );

    /*const posM1 = bar.indexOf('M1');
    const posM2 = bar.indexOf('M2');
    const posM3 = bar.indexOf('M3');
    const posM4 = bar.indexOf('M4');

    let strM1 = '';
    let strM2 = '';
    let strM3 = '';
    let strM4 = '';*/

    //let barcode = '';

    /*if (posM4 != -1) {
      strM4 = bar.substr(posM4);
      strM4 = strM4.substr(2);
      if (strM4.substr(28, 3).trim() == this.fromAirport) {
        barcode = strM4;
      }
    }

    if (posM3 != -1) {
      if (posM4 != -1) {
        strM3 = bar.substr(posM3, posM4);
      } else {
        strM3 = bar.substr(posM3);
      }
      strM3 = strM3.substr(2);
      if (strM3.substr(28, 3).trim() == this.fromAirport) {
        barcode = strM3;
      }
    }

    if (posM2 != -1) {
      if (posM3 != -1) {
        strM2 = bar.substr(posM2, posM3);
      } else {
        strM2 = bar.substr(posM2);
      }
      strM2 = strM2.substr(2);
      if (strM2.substr(28, 3).trim() == this.fromAirport) {
        barcode = strM2;
      }
    }

    if (posM1 != -1) {
      if (posM2 != -1) {
        strM1 = bar.substr(posM1, posM2);
      } else {
        strM1 = bar.substr(posM1);
      }
      strM1 = strM1.substr(2);

      if (strM1.substr(28, 3).trim() == this.fromAirport) {
        barcode = strM1;
      } else {
        // несовпадает аэропорт отправления
        if (barcode == '') {
          barcode = strM1;
        }
      }
    }*/

    if (
      bar.indexOf('M1') != -1 ||
      bar.indexOf('M2') != -1 ||
      bar.indexOf('M3') != -1 ||
      bar.indexOf('M4') != -1
    ) {
      // if ((posM1 != -1) || (posM2 != -1) || (posM3 != -1) || (posM4 != -1)) {

      let barcode = bar.substr(2);
      console.log('BARCODE' + barcode);

      const lenBarcode = barcode.length;

      if (lenBarcode >= 56) {
        const countDay = Number(barcode.substr(42, 3).trim());

        if (countDay <= 366 && countDay >= 1) {
          const dateNow = new Date();
          const dateBC = new Date(dateNow.getFullYear(), 0, countDay);
          // let dateFormat = this.formatDateFromBarcode(dateBC.getMonth(), dateBC.getDate());

          // Дата format YYYY-MM-DD HH:mm:ss
          const dateFlightFormat: moment.Moment = moment(dateBC);

          this.dataFromBarCode.dateCompare = dateFlightFormat.format(
            'YYYY.MM.DD'
          );
          this.dataFromBarCode.dateTimeFormat = dateFlightFormat.format(
            'YYYY-MM-DD'
          ); // HH:mm:ss
          this.dataFromBarCode.numFlight[0] = barcode.substr(37, 5).trim();
          this.dataFromBarCode.airlineCode[0] = barcode.substr(34, 3).trim();
          this.dataFromBarCode.dateFlight[0] = countDay;
          this.dataFromBarCode.cityDeparture[0] = barcode.substr(28, 3).trim();
          this.dataFromBarCode.cityArrival[0] = barcode.substr(31, 3).trim();
          this.dataFromBarCode.passengerName[0] = barcode.substr(0, 20).trim();
          this.dataFromBarCode.seatNumber[0] = barcode.substr(46, 4).trim();
          this.dataFromBarCode.className[0] = barcode.substr(45, 1).trim();
          this.dataFromBarCode.prnCode[0] = barcode.substr(21, 7).trim();
          this.dataFromBarCode.code15field = barcode.substr(62, 1).trim();

          // Ручной ввод
          if (this.dataFromBarCode.prnCode[0] == '0000000') {
            this.dataFromBarCode.manualInput = true;
          }

          if (lenBarcode > 56) {
            // Поле место INF, значит ребенок или 0000 или 15 поле 4
            if (
              this.dataFromBarCode.seatNumber[0].toUpperCase() == 'INF' ||
              this.dataFromBarCode.seatNumber[0] == '' ||
              this.dataFromBarCode.code15field == '4' ||
              this.dataFromBarCode.code15field == '0000'
            ) {
              this.prnCodeInfant = this.dataFromBarCode.prnCode[0];
              this.dataFromBarCode.infant = true;
            }

            // Один билет родитель с ребенком
            if (this.dataFromBarCode.code15field == '6') {
              this.prnCodeInfant = this.dataFromBarCode.prnCode[0];
              this.dataFromBarCode.infant = true;
            }
          }

          this.dataFromBarCode.barcode = barcode;
          this.indexCurrentFlights = this.getFlightToCompareBarcode();

          this.createBarcodeGUID(countDay, barcode.substr(50, 5).trim());
          await this.haveBarcode();
          await this.parceBarcode();
        } else {
          this.getBoardingPass = true;
          this.dataFromBarCode.status = 7;
          this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusIncorrectDate'
          );
          this.addError(
            7,
            this.globalI18n.getMessage(
              ModuleName.BoardingControl,
              'statusIncorrectDate'
            )
          );
        }
      } else {
        this.getBoardingPass = true;
        this.dataFromBarCode.status = 7;
        this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          'statusInvalidBarCode'
        );
        this.addError(
          7,
          this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusInvalidBarCode'
          )
        );
      }
    } else {
      this.getBoardingPass = true;
      this.dataFromBarCode.status = 7;
      this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
        ModuleName.BoardingControl,
        'statusInvalidBarCode'
      );
      this.addError(
        7,
        this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          'statusInvalidBarCode'
        )
      );
    }
  }

  //  Разбор баркода
  async parceBarcode() {
    this.passengers.active = true;

    if (this.indexCurrentFlights != -1) {
      // -1 ненайден рейс
      this.dataFromBarCode.terminalName[0] = this.currentFlights[
        this.indexCurrentFlights
      ].terminalName;
      this.dataFromBarCode.geoName[0] = this.currentFlights[
        this.indexCurrentFlights
      ].geoName;
      this.dataFromBarCode.idFlight = this.currentFlights[
        this.indexCurrentFlights
      ].idFlight;
      this.dataFromBarCode.statusFlight = this.currentFlights[
        this.indexCurrentFlights
      ].statusFlight;

      if (
        this.currentFlights[this.indexCurrentFlights].statusFlight ==
          'delayed' ||
        this.currentFlights[this.indexCurrentFlights].statusFlight == 'задержан'
      ) {
        this.dataFromBarCode.delayTime = this.currentFlights[
          this.indexCurrentFlights
        ].departureCalc;
      }

      this.dataFromBarCode.airlineName = this.currentFlights[
        this.indexCurrentFlights
      ].airlineName;
      this.dataFromBarCode.airportDepartureName = this.currentFlights[
        this.indexCurrentFlights
      ].airportDepartureName;
      this.dataFromBarCode.airportArrivalName = this.currentFlights[
        this.indexCurrentFlights
      ].airportArrivalName;

      //this.dataFromBarCode.departureTime[0] = this.currentFlights[this.indexCurrentFlights].departureTime;

      if (this.dataFromBarCode.haveBarcode == 0) {
        await this.insertBarcode();
        this.passengers.idBoardingpass = this.lastInsertBarcodeId;
      } else {
        this.passengers.idBoardingpass = this.dataFromBarCode.haveBarcode;
      }

      // Пускать или не пускать расчитывает, время за сколько пускать
      let estimatedTime;
      if ( this.currentFlights[this.indexCurrentFlights].departureCalc == '0001.01.01 00:00:00' ||
           this.currentFlights[this.indexCurrentFlights].departureCalc == '') {
        estimatedTime = this.currentFlights[this.indexCurrentFlights].departurePlan;
      } else {
        estimatedTime = this.currentFlights[this.indexCurrentFlights].departureCalc;
      }

      console.log('ВРЕМЯ РЕЙСА (UTC): ' + estimatedTime);

      if (this.enterTime(estimatedTime)) {
        console.log('НЕ ПУСКАТЬ, РАННИЙ ПРИХОД ДО ПЛАНОВОГО/ОЖИДАЕМОГО ВРЕМЯ ВЫЛЕТА');
        this.dataFromBarCode.status = 6;
        this.dataFromBarCode.statusMessage =
          this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusNoTimeEnter'
          ) +
          ' (' +
          this.dataFromBarCode.statusFlight +
          ')';
        this.addError(
          6,
          this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusNoTimeEnter'
          ) +
            ' (' +
            this.dataFromBarCode.statusFlight +
            ')'
        );
      }

      if (this.timeToEndOfBoarding(estimatedTime)) {
        console.log('НЕ ПУСКАТЬ, ПОЗДНИЙ ПРИХОД ДО ПЛАНОВОГО/ОЖИДАЕМОГО ВРЕМЕНИ ВЫЛЕТА');
        this.dataFromBarCode.status = 6;
        this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          'statusTimeToEndBoarding'
        );
        this.addError(
          6,
          this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusTimeToEndBoarding'
          )
        );
      }
      // Пускать или не пускать расчитывает, время за сколько пускать

      this.passengers.idFlight = this.currentFlights[
        this.indexCurrentFlights
      ].idFlight;

      let tmpIncorrectData = 0;

      // Сравнение данных с баркода с данными из базы
      if (
        this.dataFromBarCode.numFlight[0] !=
        this.currentFlights[this.indexCurrentFlights].numFlight
      ) {
        this.dataFromBarCode.numFlight[1] = true;
        tmpIncorrectData = 2;
        this.addIncorrectBarCodeData(
          'num_flight',
          this.passengers.idBoardingpass,
          this.dataFromBarCode.numFlight[0],
          this.currentFlights[this.indexCurrentFlights].numFlight
        );
      }
      if (
        this.dataFromBarCode.airlineCode[0] !=
        this.currentFlights[this.indexCurrentFlights].ak
      ) {
        this.dataFromBarCode.airlineCode[1] = true;
        tmpIncorrectData = 2;
        this.addIncorrectBarCodeData(
          'code_ak',
          this.passengers.idBoardingpass,
          this.dataFromBarCode.airlineCode[0],
          this.currentFlights[this.indexCurrentFlights].ak
        );
      }
      if (
        this.dataFromBarCode.dateCompare !=
        this.currentFlights[this.indexCurrentFlights].date
      ) {
        this.dataFromBarCode.dateFlight[1] = true;
        tmpIncorrectData = 2;
        this.addIncorrectBarCodeData(
          'date_flight',
          this.passengers.idBoardingpass,
          this.dataFromBarCode.dateFlight[0],
          this.currentFlights[this.indexCurrentFlights].date
        );
      }
      if (
        this.dataFromBarCode.cityDeparture[0] !=
        this.currentFlights[this.indexCurrentFlights].fromAiport
      ) {
        this.dataFromBarCode.cityDeparture[1] = true;
        tmpIncorrectData = 2;
        this.addIncorrectBarCodeData(
          'from_airport',
          this.passengers.idBoardingpass,
          this.dataFromBarCode.cityDeparture[0],
          this.currentFlights[this.indexCurrentFlights].fromAiport
        );
      }
      if (
        this.dataFromBarCode.cityArrival[0] !=
        this.currentFlights[this.indexCurrentFlights].toAiport
      ) {
        this.dataFromBarCode.cityArrival[1] = true;
        tmpIncorrectData = 2;
        this.addIncorrectBarCodeData(
          'to_airport',
          this.passengers.idBoardingpass,
          this.dataFromBarCode.cityArrival[0],
          this.currentFlights[this.indexCurrentFlights].toAiport
        );
      }
      if (
        this.installationWorkplace.idTerminal !=
        this.currentFlights[this.indexCurrentFlights].idTerminal
      ) {
        this.dataFromBarCode.terminalName[1] = true;
        tmpIncorrectData = 2;
        this.addIncorrectBarCodeData(
          'terminal',
          this.passengers.idBoardingpass,
          this.installationWorkplace.terminalName,
          this.currentFlights[this.indexCurrentFlights].terminalName
        );
      }
      if (
        this.installationWorkplace.idGeo !=
        this.currentFlights[this.indexCurrentFlights].idGeo
      ) {
        this.dataFromBarCode.geoName[1] = true;
        tmpIncorrectData = 2;
        this.addIncorrectBarCodeData(
          'geo',
          this.passengers.idBoardingpass,
          this.installationWorkplace.geoName,
          this.currentFlights[this.indexCurrentFlights].geoName
        );
      }

      // дубликат места
      let doubleSeat: boolean;
      doubleSeat = await this.getFirstWorkplaceDuplicate(
        this.dataFromBarCode.seatNumber[0],
        this.dataFromBarCode.idFlight
      );

      // сразвнение с розыском
      let wanted: boolean;
      wanted = this.compareWanted();

      if (tmpIncorrectData == 2) {
        this.dataFromBarCode.status = 2;
        this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          'statusWrong'
        );
        this.addError(
          2,
          this.globalI18n.getMessage(ModuleName.BoardingControl, 'statusWrong')
        );
      }

      if (doubleSeat) {
        tmpIncorrectData = 2;
        this.dataFromBarCode.status = 2;
        this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          'statusWrong'
        );
        this.addError(
          2,
          this.globalI18n.getMessage(ModuleName.BoardingControl, 'statusWrong')
        );
        /*this.addIncorrectBarCodeData(
          'seat',
          this.passengers.idBoardingpass,
          this.dataFromBarCode.seatNumber,
          this.dataFromBarCode.seatNumber
        );*/
      }

      if (wanted) {
        this.dataFromBarCode.status = 3;
        this.dataFromBarCode.statusMessage =
          this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusWanted'
          ) +
          ' (' +
          this.reasonBaggageWanted +
          ')';
        this.addError(
          3,
          this.globalI18n.getMessage(
            ModuleName.BoardingControl,
            'statusWanted'
          ) +
            ' (' +
            this.reasonBaggageWanted +
            ')'
        );
      }

      if (this.dataFromBarCode.haveBarcode > 0) {
        this.buttonExit = true;
      }

      // паспорт passport
      await this.loadPassportData(this.dataFromBarCode.idFlight, this.dataFromBarCode.passengerName[0], this.dataFromBarCode.prnCode[0]);

      if (this.dataFromBarCode.status == 8) {
        // Никаких ошибок все гуд
        this.dataFromBarCode.status = 1;
        this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          'statusCheckPassed'
        );
        //this.clearErrors();
        //this.rightSide = 1;
        //this.insertPass();
      } else {
        if (tmpIncorrectData == 2) {
          this.insertIncorrect();
        }
        //this.rightSide = 2;

        /*if (this.scannerOff) {
          if (this.dataFromBarCode.status == 3) {
            // ошибка сканер отключен
            this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(ModuleName.BoardingControl, 'statusWaiting');
            this.passengers.idSolution = 0;   // по умолчанию пропустить это 0
            this.clearErrors();

            // Добавляем в таблицу first-workplace
            this.insertPass();
          } else {
            this.getBoardingPass = false;
            if (this.dataFromBarCode.status != 5) {
              this.insertIncorrect();
            }
          }
        } else {
          this.getBoardingPass = false;
          if (this.dataFromBarCode.status != 5) {
            this.insertIncorrect();
          }
        }*/
      }
    } else {
      this.getBoardingPass = true;
      this.dataFromBarCode.status = 7;
      this.dataFromBarCode.statusMessage = this.globalI18n.getMessage(
        ModuleName.BoardingControl,
        'statusFlightNotFound'
      );
      this.addError(
        1,
        this.globalI18n.getMessage(
          ModuleName.BoardingControl,
          'statusFlightNotFound'
        )
      );
      await this.insertBarcode();
      this.passengers.solutionEntrance = false;
      this.passengers.active = false;
      this.passengers.entranceMark = true;
      // Не пропустили нет рейса
      this.passengers.idReasonSolutionEntrance = -1;
      this.insertPass(false);
      //this.clearAll();
    }
  }

  /*exitInClearArea() {
    //console.log('exitInClearArea' + this.dataFromBarCode.haveBarcode);
    this.restApi.setExitClearArea(this.dataFromBarCode.haveBarcode, this.passengers.userName, this.installationWorkplace.id).subscribe(
        (data) => {
          this.clearAll();
        },
        (err) => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
  }*/

  exitInClearArea() {
    //console.log('exitInClearArea' + this.dataFromBarCode.haveBarcode);
    this.restApi.setExitClearArea(this.dataFromBarCode.haveBarcode, this.passengers.userName, this.installationWorkplace.id).subscribe(
      (data) => {
        this.clearAll();
      },
      (err) => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  createBarcodeGUID(countDay: any, seq: string) {
    this.dataFromBarCode.barcodeGuid = '';
    this.dataFromBarCode.barcodeGuid = countDay;
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.numFlight[0];
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.airlineCode[0];
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.cityDeparture[0];
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.cityArrival[0];
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.passengerName[0];
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.seatNumber[0];
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.className[0];
    this.dataFromBarCode.barcodeGuid = this.dataFromBarCode.barcodeGuid + seq;
    this.dataFromBarCode.barcodeGuid =
      this.dataFromBarCode.barcodeGuid + this.dataFromBarCode.code15field;

    console.log(this.dataFromBarCode.barcodeGuid);
  }

  // ищем с такимже местом для определенного рейса в first_workplace
  async getFirstWorkplaceDuplicate(seat: string, idflight: number) {
    return await this.restApi
      .getDuplicate(seat, idflight)
      .toPromise()
      .then((data) => {
        if (data) {
          if (String(data) == 'true') {
            this.dataFromBarCode.seatNumber[1] = true;
            this.addIncorrectBarCodeData(
              'seat',
              this.passengers.idBoardingpass,
              this.dataFromBarCode.seatNumber[0],
              this.dataFromBarCode.seatNumber[0]
            );
            return true;
          }
        }
      });
  }

  // ищем в списке рейсов нужный рес для сравнения
  getFlightToCompareBarcode() {
    console.log('getFlightToCompareBarcode');
    let index = -1;

    for (let i = 0; i < this.currentFlights.length; i++) {
      /*console.log(this.currentFlights[i].ak+" == "+this.dataFromBarCode.airlineCode[0]);
      console.log(this.currentFlights[i].numFlight+" == "+this.dataFromBarCode.numFlight[0]);
      console.log(this.currentFlights[i].fromAiport+" == "+this.dataFromBarCode.cityDeparture[0]);
      console.log(this.currentFlights[i].toAiport+" == "+this.dataFromBarCode.cityArrival[0]);
      console.log(this.currentFlights[i].date+" == "+this.dataFromBarCode.dateCompare);
      console.log(this.currentFlights[i].terminalName+" == terminal");
      console.log("================================");*/

      if (
        this.currentFlights[i].ak == this.dataFromBarCode.airlineCode[0] &&
        this.currentFlights[i].numFlight == this.dataFromBarCode.numFlight[0] &&
        this.currentFlights[i].fromAiport ==
          this.dataFromBarCode.cityDeparture[0] &&
        this.currentFlights[i].toAiport ==
          this.dataFromBarCode.cityArrival[0] &&
        this.currentFlights[i].date == this.dataFromBarCode.dateCompare
      ) {
        index = i;
        break;
      }
    }
    return index;
  }

  // ищем в таблице wanted-list
  compareWanted() {
    for (let i = 0; i < this.wantedLists.length; i++) {
      console.log(this.dataFromBarCode.passengerName[0].toUpperCase()+ " " + this.wantedLists[i].passenger.toUpperCase() + " " + this.dataFromBarCode.prnCode[0].trim() +" "+ this.wantedLists[i].prn.trim())
      if ((this.dataFromBarCode.passengerName[0].toUpperCase().includes(this.wantedLists[i].passenger.toUpperCase()) == true && (this.dataFromBarCode.prnCode[0].trim() == this.wantedLists[i].prn.trim()))) {
      //if ((this.dataFromBarCode.passengerName[0].toUpperCase() == this.wantedLists[i].passenger.toUpperCase()) && (this.dataFromBarCode.prnCode[0].toUpperCase() == this.wantedLists[i].prn.toUpperCase())) {
        console.log("FIND FIRST");
        this.reasonBaggageWanted = this.wantedLists[i].reasonName;
        this.passengers.idWantedList = this.wantedLists[i].id;
        return true;
        break;
      }
    }

    console.log("NEXT");

    for (let i = 0; i < this.wantedLists.length; i++) {

      console.log(this.dataFromBarCode.passengerName[0].toUpperCase()+ " " + this.wantedLists[i].passenger.toUpperCase() + " " + this.wantedLists[i].prn.trim() )
      if ((this.dataFromBarCode.passengerName[0].toUpperCase().includes(this.wantedLists[i].passenger.toUpperCase()) == true) && (this.wantedLists[i].prn.trim() == "")) {
      //if (this.dataFromBarCode.passengerName[0].toUpperCase() == this.wantedLists[i].passenger.toUpperCase()) {
        console.log("FIND NEXT");
        this.reasonBaggageWanted = this.wantedLists[i].reasonName;
        this.passengers.idWantedList = this.wantedLists[i].id;
        return true;
        break;
      }
    }
  }

  // добавление полей из баркода которые не соответствуют из АОДБ
  addIncorrectBarCodeData(
    nameField: string,
    id: number,
    frombarcode: any,
    froaodb: any
  ) {
    const ibd = new DictIncorrect();
    ibd.nameField = nameField;
    ibd.idBoardingPass = id;
    ibd.fromBarcode = frombarcode;
    ibd.fromAODB = froaodb;

    this.dictIncorrect.push(ibd);
  }

  // Добавляем в таблицу incorrect
  insertIncorrect() {
    this.restApi.insertDictIncorrect(this.dictIncorrect).subscribe(
      (data) => {
        console.log('ok');
        this.dictIncorrect = [];
      },
      (err) => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  async insertManualDFBC() {
    if (this.addBarcodePlaceForm.valid) {
      let barcode = 'M1';

      while (this.dataFromBarCode.passengerName[0].length < 20) {
        this.dataFromBarCode.passengerName[0] =
          this.dataFromBarCode.passengerName[0] + ' ';
      }

      barcode =
        barcode +
        this.dataFromBarCode.passengerName[0].toUpperCase() +
        '00000000';
      barcode =
        barcode +
        this.dataFromBarCode.cityDeparture[0] +
        this.dataFromBarCode.cityArrival[0];
      if (this.dataFromBarCode.airlineCode[0].length == 2) {
        barcode = barcode + this.dataFromBarCode.airlineCode[0] + ' ';
      } else {
        barcode = barcode + this.dataFromBarCode.airlineCode[0];
      }

      while (this.dataFromBarCode.numFlight[0].length < 5) {
        this.dataFromBarCode.numFlight[0] =
          this.dataFromBarCode.numFlight[0] + ' ';
      }

      barcode = barcode + this.dataFromBarCode.numFlight[0];
      const dateFlightFormat: moment.Moment = moment(
        this.dataFromBarCode.dateFlight[0]
      );
      let countday = dateFlightFormat.dayOfYear() + '';
      console.log(
        'COUNTDAY=' +
          countday +
          '-' +
          this.dataFromBarCode.dateFlight[0] +
          '-' +
          dateFlightFormat
      );

      while (countday.length < 3) {
        countday = countday + ' ';
      }

      barcode =
        barcode + countday + this.dataFromBarCode.className[0].toUpperCase();
      while (this.dataFromBarCode.seatNumber[0].length < 4) {
        this.dataFromBarCode.seatNumber[0] =
          this.dataFromBarCode.seatNumber[0] + ' ';
      }

      barcode =
        barcode +
        this.dataFromBarCode.seatNumber[0].toUpperCase() +
        '00000' +
        '0' +
        '00' +
        '>' +
        '0' +
        '00' +
        '0' +
        'WW';
      // M1FEDOROVSERGEY       EFVMUYR ULYLEDU6 1287 288YINF 0052 162>5320WW0056BSU                                        2A55521242178030    SU 96408642            N"}
      this.clearAll();
      this.preParceBarcode(barcode);
      this.addBarcodePlaceForm.form.markAllAsTouched();
      this.addBarcodeModalForm.nativeElement.click();
      console.log('BARCODE' + barcode);
    }
  }

  // Добавляем в таблицу data-from-barcode
  async insertBarcode() {
    return await this.restApi
      .insertBarcode(this.dataFromBarCode)
      .toPromise()
      .then((data) => {
        if (data) {
          const obj = JSON.parse(JSON.stringify(data));
          this.lastInsertBarcodeId = obj.body.id;
        }
      });
  }

  // Есть ли такой билет уже ?
  async haveBarcode() {
    return await this.restApi
      .haveBarcodeTable(this.dataFromBarCode.barcodeGuid)
      .toPromise()
      .then((data) => {
        //console.log('have222 = ' + data);
        this.dataFromBarCode.haveBarcode = data;
        console.log(
          'haveBarcodeInClearArea = ' + this.dataFromBarCode.haveBarcode
        );
      });
  }

  // Новое место, записывваем в таблицу GUID  нового раб места
  insertNewInstallationPlace() {
    this.restApi.insertInstallationPlace(this.GUID).subscribe(
      (data) => {
        console.log('ok');
      },
      (err) => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  // Добавляем в таблицу first-workplace
  insertPass(clear: boolean) {
    this.restApi.insertPassengers(this.passengers).subscribe(
      (data) => {
        if (clear) {
          this.clearAll();
        }
      },
      (err) => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  manualFlightOnChange(event): void {
    for (let i = 0; i < this.currentFlights.length; i++) {
      if (this.currentFlights[i].idFlight == event.target.value) {
        this.dataFromBarCode.numFlight[0] = this.currentFlights[i].numFlight;
        this.dataFromBarCode.airlineCode[0] = this.currentFlights[i].ak;
        this.dataFromBarCode.cityDeparture[0] = this.currentFlights[
          i
        ].fromAiport;
        this.dataFromBarCode.cityArrival[0] = this.currentFlights[i].toAiport;
        this.dataFromBarCode.dateFlight[0] = this.currentFlights[i].date;
        this.dataFromBarCode.terminalName[0] = this.currentFlights[
          i
        ].terminalName;
        this.dataFromBarCode.geoName[0] = this.currentFlights[i].geoName;
        this.dataFromBarCode.airlineName = this.currentFlights[i].airlineName;
        this.dataFromBarCode.airportDepartureName = this.currentFlights[
          i
        ].airportDepartureName;
        this.dataFromBarCode.airportArrivalName = this.currentFlights[
          i
        ].airportArrivalName;
        break;
      }
    }
  }
  // Из кол дней в '23JAN'
  /*formatDateFromBarcode(mon: number, day: number) {
    let formatDate = '';

    if (String(day).length == 1 ) {
      formatDate = '0' + day;
    } else {
      formatDate = String(day);
    }

    switch (mon) {
      case 0: formatDate = formatDate + 'JAN'; break;
      case 1: formatDate = formatDate + 'FEB'; break;
      case 2: formatDate = formatDate + 'MAR'; break;
      case 3: formatDate = formatDate + 'APR'; break;
      case 4: formatDate = formatDate + 'MAY'; break;
      case 5: formatDate = formatDate + 'JUN'; break;
      case 6: formatDate = formatDate + 'JUL'; break;
      case 7: formatDate = formatDate + 'AUG'; break;
      case 8: formatDate = formatDate + 'SEP'; break;
      case 9: formatDate = formatDate + 'OCT'; break;
      case 10: formatDate = formatDate + 'NOV'; break;
      case 11: formatDate = formatDate + 'DEC'; break;
      default: formatDate = formatDate + '';
    }

    return formatDate;
  }*/

  // За сколько времени пускать timeEnter + (planTime или calcTime)
  enterTime(timeFromAODB: string): boolean {
    let currentDateTimeUtc: moment.Moment = moment().utc();
    let fromAODB: moment.Moment = moment(timeFromAODB);

    let enterHour = this.installationWorkplace.timeEnter.substring(0, 2);
    let enterMinute = this.installationWorkplace.timeEnter.slice(-2);

    console.log(
      'Проверка входа в чистую зону, время начала. ТЕКУЩЕЕ ВРЕМЯ НА РАБОЧЕМ МЕСТЕ (UTC): ' +
        currentDateTimeUtc.format('YYYY MM DD HH:mm:ss')
    );
    console.log(
      'Проверка входа в чистую зону, время начала. ВРЕМЯ РЕЙСА (UTC): ' + fromAODB.format('YYYY MM DD HH:mm:ss')
    );

    fromAODB.subtract(enterHour, 'hours');
    fromAODB.subtract(enterMinute, 'minutes');

    console.log(
      'Проверка входа в чистую зону, время начала. ВРЕМЯ РАЗРЕШЕННОГО ВХОДА (UTC) C: ' + fromAODB.format('YYYY MM DD HH:mm:ss')
    );

    if (moment(currentDateTimeUtc).format('YYYY-MM-DD HH:mm:ss') < moment(fromAODB).format('YYYY-MM-DD HH:mm:ss')) {
      //if (moment(currentDateTimeUtc).isAfter(fromAODB)) {
      console.log('Проверка входа в чистую зону, время начала. ВХОД ПАССАЖИРУ НЕ РАЗРЕШЕН');
      return true;
    } else {
      console.log('Проверка входа в чистую зону, время начала. ВХОД ПАССАЖИРУ РАЗРЕШЕН');
      return false;
    }
  }

  // Время до конца посадки
  timeToEndOfBoarding(timeBoarding: string): boolean {
    let currentDateTimeUtc: moment.Moment = moment().utc();
    let Boarding: moment.Moment = moment(timeBoarding);

    console.log(
      'Проверка входа в чистую зону, время окончания. ТЕКУЩЕЕ ВРЕМЯ НА РАБОЧЕМ МЕСТЕ (UTC): ' +
        currentDateTimeUtc.format('YYYY MM DD HH:mm:ss')
    );
    console.log(
      'Проверка входа в чистую зону, время окончания. ВРЕМЯ РЕЙСА (UTC): ' + Boarding.format('YYYY MM DD HH:mm:ss')
    );

    let intervalHour = this.installationWorkplace.timeInterval.substring(0, 2);
    let intervalMinute = this.installationWorkplace.timeInterval.slice(-2);

    Boarding.subtract(intervalHour, 'hours');
    Boarding.subtract(intervalMinute, 'minutes');

    console.log(
      'Проверка входа в чистую зону, время окончания. ВРЕМЯ РАЗРЕШЕННОГО ВХОДА (UTC) ДО: ' +
        Boarding.format('YYYY MM DD HH:mm:ss')
    );

    /*if (currentDateTimeUtc > Boarding){
      console.log('TRUE');
      return true;
    } else {
      console.log('FALSE');
      return false;
    }*/

    if (moment(currentDateTimeUtc).format('YYYY-MM-DD HH:mm:ss') > moment(Boarding).format('YYYY-MM-DD HH:mm:ss')) {
      console.log('Проверка входа в чистую зону, время окончания. ВХОД ПАССАЖИРУ НЕ РАЗРЕШЕН');
      return true;
    } else {
      console.log('Проверка входа в чистую зону, время окончания. ВХОД ПАССАЖИРУ РАЗРЕШЕН');
      return false;
    }

    /*if (moment(currentDateTimeUtc).isAfter(Boarding)) {
      //this.timeToEndBoarding = this.globalI18n.getMessage(ModuleName.BoardingControl, 'timeToEndBoarding');
      console.log('TRUE');
      return true;
    } else {
      console.log('FALSE');
      return false;
    }*/
  }

  addError(error: number, message: string) {
    const le = new ListError();
    le.errorType = error;
    le.statusMessage = message;
    this.listError.push(le);
  }

  clearWantedForm() {
    this.wantedModalFormContent.reset();
  }

  /*open(content) {
    this.modalService.open(content, {size: 'xl', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }*/
}

