<table class="w-100 table-striped table-border">
    <thead>
        <tr class="bg-blue color-white font-weight-bold">
            <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.compartments">
                Compartments</th>
            <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.baySection">
                Bay / section</th>
            <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.maxWeight">
                MAX weight</th>
            <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.load">Load</th>
            <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.overload">Overload
            </th>
        </tr>
    </thead>
    <tbody *ngFor="let compartment of holds">
        <tr *ngIf="compartment.maxWeight !== 0 && compartment.index !==0">
            <td class="font-weight-bold">{{ compartment.name }}</td>
            <td>{{ compartment.name }}</td>
            <td>{{ compartment.maxWeight }}</td>
            <td class="font-weight-bold"
                [ngClass]="{'text-red': getLoadWeight(compartment.name) > compartment.maxWeight}">
                {{ getLoadCompartment(compartment.name) > 0 ? getLoadCompartment(compartment.name) : '' }}
            </td>
            <td class="font-weight-bold text-red">
                {{ getLoadCompartment(compartment.name) > compartment.maxWeight ? getLoadCompartment(compartment.name) -
                compartment.maxWeight : '' }}
            </td>
        </tr>
            <tr *ngFor="let bay of compartment.bays">
                <td class="font-weight-bold">
                    {{ compartment.name }}
                </td>
                <td>
                    {{ bay.name }}
                </td>
                <td>
                    {{ bay.maxWeight }}
                </td>
                <td class="font-weight-bold">
                    {{ getLoadWeight(bay.name) > 0 ? getLoadWeight(bay.name) : '' }}
                </td>
                <td class="font-weight-bold text-red">
                    {{ getLoadWeight(bay.name) > bay.maxWeight ? getLoadWeight(bay.name) - bay.maxWeight : '' }}
                </td>
            </tr>
    </tbody>
</table>