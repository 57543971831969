<ul class="nav nav-tabs">
  <li class="nav-item">
    <a id="tab-offices" [ngClass]="{'active': activeTab=='tab-offices'}" class="nav-link active"
      (click)="changeTab($event)" i18n="adm|Lost-found module tabs - offices@@adm.lostFound.tabs.offices">
      Offices
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-users" [ngClass]="{'active': activeTab=='tab-users'}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Lost-found module tabs - users@@adm.lostFound.tabs.users">
      Users
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-groups" [ngClass]="{'active': activeTab=='tab-groups'}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Lost-found module tabs - groups@@adm.lostFound.tabs.groups">
      Groups
    </a>
  </li>
</ul>

<!-- Tab offices -->
<div *ngIf="activeTab=='tab-offices'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <!-- (click)="createResourceType()" -->
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal"
          data-target=".bd-offices-modal-xl" (click)="createOffice()">
          <i class="material-icons">add</i>
          <span i18n="adm|Lost-found module tab offices - button new@@adm.lostFound.tab.offices.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Lost-found module table offices - name@@adm.lostFound.table.offices.name">
          Name
        </th>
        <th class="text-uppercase"
          i18n="adm|Lost-found module table offices - airport@@adm.lostFound.table.offices.airport">
          Airport
        </th>
        <th class="text-uppercase"
          i18n="adm|Lost-found module table offices - airline@@adm.lostFound.table.offices.airline">
          Airline
        </th>
        <th class="text-uppercase"
          i18n="adm|Lost-found module table offices - lastUpdate@@adm.lostFound.table.offices.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let office of officesArray | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
        data-toggle="modal" data-target=".bd-offices-modal-xl" (click)="loadOffice(office.id)">
        <td>{{ office.name }}</td>
        <td>{{ office.airport }}</td>
        <td>{{ office.airline }}</td>
        <td>{{ office.lastUpdate | date: 'HH:mm dd.MM.yyyy' }}</td>
      </tr>
    </tbody>
  </table>

  <pagination-controls (pageChange)="currentPage = $event" maxSize="7" directionLinks="true" autoHide="true"
    previousLabel="Previous" nextLabel="Next"
    i18n-previousLabel="ref|Tab airports - pagination previous label@@ref.main.tab.airports.pagPrevious"
    i18n-nextLabel="ref|Tab airports - pagination next label@@ref.main.tab.airports.pagNext">
  </pagination-controls>

  <div class="modal fade bd-offices-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 *ngIf="!office.id" class="modal-title">
            <span
              i18n="adm|Modal Lost-found module offices - header for append mode@@adm.lostFound.modal.offices.headerAppend">Office
              append</span> {{ officeForm?.get('name')?.value }}
          </h5>
          <h5 *ngIf="office.id" class="modal-title">
            <span
              i18n="adm|Modal Lost-found module offices - header for edit mode@@adm.lostFound.modal.offices.headerEdit">Edit
              office</span> {{ officeForm?.get('name')?.value }}
          </h5>
          <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="loading" *ngIf="loading; else: officeFormTemplate">
            <div class="cssload-spin-box"></div>
          </div>
          <ng-template #officeFormTemplate>
            <form [formGroup]="officeForm">
              <div class="row mb-4">
                <div class="col-4">
                  <label for="resourseName"
                    i18n="adm|Modal Lost-found module offices - field office name@@adm.lostFound.modal.offices.field.resourceTypeName">
                    Office name
                  </label>
                  <input type="text" class="form-control form-control-sm" formControlName="name">
                </div>
                <div class="col-4">
                  <label for="airportId"
                    i18n="adm|Modal Lost-found module offices - field office airport@@adm.lostFound.modal.offices.field.airport">
                    Airport
                  </label>
                  <ng-select class="w-100 custom-sm bg-white select-long" [items]="references.airports.data"
                    bindLabel="iata" bindValue="id" appendTo="body" [virtualScroll]="true"
                    [loading]="references.airports.loading"
                    (scroll)="selectFuc.onScroll($event, references.airports, 'airports')"
                    (scrollToEnd)="selectFuc.onScrollToEnd(references.airports, 'airports')"
                    [searchFn]="selectFuc.customSearchAirport" formControlName="airportId" [selectOnTab]="true"
                    id="airportId">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-4">
                  <label for="airlineId"
                    i18n="adm|Modal Lost-found module offices - field office airline@@adm.lostFound.modal.offices.field.airline">
                    Airline
                  </label>
                  <ng-select class="w-100 custom-sm bg-white select-long" [items]="references.airlines.data"
                    bindLabel="iata" bindValue="id" appendTo="body" [virtualScroll]="true"
                    [loading]="references.airlines.loading"
                    (scroll)="selectFuc.onScroll($event, references.airlines, 'airlines')"
                    (scrollToEnd)="selectFuc.onScrollToEnd(references.airlines, 'airlines')"
                    [searchFn]="selectFuc.customSearchAirport" formControlName="airlineId" [selectOnTab]="true"
                    id="airlineId">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="font-small">
                        <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="card" formGroupName="data">
                <div class="card-body">

                  <div class="d-flex align-items-center mb-2">
                    <p class="font-weight-bold text-blue m-0"
                      i18n="adm|Modal Lost-found module offices - field addresses@@adm.lostFound.modal.offices.addresses">
                      Addresses
                    </p>
                    <button class="btn btn-ico btn-outline-primary btn-xs ml-3" style="height: 29px;"
                      (click)="addItemData('addresses')">
                      <span class="material-icons">add</span>
                    </button>
                  </div>

                  <div class="row mb-3" formArrayName="addresses">
                    <div class="col-4" *ngFor="let element of addresses.controls; let i = index">
                      <div class="input-group">
                        <div class="input-group mb-2">
                          <input type="text" class="form-control form-control-sm" [formControl]="addresses.controls[i]">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-danger btn-xs"
                              (click)="deleteItem(addresses, i)">
                              <i class="material-icons m-0">clear</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="d-flex align-items-center mb-2">
                    <p class="font-weight-bold text-blue m-0"
                      i18n="adm|Modal Lost-found module offices - field phones@@adm.lostFound.modal.offices.phones">
                      Phones
                    </p>
                    <button class="btn btn-ico btn-outline-primary btn-xs ml-3" style="height: 29px;"
                      (click)="addItemData('phones')">
                      <span class="material-icons">add</span>
                    </button>
                  </div>

                  <div class="row mb-3" formArrayName="phones">
                    <div class="col-4" *ngFor="let element of phones.controls; let i = index">
                      <div class="input-group">
                        <div class="input-group mb-2">
                          <input type="text" class="form-control form-control-sm" [formControl]="phones.controls[i]">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-danger btn-xs" (click)="deleteItem(phones, i)">
                              <i class="material-icons m-0">clear</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="d-flex align-items-center mb-2">
                    <p class="font-weight-bold text-blue m-0"
                      i18n="adm|Modal Lost-found module offices - field emails@@adm.lostFound.modal.offices.emails">
                      Emails
                    </p>
                    <button class="btn btn-ico btn-outline-primary btn-xs ml-3" style="height: 29px;"
                      (click)="addItemData('emails')">
                      <span class="material-icons">add</span>
                    </button>
                  </div>

                  <div class="row mb-3" formArrayName="emails">
                    <div class="col-4" *ngFor="let element of emails.controls; let i = index">
                      <div class="input-group">
                        <div class="input-group mb-2">
                          <input type="text" class="form-control form-control-sm" [formControl]="emails.controls[i]">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-danger btn-xs" (click)="deleteItem(emails, i)">
                              <i class="material-icons m-0">clear</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="d-flex align-items-center mb-2">
                    <p class="font-weight-bold text-blue m-0"
                      i18n="adm|Modal Lost-found module offices - field urls@@adm.lostFound.modal.offices.urls">URLs
                    </p>
                    <button class="btn btn-ico btn-outline-primary btn-xs ml-3" style="height: 29px;"
                      (click)="addItemData('urls')">
                      <span class="material-icons">add</span>
                    </button>
                  </div>

                  <div class="row mb-3" formArrayName="urls">
                    <div class="col-4" *ngFor="let element of urls.controls; let i = index">
                      <div class="input-group">
                        <div class="input-group mb-2">
                          <input type="text" class="form-control form-control-sm" [formControl]="urls.controls[i]">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-danger btn-xs" (click)="deleteItem(urls, i)">
                              <i class="material-icons m-0">clear</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="d-flex align-items-center mb-2">
                    <p class="font-weight-bold text-blue m-0"
                      i18n="adm|Modal Lost-found module offices - field notes@@adm.lostFound.modal.offices.notes">Notes
                    </p>
                    <button class="btn btn-ico btn-outline-primary btn-xs ml-3" style="height: 29px;"
                      (click)="addItemData('notes')">
                      <span class="material-icons">add</span>
                    </button>
                  </div>

                  <div class="row mb-3" formArrayName="notes">
                    <div class="col-4" *ngFor="let element of notes.controls; let i = index">
                      <div class="input-group">
                        <div class="input-group mb-2">
                          <input type="text" class="form-control form-control-sm" [formControl]="notes.controls[i]">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-danger btn-xs" (click)="deleteItem(notes, i)">
                              <i class="material-icons m-0">clear</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="d-flex align-items-center mb-2">
                    <p class="font-weight-bold text-blue m-0"
                      i18n="adm|Modal Lost-found module offices - field workingHours@@adm.lostFound.modal.offices.workingHours">
                      Working hours
                    </p>
                    <button class="btn btn-ico btn-outline-primary btn-xs ml-3" style="height: 29px;"
                      (click)="addItemData('workingHours')">
                      <span class="material-icons">add</span>
                    </button>
                  </div>

                  <div class="row mb-3" formArrayName="workingHours">
                    <div class="col-4" *ngFor="let element of workingHours.controls; let i = index">
                      <div class="input-group">
                        <div class="input-group mb-2">
                          <input type="text" class="form-control form-control-sm"
                            [formControl]="workingHours.controls[i]">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-outline-danger btn-xs"
                              (click)="deleteItem(workingHours, i)">
                              <i class="material-icons m-0">clear</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </ng-template>
        </div>
        <div class="modal-footer">
          <div *ngIf="!office.id">
            <button type="button" class="btn btn-sm btn-blue" (click)="addOffice()"
              i18n="adm|Modal Lost-found module offices - append@@adm.lostFound.modal.offices.button.append">
              Append
            </button>
          </div>
          <!-- <div *ngIf="activeState=='action-update'">
                <button type="button"
                        class="btn btn-sm btn-red"
                        (click)="confirmAction(deleteResourceType, office.id)"
                        i18n="adm|Modal Lost-found module offices - delete@@adm.lostFound.modal.offices.button.delete">
                  Delete
                </button>
              </div> -->
          <div *ngIf="office.id">
            <button type="button" class="btn btn-sm btn-blue" (click)="updateOffice(office.id)"
              i18n="adm|Modal Lost-found module offices - save@@adm.lostFound.modal.offices.button.save">
              Save
            </button>
          </div>
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal"
            i18n="adm|Modal Lost-found module offices - close@@adm.lostFound.modal.offices.button.close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Tab users -->
<div *ngIf="activeTab=='tab-users'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal"
          data-target=".bd-users-modal-xl" (click)="createUser()">
          <i class="material-icons">add</i>
          <span i18n="adm|Lost-found module tab users - button new@@adm.lostFound.tab.users.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-uppercase"
          i18n="adm|Lost-found module table users - username@@adm.lostFound.table.users.username">
          Username
        </th>
        <th class="text-center text-uppercase"
          i18n="adm|Lost-found module table users - firstName@@adm.lostFound.table.users.firstName">
          First name
        </th>
        <th class="text-uppercase"
          i18n="adm|Lost-found module table users - lastName@@adm.lostFound.table.users.lastName">
          Last name
        </th>
        <th class="text-uppercase" i18n="adm|Lost-found module table users - groups@@adm.lostFound.table.users.groups">
          Groups
        </th>
        <th class="text-uppercase"
          i18n="adm|Lost-found module table users - enabled@@adm.lostFound.table.users.enabled">
          Enabled
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
        data-toggle="modal" data-target=".bd-users-modal-xl" (click)="loadUser(user.id)">
        <td>{{ user.username }}</td>
        <td>{{ user.firstName }}</td>
        <td>{{ user.lastName }}</td>
        <td>{{ groupsString(user.groups) }}</td>
        <td>
          <span class="material-icons text-green" *ngIf="user.enabled">check</span>
          <span class="material-icons text-dark-gray" *ngIf="!user.enabled">close</span>
        </td>
      </tr>
    </tbody>
  </table>

  <pagination-controls (pageChange)="currentPage = $event" maxSize="7" directionLinks="true" autoHide="true"
    previousLabel="Previous" nextLabel="Next"
    i18n-previousLabel="ref|Tab airports - pagination previous label@@ref.main.tab.airports.pagPrevious"
    i18n-nextLabel="ref|Tab airports - pagination next label@@ref.main.tab.airports.pagNext">
  </pagination-controls>

  <div class="modal fade bd-users-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" *ngIf="userForm">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 *ngIf="!user.id" class="modal-title">
            <span
              i18n="adm|Modal Lost-found module users - header for append mode@@adm.lostFound.modal.users.headerAppend">User
              append</span> {{ userForm?.get('username')?.value }}
          </h5>
          <h5 *ngIf="user.id" class="modal-title">
            <span
              i18n="adm|Modal Lost-found module users - header for edit mode@@adm.lostFound.modal.users.headerEdit">Edit
              user</span> {{ userForm?.get('username')?.value }}
          </h5>
          <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="loading" *ngIf="loading; else: userFormTemplate">
            <div class="cssload-spin-box"></div>
          </div>
          <ng-template #userFormTemplate>
            <form>
              <div class="row mb-3" [formGroup]="userForm">
                <div class="col-4">
                  <label i18n="adm|Modal Lost-found module users - field name@@admlostFound.modal.users.field.name">
                    Username
                  </label>
                  <div class="input-group">
                    <input id="username" type="text" class="form-control form-control-sm" formControlName="username">
                  </div>
                </div>
                <div class="col-4">
                  <label
                    i18n="adm|Modal Lost-found module users - field first name@@admlostFound.modal.users.field.firstName">
                    First name
                  </label>
                  <div class="input-group">
                    <input id="firstName" type="text" class="form-control form-control-sm" formControlName="firstName">
                  </div>
                </div>
                <div class="col-4">
                  <label
                    i18n="adm|Modal Lost-found module users - field last name@@admlostFound.modal.users.field.lastName">
                    Last name
                  </label>
                  <div class="input-group">
                    <input id="lastName" type="text" class="form-control form-control-sm" formControlName="lastName">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-10">
                  <label i18n="adm|Modal Lost-found module users - field groups@@admlostFound.modal.users.field.groups">
                    Groups
                  </label>
                  <ng-select class="form-control custom-sm p-0" [items]="userGroups" bindLabel="name" bindValue="id"
                    appendTo="body" [multiple]="true" [closeOnSelect]="false" [virtualScroll]="true" name="groupsIds"
                    [(ngModel)]="groupsIds">
                    <ng-template ng-option-tmp let-item="item">{{item.name}}</ng-template>
                  </ng-select>
                </div>
                <div class="col-2 pt-4 mt-2" [formGroup]="userForm">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="enabled" formControlName="enabled">
                    <label class="form-check-label" for="enabled"
                      i18n="adm|Modal Lost-found module users - field enabled@@admlostFound.modal.users.field.enabled">
                      Enabled
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </div>
        <div class="modal-footer">
          <div *ngIf="!user.id">
            <button type="button" class="btn btn-sm btn-blue" (click)="addUser()"
              i18n="adm|Modal Lost-found module users - append@@adm.lostFound.modal.users.button.append">
              Append
            </button>
          </div>
          <div *ngIf="user.id">
            <button type="button" class="btn btn-sm btn-blue" (click)="updateUser(user.id)"
              i18n="adm|Modal Lost-found module users - save@@adm.lostFound.modal.users.button.save">
              Save
            </button>
          </div>
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal"
            i18n="adm|Modal Lost-found module users - close@@adm.lostFound.modal.users.button.close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Tab users -->
<div *ngIf="activeTab=='tab-groups'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal"
          data-target=".bd-groups-modal-xl" (click)="createUserGroup()">
          <i class="material-icons">add</i>
          <span i18n="adm|Lost-found module tab groups - button new@@adm.lostFound.tab.groups.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <!-- <th class="text-uppercase" i18n="adm|Lost-found module table groups - id@@adm.lostFound.table.groups.id">
          ID
        </th> -->
        <th class="text-center text-uppercase"
          i18n="adm|Lost-found module table groups - name@@adm.lostFound.table.groups.name">
          Name
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let group of userGroups | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
        data-toggle="modal" data-target=".bd-groups-modal-xl" (click)="loadUserGroup(group.id)">
        <!-- <td>{{ group.id }}</td> -->
        <td>{{ group.name }}</td>
      </tr>
    </tbody>
  </table>

  <pagination-controls (pageChange)="currentPage = $event" maxSize="7" directionLinks="true" autoHide="true"
    previousLabel="Previous" nextLabel="Next"
    i18n-previousLabel="ref|Tab airports - pagination previous label@@ref.main.tab.airports.pagPrevious"
    i18n-nextLabel="ref|Tab airports - pagination next label@@ref.main.tab.airports.pagNext">
  </pagination-controls>

  <div class="modal fade bd-groups-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" *ngIf="userGroupForm">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 *ngIf="!user.id" class="modal-title">
            <span
              i18n="adm|Modal Lost-found module groups - header for append mode@@adm.lostFound.modal.groups.headerAppend">Group
              append</span> {{ userGroupForm?.get('name')?.value }}
          </h5>
          <h5 *ngIf="user.id" class="modal-title">
            <span
              i18n="adm|Modal Lost-found module groups - header for edit mode@@adm.lostFound.modal.groups.headerEdit">Edit
              group</span> {{ userGroupForm?.get('name')?.value }}
          </h5>
          <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="loading" *ngIf="loading; else: groupFormTemplate">
            <div class="cssload-spin-box"></div>
          </div>
          <ng-template #groupFormTemplate>
            <form [formGroup]="userGroupForm">
              <label i18n="adm|Modal Lost-found module groups - field name@@admlostFound.modal.groups.field.name">
                Name
              </label>
              <div class="input-group">
                <input type="text" class="form-control form-control-sm" formControlName="name">
              </div>
              <div class="card mt-3" formArrayName="offices">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-2">
                    <p class="font-weight-bold text-blue m-0"
                      i18n="adm|Modal Lost-found module groups - offices@@adm.lostFound.modal.groups.offices">
                      Offices
                    </p>
                    <button class="btn btn-ico btn-outline-primary btn-xs ml-3" style="height: 29px;" (click)="addOfficeInGroup()">
                      <span class="material-icons">add</span>
                    </button>
                  </div>
                  <div class="row mb-3" *ngFor="let office of officesForGroup.controls; let i = index" [formGroupName]="i">
                    <div class="col-8">
                      <ng-select class="form-control custom-sm p-0"
                        [items]="officesArray"
                        bindLabel="name" bindValue="id"
                        appendTo="body"
                        [virtualScroll]="true"
                        formControlName="office">
                      </ng-select>
                    </div>
                    <div class="col-4">
                      <ng-select class="form-control custom-sm p-0"
                        [items]="['airline', 'handler']"
                        formControlName="type">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </div>
        <div class="modal-footer">
          <div *ngIf="!userGroup.id">
            <button type="button" class="btn btn-sm btn-blue" (click)="addUserGroup()"
              i18n="adm|Modal Lost-found module groups - append@@adm.lostFound.modal.groups.button.append">
              Append
            </button>
          </div>
          <div *ngIf="userGroup.id">
            <button type="button" class="btn btn-sm btn-blue" (click)="updateUserGroup(userGroup.id)"
              i18n="adm|Modal Lost-found module groups - save@@adm.lostFound.modal.groups.button.save">
              Save
            </button>
          </div>
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal"
            i18n="adm|Modal Lost-found module groups - close@@adm.lostFound.modal.groups.button.close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-message></app-modal-message>