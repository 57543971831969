<div class="d-flex justify-content-between mb-3">
    <div>
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".modal-airport"
            (click)="createRouteNetwork()">
            <i class="material-icons">add</i>
            <span i18n="adm|Weight and balance@@adm.wb.button.new">New</span>
        </button>
    </div>
    <div>
      <div class="input-group flex-nowrap mr-2">
        <div class="input-group-prepend custom-sm">
          <span class="input-group-text font-small py-0"
            i18n="adm|Weight and balance@@adm.wb.label.sercch">Search
          </span>
        </div>
        <input type="text"
            class="form-control custom-sm"
            placeholder="AIRLINE IATA/ICAO"
            maxlength="3"
            [(ngModel)]="view.searchAirline"/>
        <input type="text"
            class="form-control custom-sm"
            placeholder="AIRPORT IATA/ICAO"
            maxlength="4"
            [(ngModel)]="view.searchAirport"/>
      </div>
    </div>
</div>

<table class="table table-hover w-100">
    <thead class="text-center text-uppercase">
        <tr>
            <th i18n="adm|Weight and balance@@adm.wb.table.column.airline">Airline</th>
            <th i18n="adm|Weight and balance@@adm.wb.table.column.airport">Airport</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let route of routeNetworks" data-toggle="modal" data-target=".modal-airport"
            (click)="loadRouteNetwork(route.id)">
            <td>{{ route.airline }}</td>
            <td>{{ route.airport }}</td>
        </tr>
    </tbody>
</table>
<div class="modal fade bd-example-modal-xl modal-airport" #addAirport data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
            <form #routeNetworkForm="ngForm">
                <div class="loading" *ngIf="view.loading">
                    <div class="cssload-spin-box"></div>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">
                        <span *ngIf="view.userAction === ACTIONS.ADD"
                            i18n="adm|Weight and balance@@adm.wb.modal.editRoutePoint.addRoutePoint">
                            Add route point
                        </span>
                        <span *ngIf="view.userAction === ACTIONS.EDIT"
                            i18n="adm|Weight and balance@@adm.wb.modal.editRoutePoint.editRoutePoint">
                            Edit route point</span>
                    </h5>
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body bg-light-gray">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-6">
                                    <label i18n="adm|Weight and balance@@adm.wb.modal.editRoutePoint.airline">
                                        Airline
                                    </label>
                                    <ng-select class="form-control custom-sm p-0" [items]="airlines" bindLabel="iata"
                                        bindValue="id" [(ngModel)]="routeNetwork.airlineId" #airline="ngModel" name="airline"
                                        [virtualScroll]="true" required>
                                        <ng-template ng-option-tmp let-item="item">
                                            <span class="font-weight-bold">{{item.iata}} | {{item.icao}}</span>&nbsp;
                                            <small>{{item.nameLocal}} /{{item.nameEnglish}}</small>
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item">
                                            <span class="font-weight-bold">{{item.iata}} | {{item.icao}}</span>&nbsp;
                                            <small>{{item.nameLocal}} /{{item.nameEnglish}}</small>
                                        </ng-template>
                                    </ng-select>
                                    <div *ngIf="!airline?.valid && (airline?.dirty || airline?.touched)"
                                        class="invalid-feedback d-block">
                                        <div *ngIf="airline.errors.required"
                                            i18n="adm|Weight and balance@@adm.wb.modal.editRoutePoint.groupAirline.req">
                                            Airline is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label i18n="adm|Weight and balance@@adm.wb.modal.editRoutePoint.airport">
                                        Airport
                                    </label>
                                    <ng-select class="form-control custom-sm p-0" [items]="airports" bindLabel="iata"
                                        bindValue="id" [(ngModel)]="routeNetwork.airportId" #airport="ngModel" name="airport"
                                        [virtualScroll]="true" required>
                                        <ng-template ng-option-tmp let-item="item">
                                            <span class="font-weight-bold">{{item.iata}} | {{item.icao}}</span>&nbsp;
                                            <small>{{item.nameLocal}} /{{item.nameEnglish}}</small>
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item">
                                            <span class="font-weight-bold">{{item.iata}} | {{item.icao}}</span>&nbsp;
                                            <small>{{item.nameLocal}} /{{item.nameEnglish}}</small>
                                        </ng-template>
                                    </ng-select>
                                    <div *ngIf="!airport?.valid && (airport?.dirty || airport?.touched)"
                                        class="invalid-feedback d-block">
                                        <div *ngIf="airport.errors.required"
                                            i18n="adm|Weight and balance@@adm.wb.modal.editRoutePoint.airport">
                                            Airport is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-tabs" id="editAirportTabs" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="addresses-items-tab" data-toggle="tab"
                          i18n="adm|Weight and balance@@adm.wb.main.tab.addresses"
                          href="#addresses-items" role="tab">Addresses</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="documents-items-tab" data-toggle="tab"
                          i18n="adm|Weight and balance@@adm.wb.main.tab.documents" href="#documents-items"
                          role="tab">Documents</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="messages-items-tab" data-toggle="tab"
                          i18n="adm|Weight and balance@@adm.wb.main.tab.messages" href="#messages-items"
                          role="tab">Messages</a>
                      </li>
                    </ul>
                    <div class="tab-content p-3 bg-white" id="editAirportTabsContent">
                        <div class="tab-pane fade show active" id="addresses-items" role="tabpanel" aria-labelledby="addresses-items-tab">
                            <table class="table table-hover w-100">
                                <thead class="text-center text-uppercase">
                                    <tr>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.type">Type</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.address">Address</th>
                                        <th style="width: 65px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let address of routeNetwork.addresses; let i = index">
                                        <td>
                                            <ng-select class="form-control custom-sm p-0"
                                                        [items]="CHANNELS"
                                                        bindLabel="name"
                                                        bindValue="id"
                                                        [(ngModel)]="address.channelId"
                                                        [name]="'channel' + i"
                                                        required>
                                            </ng-select>
                                        </td>
                                        <td>
                                            <input type="text"
                                                  class="form-control custom-sm"
                                                  [name]="'address' + i"
                                                  [ngModel]="address.address"
                                                  #address="ngModel"
                                                  [addressValidator]="address.channelId"
                                                  (blur)="changeAddress(address, $event.target.value)"
                                                  required>
                                            <div *ngIf="!address?.valid && (address?.dirty || address?.touched)"
                                                 class="invalid-feedback d-block">
                                                <div *ngIf="address.errors?.['required']"
                                                      i18n="adm|Weight and balance@@adm.wb.modal.editAirport.address">
                                                      Address is required
                                                  </div>
                                            </div>
                                            <div *ngIf="!address?.valid && (address?.dirty || address?.touched)"
                                                 class="invalid-feedback d-block">
                                                <div *ngIf="address.errors?.['pattern']"
                                                      i18n="adm|Weight and balance@@adm.wb.modal.editAirport.address">
                                                      Address is 
                                                  </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button class="btn btn-outline-danger btn-xs"
                                                    (click)="deleteAddress(i)">
                                                <i class="material-icons mr-0">clear</i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-right">
                                <button class="btn btn-sm btn-blue"
                                        (click)="addAddress()"
                                        i18n="adm|Weight and balance@@adm.wb.main.button.add">Add</button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="documents-items" role="tabpanel" aria-labelledby="documents-items-tab">
                            <table class="table table-hover w-100">
                                <thead class="text-center text-uppercase">
                                    <tr>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.address">Address</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.arrivalDeparture">Arrival/Departure</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.notoc">NOTOC</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.loadsheet">Loadsheet</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.loadingInstruction">Loading Instruction</th>
                                        <th style="width: 65px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let document of routeNetwork.documents; let i = index">
                                        <td>
                                            <ng-select class="form-control custom-sm p-0"
                                                    [items]="routeNetwork.addresses"
                                                    bindLabel="address"
                                                    bindValue="address"
                                                    [(ngModel)]="document.address"
                                                    [name]="'document-address' + i"
                                                    required>
                                            </ng-select>
                                        </td>
                                        <td>
                                            <ng-select class="form-control custom-sm p-0"
                                                    [items]="ARRDEP"
                                                    bindLabel="name"
                                                    bindValue="id"
                                                    [(ngModel)]="document.arrDep"
                                                    [name]="'document-arrDep' + i"
                                                    required>
                                            </ng-select>
                                        </td>
                                        <ng-container *ngFor="let doc of document.docs">
                                            <td class="text-center">
                                                <input type="checkbox" [name]="doc.name + '_' + i" [(ngModel)]="doc.enabled">
                                            </td>
                                        </ng-container>
                                        <td>
                                            <button class="btn btn-outline-danger btn-xs"
                                                    (click)="deleteElement(routeNetwork.documents, i)">
                                                <i class="material-icons mr-0">clear</i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-right">
                                <button class="btn btn-sm btn-blue"
                                        (click)="addDocument()"
                                        i18n="adm|Weight and balance@@adm.wb.main.button.add">Add</button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="messages-items" role="tabpanel" aria-labelledby="messages-items-tab">
                            <table class="table table-hover w-100">
                                <thead class="text-center text-uppercase">
                                    <tr>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.address">Address</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.arrivalDeparture">Arrival/Departure</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.ldm">LDM</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.cpm">CPM</th>
                                        <th i18n="adm|Weight and balance@@adm.wb.table.column.ucm">UCM</th>
                                        <th style="width: 65px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let message of routeNetwork.messages; let i = index">
                                        <td>
                                            <ng-select class="form-control custom-sm p-0"
                                                    [items]="routeNetwork.addresses"
                                                    bindLabel="address"
                                                    bindValue="address"
                                                    [(ngModel)]="message.address"
                                                    [name]="'message-address' + i"
                                                    required>
                                            </ng-select>
                                        </td>
                                        <td>
                                            <ng-select class="form-control custom-sm p-0"
                                                    [items]="ARRDEP"
                                                    bindLabel="name"
                                                    bindValue="id"
                                                    [(ngModel)]="message.arrDep"
                                                    [name]="'message-arrDep' + i"
                                                    required>
                                            </ng-select>
                                        </td>
                                        <ng-container *ngFor="let doc of message.docs">
                                            <td class="text-center">
                                                <input type="checkbox" [name]="doc.name + '_' + i" [(ngModel)]="doc.enabled">
                                            </td>
                                        </ng-container>
                                        <td>
                                            <button class="btn btn-outline-danger btn-xs"
                                                    (click)="deleteElement(routeNetwork.messages, i)">
                                                <i class="material-icons mr-0">clear</i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-right">
                                <button class="btn btn-sm btn-blue"
                                        (click)="addMessage()"
                                        i18n="adm|Weight and balance@@adm.wb.main.button.add">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer bg-light-gray d-flex justify-content-between">
                    <div>
                        <button *ngIf="view.userAction === ACTIONS.EDIT" type="button" class="btn btn-sm btn-red"
                            (click)="deleteRouteNetwork(routeNetwork.id)">
                            <span i18n="adm|Weight and balance@@adm.wb.modal.editAirport.delete">Delete</span>
                        </button>
                    </div>
                    <div>
                        <button *ngIf="view.userAction === ACTIONS.ADD" type="button" class="btn btn-sm btn-blue"
                            (click)="addRouteNetwork()">
                            <span i18n="adm|Weight and balance@@adm.wb.modal.editAirport.append">Append</span>
                        </button>
                        <button *ngIf="view.userAction === ACTIONS.EDIT" type="button" class="btn btn-sm btn-blue"
                            (click)="saveRouteNetwork(routeNetwork.id)">
                            <span i18n="adm|Weight and balance@@adm.wb.modal.editAirport.button.save">Save</span>
                        </button>
                        <button type="button" class="btn btn-sm btn-gray-blue-dark ml-2" data-dismiss="modal">
                            <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.close">Close</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div *ngIf="message.errorMessage" class="ms-modal-message">
  <div class="modal-content w-50">
    <div class="modal-header" [ngClass]="{'bg-info': message.errorType == 'info',
                                    'bg-warning': message.errorType == 'warning',
                                    'bg-danger': message.errorType == 'error'}">
      <h5 *ngIf="message.errorType == 'info'" class="modal-title"
        i18n="adm|Weight and balance@@cmd.main.modalMessage.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="message.errorType == 'warning'" class="modal-title"
        i18n="adm|Weight and balance@@cmd.main.modalMessage.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="message.errorType == 'error'" class="modal-title"
        i18n="adm|Weight and balance@@cmd.main.modalMessage.errMes.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="white-space: pre-line">
      {{message.errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="message.modalType==='setAnswer'" class="btn btn-sm btn-blue" (click)="userAnswer = true"
        i18n="adm|Weight and balance@@cmd.main.modalMessage.errMes.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="message.modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="userAnswer = false" i18n="adm|Weight and balance@@cmd.main.modalMessage.errMes.button.no">
        No
      </button>
      <button type="button" *ngIf="message.modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="message.errorMessage = null"
        i18n="adm|Weight and balance@@cmn.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>