export class Parking {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.closeFrom = null;
    this.closeTo = null;
  }
  id: number;
  name: string;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  information: string;
  warning: string;
  closeFrom: Date;
  closeTo: Date;
  closeReasonId: number;
  closeReasonName: Array<string>;
  flightId: number;
  flightInfo: Flight;
}

export class CloseReason {
  id: number;
  name: Array<string>;

  get nameEnglish() {
    if (this.name.length > 0 && this.name[0]) {
      return this.name[0];
    } else {
      return '';
    }
  }

  set nameEnglish(value: string) {
    this.name[0] = value;
  }

  get nameLocal() {
    if (this.name.length > 1 && this.name[1]) {
      return this.name[1];
    } else {
      return '';
    }
  }

  set nameLocal(value: string) {
    this.name[1] = value;
  }

}

export class Flight {
  id: number;
  arrival: string;
  plane: string;
  departure: string;
  arrivalTime: Date;
  departureTime: Date;
  parkingId: number;
  parkingName: string;
  aircraft: string;
}