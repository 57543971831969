<!-- Вклакдки -->
<div class="row pt-2">
  <div class="col">
    <ul id="workplace-tabs" class="nav nav-tabs" active="activeForm">
      <li class="nav-item" index="0" *ngIf="installationWorkplace.showTabScanner">
        <a id="tab-boarding-control-scan"
           class="nav-link active"
           data-toggle="tab"
           href="#tab1"
           i18n="brc|Main menu - tab scanning@@brc.main.menu.tab.scanning">
          Scanning
        </a>
      </li>
      <li class="nav-item" index="1" *ngIf="installationWorkplace.showTabWanted">
        <a id="tab-boarding-control-list"
           class="nav-link"
           data-toggle="tab"
           href="#tab2"
           i18n="brc|Main menu - tab wanted list@@brc.main.menu.tab.wantedList">
          Wanted list
        </a>
      </li>
      <li class="nav-item" index="2" *ngIf="installationWorkplace.showClearArea">
        <a id="tab-boarding-control-reis"
           class="nav-link"
           data-toggle="tab"
           href="#tab3"
           i18n="brc|Main menu - tab list passengers in clean area@@brc.main.menu.tab.passengersInCleanArea">
          List of passengers in the clean area
        </a>
      </li>
      <li class="nav-item" index="3" *ngIf="installationWorkplace.showReport">
        <a id="tab-boarding-control-report"
           class="nav-link"
           data-toggle="tab"
           href="#tab4">
          Report
        </a>
      </li>
    </ul>
  </div>
  <div class="float-right mr-3 mt-1"
       *ngIf="dataFromBarCode.status != 5"
       ng-show="activeForm == 0">
    <button type="button"
            class="btn btn-xs btn-ico btn-light-gray"
            data-toggle="modal"
            data-target=".modal-installation-place">
      <i class="material-icons">info</i>
    </button>
  </div>
</div>
<!-- Вклакдки -->

<!-- Типа экраны -->
<div class="tab-content tab-borders h-75">
  <!--Сканирование-->
  <!--4 x 3-->
  <div class="tab-pane fade show active h-100" id="tab1" *ngIf="installationWorkplace.aspectRatio4x3">
    <div class="row h-100" [ngSwitch]="aspect4status">
      <div *ngSwitchCase="0" class="d-flex flex-column justify-content-between w-100 p-3">
        <div class="d-flex justify-content-between mb-2">
          <button type="button"
                  class="btn btn-xs btn-ico btn-dark-gray mr-3"
                  [ngClass]="{'btn-red': listError.length > 0}"
                  (click)="showErrorList3x4()">
            <i class="material-icons text-white">reorder</i>
          </button>

          <button type="button"
                  class="btn btn-xs btn-ico btn-dark-gray"
                  (click)="clearAll()"
                  [disabled]="dataFromBarCode.status != 0">
            <i class="material-icons">fullscreen</i>
          </button>
        </div>

        <div class="border rounded border-2 bg-light-gray h-100 d-flex align-items-center flex-column pt-5">
          <div [ngSwitch]="dataFromBarCode.status" class="align-items-center pt-5">
            <i *ngSwitchCase="0" class="material-icons text-black-50" style="font-size: 176px">phonelink</i>
            <i *ngSwitchCase="4" class="material-icons text-red" style="font-size: 176px">phonelink_off</i>
            <i *ngSwitchCase="5" class="material-icons text-red" style="font-size: 176px">phonelink_off</i>
            <i *ngSwitchDefault class="material-icons text-black-50" style="font-size: 176px">phonelink</i>
          </div>

          <div [ngSwitch]="dataFromBarCode.status">
            <div *ngSwitchCase="0" class="h3 font-weight-bold text-center text-black-50">{{dataFromBarCode.statusMessage}}</div>
            <div *ngSwitchCase="4" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
            <div *ngSwitchCase="5" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
            <div *ngSwitchDefault class="h3 font-weight-bold text-center text-black-50">{{dataFromBarCode.statusMessage}}</div>
          </div>

          <div class="h5 text-black-50"
               *ngIf="dataFromBarCode.status == 0"
               i18n="brc|Scan area - hold barcode@@brc.scanArea.holdBarcode">
            Hold barcode near the scanner to get started data processing
          </div>

          <button type="button" class="btn btn-blue px-4 my-4"
                  *ngIf="dataFromBarCode.status == 0 ||
                         dataFromBarCode.status == 4 ||
                         dataFromBarCode.status == 5"
                  data-toggle="modal"
                  data-target=".modal-add-passenger">
            <i class="material-icons color-white">person_add</i>
            <span class="text-white"
                  i18n="brc|Scan area - manual input@@brc.scanArea.manualInput">
              Manual input
            </span>
          </button>
        </div>
      </div>

      <div *ngSwitchCase="1" class="d-flex flex-column justify-content-between w-100 p-3">
        <div class="d-flex justify-content-between mb-2">
          <button type="button"
                  class="btn btn-xs btn-ico btn-dark-gray mr-3"
                  [ngClass]="{'btn-red': listError.length > 0}"
                  (click)="showErrorList3x4()">
            <i class="material-icons text-white">reorder</i>
          </button>

          <div *ngIf="dataFromBarCode.status == 0"
               class="rounded border-2 bg-white w-100 mr-3 h3 font-weight-bold text-center text-black-50">
            <div>{{dataFromBarCode.statusMessage}}</div>
          </div>

          <div *ngIf="dataFromBarCode.status == 1"
               class="rounded border-2 bg-green w-100 mr-3 h3 font-weight-bold text-center text-white">
            <div>{{dataFromBarCode.statusMessage}}</div>
          </div>

          <div *ngIf="dataFromBarCode.status == 2 ||
                      dataFromBarCode.status == 3  ||
                      dataFromBarCode.status == 4  ||
                      dataFromBarCode.status == 5  ||
                      dataFromBarCode.status == 6  ||
                      dataFromBarCode.status == 7"
               class="rounded border-2 bg-red w-100 mr-3 h3 font-weight-bold text-center text-white">
            <div>{{dataFromBarCode.statusMessage}}</div>
          </div>

          <div *ngIf="dataFromBarCode.status == 8"
               class="rounded border-2 bg-white w-100 mr-3 h3 font-weight-bold text-center text-black-50">
            <div>{{dataFromBarCode.statusMessage}}</div>
          </div>

          <button type="button"
                  class="btn btn-xs btn-ico btn-dark-gray"
                  (click)="clearAll()">
            <i class="material-icons">fullscreen</i>
          </button>
        </div>

        <div class="border rounded border-2 d-flex flex-column mb-3">
          <div class="row w-100 mt-3 mb-3 ml-3">
            <div class="row w-100">
              <div class="col w-100">
                <i class="material-icons text-black-50 h3 align-middle">flight_land</i>
                <span class="icons h4 text-black-50 font-weight-bold text-uppercase"
                      i18n="brc|Data area - flight@@brc.dataArea.flight">
                  Flight
                </span>
                <span class="h4 text-black font-weight-bold text-uppercase">
                  &nbsp;{{dataFromBarCode.delayTime}}
                </span>
              </div>
              <div class="col-3">
                <div class="h4 text-right w-100 font-weight-bold text-uppercase">
                  {{dataFromBarCode.statusFlight}}
                </div>
              </div>
            </div>

            <div class="row w-100">
              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - airline@@brc.dataArea.airline">
                  Airline
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-airline"
                       id="barcode-airline"
                       [ngClass]="{'error-show': dataFromBarCode.airlineCode[1] === true }"
                       [(ngModel)]="dataFromBarCode.airlineName"
                       [readOnly]="true">
              </div>

              <div class="col-3">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - flight number@@brc.dataArea.flightNumber">
                  Flight number
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-flight-number"
                       id="barcode-flight-number"
                       [ngClass]="{'error-show': dataFromBarCode.numFlight[1] === true }"
                       [(ngModel)]="dataFromBarCode.numFlight[0]"
                       [readOnly]="true">
              </div>
            </div>

            <div class="row w-100">
              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - from@@brc.dataArea.from">
                  From
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-from"
                       id="barcode-from"
                       [ngClass]="{'error-show': dataFromBarCode.cityDeparture[1] === true }"
                       [(ngModel)]="dataFromBarCode.airportDepartureName"
                       [readOnly]="true">
              </div>

              <div class="col">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - to@@brc.dataArea.to">
                  To
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-to"
                       id="barcode-to"
                       [ngClass]="{'error-show': dataFromBarCode.cityArrival[1] === true }"
                       [(ngModel)]="dataFromBarCode.airportArrivalName"
                       [readOnly]="true">
              </div>
            </div>

            <div class="row w-100">
              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - departure@@brc.dataArea.departure">
                  Departure
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-departure-time"
                       id="barcode-departure-time"
                       [(ngModel)]="dataFromBarCode.dateTimeFormat"
                       [readOnly]="true">
              </div>

              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - seat@@brc.dataArea.seat">
                  Seat
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-seat"
                       id="barcode-seat"
                       [ngClass]="{'error-show': dataFromBarCode.seatNumber[1] === true }"
                       [(ngModel)]="dataFromBarCode.seatNumber[0]"
                       [readOnly]="true">
              </div>

              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - class@@brc.dataArea.class">
                  Class
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-class"
                       id="barcode-class"
                       [ngClass]="{'error-show': dataFromBarCode.className[1] === true }"
                       [(ngModel)]="dataFromBarCode.className[0]"
                       [readOnly]="true">
              </div>

              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - terminal@@brc.dataArea.terminal">
                  Terminal
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-terminal"
                       id="barcode-terminal"
                       [ngClass]="{'error-show': dataFromBarCode.terminalName[1] === true }"
                       [(ngModel)]="dataFromBarCode.terminalName[0]"
                       [readOnly]="true">
              </div>

              <div class="col">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area - type@@brc.dataArea.type">
                  Type
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-type"
                       id="barcode-type"
                       [ngClass]="{'error-show': dataFromBarCode.geoName[1] === true }"
                       [(ngModel)]="dataFromBarCode.geoName[0]"
                       [readOnly]="true">
              </div>
            </div>
          </div>
        </div>

        <div class="border rounded border-2 d-flex flex-column mb-3">
          <div class="row w-100 mt-3  ml-3">
            <div class="row w-100">
              <div class="col">
                <i class="material-icons text-black-50 h3 align-middle">person</i>
                <span class="icons h4 text-black-50 font-weight-bold text-uppercase"
                      i18n="brc|Data area - passenger@@brc.dataArea.passenger">
                  Passenger
                </span>
              </div>

              <div class="col custom-control custom-switch w-100 text-right">
                <input type="checkbox"
                       id="infant"
                       name="infant"
                       [checked]="dataFromBarCode.infant"
                       class="custom-control-input">
                <label for="infant"
                       class="custom-control-label"
                       i18n="brc|Data area - infant@@brc.dataArea.infant">
                  Infant
                </label>
              </div>
            </div>

            <div class="row w-100 pr-3 pl-3 pb-3">
              <div class="p-0 border rounded border-2">
                <i class="material-icons text-black-50" style="font-size: 176px;">face</i>
              </div>

              <div class="col ml-3" [ngSwitch]="passportData">
                <div class="row">
                  <label class="col-form-label col-form-label-sm"
                         i18n="brc|Data area - name@@brc.dataArea.name">
                    Name
                  </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="barcode-passenger-name"
                         id="barcode-passenger-name"
                         [(ngModel)]="dataFromBarCode.passengerName[0]"
                         [readOnly]="true">
                </div>

                <div *ngSwitchCase="0">
                  <div class="row d-flex flex-column align-content-center w-100">
                    <div class="h5 text-black-50 pt-4"
                         i18n="brc|Data area - passport data not found@@brc.dataArea.passportDataNotFound">
                      Passport data not found
                    </div>
                    <button type="button" class="btn btn-dark-gray" disabled>
                      <i class="material-icons">portrait</i>
                      <span i18n="brc|Data area - button check passport@@brc.dataArea.button.checkPassport">Check passport</span>
                    </button>
                  </div>
                </div>

                <div *ngSwitchCase="1" >
                  <div class="row d-flex flex-column align-content-center w-100">
                    <div class="h5 text-black-50 pt-4"
                         i18n="brc|Data area - passport data available@@brc.dataArea.passportDataAvailable">
                      Passport data available
                    </div>
                    <button type="button"
                            class="btn btn-blue"
                            (click)="passportDataTypeShow(true)">
                      <i class="material-icons">portrait</i>
                      <span i18n="brc|Data area - button check passport@@brc.dataArea.button.checkPassport">Check passport</span>
                    </button>

                  </div>
                </div>

                <div *ngSwitchCase="2" >
                  <div class="row">
                    <div class="col p-0 mr-2">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Data area - passport id@@brc.dataArea.passport.id">
                        Passport ID
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="barcode-passport-id"
                             id="barcode-passport-id"
                             [(ngModel)]="passport.passportId"
                             [readOnly]="true">
                    </div>

                    <div class="col p-0 mr-2">
                      <label class="col-form-label col-form-label-sm">
                        Passport validity
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="barcode-passport-validity"
                             id="barcode-passport-validity"
                             [(ngModel)]="passport.validity"
                             [readOnly]="true">
                    </div>

                    <div class="col p-0">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Data area - passport country@@brc.dataArea.passport.country">
                        Country
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="barcode-passport-country"
                             id="barcode-passport-country"
                             [(ngModel)]="passport.country"
                             [readOnly]="true">
                    </div>
                  </div>

                  <div class="row">
                    <div class="col p-0 mr-2">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Data area - passport data of birth@@brc.dataArea.passport.dateOfBitrh">
                        Date of birth
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="barcode-passport-date"
                             id="barcode-passport-date"
                             [(ngModel)]="passport.birthDay"
                             [readOnly]="true">
                    </div>

                    <div class="col p-0 mr-2">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Data area - passport sex@@brc.dataArea.passport.sex">
                        Sex
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="barcode-passport-sex"
                             id="barcode-passport-sex"
                             [(ngModel)]="passport.sex"
                             [readOnly]="true">
                    </div>

                    <div class="col p-0 d-flex">
                      <button type="button"
                              class="btn btn-sm btn-blue align-self-end w-100 h-auto p-1"
                              (click)="passportDataTypeShow(false)"
                              i18n="brc|Data area - button hide passport@@brc.dataArea.passport.button.hidePassport">
                        Hide passport
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <button type="button"
                  class="btn btn-gray-blue-dark flex-grow-1 mr-2"
                  *ngIf="dataFromBarCode.status != 0 &&
                         dataFromBarCode.status != 4 &&
                         dataFromBarCode.status != 5 &&
                         dataFromBarCode.status != 7 &&
                         dataFromBarCode.status != 8"
                  (click)="setStop3x4()">
            <i class="material-icons color-white">clear</i>
            <span class="text-white"
                  i18n="brc|Scan area - button stop@@brc.scanArea.button.stop">
              Stop
            </span>
          </button>

          <button *ngIf="buttonExit" type="button" class="btn bg-yellow flex-grow-1 mr-2" (click)="exitInClearArea()">
            <i class="material-icons color-white">arrow_back</i>
            <span class="text-white"
                  i18n="brc|Scan area - button exit@@brc.scanArea.button.exit">
              Exit
            </span>
          </button>

          <button type="button"
                  class="btn btn-blue flex-grow-1"
                  *ngIf="dataFromBarCode.status != 0 &&
                         dataFromBarCode.status != 4 &&
                         dataFromBarCode.status != 5 &&
                         dataFromBarCode.status != 7 &&
                         dataFromBarCode.status != 8"
                  (click)="setPass3x4()">
            <i class="material-icons">done</i>
            <span i18n="brc|Scan area - button pass@@brc.scanArea.button.pass">
              Pass
            </span>
          </button>
        </div>
      </div>

      <div *ngSwitchCase="2" class="d-flex flex-column justify-content-between w-100 p-3">
        <div class="d-flex justify-content-start p-3 flex-column rounded bg-light-gray h-100" >
          <span class="icons h4 text-black-50 font-weight-bold">
            <i class="material-icons text-black-50">reorder</i>
            <span i18n="brc|Scan area - button problem list@@brc.scanArea.button.problemList">Problem list</span>
          </span>
          <div class="bg-white border p-2 mb-2" *ngFor="let item of listError">
            <div class="d-flex justify-content-between">
              <span class="text-red font-weight-bold">{{item.statusMessage}}</span>
            </div>
          </div>

          <div class="d-flex h-100 align-items-end ml-auto">
            <button type="button"
                    class=" btn btn-secondary"
                    (click)="hideErrorList3x4()"
                    i18n="brc|Scan area - problem list button close@@brc.scanArea.problemList.button.close">
              Close
            </button>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="3" class="d-flex flex-column justify-content-between w-100 p-3 bg-light-gray m-3">
          <div>
            <i class="material-icons text-black-50 h3 align-middle">done</i>
            <span class="icons h4 text-black-50 font-weight-bold"
                  i18n="brc|Scan area - reason for passing@@brc.scanArea.reasonPassing">
              Reason for passing
            </span>
          </div>
          <div class="d-flex flex-row">
            <div class="col d-flex flex-column p-0 m-0 mr-3">
              <label class="col-form-label col-form-label"
                     i18n="brc|Scan area - reason for passing list@@brc.scanArea.reasonPassing.reasonList">
                Reason list
              </label>
              <div class="d-flex flex-column border rounded border-2 bg-white pl-2 pt-2 h-100">
                <div class="form-check" *ngFor="let item of dictReasonSolutionsPass; let first = first;">
                  <input class="form-check-input"
                         [value]="item.id"
                         type="radio"
                         [checked]="first"
                         name="reasonSkipping"
                         id="reasonSkipping{{item.id}}"
                         [(ngModel)]="passengers.idReasonSolutionEntrance" />
                  <label class="form-check-label"
                         for="reasonSkipping{{item.id}}">
                    {{item.name}}
                  </label>
                </div>
              </div>
            </div>
            <div class="col d-flex flex-column p-0 m-0">
              <label class="col-form-label col-form-label"
                     i18n="brc|Scan area - reason for passing note@@brc.scanArea.reasonPassing.note">
                Note
              </label>
              <textarea class="form-control p-0 pl-2 pr-2 pt-2"
                        rows="15"
                        [(ngModel)]="passengers.entranceNote">
              </textarea>
            </div>
          </div>

          <div class="mt-auto pt-3">
            <button type="button"
                    class="btn btn-gray-blue-dark float-right"
                    (click)="closeSkipSolution3x4()"
                    i18n="brc|Scan area - reason for passing button cancel@@brc.scanArea.reasonPassing.button.cancel">
              Cancel
            </button>

            <button type="button"
                    class="btn btn-blue float-right mr-2"
                    (click)="insertPass(true)"
                    i18n="brc|Scan area - reason for passing button save@@brc.scanArea.reasonPassing.button.save">
              Save
            </button>
          </div>
      </div>

      <div *ngSwitchCase="4" class="d-flex flex-column justify-content-between w-100 p-3 bg-light-gray m-3">
        <div>
          <i class="material-icons text-black-50 h3 align-middle">clear</i>
          <span class="icons h4 text-black-50 font-weight-bold"
                i18n="brc|Scan area - reason for stopping@@brc.scanArea.reasonStopping">
            Reason for stopping
          </span>
        </div>
        <div class="d-flex flex-row">
          <div class="col d-flex flex-column p-0 m-0 mr-3">
            <label class="col-form-label col-form-label"
                   i18n="brc|Scan area - reason for stopping list@@brc.scanArea.reasonStopping.list">
              Reason list
            </label>
            <div class="d-flex flex-column border rounded border-2 bg-white pl-2 pt-2 h-100">
              <div class="form-check" *ngFor="let item of dictReasonSolutionsStop; let first = first;">
                <input class="form-check-input"
                       [value]="item.id"
                       type="radio"
                       [checked]="first"
                       name="reasonStopping"
                       id="reasonStopping{{item.id}}"
                       [(ngModel)]="passengers.idReasonSolutionEntrance" />
                <label class="form-check-label" for="reasonStopping{{item.id}}">{{item.name}}</label>
              </div>
            </div>
          </div>

          <div class="col d-flex flex-column p-0 m-0">
            <label class="col-form-label col-form-label"
                   i18n="brc|Scan area - reason for stopping note@@brc.scanArea.reasonStopping.note">
              Note
            </label>
            <textarea class="form-control p-0 pl-2 pr-2 pt-2"
                      rows="19"
                      [(ngModel)]="passengers.entranceNote">
            </textarea>
          </div>
        </div>

        <div class="mt-auto pt-3">
          <button type="button"
                  class="btn btn-gray-blue-dark float-right"
                  (click)="closeStopSolution3x4()"
                  i18n="brc|Scan area - reason for stopping button cancel@@brc.scanArea.reasonStopping.button.cancel">
            Cancel
          </button>

          <button type="button"
                  class="btn btn-blue float-right mr-2"
                  (click)="insertPass(true)"
                  i18n="brc|Scan area - reason for stopping button save@@brc.scanArea.reasonStopping.button.save">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--16 x 9-->
  <div class="tab-pane fade show active" id="tab1" *ngIf="!installationWorkplace.aspectRatio4x3">
    <div class="row px-3 py-4">
      <div class="col bg-white">
        <div class="row border rounded border-2 mb-3">
          <div class="row w-100 mt-3 mb-3 ml-3">

            <div class="row w-100">
              <div class="col w-100">
                <i class="material-icons text-black-50 h3 align-middle">flight_land</i>
                <span class="icons h4 text-black-50 font-weight-bold text-uppercase"
                      i18n="brc|Data area long - flight@@brc.dataAreaLong.flight">
                  Flight
                </span>
                <span class="h4 text-black font-weight-bold text-uppercase">&nbsp;{{dataFromBarCode.delayTime}}</span>
              </div>
              <div class="col-3">
                <div class="h4 text-right w-100 font-weight-bold text-uppercase">{{dataFromBarCode.statusFlight}}</div>
              </div>
            </div>

            <div class="row w-100">
              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - flight@@brc.dataAreaLong.flight">
                  Airline
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-airline"
                       id="barcode-airline"
                       [ngClass]="{'error-show': dataFromBarCode.airlineCode[1] === true }"
                       [(ngModel)]="dataFromBarCode.airlineName"
                       [readOnly]="true">
              </div>

              <div class="col-3">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - flight number@@brc.dataAreaLong.flightNumber">
                  Flight number
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-flight-number"
                       id="barcode-flight-number"
                       [ngClass]="{'error-show': dataFromBarCode.numFlight[1] === true }"
                       [(ngModel)]="dataFromBarCode.numFlight[0]"
                       [readOnly]="true">
              </div>
            </div>

            <div class="row w-100">
              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - from@@brc.dataAreaLong.from">
                  From
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-from"
                       id="barcode-from"
                       [ngClass]="{'error-show': dataFromBarCode.cityDeparture[1] === true }"
                       [(ngModel)]="dataFromBarCode.airportDepartureName"
                       [readOnly]="true">
              </div>

              <div class="col">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - to@@brc.dataAreaLong.to">
                  To
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-to"
                       id="barcode-to"
                       [ngClass]="{'error-show': dataFromBarCode.cityArrival[1] === true }"
                       [(ngModel)]="dataFromBarCode.airportArrivalName"
                       [readOnly]="true">
              </div>
            </div>

            <div class="row w-100">
              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - departure@@brc.dataAreaLong.departure">
                  Departure
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-departure-time"
                       id="barcode-departure-time"
                       [(ngModel)]="dataFromBarCode.dateTimeFormat"
                       [readOnly]="true">
              </div>

              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - seat@@brc.dataAreaLong.seat">
                  Seat
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-seat"
                       id="barcode-seat"
                       [ngClass]="{'error-show': dataFromBarCode.seatNumber[1] === true }"
                       [(ngModel)]="dataFromBarCode.seatNumber[0]"
                       [readOnly]="true">
              </div>

              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - class@@brc.dataAreaLong.class">
                  Class
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-class"
                       id="barcode-class"
                       [ngClass]="{'error-show': dataFromBarCode.className[1] === true }"
                       [(ngModel)]="dataFromBarCode.className[0]"
                       [readOnly]="true">
              </div>

              <div class="col pr-0">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - terminal@@brc.dataAreaLong.terminal">
                  Terminal
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-terminal"
                       id="barcode-terminal"
                       [ngClass]="{'error-show': dataFromBarCode.terminalName[1] === true }"
                       [(ngModel)]="dataFromBarCode.terminalName[0]"
                       [readOnly]="true">
              </div>

              <div class="col">
                <label class="col-form-label col-form-label-sm"
                       i18n="brc|Data area long - type@@brc.dataAreaLong.type">
                  Type
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="barcode-type"
                       id="barcode-type"
                       [ngClass]="{'error-show': dataFromBarCode.geoName[1] === true }"
                       [(ngModel)]="dataFromBarCode.geoName[0]"
                       [readOnly]="true">
              </div>
            </div>

          </div>
        </div>

        <!-- Passenger -->
        <div class="row border rounded border-2" [ngSwitch]="passportData">
          <div *ngSwitchCase="0" class="row w-100 mt-3 ml-3">
            <div class="row w-100">
              <div class="col">
                <i class="material-icons text-black-50 h3 align-middle">person</i>
                <span class="icons h4 text-black-50 font-weight-bold text-uppercase"
                      i18n="brc|Data area long - passenger@@brc.dataAreaLong.passenger">
                  Passenger
                </span>
              </div>

              <div class="col custom-control custom-switch w-100 text-right">
                <label for="infant"
                       class="custom-control-label"
                       i18n="brc|Data area long - infant@@brc.dataAreaLong.infant">
                  Infant
                </label>
                <input type="checkbox"
                       id="infant"
                       name="infant"
                       [checked]="dataFromBarCode.infant"
                       class="custom-control-input">
              </div>
            </div>

            <div class="row w-100 pr-3 pl-3 pb-3">
              <div class="p-0 border rounded border-2">
                <i class="material-icons text-black-50" style="font-size: 176px;">face</i>
              </div>

              <div class="col ml-3">
                <div class="row">
                  <label class="col-form-label col-form-label-sm pt-0"
                         i18n="brc|Data area long - passenger name@@brc.dataAreaLong.passenger.name">
                    Name
                  </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="barcode-passenger-name"
                         id="barcode-passenger-name"
                         [(ngModel)]="dataFromBarCode.passengerName[0]"
                         [readOnly]="true">
                </div>

                <div class="row d-flex flex-column align-content-center w-100">
                  <div class="h5 text-black-50 pt-4"
                       i18n="brc|Data area long - passport data not found@@brc.dataAreaLong.passportDataNotFound">
                    Passport data not found
                  </div>
                  <button type="button" class="btn btn-dark-gray" disabled>
                    <i class="material-icons">portrait</i>
                    <span i18n="brc|Data area long - button check passport@@brc.dataAreaLong.button.checkPassport">
                      Check passport
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="1" class="row w-100 mt-3 ml-3">
            <div class="row w-100">
              <div class="col">
                <i class="material-icons text-black-50 h3 align-middle">person</i>
                <span class="icons h4 text-black-50 font-weight-bold text-uppercase"
                      i18n="brc|Data area long - passenger@@brc.dataAreaLong.passenger">
                  Passenger
                </span>
              </div>

              <div class="col custom-control custom-switch w-100 text-right">
                <label for="infant"
                       class="custom-control-label"
                       i18n="brc|Data area long - infant@@brc.dataAreaLong.infant">
                  Infant
                </label>
                <input type="checkbox"
                       id="infant"
                       name="infant"
                       [checked]="dataFromBarCode.infant"
                       class="custom-control-input">
              </div>
            </div>

            <div class="row w-100 pr-3 pl-3 pb-3">
              <div class="p-0 border rounded border-2" style="min-width: 185px; width: 185px;">
                <i class="material-icons text-black-50" style="font-size: 176px;">face</i>
              </div>

              <div class="col ml-3">
                <div class="row">
                  <label class="col-form-label col-form-label-sm"
                         i18n="brc|Data area long - passenger name@@brc.dataAreaLong.passenger.name">
                    Name
                  </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="barcode-passenger-name"
                         id="barcode-passenger-name"
                         [(ngModel)]="dataFromBarCode.passengerName[0]"
                         [readOnly]="true">
                </div>

                <div class="row d-flex flex-column align-content-center w-100">
                  <div class="h5 text-black-50 pt-4"
                       i18n="brc|Data area long - passport data available@@brc.dataAreaLong.passportDataAvailable">
                    Passport data available
                  </div>
                  <button type="button" class="btn btn-blue" (click)="passportDataTypeShow(true)">
                    <i class="material-icons">portrait</i>
                    <span i18n="brc|Data area long - button check passport available@@brc.dataAreaLong.button.checkPassportAvailable">
                      Check passport
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="2" class="row w-100 mt-3 ml-3">
            <div class="row w-100">
              <div class="col">
                <i class="material-icons text-black-50 h3 align-middle">person</i>
                <span class="icons h4 text-black-50 font-weight-bold text-uppercase"
                      i18n="brc|Data area long - passenger@@brc.dataAreaLong.passenger">
                  Passenger
                </span>
              </div>

              <div class="col custom-control custom-switch w-100 text-right">
                <label for="infant"
                       class="custom-control-label"
                       i18n="brc|Data area long - infant@@brc.dataAreaLong.infant">
                  Infant
                </label>
                <input type="checkbox"
                       id="infant"
                       name="infant"
                       [checked]="dataFromBarCode.infant"
                       class="custom-control-input">
              </div>
            </div>

            <div class="row w-100 pr-3 pl-3 pb-3">
              <div class="p-0 border rounded border-2">
                <i class="material-icons text-black-50" style="font-size: 176px;">face</i>
              </div>

              <div class="col ml-3">
                <div class="row">
                  <label class="col-form-label col-form-label-sm"
                         i18n="brc|Data area long - passenger name@@brc.dataAreaLong.passenger.name">
                    Name
                  </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="barcode-passenger-name"
                         id="barcode-passenger-name"
                         [(ngModel)]="dataFromBarCode.passengerName[0]"
                         [readOnly]="true">
                </div>

                <div class="row">
                  <div class="col p-0 mr-2">
                    <label class="col-form-label col-form-label-sm"
                           i18n="brc|Data area long - passenger passport id@@brc.dataAreaLong.passport.id">
                      Passport ID
                    </label>
                    <input type="text"
                           class="form-control form-control-sm"
                           name="barcode-passport-id"
                           id="barcode-passport-id"
                           [(ngModel)]="passport.passportId"
                           [readOnly]="true">
                  </div>

                  <div class="col p-0 mr-2">
                    <label class="col-form-label col-form-label-sm"
                           i18n="brc|Data area long - passenger passport validity@@brc.dataAreaLong.passport.validity">
                      Passport validity
                    </label>
                    <input type="text"
                           class="form-control form-control-sm"
                           name="barcode-passport-validity"
                           id="barcode-passport-validity"
                           [(ngModel)]="passport.validity"
                           [readOnly]="true">
                  </div>

                  <div class="col p-0">
                    <label class="col-form-label col-form-label-sm"
                           i18n="brc|Data area long - passenger passport country@@brc.dataAreaLong.passport.country">
                      Country
                    </label>
                    <input type="text"
                           class="form-control form-control-sm"
                           name="barcode-passport-country"
                           id="barcode-passport-country"
                           [(ngModel)]="passport.country"
                           [readOnly]="true">
                  </div>
                </div>

                <div class="row">
                  <div class="col p-0 mr-2">
                    <label class="col-form-label col-form-label-sm"
                           i18n="brc|Data area long - passenger passport date of birth@@brc.dataAreaLong.passport.dateOfBirth">
                      Date of birth
                    </label>
                    <input type="text"
                           class="form-control form-control-sm"
                           name="barcode-passport-date"
                           id="barcode-passport-date"
                           [(ngModel)]="passport.birthDay"
                           [readOnly]="true">
                  </div>

                  <div class="col p-0 mr-2">
                    <label class="col-form-label col-form-label-sm"
                           i18n="brc|Data area long - passenger passport sex@@brc.dataAreaLong.passport.sex">
                      Sex
                    </label>
                    <input type="text"
                           class="form-control form-control-sm"
                           name="barcode-passport-sex"
                           id="barcode-passport-sex"
                           [(ngModel)]="passport.sex"
                           [readOnly]="true">
                  </div>

                  <div class="col p-0 d-flex">
                    <button type="button"
                            class="btn btn-sm btn-blue align-self-end w-100 h-auto p-1"
                            (click)="passportDataTypeShow(false)"
                            i18n="brc|Data area long - passenger passport button hide passport@@brc.dataAreaLong.passport.button.hide">
                      Hide passport
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Passenger -->
      </div>

      <!-- Right side -->
      <div class="col border rounded border-2 bg-light-gray ml-3" [ngSwitch]="rightSide">
        <div *ngSwitchCase="0" class="d-flex flex-column justify-content-between w-100 h-100 p-3">
          <div class="row d-flex justify-content-between" >
            <button type="button"
                    class="btn btn-xs btn-ico btn-dark-gray"
                    [ngClass]="{'btn-red': listError.length > 0}"
                    (click)="showErrorList()">
              <i class="material-icons text-white">reorder</i>
            </button>

            <button type="button"
                    class="btn btn-xs btn-ico btn-dark-gray"
                    (click)="clearAll()"
                    [disabled]="dataFromBarCode.status != 0">
              <i class="material-icons">fullscreen</i>
            </button>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="d-flex align-items-center flex-column">

              <div [ngSwitch]="dataFromBarCode.status" class="align-items-center">
                <i *ngSwitchCase="0" class="material-icons text-black-50" style="font-size: 176px">phonelink</i>
                <i *ngSwitchCase="1" class="material-icons text-green" style="font-size: 176px">check_circle</i>
                <i *ngSwitchCase="2" class="material-icons text-red" style="font-size: 176px">error</i>
                <i *ngSwitchCase="3" class="material-icons text-red" style="font-size: 176px">error</i>
                <i *ngSwitchCase="4" class="material-icons text-red" style="font-size: 176px">phonelink_off</i>
                <i *ngSwitchCase="5" class="material-icons text-red" style="font-size: 176px">phonelink_off</i>
                <i *ngSwitchCase="6" class="material-icons text-red" style="font-size: 176px">error</i>
                <i *ngSwitchCase="7" class="material-icons text-red" style="font-size: 176px">error</i>
                <i *ngSwitchCase="8" class="material-icons text-black-50" style="font-size: 176px">loop</i>
              </div>

              <div [ngSwitch]="dataFromBarCode.status">
                <div *ngSwitchCase="0" class="h3 font-weight-bold text-center text-black-50">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="1" class="h3 font-weight-bold text-center text-green">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="2" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="3" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="4" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="5" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="6" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="7" class="h3 font-weight-bold text-center text-red">{{dataFromBarCode.statusMessage}}</div>
                <div *ngSwitchCase="8" class="h3 font-weight-bold text-center text-black-50">{{dataFromBarCode.statusMessage}}</div>
              </div>

              <div class="h5 text-black-50"
                   *ngIf="dataFromBarCode.status == 0"
                   i18n="brc|Scan area - hold barcode@@brc.scanAreaLong.holdBarcode">
                Hold barcode near the scanner to get started data processed
              </div>

              <button type="button" class="btn btn-blue px-4 mt-2"
                      *ngIf="dataFromBarCode.status == 0 ||
                             dataFromBarCode.status == 4 ||
                             dataFromBarCode.status == 5"
                      data-toggle="modal"
                      data-target=".modal-add-passenger">
                <i class="material-icons color-white">person_add</i>
                <span class="text-white"
                      i18n="brc|Scan area long - manual input@@brc.scanAreaLong.manualInput">
                  Manual input
                </span>
              </button>
            </div>
          </div>

          <div class="row d-flex justify-content-between">
            <button type="button"
                    class="btn btn-gray-blue-dark flex-grow-1 mr-2"
                    *ngIf="dataFromBarCode.status != 0 &&
                           dataFromBarCode.status != 4 &&
                           dataFromBarCode.status != 5 &&
                           dataFromBarCode.status != 7 &&
                           dataFromBarCode.status != 8"
                    (click)="setStop()">
              <i class="material-icons color-white">clear</i>
              <span class="text-white"
                    i18n="brc|Scan area long - button stop@@brc.scanAreaLong.button.stop">
                Stop
              </span>
            </button>

            <button *ngIf="buttonExit"
                    type="button"
                    class="btn bg-yellow flex-grow-1 mr-2"
                    (click)="exitInClearArea()">
              <i class="material-icons color-white">arrow_back</i>
              <span class="text-white"
                    i18n="brc|Scan area long - button exit@@brc.scanAreaLong.button.exit">
                Exit
              </span>
            </button>

            <button type="button"
                    class="btn btn-blue flex-grow-1"
                    *ngIf="dataFromBarCode.status != 0 &&
                           dataFromBarCode.status != 4 &&
                           dataFromBarCode.status != 5 &&
                           dataFromBarCode.status != 7 &&
                           dataFromBarCode.status != 8"
                    (click)="setPass()">
              <i class="material-icons">done</i>
              <span i18n="brc|Scan area long - button pass@@brc.scanAreaLong.button.pass">
                Pass
              </span>
            </button>
          </div>

        </div>

        <div *ngSwitchCase="1" class="d-flex flex-column py-3 h-100">
          <div>
            <i class="material-icons text-black-50 h3 align-middle">reorder</i>
            <span class="icons h4 text-black-50 font-weight-bold"
                  i18n="brc|Scan area long - problem list@@brc.scanAreaLong.problemList">
              Problem list
            </span>
            <div class="bg-white border p-2 mb-2" *ngFor="let item of listError">
              <div class="d-flex justify-content-between">
                <span class="text-red font-weight-bold">{{item.statusMessage}}</span>
              </div>
            </div>
          </div>

          <div class="mt-auto">
            <button type="button"
                  class="btn btn-secondary float-right"
                  (click)="hideErrorList()"
                  i18n="brc|Scan area long - problem list button close@@brc.scanAreaLong.problemList.button.close">
            Close
          </button>
          </div>
        </div>

        <div *ngSwitchCase="2" class="d-flex flex-column py-3 h-100">
          <div>
            <i class="material-icons text-black-50 h3 align-middle">clear</i>
            <span class="icons h4 text-black-50 font-weight-bold"
                  i18n="brc|Scan area long - reason for stopping@@brc.scanAreaLong.reasonStopping">
              Reason for stopping
            </span>
          </div>
          <div class="d-flex flex-row">
            <div class="col d-flex flex-column p-0 m-0 mr-3">
              <label class="col-form-label col-form-label-sm"
                     i18n="brc|Scan area long - reason for stopping list@@brc.scanAreaLong.reasonStopping.list">
                Reason list
              </label>
              <div class="d-flex flex-column border rounded border-2 bg-white pl-2 pt-2 h-100">
                <div class="form-check" *ngFor="let item of dictReasonSolutionsStop; let first = first;">

                  <input class="form-check-input"
                          [value]="item.id" type="radio"
                          [checked]="first"
                          name="reasonStopping"
                          id="reasonStopping{{item.id}}"
                          [(ngModel)]="passengers.idReasonSolutionEntrance"/>
                  <label class="form-check-label" for="reasonStopping{{item.id}}">{{item.name}}</label>
                </div>
              </div>
            </div>

            <div class="col d-flex flex-column p-0 m-0">
              <label class="col-form-label col-form-label-sm" i18n="brc|Scan area long - reason for stopping note@@brc.scanAreaLong.reasonStopping.note">
                Note
              </label>
              <textarea class="form-control p-0 pl-2 pr-2 pt-2"
                        rows="15"
                        [(ngModel)]="passengers.entranceNote">
              </textarea>
            </div>
          </div>

          <div class="mt-auto pt-3">
            <button type="button"
                    class="btn btn-gray-blue-dark float-right"
                    (click)="closeStopSolution()"
                    i18n="brc|Scan area long - reason for stopping button cancel@@brc.scanAreaLong.reasonStopping.button.cancel">
              Cancel
            </button>

            <button type="button"
                    class="btn btn-blue float-right mr-2"
                    (click)="insertPass(true)"
                    i18n="brc|Scan area long - reason for stopping button save@@brc.scanAreaLong.reasonStopping.button.save">
              Save
            </button>
          </div>
        </div>

        <div *ngSwitchCase="3" class="d-flex flex-column py-3 h-100">
          <div>
            <i class="material-icons text-black-50 h3 align-middle">done</i>
            <span class="icons h4 text-black-50 font-weight-bold"
                  i18n="brc|Scan area long - reason for passing@@brc.scanAreaLong.reasonPassing">
              Reason for passing
            </span>
          </div>
          <div class="d-flex flex-row">
            <div class="col d-flex flex-column p-0 m-0 mr-3">
              <label class="col-form-label col-form-label" i18n="brc|Scan area long - reason for passing list@@brc.scanAreaLong.reasonPassing.list">
                Reason list
              </label>
              <div class="d-flex flex-column border rounded border-2 bg-white pl-2 pt-2 h-100">
                <div class="form-check" *ngFor="let item of dictReasonSolutionsPass; let first = first;">
                  <input class="form-check-input"
                         [value]="item.id"
                         type="radio"
                         [checked]="first"
                         name="reasonSkipping"
                         id="reasonSkipping{{item.id}}"
                         [(ngModel)]="passengers.idReasonSolutionEntrance" />
                  <label class="form-check-label" for="reasonSkipping{{item.id}}">{{item.name}}</label>
                </div>
              </div>
            </div>

            <div class="col d-flex flex-column p-0 m-0">
              <label class="col-form-label col-form-label"
                     i18n="brc|Scan area long - reason for passing note@@brc.scanAreaLong.reasonPassing.note">
                Note
              </label>
              <textarea class="form-control p-0 pl-2 pr-2 pt-2"
                        rows="15"
                        [(ngModel)]="passengers.entranceNote">
              </textarea>
            </div>
          </div>

          <div class="mt-auto pt-3">
            <button type="button"
                    class="btn btn-gray-blue-dark float-right"
                    (click)="closeSkipSolution()"
                    i18n="brc|Scan area long - reason for passing button cancel@@brc.scanAreaLong.reasonPassing.button.cancel">
              Cancel
            </button>

            <button type="button"
                    class="btn btn-blue float-right mr-2"
                    (click)="insertPass(true)"
                    i18n="brc|Scan area long - reason for passing button save@@brc.scanAreaLong.reasonPassing.button.save">
              Save
            </button>
          </div>
        </div>
      </div>
      <!-- Right side -->
    </div>

  </div>
  <!--Список розыска-->
  <div class="tab-pane fade" id="tab2">
    <div class="row py-2">
      <div class="col-6">
        <button type="button" (click)="clearWantedForm()"
              class="btn btn-sm btn-light-gray"
              data-toggle="modal"
              data-target=".modal-add-wanted">
          <i class="material-icons">add</i>
          <span i18n="brc|Wanted list - button new@@brc.wantedList.button.new">New</span>
        </button>
      </div>

      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"
                  i18n="brc|Wanted list - search@@brc.wantedList.search">
              Search
            </span>
          </div>
          <input type="text"
                 aria-label="NAME"
                 placeholder="NAME"
                 class="form-control"
                 i18n-placeholder="brc|Wanted list - search name@@brc.wantedList.search.name">
          <input type="text"
                 aria-label="FLIGHT"
                 placeholder="FLIGHT"
                 class="form-control"
                 i18n-placeholder="brc|Wanted list - search flight@@brc.wantedList.search.flight">
          <input type="text"
                 aria-label="TAG NUMBER"
                 placeholder="TAG NUMBER"
                 class="form-control"
                 i18n-placeholder="brc|Wanted list - search tag number@@brc.wantedList.search.tagNumber">
        </div>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr class="text-light type-head table__main-header">
        <th class="text-uppercase head-border__top text-center"
            i18n="brc|Wanted list - table header flight@@brc.wantedList.table.header.flight">
          Flight
        </th>
        <!-- <th class="text-uppercase head-border__top text-center">Installation name</th> -->
        <!-- <th class="text-uppercase head-border__top text-center align-middle">Geo</th> -->
        <th class="text-uppercase head-border__top text-center align-middle"
            i18n="brc|Wanted list - table header pasenger@@brc.wantedList.table.header.pasenger">
          Passenger
        </th>
        <th class="text-uppercase head-border__top text-center align-middle"
            i18n="brc|Wanted list - table header pnr@@brc.wantedList.table.header.pnr">
          PNR
        </th>
        <!-- <th class="text-uppercase head-border__top text-center align-middle">Sequence</th> -->
        <th class="text-uppercase head-border__top text-center"
            i18n="brc|Wanted list - table header tag number@@brc.wantedList.table.header.tagNumber">
          Tag number
        </th>
        <th class="text-uppercase head-border__top text-center align-middle"
            i18n="brc|Wanted list - table header reason@@brc.wantedList.table.header.reason">
          Reason
        </th>
        <!-- <th class="text-uppercase head-border__top text-center align-middle">User</th> -->
        <!-- <th class="text-uppercase head-border__top text-center align-middle">Added</th> -->
        <!-- <th class="text-uppercase head-border__top text-center align-middle">Find</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Date time entrance</th> -->
        <!-- <th class="text-uppercase head-border__top text-center">Date time boarding</th> -->
        <th class="text-uppercase head-border__top text-center align-middle"
            i18n="brc|Wanted list - table header remove from wanted list@@brc.wantedList.table.header.removeFromWanted">
          Remove from wanted
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of wantedLists" data-toggle="modal">
        <td>{{item.akName}}&nbsp;{{item.numFlight}}</td>
        <!-- <td>{{item.geoName}}</td> -->
        <td>{{item.passenger}}</td>
        <td>{{item.prn}}</td>
        <!-- <td>{{item.seq}}</td> -->
        <td>{{item.tagNumber}}</td>
        <td>{{item.reasonName}}</td>
        <!-- <td>{{item.installationName}}</td> -->
        <!-- <td>{{item.userLogin}}</td> -->
        <!-- <td>{{item.dateTimeAdded}}</td> -->
        <!-- <td *ngIf="item.find" class="text-center"><i class="material-icons text-green">done</i></td> -->
        <!-- <td *ngIf="!item.find" class="text-center"><i class="material-icons text-red">clear</i></td> -->
        <!-- <td>{{item.dateTimeEntrance}}</td> -->
        <!-- <td>{{item.dateTimeBoarding}}</td> -->
        <td class="d-flex justify-content-center">
          <button type="button"
                  class="btn btn-red"
                  data-toggle="modal"
                  data-target=".modal-del-wanted"
                  (click)="delWanted(item.id, item.passenger)"
                  i18n="brc|Wanted list - table data button remove@@brc.wantedList.table.data.button.remove">
            Remove
          </button>
        </td>

      </tr>
      </tbody>
    </table>
  </div>

  <!--Clean Area-->
  <div class="tab-pane fade my-4" id="tab3">
    <div class="row mb-1">
      <div class="col-7">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
                  i18n="brc|Clean area - flight and passengers@@brc.cleanArea.selectedFlightAndPassengers">
              Selected flight and number of passengers
            </span>
          </div>
          <input type="text"
                 aria-label="Flight"
                 class="form-control"
                 [value]="selectedFlight"
                 readonly>
          <input type="text"
                 aria-label="Passengers"
                 class="form-control"
                 [value]="countPassenger"
                 readonly>
        </div>
        <p class="h5 text-black-50"
           i18n="brc|Clean area - flights@@brc.cleanArea.flights">
          Flights
        </p>
      </div>
      <div class="col-5">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" i18n="brc|Clean area - find passenger@@brc.cleanArea.findPassenger">
              Find passenger
            </span>
          </div>
          <input type="text" class="form-control" placeholder="Name" aria-label="Name" (keyup)="filterShow()" [(ngModel)]="filterText" >
        </div>
        <div>
          <p class="h5 text-black-50 float-left" i18n="brc|Clean area - passengers@@brc.cleanArea.passengers">
            Passengers
          </p>
          <button type="button"
                  class="btn btn-xs btn-blue float-right"
                  data-toggle="modal"
                  data-target=".modal-let-everyone"
                  (click)="setEveryoneOut()"
                  [disabled]="selectedFlightId == 0"
                  i18n="brc|Clean area - passengers let everyony out@@brc.cleanArea.letEveryoneOut">
            Let everyone out
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7 pr-0 mr-0">
        <div class="table-wrapper w-100">
          <table class="table table-earnings">
            <thead>
            <tr class="text-light type-head table__main-header">
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - flight table header airline@@brc.cleanArea.flights.table.header.airline">
                Airline
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - flight table header airline@@brc.cleanArea.flights.table.header.flightNumber">
                Flight number
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - flight table header airport@@brc.cleanArea.flights.table.header.airport">
                Airport
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - flight table header departure time@@brc.cleanArea.flights.table.header.departureTime">
                Departure time
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - flight table header status@@brc.cleanArea.flights.table.header.status">
                Status
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cr of currentFlights"
                (click)="selectFlight(cr.ak + '-'+cr.numFlight, cr.countPassenger, cr.idFlight)"
                class="bg-white">
              <ng-container *ngIf="installationWorkplace.idGeo == cr.idGeo">
                <td>{{cr.ak}}</td>
                <td>{{cr.numFlight}}</td>
                <td>{{cr.toAiport}}</td>
                <td>{{cr.departureTime}}</td>
                <td>{{cr.statusFlight}}</td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-5">
        <div class="table-wrapper w-100">
          <table class="table table-earnings">
            <thead>
            <tr class="text-light type-head table__main-header">
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - passengers table header passenger@@brc.cleanArea.passengers.table.header.passenger">
                Passenger
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - passengers table header seat@@brc.cleanArea.passengers.table.header.seat">
                Seat
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Clean area - passengers table header history@@brc.cleanArea.passengers.table.header.history">
                History
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="selectedFlightId == 0">
              <td colspan="3">
                <div class="d-flex justify-content-center"
                     i18n="brc|Clean area - passengers table data empty@@brc.cleanArea.passengers.table.data.empty">
                  Select a flight to view the list of passengers...
                </div>
              </td>
            </tr>
            <tr *ngFor="let fp of findPassenger" class="bg-white">
              <td>{{fp.fioPassenger}}</td>
              <td>{{fp.seat}}</td>
              <td class="text-center">
                <button type="button"
                        class="btn btn-light-gray"
                        data-toggle="modal"
                        data-target=".modal-show-history"
                        (click)="showHistory(fp.barcodeGUID)"
                        i18n="brc|Clean area - passengers table data button history@@brc.cleanArea.passengers.table.data.button.history">
                  History
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!--Отчеты-->
  <div class="tab-pane fade" id="tab4">
  </div>
</div>
<!-- Типа экраны -->

<!-- Modal -->
<!--Info Workplace-->
<div class="modal fade modal-installation-place mw-75" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-lg load--max-width">

    <div class="modal-header">
      <h5 class="modal-title"
          i18n="brc|Modal workplace info - name@@brc.modal.workplace.info">
        Information
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-content bg-light-gray">
      <div class="card-body p-3 bg-white rounded ">
        <table class="table table-earnings">
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - workplace@@brc.modal.workplace.workplace">Workplace</td>
            <td><span class="font-weight-bold">{{installationWorkplace.workplaceName}}</span></td>
            <td i18n="brc|Modal workplace info - passport scaner@@brc.modal.workplace.passportScaner">Passport scanner</td>
            <td *ngIf="installationWorkplace.passportScanner"><i class="material-icons text-green">done</i></td>
            <td *ngIf="!installationWorkplace.passportScanner"><i class="material-icons text-red">clear</i></td>
          </tr>
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - terminal@@brc.modal.workplace.terminal">Terminal</td>
            <td><span class="font-weight-bold">{{installationWorkplace.terminalName}}</span></td>
            <td i18n="brc|Modal workplace info - show tab wanted@@brc.modal.workplace.showTabWanted">Show tab wanted</td>
            <td *ngIf="installationWorkplace.showTabWanted"><i class="material-icons text-green">done</i></td>
            <td *ngIf="!installationWorkplace.showTabWanted"><i class="material-icons text-red">clear</i></td>
          </tr>
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - time enter@@brc.modal.workplace.name">Time enter</td>
            <td><span class="font-weight-bold">{{installationWorkplace.timeEnter}}</span></td>
            <td i18n="brc|Modal workplace info - show tab report@@brc.modal.workplace.name">Show tab report</td>
            <td *ngIf="installationWorkplace.showReport"><i class="material-icons text-green">done</i></td>
            <td *ngIf="!installationWorkplace.showReport"><i class="material-icons text-red">clear</i></td>
          </tr>
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - name@@brc.modal.workplace.name">Name</td>
            <td><span class="font-weight-bold">{{installationWorkplace.name}}</span></td>
            <td i18n="brc|Modal workplace info - show tab scanner@@brc.modal.workplace.showTabScanner">Show tab scanner</td>
            <td *ngIf="installationWorkplace.showTabScanner"><i class="material-icons text-green">done</i></td>
            <td *ngIf="!installationWorkplace.showTabScanner"><i class="material-icons text-red">clear</i></td>
          </tr>
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - geo name@@brc.modal.workplace.geoName">Geo name</td>
            <td><span class="font-weight-bold">{{installationWorkplace.geoName}}</span></td>
            <td>Show tab clear area</td>
            <td *ngIf="installationWorkplace.showClearArea"><i class="material-icons text-green">done</i></td>
            <td *ngIf="!installationWorkplace.showClearArea"><i class="material-icons text-red">clear</i></td>
          </tr>
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - time interval@@brc.modal.workplace.timeInterval">Time interval</td>
            <td><span class="font-weight-bold">{{installationWorkplace.timeInterval}}</span></td>
            <td i18n="brc|Modal workplace info - aspect ratio@@brc.modal.workplace.aspectRatio">Compact look, for small screens (4x3)</td>
            <td *ngIf="installationWorkplace.aspectRatio4x3"><i class="material-icons text-green">done</i></td>
            <td *ngIf="!installationWorkplace.aspectRatio4x3"><i class="material-icons text-red">clear</i></td>
          </tr>
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - delay between scans@@brc.modal.workplace.delayBetweenScans">Delay between scans, sec</td>
            <td><span class="font-weight-bold">{{installationWorkplace.reReadSec}}</span></td>
            <td i18n="brc|Modal workplace info - desk and gate@@brc.modal.workplace.deskGate">Desk/Gate</td>
            <td><span class="font-weight-bold">{{installationWorkplace.gate}}</span></td>
          </tr>
          <tr class="bg-white">
            <td i18n="brc|Modal workplace info - guid@@brc.modal.workplace.guid">GUID</td>
            <td colspan="3"><span class="font-weight-bold">{{installationWorkplace.guid}}</span></td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                i18n="brc|Modal workplace info - button close@@brc.modal.workplace.button.close">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!--Info Workplace-->

<!--Delete Wanted-->
<div class="modal fade modal-del-wanted mw-100" tabindex="-1" role="dialog" >
  <div class="modal-dialog">
    <div class="modal-header">
      <h5 class="modal-title"
          i18n="brc|Modal wanted list delete - header@@brc.modal.wantedList.delete.header">
        Delete for wanted list
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-content bg-light-gray">
      <div class="card-body p-3 bg-white rounded ">
        <label class="col-form-label col-form-label-sm"
               i18n="brc|Modal wanted list delete - passenger@@brc.modal.wantedList.delete.passenger">
          Passenger
        </label>
        <h6>{{wantedList.passenger}}</h6>
      </div>

      <div class="modal-footer">
        <button type="button"
                class="btn btn-sm btn-red"
                data-dismiss="modal"
                (click)="deleteWanted(wantedList.id)"
                i18n="brc|Modal wanted list delete - button passenger@@brc.modal.wantedList.delete.button.delete">
          Delete
        </button>
        <button type="button"
                class="btn btn-sm btn-secondary"
                data-dismiss="modal"
                i18n="brc|Modal wanted list delete - button close@@brc.modal.wantedList.delete.button.close">
          Close
        </button>
      </div>

    </div>
  </div>
</div>
<!--Delete Wanted-->

<!--Add Wanted-->
<div #wantedModalForm class="modal fade modal-add-wanted mw-100" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-xl load--max-width">

    <div class="modal-header">
      <h5 class="modal-title"
          i18n="brc|Modal wanted list add - header@@brc.modal.wantedList.add.header">
        Adding a passenger to the wanted list
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form #wantedModalFormContent="ngForm">
      <div class="modal-content bg-light-gray">
        <div class="modal-body">
          <div class="row">
            <div class="col-6 pr-1">
              <div class="card">
                <div class="card-body">
                  <p class="card-title text-blue"
                     i18n="brc|Modal wanted list add - passenger@@brc.modal.wantedList.add.passenger">
                    Passenger
                  </p>
                  <div class="row">
                    <div class="col-8">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - passenger name@@brc.modal.wantedList.add.passenger.name">
                        Name
                      </label><span class="required-asterisk">*</span>
                      <input type="text"
                            class="form-control form-control-sm"
                            name="passenger"
                            id="passenger"
                            [(ngModel)]="wantedList.passenger"
                            #passenger="ngModel"
                            name="passenger"
                            maxlength="50"
                            required>
                      <div *ngIf="!passenger?.valid && (passenger?.dirty || passenger?.touched)"
                          class="invalid-feedback d-block">
                        <div *ngIf="passenger.errors.required">
                          <div i18n="brc|Modal wanted list add - passenger name required@@brc.modal.wantedList.add.passenger.name.err.required">
                            Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - passenger sequence@@brc.modal.wantedList.add.passenger.sequence">
                        Sequence
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="numBirki"
                             id="seq"
                             [(ngModel)]="wantedList.seq"
                             #seq="ngModel"
                             name="seq"
                             maxlength="7">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - passenger tag number@@brc.modal.wantedList.add.passenger.tagNumber">
                        Tag number
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="numBirki"
                             id="numBirki"
                             [(ngModel)]="wantedList.tagNumber"
                             #numBirki="ngModel"
                             name="numBirki"
                             maxlength="11">
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - passenger pnt@@brc.modal.wantedList.add.passenger.pnr">
                        PNR
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="prnCode"
                             id="prnCode"
                             [(ngModel)]="wantedList.prn"
                             #prnCode="ngModel"
                             name="prnCode"
                             maxlength="7">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 pl-1">
              <div class="card">
                <div class="card-body">
                  <p class="card-title text-blue" i18n="brc|Modal wanted list add - flight@@brc.modal.wantedList.add.flight">
                    Flight
                  </p>
                  <div class="row">
                    <div class="col-8">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - flight number@@brc.modal.wantedList.add.flight.number">
                        Number
                      </label>
                      <select #idFlight
                              class="form-control form-control-sm"
                              name="idFlight"
                              [(ngModel)]="wantedList.idFlight"
                              (change)="wantedFlightOnChange($event)">
                        <option value="-1"> </option>
                        <option *ngFor="let cf of currentFlights" value="{{cf.idFlight}}">{{cf.numFlight}}</option>
                      </select>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm"
                      i18n="brc|Modal wanted list add - flight airline@@brc.modal.wantedList.add.flight.airline">
                        Airline
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="id_ak"
                             id="id_ak"
                             [(ngModel)]="wantedList.akName"
                             #id_ak="ngModel"
                             name="id_ak"
                             maxlength="50"
                             disabled>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - flight departure@@brc.modal.wantedList.add.flight.departure">
                        Departure
                      </label>
                      <input type="text"
                            class="form-control form-control-sm"
                            name="departureName"
                            id="departureName"
                             [(ngModel)]="wantedList.departureTime"
                            name="departureName"
                            maxlength="50"
                            disabled>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - flight type@@brc.modal.wantedList.add.flight.type">
                        Type
                      </label>
                      <input type="text"
                            class="form-control form-control-sm"
                            name="geoName"
                            id="geoName"
                            [(ngModel)]="wantedList.geoName"
                            name="geoName"
                            maxlength="50"
                            disabled>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm"
                             i18n="brc|Modal wanted list add - flight terminal@@brc.modal.wantedList.add.flight.terminal">
                        Terminal
                      </label>
                      <input type="text"
                            class="form-control form-control-sm"
                            name="terminalName"
                            id="terminalName"
                             [(ngModel)]="wantedList.terminalName"
                            name="terminalName"
                            maxlength="50"
                            disabled>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="col-form-label col-form-label-sm">
                <span i18n="brc|Modal wanted list add - reason wanted@@brc.modal.wantedList.add.reasonWanted">Reason for
                  wanted</span><span class="required-asterisk">*</span>
              </label>
              <!--<select #idFlight
                      class="form-control form-control-sm"
                      name="idFlight"
                      [(ngModel)]="wantedList.idFlight"
                      (change)="wantedFlightOnChange($event)">
                <option value="-1"> </option>
                <option *ngFor="let cf of currentFlights" value="{{cf.idFlight}}">{{cf.numFlight}}</option>
              </select>-->


              <ng-select class="form-control custom-sm border p-0"
                         [items]="dictReasonWanteds"
                         bindLabel="name"
                         bindValue="id"
                         (change)="wantedReasonOnChange($event)"
                         [(ngModel)]="wantedList.idReason"
                         name="idReason"
                         required>
              </ng-select>


              <!--<select #idReason
                      class="form-control form-control-sm"
                      name="idReason"
                      (change)="wantedReasonOnChange($event)">
                      [(ngModel)]="wantedList.idReason"
                      required>
                <option value="-1"> </option>
                <option *ngFor="let item of dictReasonWanteds" value="{{item.id}}">{{item.name}}</option>
              </select>-->
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-blue"
                  (click)="insertWanted()"
                  i18n="brc|Modal wanted list add - button add@@brc.modal.wantedList.add.button.add">
            Add
          </button>
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                  i18n="brc|Modal wanted list add - button close@@brc.modal.wantedList.add.button.close">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!--Add Wanted-->

<!-- Modal Выпустить всех -->
<div class="modal fade modal-let-everyone mw-100" tabindex="-1" role="dialog" >
  <div class="modal-dialog">
    <div class="modal-header">
      <h5 class="modal-title"
          i18n="brc|Modal everyone out - header@@brc.modal.everyonyOut">
        Let everyone out
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-content bg-light-gray">
      <div class="card-body p-3 bg-white rounded ">
        <label class="col-form-label col-form-label-sm">
          <span i18n="brc|Modal everyone out - passengers on flight@@brc.modal.passengersOnFlight">All passengers on
            flight</span>&nbsp;{{selectedFlight}}&nbsp;<span
                i18n="brc|Modal everyone out - left the area@@brc.modal.leftArea">left the area.</span>
        </label>
      </div>

      <div class="modal-footer">
        <button type="button"
                class="btn btn-sm btn-secondary"
                data-dismiss="modal"
                i18n="brc|Modal everyone out - button close@@brc.modal.everyonyOut.button.close">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal add passenger -->
<div #AddBarcodeModalForm class="modal fade modal-add-passenger mw-100" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-xl load--max-width">
    <div class="modal-header">
      <h5 class="modal-title"
          i18n="brc|Modal add passenger - header@@brc.modal.addPassenger">
        Add passenger
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form #addBarcodePlaceForm="ngForm">
      <div class="modal-content bg-light-gray">
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <p class="text-blue"
                     i18n="brc|Modal add passenger - passenger@@brc.modal.addPassenger.passenger">
                    Passenger
                  </p>
                  <div class="row">
                    <div class="col">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - passenger name@@brc.modal.addPassenger.passenger.name">Name</span><span class="required-asterisk">*</span>
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="addpassenger"
                             id="addpassenger"
                             [(ngModel)]="dataFromBarCode.passengerName[0]"
                             #addpassenger="ngModel"
                             name="addpassenger"
                             maxlength="20"
                             required>

                      <div *ngIf="!addpassenger?.valid && (addpassenger?.dirty || addpassenger?.touched)"
                           class="invalid-feedback d-block">
                        <div *ngIf="addpassenger.errors.required">
                          <div i18n="brc|Modal add passenger - passenger name required@@brc.modal.addPassenger.passenger.name.err.required">Name is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - passenger seat@@brc.modal.addPassenger.passenger.seat">Seat</span><span class="required-asterisk">*</span>
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="seatNumber"
                             id="seatNumber"
                             [(ngModel)]="dataFromBarCode.seatNumber[0]"
                             #seatNumber="ngModel"
                             name="seatNumber"
                             maxlength="5"
                             required>
                    </div>
                    <div class="col-6">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - passenger class@@brc.modal.addPassenger.passenger.class">Class</span><span class="required-asterisk">*</span>
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="className"
                             id="className"
                             [(ngModel)]="dataFromBarCode.className[0]"
                             #className="ngModel"
                             name="className"
                             maxlength="1"
                             required>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <p class="text-blue"
                     i18n="brc|Modal add passenger - flight@@brc.modal.addPassenger.flight">
                    Flight
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - flight number@@brc.modal.addPassenger.flightNumber">Number</span><span class="required-asterisk">*</span>
                      </label>
                      <select #idFlight
                              class="form-control form-control-sm"
                              name="idFlight"
                              [(ngModel)]="dataFromBarCode.idFlight"
                              required
                              (change)="manualFlightOnChange($event)">
                        <option *ngFor="let item of currentFlights" value="{{item.idFlight}}">
                          {{item.numFlight}}
                        </option>
                      </select>
                    </div>
                    <div class="col-6">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - airline@@brc.modal.addPassenger.airline">Airline</span><span class="required-asterisk">*</span>
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="airlineCode"
                             id="airlineCode"
                             [(ngModel)]="dataFromBarCode.airlineCode[0]"
                             #airlineCode="ngModel"
                             name="airlineCode"
                             maxlength="3"
                             required
                             disabled>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - departure@@brc.modal.addPassenger.departure">Departure</span><span class="required-asterisk">*</span>
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="dateFlight"
                             id="dateFlight"
                             [(ngModel)]="dataFromBarCode.dateFlight[0]"
                             #dateFlight="ngModel"
                             name="dateFlight"
                             maxlength="20"
                             required
                             disabled>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - terminal@@brc.modal.addPassenger.terminal">Terminal</span><span class="required-asterisk">*</span>
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="terminalName"
                             id="terminalName"
                             [(ngModel)]="dataFromBarCode.terminalName[0]"
                             #terminalName="ngModel"
                             name="terminalName"
                             maxlength="50"
                             required
                             disabled>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="brc|Modal add passenger - type@@brc.modal.addPassenger.type">Type</span>
                        <span class="required-asterisk">*</span>
                      </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="geoName"
                             id="geoName"
                             [(ngModel)]="dataFromBarCode.geoName[0]"
                             #geoName="ngModel"
                             name="geoName"
                             maxlength="50"
                             required
                             disabled>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-blue"
                  (click)="insertManualDFBC()"
                  i18n="brc|Modal add passenger - button add@@brc.modal.addPassenger.button.add">
            Add
          </button>
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                  i18n="brc|Modal add passenger - button close@@brc.modal.addPassenger.button.close">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Modal Show History-->
<div class="modal fade modal-show-history mw-100" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-xl load--max-width">

    <div class="modal-header">
      <h5 class="modal-title" i18n="brc|Modal passenger history - header@@brc.modal.passengerhistory">
        History passenger
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-content bg-light-gray">
      <div class="modal-body">
        <div class="table-wrapper w-100">
          <table class="table table-earnings">
            <thead>
            <tr class="text-light type-head table__main-header">
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Modal passenger history - table header passenger@@brc.modal.passengerhistory.table.header.passenger">
                Passenger
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Modal passenger history - table header workplace@@brc.modal.passengerhistory.table.header.workplace">
                Workplace
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Modal passenger history - table header event@@brc.modal.passengerhistory.table.header.event">
                Event
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Modal passenger history - table header event@@brc.modal.passengerhistory.table.header.reason">
                Reason
              </th>
              <th class="text-uppercase head-border__top text-center"
                  i18n="brc|Modal passenger history - table header date time@@brc.modal.passengerhistory.table.header.dateTime">
                Date time
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of showHistoryPassenger" class="bg-white">
              <td>{{item.passengerName}}</td>
              <td>{{item.workplaceName}}</td>
              <td>{{item.eventName}}</td>
              <td>{{item.reason}}</td>
              <td>{{item.date}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                i18n="brc|Modal passenger history - button close@@brc.modal.passengerhistory.button.close">
          Close
        </button>
      </div>

    </div>
  </div>
</div>

<!-- Modal Question ClearAreaExit-->
<!--<ng-template #modalQuestionExitClearArea let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add Wanted</h5>
    <label class="col-form-label col-form-label-sm">Такой пассажир уже есть в розыске, добавить еще одного ?</label>
  </div>

  &lt;!&ndash; Modal footer &ndash;&gt;
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-blue" (click)="modal.dismiss(true)">
      Yes
    </button>
    <button type="button" class="btn btn-sm btn-blue" (click)="modal.dismiss(false)">
      No
    </button>
  </div>
</ng-template>-->
