import { Baggage } from '../types/statements';

export function getCharacteristicCode(baggage: Baggage, references: {baggage_colors, baggage_types, baggage_materials, baggage_elements}) {
  const codeId = baggage.characteristics.colorId;
  const colorCode = getById(references.baggage_colors.data, codeId)?.code;
  const typeId = baggage.characteristics.typeId;
  const typeCode = getById(references.baggage_types.data, typeId)?.code;
  let materialCode = '';
  if (typeCode !== '22D' && typeCode !== '22R') {
    const materialId = baggage.characteristics.materialId;
    materialCode = getById(references.baggage_materials.data, materialId)?.code;
  }
  const elem = baggage.characteristics.externalElements.reduce((acc, el) => {
    return acc += getById(references.baggage_elements.data, el)?.code;
  }, '');
  return (colorCode ?? 'XX') + (typeCode ?? 'XX') + (materialCode ?? 'X') + elem;
}

function getById(array, id) {
  if (array && id) {
    return array.find(el => el.id === id) || null;
  }
}