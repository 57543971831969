import { NgModule } from '@angular/core';
import { AppAuthGuard } from '../app.authguard';
import { RouterModule, Routes } from '@angular/router';
import { DesktopBaggageComponent } from './desktop-baggage/desktop-baggage.component';
import { StatementComponent } from './desktop-baggage/statement/statement.component';
import { ManualSearchComponent } from './desktop-baggage/manual-search/manual-search.component';
import { LostStatementComponent } from './lost-items/lost-statement/lost-statement.component';
import { LostItemsComponent } from './lost-items/lost-items.component';

const routes: Routes = [
  {
    path: 'lostfound',
    component: DesktopBaggageComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_lostfound']
    }
  }, {
    path: 'lostfound/manual',
    component: ManualSearchComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_lostfound']
    }
  }, {
    path: 'lostfound/:id',
    component: StatementComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_lostfound']
    }
  }, {
    path: 'lostitems',
    component: LostItemsComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_lostfound']
    }
  }, {
    path: 'lostitems/:id',
    component: LostItemsComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_lostfound']
    }
  }, {
    path: 'lostitem/:id',
    component: LostStatementComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_lostfound']
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class LostFoundModuleRoutingModule { }
