export class LostItems {
  constructor(obj?: LostItems) {
    if (obj) {
      Object.assign(this, obj);
      this.internalElements = [];
      if (obj.internalElements && obj.internalElements.length > 0) {
        obj.internalElements.forEach(item => {
          const internal = new InternalElement(item);
          this.internalElements.push(internal);
        });
      }
      this.dt = new Date(obj.dt).getFullYear() > 2000 ? new Date(obj.dt) : null;
      this.found.dt = new Date(obj.found.dt).getFullYear() > 2000 ? new Date(obj.found.dt) : null;
      this.storage.dtStart = new Date(obj.storage.dtStart).getFullYear() > 2000 ? new Date(obj.storage.dtStart) : null;
      this.storage.dtFinish = new Date(obj.storage.dtFinish).getFullYear() > 2000 ? new Date(obj.storage.dtFinish) : null;
      this.finish.dtSent = new Date(obj.finish.dtSent).getFullYear() > 2000 ? new Date(obj.finish.dtSent) : null;
      this.finish.dtAct = new Date(obj.finish.dtAct).getFullYear() > 2000 ? new Date(obj.finish.dtAct) : null;
    } else {
      this.id = null,
      this.airportId = null,
      this.airport = '',
      this.dt = null,
      this.statusId = null,
      this.weight = null,
      this.name = '',
      this.notes = '',
      this.internalElements = [],
      this.found = {
        name: '',
        flight: '',
        airlineId: null,
        acReg: '',
        agent: '',
        dt: null,
        itemName: '',
        witness: '',
      };
      this.storage = {
        id: null,
        name: '',
        dtStart: null,
        dtFinish: null,
      };
      this.finish = {
        owner: '',
        airlineId: null,
        airportId: null,
        flight: '',
        dtSent: null,
        act: '',
        dtAct: null,
      };
      this.archived = false;
    }
  }

  id: string;
  airport?: string;
  airportId: number;
  dt: Date;
  statusId: number;
  weight: number;
  name: string;
  notes: string;
  internalElements: Array<InternalElement>;
  found: {
    name: string;
    airlineId: number;
    flight: string;
    acReg: string;
    agent: string;
    dt: Date;
    itemName: string;
    witness: string;
  };
  storage: {
    id: number;
    name: string;
    dtStart: Date;
    dtFinish: Date;
  };
  finish: {
    owner: string;
    airlineId: number;
    airportId: number;
    flight: string;
    dtSent: Date;
    act: string;
    dtAct: Date;
  };
  archived: boolean;
}

export class InternalElement {
  constructor(obj?: InternalElement) {
    if (obj) {
      Object.assign(this, obj);
      if (!this.internals) {
        this.categoryName = [];
      }
      this.internals = [];
      if (obj.internals && obj.internals.length > 0) {
        obj.internals.forEach(item => {
          const internal = new Internals(item);
          this.internals.push(internal);
        });
      }
    } else {
      this.categoryId = null;
      this.categoryName = [];
      this.description = '';
      this.internals = [];
    }
  }
  categoryId: number;
  categoryName: Array<string>;
  internals: Array<Internals>;
  description: string;
}

export class Internals {
  constructor(obj?: Internals) {
    if (obj) {
      Object.assign(this, obj);
    } else {
      this.id = null;
      this.name = [];
      this.description = '';
    }
  }
  id: number;
  name: Array<string>;
  description: string;
}
