import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as validator from '@shared/functions/validateInput';

@Component({
  selector: 'app-composition-seats-table',
  templateUrl: './composition-seats-table.component.html',
})
export class CompositionSeatsTableComponent implements OnInit {

  @Input() seats;
  @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>();
  // Переменная содержащая описание ошибки возникающей при работе модуля
  errorMessage = '';
  // Тип ошибки: error, warning, info
  errorType = '';
  // Ответ пользователя
  userAnswer = null;
  view = {
    // Текущее действие пользователя
    userAction: '',
    modalType: '',
  };

  constructor() { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>, array) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);
  }

  inputNotLetter(event, type = '') {
    return validator.inputNotLetter(event, type);
  }

  toNumber(value) {
    return validator.toNumber(value);
  }

  deleteElement(index) {
    this.deleteItem.emit(index);
  }

  clearTags(value: string) {
    return validator.clearTags(value);
  }

}
