<!-- HTML форма пользовательского интерфейса -->
<!-- верстаем на Bootstrap -->

<p>Material accounting works!</p>


<div *ngFor="let aircraft of aircraftList; let i = index" class="col-4">
  <p>ID: {{aircraft.id}}</p>
  <p>Type: {{aircraft.type}}</p>
  <p>Registration: {{aircraft.registrationNumber}}</p>
</div>