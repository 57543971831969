export class Folder {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.unread = undefined;
    //this.unreadFiltered = undefined;
    this.all = 0;
    this.opened = false;
  }

  id: number;
  name: string;
  unread: number;
  //unreadFiltered: number;
  all: number;
  children: Folder;
  parentId: number;
  opened: boolean;

  get unreadSpaceDelimited(): string {
    return this.unread.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  }
}

export class Folders {
  constructor() {
    this.folders = [];
  }

  folders: Array<Folder>;
}

export class FolderSetting {
  id: number;
  name: string;
  channelId: number;
  channelName: string;
  messageTypeId: number;
  messageTypeName: string;
  senderId: number;
  senderName: string;
  aftnSenderId: number;
  aftnSenderName: string;
  sitatexSenderId: number;
  sitatexSenderName: string;
  emailSenderId: number;
  emailSenderName: string;
  dtRange: Array<Date>;
  lastupdate: Date;
  defaultAftnLang: number;
  defaultAftnUrgencyId: number;
}
