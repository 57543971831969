<table class="w-100 contenteditable text-center mb-3">
  <thead>
    <tr class="font-weight-bold">
      <td width="35px"></td>
      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.crewCode">
        Crew code</td>
      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.standard">
        Standard</td>
      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.dow">
        DOW</td>
      <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.doi">
        DOI</td>
      <td width="35px"></td>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="drop($event, crew)">
    <tr *ngFor="let item of crew; let i = index" cdkDrag
      [ngClass]="{'bg-gray-blue-dark text-white': id === i }"
      (click)="editItemCrew(i)">
      <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
      <td data-toggle="modal" data-target=".bd-example-modal-xl">{{item.name}}</td>
      <td class="text-center" data-toggle="modal" data-target=".bd-example-modal-xl">
        <span *ngIf="item.standard" class="material-icons">done</span>
        <!-- <input type="checkbox" class="d-hover m-auto" [checked]="item.standard" disabled> -->
      </td>
      <td data-toggle="modal" data-target=".bd-example-modal-xl">{{item.dow}}</td>
      <td data-toggle="modal" data-target=".bd-example-modal-xl">{{item.doi}}</td>
      <td>
        <i class="material-icons m-0 text-red font-weight-bold pointer" (click)="deleteElement(i)">clear</i>
      </td>
    </tr>
  </tbody>
</table>
<div class="text-right">
  <button class="btn btn-blue" data-toggle="modal" data-target=".bd-example-modal-xl" (click)="createItemCrew()"
    i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.button.add">
    Add
  </button>
</div>

<!-- Modal groups -->
<form #groupForm="ngForm">
  <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.modal.header">
            Composition of crew
          </h5>
          <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body bg-white">
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.modal.crewCode">
                    Crew code
                  </span>
                </div>
                <input type="text" class="w-100 form-control custom-sm" [value]="crewItem.code" disabled />
                <input type="text"
                       class="w-100 form-control custom-sm"
                       name="crewItemName"
                       placeholder="ALTERNATIVE"
                       i18n-placeholder="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.crewCode.alternative"
                       [value]="crewItem.name === crewItem.code ? '' : crewItem.name"
                       (blur)="crewItem.title = $event.target.value" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0">DOW</span>
                </div>
                <input type="number" class="w-100 form-control custom-sm" name="crewItemDow"
                  [(ngModel)]="crewItem.dow" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0">DOI</span>
                </div>
                <input type="number" class="w-100 form-control custom-sm" name="crewItemDoi"
                  [(ngModel)]="crewItem.doi" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="input-group-prepend">
                <div class="custom-control custom-switch d-flex" style="min-width: fit-content;">
                  <input type="checkbox" class="custom-control-input" id="standard" name="standard"
                    [(ngModel)]="crewItem.standard" [checked]="crewItem.standard">
                  <!-- (click)="showArchiveAhm()"-->
                  <label class="custom-control-label" for="standard"
                    i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.modal.standard">
                    Standard
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <p class="font-weight-bold text-dark-gray h5 mb-3"
                i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.crewInCockpitLocations">
                Crew in cockpit locations
              </p>
              <table class="w-100 contenteditable text-center mb-3">
                <thead>
                  <tr class="font-weight-bold">
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.location">
                      Location
                    </td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.maxSeats">
                      Max seats
                    </td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.occupiedSeats">
                      Occupied seats
                    </td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.remark">
                      Remark
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cockpit of crewItem.cockpitLocations; let i = index">
                    <td>{{cockpit.location}}</td>
                    <td>{{cockpit.maxSeats}}</td>
                    <td>
                      <input type="number"
                            class="w-100 form-control custom-sm"
                            [name]="'cockpitSeats' + i"
                            [value]="cockpit.seats || ''"
                            (input)="cockpit.seats = +$event.target.value; updateCrewName();" />
                    </td>
                    <!-- <td contenteditable="true" [innerHTML]="cockpit.seats || ''" (keydown)="inputNotLetter($event)"
                      (blur)="cockpit.seats = toNumber($event.target.innerHTML)"></td> -->
                    <!-- <td contenteditable="true" [innerHTML]="cockpit.seats" (keydown)="inputNotLetter($event)"
                      (blur)="setOccupiedSeats(cockpit, $event.target.innerHTML, i)"></td> -->
                    <td>{{cockpit.remark}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6">
              <p class="font-weight-bold text-dark-gray h5 mb-3"
                i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.crewInCabinLocations">
                Crew in cabin locations
              </p>
              <table class="w-100 contenteditable text-center mb-3">
                <thead>
                  <tr class="font-weight-bold">
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.location">
                      Location
                    </td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.maxSeats">
                      Max seats
                    </td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.occupiedSeats">
                      Occupied seats
                    </td>
                    <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCabinSeats.table.remark">
                      Remark
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cabin of crewItem.cabinLocations; let i = index">
                    <td>{{cabin.location}}</td>
                    <td>{{cabin.maxSeats}}</td>
                    <td>
                      <input type="number"
                            class="w-100 form-control custom-sm"
                            [name]="'cabinSeats' + i"
                            [value]="cabin.seats || ''"
                            (input)="cabin.seats = +$event.target.value; updateCrewName();" />
                    </td>
                    <!-- <td contenteditable="true" [innerHTML]="cabin.seats || ''" (keydown)="inputNotLetter($event)"
                      (blur)="cabin.seats = toNumber($event.target.innerHTML)"></td> -->
                    <td>{{cabin.remark}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" *ngIf="userAction === USER_ACTIONS.ADD" class="btn btn-sm btn-blue"
            (click)="addItemCrew()"
            i18n="adm|Modal Comunication module groups - button append@@adm.comMod.modal.groups.button.append">
            Append
          </button>
          <button type="button" *ngIf="userAction === USER_ACTIONS.EDIT" class="btn btn-sm btn-blue"
            (click)="updateItemCrew(id)"
            i18n="adm|Modal Comunication module groups - button save@@adm.comMod.modal.groups.button.save">
            Save
          </button>
          <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal" aria-hidden="true"
            i18n="adm|Modal Comunication module groups - button close@@adm.comMod.modal.groups.button.close">
            Close
          </button>
        </div>
        <!-- <div *ngIf="errorMessage !== ''" class="ms-modal-message">
                <div class="modal-content w-50">
                    <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                    'bg-warning': errorType == 'warning',
                                                    'bg-danger': errorType == 'error'}">
                        <h5 *ngIf="errorType == 'info'"
                            i18n="adm|Modal Comunication module - header information@@adm.comMod.modal.header.information"
                            class="modal-title">
                            Information
                        </h5>
                        <h5 *ngIf="errorType == 'warning'"
                            i18n="adm|Modal Comunication module - header warning@@adm.comMod.modal.header.warning"
                            class="modal-title">
                            Warning
                        </h5>
                        <h5 *ngIf="errorType == 'error'"
                            i18n="adm|Modal Comunication module - header error@@adm.comMod.modal.header.error"
                            class="modal-title">
                            Error
                        </h5>
                        <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ errorMessage }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" *ngIf="userAction == 'delete'" class="btn btn-sm btn-blue"
                            (click)="deleteGroupFolder()"
                            i18n="adm|Modal Comunication module senders - delete yes@@adm.comMod.modal.senders.button.deleteYes">
                            Yes
                        </button>
                        <button type="button" *ngIf="userAction == 'delete'" class="btn btn-sm btn-gray-blue-dark"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Comunication module senders - delete no@@adm.comMod.modal.senders.button.deleteNo">
                            No
                        </button>
                    </div>
                </div>
            </div> -->
      </div>
    </div>
  </div>
</form>
