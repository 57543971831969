export class NgSelectFunctions {

  constructor() {
    this.numberOfItemsFromEndBeforeFetchingMore = 10;
    this.selectLoadAnimation = {
      notifications: false,
      worldTracer: false,
      baggage_statuses_animation: false,
      baggage_brands: false
    };
    this.buffer = {
      airlines: [],
      airports: [],
    };
    this.bufferSize = {
      airlines: 50,
      airports: 50,
    };
  }

  numberOfItemsFromEndBeforeFetchingMore: number;
  selectLoadAnimation: {
    notifications: boolean,
    worldTracer: boolean,
    baggage_statuses_animation: boolean,
    baggage_brands: boolean
  };

  buffer: {
    airlines: [],
    airports: [],
  };

  bufferSize: {
    airlines: number,
    airports: number,
  };

  onScrollToEnd(reference, name) {
    this.fetchMore(reference, name);
  }

  onScroll({ end }, reference, name) {
    if (this.selectLoadAnimation[name] || reference.length <= this.buffer[name].length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(reference, name);
    }
  }

  private fetchMore(reference, name) {
    const len = this.buffer[name].length;
    const more = reference.data.slice(len, this.bufferSize[name] + len);
    reference.loading = true;
    this.buffer[name] = this.buffer[name].concat(more);
    reference.loading = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param term Строка для поиска введеня пользователем
   * @param item Элемент для поиска
   */
  customSearchAirport(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
      (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
      (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 5 ?
        (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
        :
        (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }
}
