export class DataFromBarCode {
  constructor() {
    this.idFlight = 0;
    this.idPassport = 0;
    this.passengerName = ["", false];
    this.dateFlight = ["", false];
    this.airlineCode = ["", false];
    this.numFlight = ["", false];
    this.cityDeparture = ["", false];
    this.cityArrival = ["", false];
    this.seatNumber = ["", false];
    this.className = ["", false];
    this.baggage = "";
    this.barcode = "";
    this.dateTimeFormat = "";
    this.barcodeGuid = "";
    this.prnCode = ["", false];
    this.infant = false;
    this.manualInput = false;
    this.code15field = "";
    this.terminalName = ["", false];
    this.geoName = ["", false];

    this.airlineName = "";
    this.airportDepartureName = "";
    this.airportArrivalName = "";

    this.dateCompare = "";
    this.haveBarcode = 0;
    this.statusFlight = "";
    this.delayTime = "";
    this.status = 4;
    this.statusMessage = "";
  }

  idFlight: number;
  idPassport: number;
  passengerName: Array<any>;
  dateFlight: Array<any>;
  airlineCode: Array<any>;
  numFlight: Array<any>;
  cityDeparture: Array<any>;
  cityArrival: Array<any>;
  seatNumber: Array<any>;
  className: Array<any>;
  baggage: string;
  barcode: string;
  dateTimeFormat: string;
  barcodeGuid: string;
  prnCode: Array<any>;
  infant: boolean;
  manualInput: boolean;
  code15field: string;
  terminalName: Array<any>;
  geoName: Array<any>;

  airlineName: string;
  airportDepartureName: string;
  airportArrivalName: string;

  dateCompare: string;
  haveBarcode: number;
  statusFlight: string;
  delayTime: string;
  status: number;
  statusMessage: string;

  // Status
  // 0 = ready to scan
  // 1 = check passed
  // 2 = wrong data
  // 3 = wanted
  // 4 = error in program
  // 5 = server off
  // 6 = error time
  // 7 = other error
  // 8 = processing
}
