import { Component, Input, OnInit } from '@angular/core';
import { References } from '../types/types';
import { Referance } from '../types/statements';
import { GlobalSettings } from '@settings/global-settings';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LANGUAGES } from '@shared/const';
import { FormsService } from '../services/forms.service';

@Component({
  selector: 'content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.less']
})
export class ContentModalComponent implements OnInit {

  LANGUAGES = LANGUAGES;

  @Input() references: References;
  @Input() internalElements: UntypedFormArray;
  @Input() parentForm: UntypedFormGroup;


  baggageInternalForCategory: Array<Referance> = [];
  // Текущая выбранная категория для содержимого багажа
  baggageInternalCategoryId: number = null;
  // Текущая выбранный элемент в категории для содержимого багажа
  baggageInternalId: number = null;
  // Текущее описание элемента в категории для содержимого багажа
  baggageInternalDescription: string = null;

  content: Array<{
    id: number,
    name: string,
    description: string,
  }>;

  constructor(
    public globalSettings: GlobalSettings,
    private fb: UntypedFormBuilder,
    private formsService: FormsService,
  ) {
    globalSettings.loadDefaultConfig();
  }

  ngOnInit(): void {
  }

  getInternalsAsArray(index: number): UntypedFormArray {
    return this.internalElements.at(index).get('internals') as UntypedFormArray;
  }

  getLocalName(element: UntypedFormArray) {
    return this.globalSettings.language === LANGUAGES.EN ? element.at(0).value : element.at(1).value;
  }

  /**
   * Функция добавления нового элемента в массив вложений
   * каждый Элемент принадлежит к Категории, все Элементы нужно помещать внутрь
   * нужной Категории для дальнейшего правильно вывода пользователю
   * @param id Идентификатор места багажа
   */
  addBaggageInternalElement() {
    if (this.baggageInternalId) {
      // Добавляемый Элемента из массива
      const internalElement = this.references.baggage_internals.data.find(el => el.id === this.baggageInternalId);
      // Категория добавляемого Элемента
      const category = this.references.baggage_internal_categories.data.find(el => el.id === this.baggageInternalCategoryId);
      // Флаг проверки существования Категории в текущем наборе данных
      let categoryIsExist = false;
      // Поиск существующей Категории элементов для добавления нового Элемента
      if (this.internalElements.controls.length > 0) {
        const group = this.internalElements.controls.find(el => el.get('categoryId').value === internalElement.categoryId);
        if (group) {
          // Проверка на существование данного Элемента, чтобы избежать дублирования
          const internals = group.get('internals') as UntypedFormArray;
          // const internalEl = internals.controls.find(el => el.get('id').value === internalElement.id);
          // if (!internalEl) {
            // Добавление элемента в виде объекта
            const value = {
              id: internalElement.id,
              name: internalElement.name,
              description: this.baggageInternalDescription,
            };
            internals.push(this.formsService.createInternalElementsInternals(value));
          // }
          categoryIsExist = true;
        }
      }

      if (!categoryIsExist) {
        // Если Категории не было, ее необходимо создать и записать туда Элемент
        const value = {
          categoryId: this.baggageInternalCategoryId,
          categoryName: category.name,
          internals: [{
            id: internalElement.id,
            name: internalElement.name,
            description: this.baggageInternalDescription,
          }]
        };
        this.internalElements.push(this.formsService.createInternalElements(value));
      }
      this.baggageInternalCategoryId = null;
      this.baggageInternalId = null;
      this.baggageInternalDescription = null;
    }
  }

  /**
   * Функция определения списка элементов для группы или
   * группы для выбранного элемента, в зависимости от действий пользователя
   * @param value Действие пользователя
   * @param element Выбранный элемент пользователем
   */
  findBaggageContentAndGroup(value: string, element) {
    // Запрос списка элементов по Категории
    if (value === 'items') {
      // Если категория задана, загрузка элементов из данной категории в
      // массив для отображения
      if (element !== undefined) {
        this.baggageInternalForCategory = this.references.baggage_internals.data.filter(el => el.categoryId === element.id);
      } else {
        // Если категории нет, загрузка полного списка Элементов
        this.baggageInternalForCategory = this.references.baggage_internals.data;
      }
      this.baggageInternalId = null;
      return;
    } else {
      // Запрос группы к которой принадлежит элемент
      if (element !== undefined) {
        for (const item of this.references.baggage_internal_categories.data) {
          if (item.id === element.categoryId) {
            this.baggageInternalCategoryId = element.categoryId;
          }
        }
      }
    }
  }

  /**
   * Функция удаления элементов с проверкой категории на пустоту
   * @param i индекс категории
   * @param j индекс элемента
   */
  removeInternal(i: number, j: number) {
    const internals = this.internalElements.at(i).get('internals') as UntypedFormArray;
    internals.removeAt(j);
    if (internals.length === 0) {
      this.internalElements.removeAt(i);
    }
  }

}
