import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {
  AdministrationFilter,
  AdministrationTemplate,
  AdministrationContact,
  AdministrationArchive,
  AdministrationAddress,
  AdministrationSender,
  AdministrationGroup,
  AdministrationUser
} from '../types/com-module';
import { Observable, throwError, from } from 'rxjs';
import { filter, retry, catchError, map } from 'rxjs/operators';
import {
  FilterFileds,
  FilterOperations,
  Folder,
  FieldTypes,
  MessageTypes,
  Channels,
  Airport
} from '../types/reference';
import {
  Playlist,
  Media,
  Client,
  Specification,
  InformationAirline,
  InformationAirport,
  InformationStatus,
  ClientType,
  GeoType,
  Template
} from '../types/informational';
import { GlobalSettings } from '@settings/global-settings';

import {
  DictGeo,
  InstallationWorkplace,
  DictReasonWanted,
  DictReasonSolution,
  DictTerminal,
  DictWorkplace,
  DictStatusPassenger,
} from '../types/boardingcontrol';
import { Urgency } from '../../communication-module-data/reference';
import { AhmData, Workspace } from '../../weight-balance-data/weight-balance';

@Injectable({
  providedIn: 'root'
})
export class AdministrationRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Filters
  getFilters(): Observable<AdministrationFilter[]> {
    return this.http.get<AdministrationFilter[]>(this.globalSettings.apiAdministrationURL + '/admin/filters?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFilter(id: string): Observable<AdministrationFilter> {
    return this.http.get<AdministrationFilter>(this.globalSettings.apiAdministrationURL +
                                           '/admin/filters/' +
                                           id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addFilter(foder: any): Observable<AdministrationFilter> {
    return this.http.post<AdministrationFilter>(this.globalSettings.apiCommunicationURL +
                                                '/admin/filters',
                                                JSON.stringify(foder),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateFilter(id: number, filter: any): Observable<AdministrationFilter> {
    return this.http.put<AdministrationFilter>(this.globalSettings.apiCommunicationURL +
                                              '/admin/filters/' + id,
                                              JSON.stringify(filter),
                                              this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFilterEnumStatus(link: string): Promise<any> {
    return this.http.get(this.globalSettings.apiCommunicationURL +
                        '/master_data/' + link)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  getFieldTypes(): Promise<FieldTypes> {
    return this.http.get<FieldTypes>(this.globalSettings.apiCommunicationURL +
                                     '/master_data/field_types')
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  getFilterFileds(): Observable<FilterFileds> {
    return this.http.get<FilterFileds>(this.globalSettings.apiCommunicationURL + '/master_data/fields')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFilterOperations(): Promise<FilterOperations> {
    return from(this.http.get<FilterOperations>(this.globalSettings.apiCommunicationURL + '/master_data/operations'))
    .pipe(
      filter(element => element.id !== 1),
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  // Templates
  getTemplates(): Observable<AdministrationTemplate[]> {
    return this.http.get<AdministrationTemplate[]>(this.globalSettings.apiAdministrationURL + '/admin/templates?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTemplate(id: number): Observable<AdministrationTemplate> {
    return this.http.get<AdministrationTemplate>(this.globalSettings.apiAdministrationURL +
                                           '/admin/templates/' +
                                           id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addTemplate(foder: any): Observable<AdministrationTemplate> {
    return this.http.post<AdministrationTemplate>(this.globalSettings.apiCommunicationURL +
                                                  '/admin/templates',
                                                  JSON.stringify(foder),
                                                  this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateTemplate(id: number, filter: any): Observable<AdministrationTemplate> {
    return this.http.put<AdministrationTemplate>(this.globalSettings.apiCommunicationURL +
                                       '/admin/templates/' + id,
                                       JSON.stringify(filter),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Folders
  getFoldersList(): Observable<Folder[]> {
    return this.http.get<Folder[]>(this.globalSettings.apiCommunicationURL + '/admin/folders?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addFolder(foder: any): Observable<Folder> {
    return this.http.post<Folder>(this.globalSettings.apiCommunicationURL +
                                  '/admin/folders',
                                  JSON.stringify(foder),
                                  this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getUrgencies(channel: number): Promise<Urgency[]> {
    if (channel === null) {
      return this.http.get<Urgency[]>(this.globalSettings.apiCommunicationURL +
                                      '/master_data/urgencies',
                                      this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        ).toPromise();
    } else {
      return this.http.get<Urgency[]>(this.globalSettings.apiCommunicationURL +
                                      '/master_data/urgencies_by_channel/' +
                                      channel,
                                      this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        ).toPromise();
    }
  }

  getFolders(): Observable<Folder[]> {
    return this.http.get<Folder[]>(this.globalSettings.apiCommunicationURL +
                                  '/admin/folders?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFolder(id: number): Observable<Folder> {
    return this.http.get<Folder>(this.globalSettings.apiCommunicationURL + '/admin/folders/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateFolder(id: number, foder: any): Observable<Folder> {
    return this.http.put<Folder>(this.globalSettings.apiCommunicationURL +
                                       '/admin/folders/' + id,
                                       JSON.stringify(foder),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Contacts
  getContacts(): Observable<AdministrationContact[]> {
    return this.http.get<AdministrationContact[]>(this.globalSettings.apiAdministrationURL + '/admin/contacts?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getContact(id: number): Observable<AdministrationContact> {
    return this.http.get<AdministrationContact>(this.globalSettings.apiAdministrationURL +
                                           '/admin/contacts/' +
                                           id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addContact(contact: any): Observable<AdministrationContact> {
    return this.http.post<AdministrationContact>(this.globalSettings.apiCommunicationURL +
                                                 '/admin/contacts',
                                                 JSON.stringify(contact),
                                                 this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateContact(id: number, contact: any): Observable<AdministrationContact> {
    return this.http.put<AdministrationContact>(this.globalSettings.apiCommunicationURL +
                                                '/admin/contacts/' + id,
                                                JSON.stringify(contact),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Archive
  getArchives(): Observable<AdministrationArchive[]> {
    return this.http.get<AdministrationArchive[]>(this.globalSettings.apiAdministrationURL +
                                                  '/admin/archive?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getArchive(id: number): Observable<AdministrationArchive> {
    return this.http.get<AdministrationArchive>(this.globalSettings.apiAdministrationURL +
                                                '/admin/archive/' +
                                                id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addArchive(archive: any): Observable<AdministrationArchive> {
    return this.http.post<AdministrationArchive>(this.globalSettings.apiCommunicationURL +
                                                 '/admin/archive',
                                                 JSON.stringify(archive),
                                                 this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateArchive(id: number, archive: any): Observable<AdministrationArchive> {
    return this.http.put<AdministrationArchive>(this.globalSettings.apiCommunicationURL +
                                                '/admin/archive/' + id,
                                                JSON.stringify(archive),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Addresses
  getAddresses(): Observable<AdministrationAddress[]> {
    return this.http.get<AdministrationAddress[]>(this.globalSettings.apiAdministrationURL +
                                                  '/admin/addresses?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAddress(id: number): Observable<AdministrationAddress> {
    return this.http.get<AdministrationAddress>(this.globalSettings.apiAdministrationURL +
                                                '/admin/addresses/' +
                                                id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addAddress(address: any): Observable<AdministrationAddress> {
    return this.http.post<AdministrationAddress>(this.globalSettings.apiCommunicationURL +
                                                '/admin/addresses',
                                                JSON.stringify(address),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateAddress(id: number, address: any): Observable<AdministrationAddress> {
    return this.http.put<AdministrationAddress>(this.globalSettings.apiCommunicationURL +
                                                '/admin/addresses/' + id,
                                                JSON.stringify(address),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Senders
  getSenders(): Observable<AdministrationSender[]> {
    return this.http.get<AdministrationSender[]>(this.globalSettings.apiAdministrationURL + '/admin/senders?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSender(id: number): Observable<AdministrationSender> {
    return this.http.get<AdministrationSender>(this.globalSettings.apiAdministrationURL +
                                           '/admin/senders/' +
                                           id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addSender(sender: any): Observable<AdministrationSender> {
    return this.http.post<AdministrationSender>(this.globalSettings.apiCommunicationURL +
                                                '/admin/senders',
                                                JSON.stringify(sender),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateSender(id: number, sender: any, confirmed = false): Observable<AdministrationSender> {
    let uri = '/admin/senders/' + id + (confirmed ? '?confirmed=true' : '');
    return this.http.put<AdministrationSender>(this.globalSettings.apiCommunicationURL + uri,
                                               JSON.stringify(sender),
                                               this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Groups
  getGroups(): Observable<AdministrationGroup[]> {
    return this.http.get<AdministrationGroup[]>(this.globalSettings.apiAdministrationURL + '/admin/groups?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGroup(id: number): Observable<AdministrationGroup> {
    return this.http.get<AdministrationGroup>(this.globalSettings.apiAdministrationURL +
                                              '/admin/groups/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addGroup(group: any): Observable<AdministrationGroup> {
    return this.http.post<AdministrationGroup>(this.globalSettings.apiCommunicationURL +
                                              '/admin/groups', JSON.stringify(group), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateGroup(id: string, group: any): Observable<AdministrationGroup> {
    return this.http.put<AdministrationGroup>(this.globalSettings.apiCommunicationURL +
                                              '/admin/groups/' + id,
                                              JSON.stringify(group),
                                              this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Users
  getUsers(): Observable<AdministrationUser[]> {
    return this.http.get<AdministrationUser[]>(this.globalSettings.apiAdministrationURL + '/admin/users?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getUser(id: number): Observable<AdministrationUser> {
    return this.http.get<AdministrationUser>(this.globalSettings.apiAdministrationURL +
                                           '/admin/users/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addUser(user: any): Observable<AdministrationUser> {
    return this.http.post<AdministrationUser>(this.globalSettings.apiCommunicationURL +
                                            '/admin/users', JSON.stringify(user), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateUser(id: string, user: any): Observable<AdministrationUser> {
    return this.http.put<AdministrationUser>(this.globalSettings.apiCommunicationURL +
                                       '/admin/users/' + id,
                                       JSON.stringify(user),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Channels
  getChannels(): Observable<Channels> {
    return this.http.get<Channels>(this.globalSettings.apiCommunicationURL + '/master_data/channels')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Airports
  getAirports(): Observable<Airport[]> {
    return this.http.get<Airport[]>(this.globalSettings.apiCommunicationURL + '/master_data/airports')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Message Types
  getMessageTypes(): Observable<MessageTypes[]> {
    return this.http.get<MessageTypes[]>(this.globalSettings.apiCommunicationURL + '/master_data/message_types')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Playlists
  getPlaylists(): Observable<Playlist[]> {
    return this.http.get<Playlist[]>(this.globalSettings.apiInformationalURL + '/playlists')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPlaylist(id: number): Observable<Playlist> {
    return this.http.get<Playlist>(this.globalSettings.apiInformationalURL + '/playlists/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addPlaylist(playlist: any): Observable<Playlist> {
    return this.http.post<Playlist>(this.globalSettings.apiInformationalURL + '/playlists', JSON.stringify(playlist), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updatePlaylist(id: number, playlist: any): Observable<Playlist> {
    console.log(this.globalSettings.apiInformationalURL + '/playlists/' + id);
    return this.http.put<Playlist>(this.globalSettings.apiInformationalURL +
                                       '/playlists/' + id,
                                       JSON.stringify(playlist),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Medias
  getMedias(): Observable<Media[]> {
    return this.http.get<Media[]>(this.globalSettings.apiInformationalURL + '/medias')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMedia(id: number): Observable<Media> {
    return this.http.get<Media>(this.globalSettings.apiInformationalURL + '/medias/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addMedia(media: any, file: File): Observable<any> {
    var formData = new FormData();
    formData.append('media', file, file.name);
    formData.append('name', media.name);
    return this.http.post<any>(this.globalSettings.apiInformationalURL + '/medias', formData)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Specifications
  getSpecifications(): Observable<Specification[]> {
    return this.http.get<Specification[]>(this.globalSettings.apiInformationalURL + '/specifications')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSpecification(id: number): Observable<Specification> {
    console.log(id)
    console.log(this.globalSettings.apiInformationalURL + '/specifications/' + id)
    return this.http.get<Specification>(this.globalSettings.apiInformationalURL + '/specifications/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addSpecification(specification: any): Observable<Specification> {
    return this.http.post<Specification>(this.globalSettings.apiInformationalURL +
                                         '/specifications',
                                         JSON.stringify(specification),
                                         this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateSpecification(id: number, specification: any): Observable<Specification> {
    return this.http.put<Specification>(this.globalSettings.apiInformationalURL +
                                       '/specifications/' + id,
                                       JSON.stringify(specification),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Clients
  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(this.globalSettings.apiInformationalURL + '/clients')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getClient(id: string): Observable<Client> {
    return this.http.get<Client>(this.globalSettings.apiInformationalURL + '/clients/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateClient(id: string, client: any): Observable<Client> {
    return this.http.put<Client>(this.globalSettings.apiInformationalURL +
                                       '/clients/' + id,
                                       JSON.stringify(client),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Information Airlines
  getInformationAirlines(): Observable<InformationAirline[]> {
    return this.http.get<InformationAirline[]>(this.globalSettings.apiInformationalURL + '/airlines')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getInformationAirline(id: number): Observable<InformationAirline> {
    return this.http.get<InformationAirline>(this.globalSettings.apiInformationalURL + '/airlines/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateInformationAirline(id: number, airline: any, files: Array<File>): Observable<any> {
    var formData = new FormData();
    if (files['logo']) {
      formData.append('logo', files['logo'], files['logo'].name);
    }
    if (files['logo_brand']) {
      formData.append('logo_brand', files['logo_brand'], files['logo_brand'].name);
    }
    if (files['logo_brand_business']) {
      formData.append('logo_brand_business', files['logo_brand_business'], files['logo_brand_business'].name);
    }
    formData.append('data', JSON.stringify(airline));
    return this.http.put<any>(this.globalSettings.apiInformationalURL + '/airlines/' + id, formData)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
    /*return this.http.put<InformationAirline>(this.globalSettings.apiInformationalURL +
                                       '/airlines/' + id,
                                       JSON.stringify(airline),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );*/
  }

  // Information Airports
  getInformationAirports(): Observable<InformationAirport[]> {
    return this.http.get<InformationAirport[]>(this.globalSettings.apiInformationalURL + '/airports')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getInformationAirport(id: number): Observable<InformationAirport> {
    return this.http.get<InformationAirport>(this.globalSettings.apiInformationalURL + '/airports/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateInformationAirport(id: number, airport: any): Observable<InformationAirport> {
    return this.http.put<InformationAirport>(this.globalSettings.apiInformationalURL +
                                       '/airports/' + id,
                                       JSON.stringify(airport),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Information Statuses
  getInformationStatuses(): Observable<InformationStatus[]> {
    return this.http.get<InformationStatus[]>(this.globalSettings.apiInformationalURL + '/statuses')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getInformationStatus(id: number): Observable<InformationStatus> {
    return this.http.get<InformationStatus>(this.globalSettings.apiInformationalURL + '/statuses/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateInformationStatus(id: number, status: any): Observable<InformationStatus> {
    return this.http.put<InformationStatus>(this.globalSettings.apiInformationalURL +
                                       '/statuses/' + id,
                                       JSON.stringify(status),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // languages
  getLanguages(): Observable<any[]> {
    return this.http.get<any[]>(this.globalSettings.apiInformationalURL + '/languages')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Aircraft Types
  getAircraftTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.globalSettings.apiInformationalURL + '/aircraft_types')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Geo Types
  getGeoTypes(): Observable<GeoType[]> {
    return this.http.get<GeoType[]>(this.globalSettings.apiInformationalURL + '/geo_types')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Templates
  getInformationTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(this.globalSettings.apiInformationalURL + '/templates')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Client Types
  getClientTypes(): Observable<ClientType[]> {
    return this.http.get<ClientType[]>(this.globalSettings.apiInformationalURL + '/client_types')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Get Installation Place
  getInstallationPlaces(lang: string): Observable<InstallationWorkplace[]> {
    return this.http.get<InstallationWorkplace[]>(this.globalSettings.apiBoardingControlURL + '?getinstallationplaces=' + lang)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTerminals(): Observable<DictTerminal[]> {
    return this.http.get<DictTerminal[]>(this.globalSettings.apiBoardingControlURL + '?getterminal')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getWorkplaces(): Observable<DictWorkplace[]> {
    return this.http.get<DictWorkplace[]>(this.globalSettings.apiBoardingControlURL + '?getworkplace')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSolutions(): Observable<DictReasonSolution[]> {
    return this.http.get<DictReasonSolution[]>(this.globalSettings.apiBoardingControlURL + '?getsolution')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



  getReasonWanteds(): Observable<DictReasonWanted[]> {
    return this.http.get<DictReasonWanted[]>(this.globalSettings.apiBoardingControlURL + '?getreasonwanted')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGeos(): Observable<DictGeo[]> {
    return this.http.get<DictGeo[]>(this.globalSettings.apiBoardingControlURL + '?getgeos')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getStatusPassengers(): Observable<DictStatusPassenger[]> {
    return this.http.get<DictStatusPassenger[]>(this.globalSettings.apiBoardingControlURL + '?getstatuspassengers')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deletePlace(id: number): Observable<InstallationWorkplace> {
    return this.http.post<InstallationWorkplace>(this.globalSettings.apiBoardingControlURL + '?deleteplace=' + id,
      this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPlace(id: number): Observable<InstallationWorkplace> {
    return this.http.get<InstallationWorkplace>(this.globalSettings.apiBoardingControlURL + '?editplace=' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setPlace(place: any): Observable<InstallationWorkplace> {
    return this.http.post<InstallationWorkplace>(this.globalSettings.apiBoardingControlURL +
                                                 '?saveplace',
                                                 JSON.stringify(place),
                                                 this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Workplace
  /*insertWorkplace(workplace: any): Observable<HttpResponse<any>> {
    console.log("insertWorkplace");
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiBoardingControlURL + '?insertworkplace', JSON.stringify(workplace), {observe: 'response'})
      .pipe(
        map(resp => {
          console.log("RESULT",JSON.stringify(resp));
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteWorkplace(id: number): Observable<DictWorkplace> {
    //console.log(this.globalSettings.apiBoardingControlURL + '?saveterminal ' + JSON.stringify(terminal));

    return this.http.post<DictWorkplace>(this.globalSettings.apiBoardingControlURL + '?deleteworkplace=' + id,
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getWorkplace(id: number): Observable<DictWorkplace> {
    return this.http.get<DictWorkplace>(this.globalSettings.apiBoardingControlURL + '?editworkplace=' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setWorkplace(workplace: any): Observable<DictWorkplace> {
    console.log(this.globalSettings.apiBoardingControlURL + '?saveworkplace ' + JSON.stringify(workplace));

    return this.http.post<DictWorkplace>(this.globalSettings.apiBoardingControlURL + '?saveworkplace', JSON.stringify(workplace),
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }*/


  // Solution
  insertSolution(solution: any): Observable<HttpResponse<any>> {
    console.log('insertSolution');
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiBoardingControlURL +
                                             '?insertsolution',
                                             JSON.stringify(solution),
                                             {observe: 'response'})
      .pipe(
        map(resp => {
          console.log('RESULT', JSON.stringify(resp));
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteSolution(id: number): Observable<DictReasonSolution> {
    return this.http.post<DictReasonSolution>(this.globalSettings.apiBoardingControlURL + '?deletesolution=' + id,
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getSolution(id: number): Observable<DictReasonSolution> {
    return this.http.get<DictReasonSolution>(this.globalSettings.apiBoardingControlURL + '?editsolution=' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setSolution(solution: any): Observable<DictReasonSolution> {
    console.log(this.globalSettings.apiBoardingControlURL + '?savesolution ' + JSON.stringify(solution));

    return this.http.post<DictReasonSolution>(this.globalSettings.apiBoardingControlURL + '?savesolution', JSON.stringify(solution),
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  // solution

  // reason wanted
  insertReasonWanted(reason: any): Observable<HttpResponse<any>> {
    console.log("insertReasonWanted"+JSON.stringify(reason));
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiBoardingControlURL +
                                             '?insertreasonwanted',
                                             JSON.stringify(reason),
                                             {observe: 'response'})
      .pipe(
        map(resp => {
          console.log('RESULT', JSON.stringify(resp));
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteReasonWanted(id: number): Observable<DictReasonWanted> {
    return this.http.post<DictReasonWanted>(this.globalSettings.apiBoardingControlURL + '?deletereasonwanted=' + id,
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getReasonWanted(id: number): Observable<DictReasonWanted> {
    return this.http.get<DictReasonWanted>(this.globalSettings.apiBoardingControlURL + '?editreasonwanted=' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setReasonWanted(reason: any): Observable<DictReasonWanted> {
    console.log(this.globalSettings.apiBoardingControlURL + '?savereasonwanted ' + JSON.stringify(reason));

    return this.http.post<DictReasonWanted>(this.globalSettings.apiBoardingControlURL + '?savereasonwanted', JSON.stringify(reason),
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  // reason wanted

  // status pass
  /*insertStatusPassenger(statuspass: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiBoardingControlURL + '?insertstatuspassenger', JSON.stringify(statuspass), {observe: 'response'})
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteStatusPassenger(id: number): Observable<DictStatusPassenger> {
    return this.http.post<DictStatusPassenger>(this.globalSettings.apiBoardingControlURL + '?deletestatuspassenger=' + id,
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStatusPassenger(id: number): Observable<DictStatusPassenger> {
    return this.http.get<DictStatusPassenger>(this.globalSettings.apiBoardingControlURL + '?editstatuspassenger=' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setStatusPassenger(reset: any): Observable<DictStatusPassenger> {
    return this.http.post<DictStatusPassenger>(this.globalSettings.apiBoardingControlURL + '?savestatuspassenger', JSON.stringify(reset),
      this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }*/
  // status passenger

  downloadFile(link: string) {
    return this.http.get(this.globalSettings.apiAdministrationURL + '/admin/' + link, {responseType: 'blob'})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // reference
  getReference(name): Promise<any[]> {
    return this.http.get<any[]>(this.globalSettings.apiWeightBalanceURL +
                                              '/master_data/' + name)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Error handling
  handleError(error) {
    console.log(error);
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorDetail.status = error.status;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}

