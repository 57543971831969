import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BagData, CommercialAct, Statement, StatementData } from '../types/statements';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '@settings/global-settings';
import { LostItems } from '../types/lost-items';
import { Chat, ChatMessage } from '../types/chats';
import { ManualStatement } from '../types/manual-statement';
import { Message, MessageForSent } from '../types/messages';

@Injectable({
  providedIn: 'root'
})

export class LostFoundModuleRestApiService {

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  // Logs
  getLogs(id, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiLostFoundURL + `/statements/${id}/updates`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  readLogs(id, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiLostFoundURL + `/statements/${id}/updates/read`, null, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Marks
  getMarks(xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiLostFoundURL + '/marks', httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // User airports
  getUserAirports(xRequestId?): Observable<any>{
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiLostFoundURL + '/users/airports', httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // User airlines
  getUserAirlines(xRequestId?): Observable<any>{
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiLostFoundURL + '/users/airlines', httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  getLostItems(getParams, filterParams, xRequestId): Promise<LostItems[]> {
    let url = `/lost-items/${getParams.perPage}/${getParams.pageActive}`;
    const params = this.createParams(filterParams);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<LostItems[]>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getCountLostItems(filterParams, xRequestId?): Promise<any> {
    let url = `/lost-items/count`;
    const params = this.createParams(filterParams);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getLostItem(id: string, xRequestId): Promise<LostItems> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<LostItems>(this.globalSettings.apiLostFoundURL +
                                        `/lost-items/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError),
      ).toPromise();
  }

  addLostItem(statement: LostItems, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post(this.globalSettings.apiLostFoundURL + `/lost-items`, statement, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateLostItem(statement: LostItems, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<any>(this.globalSettings.apiLostFoundURL +
                         `/lost-items/${statement.id}`,
                         JSON.stringify(statement),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getManualStatements(filterParams, times, xRequestId): Promise<ManualStatement[]> {
    let url = `/search`;
    const params = this.createParams(filterParams, times);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<ManualStatement[]>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  downloadManualStatements(filterParams, times) {
    let url = `/search?export=xlsx`;
    const params = this.createParams(filterParams, times);
    if (params.length > 0) {
      url += '&' + params.join('&');
    }
    return this.http.get(this.globalSettings.apiLostFoundURL + url, { responseType: 'blob' })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStatements(getParams, filterParams, times, xRequestId): Promise<Statement[]> {
    let url = `/statements/${getParams.perPage}/${getParams.pageActive}`;
    const params = this.createParams(filterParams, times);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Statement[]>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getCountStatements(filterParams, times, xRequestId?): Promise<any> {
    let url = `/statements/counters`;
    const params = this.createParams(filterParams, times);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<number>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  createParams(filterParams, times?) {
    const newParams = {...filterParams};
    const params = [];
    if (times && times.length > 0) {
      const dayMilliseconds = 24 * 60 * 60 * 1000 * Math.max(...times);
      const currentDate = new Date();
      currentDate.setTime(currentDate.getTime() - dayMilliseconds);
      newParams.finish = currentDate;
    }
    if (newParams) {
      for (const key in newParams) {
        if (Object.prototype.hasOwnProperty.call(newParams, key)) {
          if ((key === 'start' || key === 'finish') && newParams[key]) {
            params.push(key + '=' + newParams[key].toISOString());
          } else if (newParams[key] && newParams[key] !== null) {
            if (Array.isArray(newParams[key]) && newParams[key].length === 0) {
              continue;
            }
            if (newParams[key + 'Flag'] && newParams[key + 'Flag'] === '!') {
              params.push(key + '=!' + newParams[key]);
            } else {
              params.push(key + '=' + newParams[key]);
            }
          }
        }
      }
    }
    return params;
  }

  getStatement(id: string, xRequestId): Promise<StatementData> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<StatementData>(this.globalSettings.apiLostFoundURL +
                                        `/statements/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError),
      ).toPromise();
  }

  addStatement(statement: StatementData, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post(this.globalSettings.apiLostFoundURL + `/statements`, statement, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateStatement(statement, xRequestId?): Promise<StatementData> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<StatementData>(this.globalSettings.apiLostFoundURL +
                         `/statements/${statement.id}`,
                         JSON.stringify(statement),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getFromBagData(id: string, xRequestId): Promise<BagData[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<BagData[]>(this.globalSettings.apiLostFoundURL +
                                        `/baggage/${id}/bagdata`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  makeBaggageSelected(id: string, bagdataId: string, xRequestId): Promise<BagData[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch<BagData[]>(this.globalSettings.apiLostFoundURL +
                                        `/baggage/${id}/select/${bagdataId}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  makeBaggageUnselected(id: string, bagdataId: string, xRequestId): Promise<BagData[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch<BagData[]>(this.globalSettings.apiLostFoundURL +
                                        `/baggage/${id}/unselect/${bagdataId}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getChats(id: string, statementBaggageId: string, xRequestId): Promise<Chat[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Chat[]>(this.globalSettings.apiLostFoundURL +
                        `/statements/${id}/baggage/${statementBaggageId}/chat`,
                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  loadChat(id: string, statementBaggageId: string, externalBaggageId: string, xRequestId): Promise<ChatMessage[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<ChatMessage[]>(this.globalSettings.apiLostFoundURL +
                        `/statements/${id}/baggage/${statementBaggageId}/chat/${externalBaggageId}`,
                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  sendMessage(id: string, statementBaggageId: string, externalBaggageId: string, message: string, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post(this.globalSettings.apiLostFoundURL +
                      `/statements/${id}/baggage/${statementBaggageId}/chat/${externalBaggageId}/send`,
                      message,
                      httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  detDocument(id: string, type: string, xRequestId) {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get(this.globalSettings.apiLostFoundURL + `/documents/${id}?document=${type}`, { responseType: 'blob' })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCommercialAct(id: number, xRequestId): Promise<CommercialAct> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<CommercialAct>(this.globalSettings.apiLostFoundURL +
                                        `/commercial-act/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateCommercialAct(commercialAct, xRequestId?): Promise<CommercialAct> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<CommercialAct>(this.globalSettings.apiLostFoundURL +
                         `/commercial-act/${commercialAct.id}`,
                         JSON.stringify(commercialAct),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // reference
  getReference(name: string, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL +
                                '/master_data/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getIndicators(xRequestId?, filterParams?, times?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    let url = `/statements/indicators`;
    if (filterParams && times) {
      const params = this.createParams(filterParams, times);
      if (params.length > 0) {
        url += '?' + params.join('&');
      }
    }
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAdminData(name, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL +
                                '/master_data/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  setMark(id: string, mark: string, xRequestId?) {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch(this.globalSettings.apiLostFoundURL +
                         `/statements/${id}/mark/${mark}`,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  unsetMark(id: string, xRequestId?) {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch(this.globalSettings.apiLostFoundURL +
                         `/statements/${id}/unmark`,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // сообщения
  getMessagesPassenger(statementId: string, baggageId: string, xRequestId?): Promise<Message> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Message>(this.globalSettings.apiLostFoundURL + `/messages/${statementId}/${baggageId}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  sendMessagePassenger(statementId: string, baggageId: string, message: MessageForSent, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiLostFoundURL + `/messages/${statementId}/${baggageId}`, message, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // files
  getFiles(id: string, type: string, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL + `/baggage/${id}/files/${type}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  uploadFiles(id: string, type: string, data, xRequestId?): Observable<any> {
    const httpOptions =  {};
    httpOptions['reportProgress'] = true;
    httpOptions['observe'] = 'events';
    httpOptions['headers'] = {
      'X-Correlation-ID' : this.globalSettings.userSessionUUID,
      'X-Request-ID' : (xRequestId === undefined) ? this.globalSettings.randomUuid : xRequestId
    };
    return this.http.post<Observable<any>>(this.globalSettings.apiLostFoundURL + `/baggage/${id}/files/${type}`, data, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getFile(id: string, type: string, fileId: string, xRequestId?): Observable<Blob> {
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['responseType'] = "blob";
    return this.http.get<Blob>(this.globalSettings.apiLostFoundURL + `/baggage/${id}/files/${type}/${fileId}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteFile(id: string, type: string, fileId: string, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.delete<any[]>(this.globalSettings.apiLostFoundURL + `/baggage/${id}/files/${type}/${fileId}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Files fot lost items
  getFilesLostItem(id: string, type: string, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL + `/lost-items/${id}/files/${type}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  uploadFilesLostItem(id: string, type: string, data, xRequestId?): Observable<any> {
    const httpOptions =  {};
    httpOptions['reportProgress'] = true;
    httpOptions['observe'] = 'events';
    httpOptions['headers'] = {
      'X-Correlation-ID' : this.globalSettings.userSessionUUID,
      'X-Request-ID' : (xRequestId === undefined) ? this.globalSettings.randomUuid : xRequestId
    };
    return this.http.post<Observable<any>>(this.globalSettings.apiLostFoundURL + `/lost-items/${id}/files/${type}`, data, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getFileLostItem(id: string, type: string, fileId: string, xRequestId?): Observable<Blob> {
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['responseType'] = "blob";
    return this.http.get<Blob>(this.globalSettings.apiLostFoundURL + `/lost-items/${id}/files/${type}/${fileId}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteFileLostItem(id: string, type: string, fileId: string, xRequestId?): Observable<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.delete<any[]>(this.globalSettings.apiLostFoundURL + `/lost-items/${id}/files/${type}/${fileId}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStatementStatus(params: {surname: string, file: string}): Observable<any> {
    return this.http.get<any>(this.globalSettings.apiLostFoundURL + `/statement_statuses`, {params})
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      // errorDetail.status = error.status;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (errorDetail) {
      return throwError(() => errorDetail);
    } else {
      return throwError(() => errorMessage);
    }
  }
}
