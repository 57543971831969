export class Passport {
  id: number;
  passportId: string;
  validity: string;
  country: string;
  birthDay: string;
  sex: string;
}


