import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DowChangesCrew, Location } from 'src/app/weight-balance-data/weight-balance';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as validator from '@shared/functions/validateInput';
import { USER_ACTIONS } from '@shared/const';

@Component({
  selector: 'app-crew-table',
  templateUrl: './crew-table.component.html'
})
export class CrewTableComponent implements OnInit {

  @Input() crew: Array<DowChangesCrew>;
  @Input() seats: {cockpit: Array<Location> , cabin: Array<Location>};
  @Output() deleteItemEvent: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('closebutton') closebutton: any;

  USER_ACTIONS = USER_ACTIONS;

  crewItem: DowChangesCrew = new DowChangesCrew();
  userAction = USER_ACTIONS.ADD;
  id: number | null = null;
  autoUpdateCrewName = false;

  constructor() { }

  ngOnInit(): void {
  }

  deleteElement(i: number) {
    this.deleteItemEvent.emit(i);
  }

  createItemCrew() {
    this.crewItem = new DowChangesCrew({cabinLocations: this.seats.cabin, cockpitLocations: this.seats.cockpit});
    this.crewItem.name = this.crewItem.code;
    this.autoUpdateCrewName = true;
    this.userAction = USER_ACTIONS.ADD;
  }

  updateCrewName() {
    if (this.autoUpdateCrewName) {
      this.crewItem.name = this.crewItem.code;
    }
  }

  setOccupiedSeats(cockpit: Location, value: string) {
    cockpit.seats = this.toNumber(value);
  }

  addItemCrew() {
    this.crew.push(this.crewItem);
    this.closebutton.nativeElement.click();
  }

  updateItemCrew(id: number) {
    this.crewItem.name = this.crewItem.name || this.crewItem.code;
    if (this.crewItem.standard) {
      this.crew.forEach((element, i) => {
        if (i !== id) {
          element.standard = false;
        }
      });
    }
    Object.assign(this.crew[id], this.crewItem);
    this.closebutton.nativeElement.click();
  }

  editItemCrew(i: number) {
    this.crewItem = new DowChangesCrew(this.crew[i]);

    const seatsCabin = [];
    this.seats.cabin.forEach((el, index) => {
      let item = new Location(el);
      if (this.crewItem.cabinLocations && this.crewItem.cabinLocations[index]) {
        item.seats = this.crewItem.cabinLocations[index].seats;
      } else {
        item.seats = 0;
      }
      seatsCabin.push(item);
    });
    this.crewItem.cabinLocations = seatsCabin;

    const seatsCockpit = [];
    this.seats.cockpit.forEach((el, index) => {
      let item = new Location(el);
      if (this.crewItem.cockpitLocations && this.crewItem.cockpitLocations[index]) {
        item.seats = this.crewItem.cockpitLocations[index].seats;
      } else {
        item.seats = 0;
      }
      seatsCockpit.push(item);
    });
    this.crewItem.cockpitLocations = seatsCockpit;

    this.id = i;

    if (this.crewItem.name === this.crewItem.code) {
      this.autoUpdateCrewName = true;
    } else {
      this.autoUpdateCrewName = false;
    }
    
    this.userAction = USER_ACTIONS.EDIT;
  }

  drop(event: CdkDragDrop<string[]>, array) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);
  }

  inputNotLetter(event, type = '') {
    return validator.inputNotLetter(event, type);
  }

  toNumber(value) {
    return validator.toNumber(value);
  }

  clearTags(value: string) {
    return validator.clearTags(value);
  }

}
