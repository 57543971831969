export class ReferanceMovement {
    id: number;
    code: string;
    private name: Array<string>;
    regularityId: number;
    regularityName: string;
    private dtRange: Array<Date>;
    lastupdate: string;

    get nameAndCode(): string {
      if (this.name && this.name.length > 0 && this.code) {
        return this.name[0] + ' (' + this.code + ')';
      } else {
        return null;
      }
    }

  }
  

  export class ReferanceAirline {

    id: number;
    iata: string;
    icao: string;
    code: Array<string>;
    name: Array<string>;
    private weightAdult: Array<number>;
    private weightChild: Array<number>;
    private weightInfant: Array<number>;
    international: boolean;
    countryId: number;
    countryIata: string;
    countryIcao: string;
    countryName: Array<string>;
    private dtRange: Array<Date>;
    lastupdate: string;

    /**
     * Функция вывода наименований авиакомпании в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.name.join('/');
      } else {
        return null;
      }
    }

  }
  

  export class ReferanceAirport {

    id: number;
    iata: string;
    icao: string;
    code: Array<string>;
    private name: Array<string>;
    private tzOffset: Array<number>;
    private dtRange: Array<Date>;
    cityId: number;
    cityIata: string;
    cityIcao: string;
    city: Array<string>;
    regionId: number;
    regionIata: string;
    regionIcao: string;
    region: Array<string>;
    countryId: number;
    countryIata: string;
    countryIcao: string;
    country: Array<string>;
    localRegionId: number;
    localRegionName: Array<string>;
    lastupdate: string;

    /**
     * Функция вывода наименований аэропорта в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.iata + ' ' + this.name.join(',  ');
      } else {
        return null;
      }
    }
  }
  
  export class ReferanceAircraft {

    id: number;
    iata: string;
    icao: string;
    private dtRange: Array<Date>;
    private name: Array<string>;
    mtow: number;
    lastupdate: string;

    /**
     * Функция вывода наименований воздушного судна в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.name.join('/');
      } else {
        return null;
      }
    }
  }
  
  
  export class ReferanceGeoTypes {

    id: number;
    name: Array<string>;
    dtRange: Array<Date>;
    lastupdate: string;

    /**
     * Функция вывода наименований воздушного судна в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.name.join('/');
      } else {
        return null;
      }
    }

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }
  
    get nameLocal(): string {
      if ((this.name) && (this.name.length === 2)) {
        return this.name[1];
      } else {
        return null;
      }
    }
  }

  export class ReferanceSeasons {

    id: number;
    name: Array<string>;
    dtRange: Array<Date>;
    range: Array<Date>;
    type: number;
    lastupdate: string;

    /**
     * Функция вывода наименований воздушного судна в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.name.join('/');
      } else {
        return null;
      }
    }

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }
  
    get nameLocal(): string {
      if ((this.name) && (this.name.length === 2)) {
        return this.name[1];
      } else {
        return null;
      }
    }
  }

  export class ReferanceScheduleTypes {
    id: number;
    name: Array<string>;
    dtRange: Array<Date>;
    lastupdate: string;

    /**
     * Функция вывода наименований воздушного судна в строку
     */
    get names(): string {
      if ((this.name) && (this.name.length > 0)) {
        return this.name.join('/');
      } else {
        return null;
      }
    }

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }
  
    get nameLocal(): string {
      if ((this.name) && (this.name.length === 2)) {
        return this.name[1];
      } else {
        return null;
      }
    }

  }
  