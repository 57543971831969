<!-- TODO нет функции на бэки для задания рабочего стола для нераспредленных рейсов -->
<div class="input-group mb-3 col-4 p-0">
  <div class="input-group-prepend">
    <span class="input-group-text" i18n="adm|Weight and balance@@adm.wb.filters.defaultDesktop">Default desktop for non-distributed flights</span>
  </div>
  <ng-select class="form-control custom-sm p-0 h-auto"
             [items]="workspaces"
             bindLabel="name"
             bindValue="id"
             appendTo="body"
             name="workspaceId"
             [virtualScroll]="true">
  </ng-select>
</div>

<div class="card bg-none">
  <div class="card-body p-3 bg-white rounded">
    <p class="font-weight-bold text-blue h5">
      <span i18n="adm|Weight and balance@@adm.wb.filters.flightDistribution">Flight distribution</span>
    </p>
    <div class="d-flex flex-row mt-3">
      <button type="button"
              class="btn btn-sm btn-light-gray mb-2"
              data-toggle="modal"
              data-target=".modal-filter"
              (click)="createFilter()">
        <i class="material-icons">add</i>
        <span i18n="adm|Weight and balance@@adm.wb.filters.button.new">New</span>
      </button>
      <div class="input-group mb-3 col-4 p-0 ml-auto">
        <div class="input-group-prepend">
          <span class="input-group-text" i18n="adm|Weight and balance@@adm.wb.filters.search.header">Search</span>
        </div>
        <ng-select class="form-control custom-sm p-0 h-auto"
                   [items]="workspaces"
                   bindLabel="name"
                   bindValue="id"
                   appendTo="body"
                   name="workspaceId"
                   [(ngModel)]="searchDesktopId"
                   [virtualScroll]="true">
        </ng-select>
      </div>
    </div>
    

    <table class="table table-hover w-100">
      <thead class="">
        <tr>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.id">
            ID
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.airline">
            Airline
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.flight">
            Flight
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.from">
            From
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.to">
            To
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.aircraftType">
            Aircraft type
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.aircraftRegistration">
            Aircraft registration
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.desktop">
            Desktop
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance@@adm.wb.filters.table.column.filterRating">
            Filter rating
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let filter of filters"
            data-toggle="modal"
            data-target=".modal-filter"
            (click)="loadFilter(filter.id)">
          <td class="text-center">{{ filter.id }}</td>
          <td>{{ getById(airlines, filter.filter.airlineID) != null ? getById(airlines, filter.filter.airlineID).iata : '' }}</td>
          <td>{{ filter.filter.flight }}</td>
          <td>{{ getById(airports, filter.filter.departureAirportID) != null ? getById(airports, filter.filter.departureAirportID).shortCode : '' }}</td>
          <td>{{ getById(airports, filter.filter.arrivalAirportID) != null ? getById(airports, filter.filter.arrivalAirportID).shortCode : '' }}</td>
          <td>{{ getById(aircraftTypes, filter.filter.aircraftTypeID) != null ? getById(aircraftTypes, filter.filter.aircraftTypeID).iata : '' }}</td>
          <td>{{ filter.filter.tail }}</td>
          <td>{{ getById(workspaces, filter.workspaceID) != null ? getById(workspaces, filter.workspaceID).name : '' }}</td>
          <td>{{ filter.rating }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Modal -->
<div class="modal fade bd-example-modal-xl modal-filter"
     data-backdrop="static"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myExtraLargeModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content bg-light-gray">
        <form #modalFilterForm="ngForm">
          <div class="modal-header">
            <h5 class="modal-title">
              <span *ngIf="view.userAction === 'add-filter'"
                i18n="adm|Weight and balance@@adm.wb.filters.modal.header.addFilter">Add
                filter</span>
              <span *ngIf="view.userAction === 'edit-filter'"
                i18n="adm|Weight and balance@@adm.wb.filters.modal.header.editFilter">Edit
                filter</span>
            </h5>
            <button #closeFilter
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="tab-content pt-3">
              <div class="row mb-2">
                <div class="col-4">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">apartment</i>
                        <span i18n="adm|Weight and balance@@adm.wb.filters.modal.groupAirline.header">Airline</span>
                      </p>
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="adm|Weight and balance@@adm.wb.filters.modal.groupAirline.airlineIataIcaoLocalCode">
                              IATA / ICAO / Local code
                            </span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                          [items]="airlines"
                                      bindLabel="iata"
                                      bindValue="id"
                                      name="airlineIata"
                                      [(ngModel)]="filter.filter.airlineID"
                                      [virtualScroll]="true"
                                      [selectOnTab]="true">
                            <ng-template ng-option-tmp
                                          let-item="item">
                              IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim() || '-'}}</span>,&nbsp;
                              ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim() || '-'}}</span>,&nbsp;
                              CODE:&nbsp;<span class="font-weight-bold">{{item.code[1] || '-'}}</span><br />
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-label-tmp
                                          let-item="item">
                              IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim() || '-'}}</span>,&nbsp;
                              ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim() || '-'}}</span>,&nbsp;
                              CODE:&nbsp;<span class="font-weight-bold">{{item.code && item.code[1] ? item.code[1] : '-'}}</span>,&nbsp;
                              {{item.name}}
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pl-0 col-4">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">flight_takeoff</i>
                        <span i18n="adm|Weight and balance@@adm.wb.filters.modal.groupFlight.header">Flight</span>
                      </p>
                      <div class="row">
                        <div class="col-4 pr-0">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="adm|Weight and balance@@adm.wb.filters.modal.groupFlight.number">Number</span>
                          </label>
                          <input 
                                  type="text"
                                  class="form-control form-control-sm"
                                  name="flightinput"
                                  [(ngModel)]="filter.filter.flight">
                        </div>
                        <div class="col-4">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="adm|Weight and balance@@adm.wb.filters.modal.groupFlight.from">From</span>
                          </label>
                          <ng-select class="form-control airports-long custom-sm p-0 ng-select"
                                     [items]="airports"
                                     bindLabel="iata"
                                     bindValue="id"
                                     [(ngModel)]="filter.filter.departureAirportID"
                                     name="airportFrom"
                                     [virtualScroll]="true"
                                     appendTo="body"
                                     [selectOnTab]="true">
                            <ng-template ng-option-tmp
                                         let-item="item">
                              IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim() || '-'}}</span>,&nbsp;
                              ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim() || '-'}}</span>,&nbsp;
                              CODE:&nbsp;<span class="font-weight-bold">{{item.code ? item.code[1] : '-'}}</span><br/>
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-label-tmp
                                         let-item="item">
                              <span *ngIf="item.iata?.trim(); else: airportIcao">
                                {{item.iata?.trim()}}
                              </span>
                              <ng-template #airportIcao>
                                <span *ngIf="item.icao?.trim(); else: airportCode">
                                  {{item.icao?.trim()}}
                                </span>
                                <ng-template #airportCode>
                                  {{item.code ? item.code[1] : '-'}}
                                </ng-template>
                              </ng-template>
                            </ng-template>
                          </ng-select>
                        </div>
                        <div class="col-4">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="adm|Weight and balance@@adm.wb.filters.modal.groupFlight.to">To</span>
                          </label>
                          <ng-select class="form-control airports-long custom-sm p-0 ng-select"
                                     [items]="airports"
                                     bindLabel="iata"
                                     bindValue="id"
                                     [(ngModel)]="filter.filter.arrivalAirportID"
                                     name="airportTo"
                                     [virtualScroll]="true"
                                     appendTo="body"
                                     [selectOnTab]="true">
                            <ng-template ng-option-tmp
                                          let-item="item">
                              IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim() || '-'}}</span>,&nbsp;
                              ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim() || '-'}}</span>,&nbsp;
                              CODE:&nbsp;<span class="font-weight-bold">{{item.code ? item.code[1] : '-'}}</span><br/>
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-label-tmp
                                         let-item="item">
                              <span *ngIf="item.iata?.trim(); else: airportIcao">
                                {{item.iata?.trim()}}
                              </span>
                              <ng-template #airportIcao>
                                <span *ngIf="item.icao?.trim(); else: airportCode">
                                  {{item.icao?.trim()}}
                                </span>
                                <ng-template #airportCode>
                                  {{item.code ? item.code[1] : '-'}}
                                </ng-template>
                              </ng-template>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 pl-0">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">flight</i>
                        <span i18n="adm|Weight and balance@@adm.wb.filters.modal.groupAircraft.header">Aircraft</span>
                      </p>
                      <div class="row">
                        <div class="col-6 pr-1">
                          <label for="departureType"
                                class="col-form-label col-form-label-sm"
                                i18n="adm|Weight and balance@@adm.wb.filters.modal.groupAircraft.tail">
                            Registration
                          </label>
                          <ng-select [items]="tails"
                                     class="form-control custom-sm p-0"
                                     bindLabel="tail"
                                     bindValue="tail"
                                     name="aircraftTypeIdD"
                                     id="aircraftTypeIdD"
                                     [(ngModel)]="filter.filter.tail"
                                     [selectOnTab]="true"
                                     [virtualScroll]="true">
                          </ng-select>
                        </div>
                        <div class="col-6 pl-0 pr-1">
                          <label for="departureTail"
                                class="col-form-label col-form-label-sm"
                                i18n="adm|Weight and balance@@adm.wb.filters.modal.groupAircraft.type">
                            Type
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                                     [items]="aircraftTypes"
                                     bindLabel="iata"
                                     bindValue="id"
                                     name="tail"
                                     id="tail"
                                     [(ngModel)]="filter.filter.aircraftTypeID"
                                     [selectOnTab]="true"
                                     [virtualScroll]="true">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3 mx-3 mt-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                          id="distribute-desktop"
                          i18n="adm|Weight and balance@@adm.wb.filters.modal.distributeToDesktop">Distribute to desktop</span>
                  </div>
                  <ng-select class="form-control custom-sm p-0 h-auto"
                             [items]="workspaces"
                             bindLabel="name"
                             bindValue="id"
                             [(ngModel)]="filter.workspaceID"
                             #workspaceId="ngModel"
                             appendTo="body"
                             name="workspaceId"
                             [virtualScroll]="true">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <p *ngIf="duplicateFilterError" class="mr-auto text-red">
              <span class="font-weight-bold"
                    i18n="adm|Weight and balance@@adm.wb.filters.modal.error.header">ERROR</span>&nbsp;<span
                    i18n="adm|Weight and balance@@adm.wb.filters.modal.error.filterExists">filter already
                exists:</span>&nbsp;{{duplicateFilterError}}
            </p>
            <button *ngIf="view.userAction === 'edit-filter'"
                    type="button"
                    class="btn btn-sm btn-red"
                    (click)="deleteFilter(filter.id)">
              <span i18n="adm|Weight and balance@@adm.wb.filters.modal.button.delete">Delete</span>
            </button>
            <button type="button"
                    *ngIf="view.userAction =='add-filter'"
                    class="btn btn-sm btn-blue"
                    (click)="addFilter()"
                    i18n="adm|Weight and balance@@adm.wb.filters.modal.button.append">
              Append
            </button>
            <button type="button"
                    *ngIf="view.userAction=='edit-filter'"
                    class="btn btn-sm btn-blue"
                    (click)="saveFilter(filter.id)"
                    i18n="adm|Weight and balance@@adm.wb.filters.modal.button.save">
              Save
            </button>
            <button type="button"
                    class="btn btn-sm btn-gray-blue-dark"
                    data-dismiss="modal"
                    i18n="adm|Weight and balance@@adm.wb.filters.modal.button.close">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="message.errorMessage"
       class="ms-modal-message">
    <div class="modal-content w-50">
      <div class="modal-header"
           [ngClass]="{'bg-info': message.errorType == 'info',
                        'bg-warning': message.errorType == 'warning',
                        'bg-danger': message.errorType == 'error'}">
        <h5 *ngIf="message.errorType == 'info'"
            class="modal-title"
            i18n="adm|Weight and balance@@adm.wb.filters.modalMessage.errMes.header.inf">
          Information
        </h5>
        <h5 *ngIf="message.errorType == 'warning'"
            class="modal-title"
            i18n="adm|Weight and balance@@adm.wb.filters.modalMessage.errMes.header.warn">
          Warning
        </h5>
        <h5 *ngIf="message.errorType == 'error'"
            class="modal-title"
            i18n="adm|Weight and balance@@adm.wb.filters.modalMessage.errMes.header.err">
          Error
        </h5>
        <button type="button"
                class="close"
                (click)="clearErrorMess()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="white-space: pre-line">
        {{message.errorMessage}}
      </div>
      <div class="modal-footer">
        <button type="button"
                *ngIf="message.modalType === 'setAnswer'"
                class="btn btn-sm btn-blue"
                (click)="userAnswer = true"
                i18n="adm|Weight and balance@@adm.wb.filters.modalMessage.errMes.button.yes">
          Yes
        </button>
        <button type="button"
                *ngIf="message.modalType === 'setAnswer'"
                class="btn btn-sm btn-gray-blue-dark"
                (click)="userAnswer = false"
                i18n="adm|Weight and balance@@adm.wb.filters.modalMessage.errMes.button.no">
          No
        </button>
        <button type="button"
                *ngIf="message.modalType !== 'setAnswer'"
                class="btn btn-sm btn-gray-blue-dark"
                (click)="message.errorMessage = null"
                i18n="adm|Weight and balance@@adm.wb.filters.modalMessage.errMes.button.close">
          Close
        </button>
      </div>
    </div>
  </div>
