export class FilterLost {
  constructor() {
    this.status = '';
    this.statusFlag = '';
    this.name = [];
    this.airport = [];
    this.content = [];
    this.item = '';
    this.start = null;
    this.finish = null;
    this.archived = false;
  }
  status: string;
  statusFlag: string;
  name: Array<string>;
  airport: Array<number>;
  content: Array<number>;
  item: string;
  start: Date | null;
  finish: Date | null;
  archived: boolean;
}
