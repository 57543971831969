export class Playlist {
  constructor() {
    this.id = undefined;
    this.dtRange = [null, null];
    this.media = []
  }

  id: number;
  name: string;
  dtRange: Array<Date>;
  media: Array<number>;
  //mediaNames: Array<string>;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}

export class Media {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.duration = null;
    this.dtRange = [null, null];
  }
  id: number;
  name: string;
  duration: number;
  dtRange: Array<Date>;
  videoLink: string;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }

}

export class Client {
  constructor() {
    this.id = '';
    this.description = '';
    this.specificationId = null;
    this.templateId = null;
    this.num = null;
    this.startRecord = null;
  }

  id: string;
  description: string;
  specificationId: number;
  specificationName: string;
  templateId: number;
  templateName: string;
  num: number;
  startRecord: number;
  lastupdate: Date;
}

export class Specification {
  constructor() {
    this.id = undefined;
    this.description = '';
    this.geoTypeId = null;
    this.clientTypeId = null;
  }

  id: number;
  description: string;
  geoTypeId: number;
  geoTypeName: Array<string>;
  clientTypeId: number;
  clientTypeName: Array<string>;
  lastupdate: Date;
  name?: string;

  get geoTypeEnglish(): string {
    if ((this.geoTypeName) && (this.geoTypeName.length >= 1)) {
      return this.geoTypeName[0];
    } else {
      return null;
    }
  }

  get geoTypeLocal(): string {
    if ((this.geoTypeName) && (this.geoTypeName.length === 2)) {
      return this.geoTypeName[1];
    } else {
      return null;
    }
  }

  get clientTypeEnglish(): string {
    if ((this.clientTypeName) && (this.clientTypeName.length >= 1)) {
      return this.clientTypeName[0];
    } else {
      return null;
    }
  }

  get clientTypeLocal(): string {
    if ((this.clientTypeName) && (this.clientTypeName.length === 2)) {
      return this.clientTypeName[1];
    } else {
      return null;
    }
  }
}

export class InformationAirline {
  id: number;
  iata: string;
  icao: string;
  code: Array<string>;
  name: Array<string>;
  branding: boolean;
  logoLink: string;
  brandLink: string;
  brandBusinessLink: string;
  dtRange: Array<Date>;
  lastupdate: Date;

  /**
   * Функции чтения/записи времени актуальности записи
   */

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }

  /**
   * Функции чтения/записи наименований на английском и локальном языке
   */

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  set nameEnglish(value: string) {
    this.name[0] = value;
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  set nameLocal(value: string) {
    this.name[1] = value;
  }

  /**
   * Функции чтения/записи кодов на английском и локальном языке
   */

  get codeEnglish(): string {
    if ((this.code) && (this.code.length >= 1)) {
      return this.code[0];
    } else {
      return null;
    }
  }

  set codeEnglish(value: string) {
    this.code[0] = value;
  }

  get codeLocal(): string {
    if ((this.code) && (this.code.length === 2)) {
      return this.code[1];
    } else {
      return null;
    }
  }

  set codeLocal(value: string) {
    this.code[1] = value;
  }
}

export class InformationAirport {
  id: number;
  iata: string;
  icao: string;
  code: Array<string>;
  name: Array<string>;
  mdName: Array<string>;
  dtRange: Array<Date>;
  lastupdate: Date;

  /**
   * Функции чтения/записи времени актуальности записи
   */

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }

  /**
   * Функции чтения/записи наименований на английском и локальном языке
   */

  get nameMDEnglish(): string {
    if ((this.mdName) && (this.mdName.length >= 1)) {
      return this.mdName[0];
    } else {
      return null;
    }
  }

  set nameMDEnglish(value: string) {
    this.mdName[0] = value;
  }

  get nameMDLocal(): string {
    if ((this.mdName) && (this.mdName.length === 2)) {
      return this.mdName[1];
    } else {
      return null;
    }
  }

  set nameMDLocal(value: string) {
    this.mdName[1] = value;
  }

  /**
   * Функции чтения/записи наименований на английском и локальном языке
   */

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  set nameEnglish(value: string) {
    this.name[0] = value;
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  set nameLocal(value: string) {
    this.name[1] = value;
  }

  /**
   * Функции чтения/записи кодов на английском и локальном языке
   */

  get codeEnglish(): string {
    if ((this.code) && (this.code.length >= 1)) {
      return this.code[0];
    } else {
      return null;
    }
  }

  set codeEnglish(value: string) {
    this.code[0] = value;
  }

  get codeLocal(): string {
    if ((this.code) && (this.code.length === 2)) {
      return this.code[1];
    } else {
      return null;
    }
  }

  set codeLocal(value: string) {
    this.code[1] = value;
  }

}

export class InformationStatus {
  id: number;
  processName: Array<string>;
  name: Array<string>;
  operationalName: Array<string>;
  lastupdate: Date;

  /**
   * Функции чтения/записи наименований на английском и локальном языке
   */

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  set nameEnglish(value: string) {
    this.name[0] = value;
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  set nameLocal(value: string) {
    this.name[1] = value;
  }

  /**
   * Функции чтения/записи processName на английском и локальном языке
   */

  get processNameEnglish(): string {
    if ((this.processName) && (this.processName.length >= 1)) {
      return this.processName[0];
    } else {
      return null;
    }
  }

  set processNameEnglish(value: string) {
    this.processName[0] = value;
  }

  get processNameLocal(): string {
    if ((this.processName) && (this.processName.length === 2)) {
      return this.processName[1];
    } else {
      return null;
    }
  }

  set processNameLocal(value: string) {
    this.processName[1] = value;
  }

  /**
   * Функции чтения/записи operationalName на английском и локальном языке
   */

  get operationalNameEnglish(): string {
    if ((this.operationalName) && (this.operationalName.length >= 1)) {
      return this.operationalName[0];
    } else {
      return null;
    }
  }

  set operationalNameEnglish(value: string) {
    this.name[0] = value;
  }

  get operationalNameLocal(): string {
    if ((this.operationalName) && (this.operationalName.length === 2)) {
      return this.operationalName[1];
    } else {
      return null;
    }
  }

  set operationalNameLocal(value: string) {
    this.operationalName[1] = value;
  }
}

export class ClientType {
  id: number;
  name: Array<string>;
  dtRange: Array<Date>;
  lastupdate: Date;
  
  /**
  * Функции чтения/записи времени актуальности записи
  */
 
  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }

 /**
  * Функции чтения/записи наименований на английском и локальном языке
  */

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
 }

  set nameEnglish(value: string) {
    this.name[0] = value;
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  set nameLocal(value: string) {
    this.name[1] = value;
  }
}

export class GeoType {
  id: number;
  name: Array<string>;
  lastupdate: Date;

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
 }

  set nameEnglish(value: string) {
    this.name[0] = value;
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  set nameLocal(value: string) {
    this.name[1] = value;
  }
}

export class Template {
  id: number;
  name: Array<string>;
  lastupdate: Date;
}