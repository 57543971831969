<p class="m-0 text-center text-gray-blue-dark" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actionNote">
  Click on photo to view or edit
</p>
<div class="row mb-3">
  <div class="col-4" *ngFor="let file of files; let i = index">
    <div class="photo-item" (click)="loadPhoto(file.id, preview)">
      <img [src]="showPhoto(file)" [alt]="file.name">
      <button type="button" class="btn btn-ico btn-light-gray btn-xs" (click)="deletePhoto(file.id, $event)">
        <span class="material-icons text-red">delete</span>
      </button>
    </div>
  </div>
  <div class="col-4" *ngIf="files.length < 6">
    <label class="photo-item">
      <span class="material-icons">add</span>
      <!-- (change)="uploadPhoto($event.target.files)" -->
      <input type="file" class="form-control-file" #fileInput (change)="uploadPhoto($event.target)">
    </label>
  </div>
</div>
<div class="progress" *ngIf="loadingPhotoProcess">
  <div class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        [attr.aria-valuenow]="loadingPhotoProcess"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]
        [style]="'width: ' + loadingPhotoProcess + '%'"></div>
</div>
<ng-template #preview let-modal>
	<div class="modal-header">
    <button type="button" class="close" (click)="modal.dismiss(); imageBlobUrl = null" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
	</div>
	<div class="modal-body text-center">
    <div class="d-flex justify-content-center p-3" *ngIf="!imageBlobUrl">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <img [src]="imageBlobUrl">
	</div>
</ng-template>

<app-modal-message></app-modal-message>