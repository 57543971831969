<div class="row">
  <div class="col-8">
    <div class="card w-100">
      <div class="card-body">
        <h5 class="card-title font-weight-bold text-blue"
          i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.chat">Chat</h5>
        <div class="row">
          <div class="col-4 position-relative">
            <div class="loading" *ngIf="loading">
              <div class="cssload-spin-box"></div>
            </div>
            <p *ngIf="chats.length === 0; else: chatsList"
              class="d-flex justify-content-center align-items-center h4 h-100 align-middle border-top border-bottom"
              i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.noQueries.chat">No queries</p>
            <ng-template #chatsList>
              <ul class="list-group">
                <li *ngFor="let chat of chats"
                  class="list-group-item d-flex align-items-center justify-content-between p-0"
                  style="font-size: 0.9em;"
                  [ngClass]="{active: activeChat.statementBaggageId === chat.statementBaggageId && activeChat.externalBaggageId === chat.externalBaggageId}">
                  <div class="p-2 flex-fill pointer" (click)="loadChat(chat)">
                    {{chat.name}}
                  </div>
                  <button type="button" *ngIf="chat.baggage" class="btn btn-primary btn-xs h-100"
                    [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="click" [autoClose]="true"
                    placement="bottom">
                    <span class="material-icons">info_outline</span>
                  </button>
                  <ng-template #popTitle>
                    <span class="text-dark"
                      i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.chat">
                      Claim information
                    </span>
                  </ng-template>
                  <ng-template #popContent>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.tag">
                        Tag
                      </span>
                      {{ chat.baggage.tag }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.code">
                        Code
                      </span>
                      {{ chat.baggage.strCode }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.brand">
                        Brand
                      </span>
                      {{ chat.baggage.brand }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.weight">
                        Weight
                      </span>
                      {{ chat.baggage.weight }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.content">
                        Content
                      </span>
                      {{ chat.baggage.strInternals }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.description">
                        Description
                      </span>
                      {{ chat.baggage.description }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.flight">
                        Flight
                      </span>
                      {{ chat.baggage.strFlight }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.route">
                        Route
                      </span>
                      {{ chat.baggage.strRoute }}
                    </div>
                    <div class="font-weight-bold"
                      i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.passenger">
                      Passenger
                    </div>
                    <div>
                      {{ chat.baggage.passengerTag.passenger }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.phone">
                        Phone
                      </span>
                      {{ chat.baggage.passengerTag.phone }}
                    </div>
                    <div>
                      <span class="text-gray-blue-dark mr-2"
                        i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.claimInfo.address">
                        Address
                      </span>
                      {{ chat.baggage.passengerTag.address }}
                    </div>
                  </ng-template>
                </li>
              </ul>
            </ng-template>
          </div>
          <div class="col-8">
            <div class="chat-block d-flex align-items-start flex-column border mb-3 p-2"
              [ngClass]="{'bg-light-gray': !activeChat.externalBaggageId}" id="chat-block">
              <div *ngFor="let message of chatMessages"
                [ngClass]="{'pl-5 w-100': message.direction === 1, 'mr-5': message.direction === 0}">
                <div class="extra-small" [ngClass]="{'text-right': message.direction === 1}">{{message.dt | date: 'HH:mm
                  dd.MM.yyyy'}}</div>
                <div class="text-white rounded p-2 mb-2 chat-message"
                  [ngClass]="{'bg-primary ml-auto': message.direction === 1, 'bg-secondary': message.direction === 0}">
                  {{message.text}}
                </div>
              </div>
            </div>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Enter text"
                [disabled]="!activeChat.externalBaggageId" [(ngModel)]="message" (keyup.enter)="sendMessage()"
                i18n-placeholder="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.chat.enterText">
              <div class="input-group-append">
                <button class="btn btn-outline-primary h-100" type="button" [disabled]="!activeChat.externalBaggageId"
                  i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.chat.button.send"
                  (click)="sendMessage()">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4" [formGroup]="statementForm">
    <div class="card w-100">
      <div class="card-body">
        <h5 class="card-title font-weight-bold text-blue"
          i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.messages">Messages</h5>
        <div class="d-flex flex-column" formGroupName="messages">
          <div class="">
            <div class="form-group m-0">
              <label for="messageIncoming"
                i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.messages.incoming">Incoming</label>
              <textarea class="form-control form-control-sm" formControlName="incoming"
                rows="2">{{ this.statementForm.get('messages').get('incoming').value }}</textarea>
            </div>
          </div>
          <div class="">
            <div class="form-group m-0">
              <label for="messageOutgoing"
                i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.messages.outgoing">Outgoing</label>
              <textarea class="form-control form-control-sm" formControlName="outgoing"
                rows="2">{{ this.statementForm.get('messages').get('outgoing').value }}</textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>