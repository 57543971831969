<div class="row border-bottom py-2 justify-content-between font-small">
  <div class="col-auto">
    <button class="btn btn-sm btn-blue mr-2"
        (click)="openLostItem('/lostitem/0')"
        i18n="laf|Main - control panel@@laf.main.controlPanel.button.newClaim">New claim</button>
  </div>
  <div class="col-5 pl-0">
    <div class="d-flex w-100">
      <div class="filter-block-wrap mr-2 w-100" (click)="filter.show = !filter.show">
        <div class="input-group-prepend">
          <span class="input-group-text border-0 rounded-0 py-1"
            i18n="laf|Main - control panel@@laf.main.controlPanel.find">Find</span>
          <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.start">
              <i class="material-icons mx-1" (click)="clearFilterParametr('start', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.from">From:</span>&nbsp;
              {{ filter.params.start | date: 'dd.MM.yyyy HH:mm' }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.finish">
              <i class="material-icons mx-1" (click)="clearFilterParametr('finish', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.to">To:</span>&nbsp;
              {{ filter.params.finish | date: 'dd.MM.yyyy HH:mm' }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.status">
              <i class="material-icons ml-2" (click)="clearFilterParametr('status', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.status">Status:</span>&nbsp;
              {{ getById(baggage_statuses, filter.params.status)?.nameLocal }}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.content?.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParametr('content', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.content">Content:</span>&nbsp;
              <span *ngFor="let content of filter.params.content">
                {{ getById(references.baggage_internals?.data, content)?.name }}
              </span>
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.airport?.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParametr('airport', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.airport">Airports:</span>&nbsp;
              <span *ngFor="let airport of filter.params.airport">
                {{ getById(references.airports?.data, airport)?.iata }}
              </span>
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.name?.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParametr('name', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.claimNumber">Claim number:</span>&nbsp;
              <span *ngFor="let name of filter.params.name">{{ name }}</span>
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.item !== ''">
              <i class="material-icons ml-2" (click)="clearFilterParametr('item', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.item">Item:</span>&nbsp;
              <span>{{ filter.params.item }}</span>
            </div>
            <!-- <div class="filter-element mr-2 my-1" *ngIf="filter.params.airline?.length > 0">
              <i class="material-icons ml-2" (click)="clearFilterParametr('airline', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.airline">Airline:</span>&nbsp;
              <span *ngFor="let airline of filter.params.airline">
                {{ getById(references.airlines?.data, airline)?.iata }}
              </span>
            </div> -->
          </div>
        </div>
        <div class="d-flex align-items-center text-dark-gray">
          <i class="material-icons mx-1">arrow_drop_down</i>
        </div>
      </div>
      <!-- Filter -->
      <div class="filter-block border p-2 bg-white font-small" *ngIf="filter.show">
        <div class="container">
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-aftn-number" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.claimNumber">
                Claim number
              </label>
            </div>
            <div class="col-9 px-1" *ngIf="!filterLoadAnimation['name']; else filterStatementLoad">
              <ng-select class="custom-sm p-0" [(ngModel)]="filter.params.name" [multiple]="true" [addTag]="addTagClaim"
                [selectOnTab]="true">
                <ng-template ng-tag-tmp let-search="searchTerm">
                  <b i18n="laf|Filter@@laf.main.filter.field.baggageTagAdd">Add:</b>&nbsp;{{search}}
                </ng-template>
              </ng-select>
            </div>
            <ng-template #filterStatementLoad>
              <div class="col-9 px-1">
                <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                  <div class="spinner-border spinner-border-sm float-left" role="status" aria-hidden="true"></div>
                  <div class="float-left pl-2" i18n="laf|Filter@@laf.main.filter.field.statementNumberMessage">
                    Parsing an claim string...
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-item" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.item">
                Item
              </label>
            </div>
            <div class="col-9 px-1">
              <input id="filter-item" class="form-control form-control-sm" [(ngModel)]="filter.params.item" name="filter-item" />
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-baggage-internals" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.content">
                Сontent
              </label>
            </div>
            <div class="col-9 px-1">
              <ng-select class="w-100 custom-sm p-0"
                         [items]="references.baggage_internals?.data"
                         [multiple]="true"
                         bindLabel="name"
                         bindValue="id"
                         [virtualScroll]="true"
                         [loading]="references.baggage_internals.loading"
                         [(ngModel)]="filter.params.content"
                         (scroll)="onScrollNgSelect($event, 'baggage_internals')"
                         id="filter-baggage-internals"
                         (scrollToEnd)="onScrollToEndNgSelect('baggage_internals')"
                         [selectOnTab]="true">
                <ng-template ng-option-tmp let-item="item">
                  <div class="font-small">{{item.name}}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-airports" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.airports">
                Airports
              </label>
            </div>
            <div class="col-9 px-1">
              <ng-select class="w-100 custom-sm p-0" [items]="references.airports?.data" [multiple]="true"
                bindLabel="iata" bindValue="id" [virtualScroll]="true" [loading]="references.airports.loading"
                [(ngModel)]="filter.params.airport" (scroll)="onScrollNgSelect($event, 'airports')"
                id="filter-airports"
                (scrollToEnd)="onScrollToEndNgSelect('airports')" [searchFn]="customSelectSearch" [selectOnTab]="true">
                <ng-template ng-option-tmp let-item="item">
                  <div class="font-small">
                    <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <!-- <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-pnr" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.airline">
                Airline
              </label>
            </div>
            <div class="col-9 px-1">
              <ng-select class="w-100 custom-sm p-0" [items]="references.airlines?.data" [multiple]="true"
                bindLabel="iata" bindValue="id" [virtualScroll]="true" [(ngModel)]="filter.params.airline"
                [loading]="references.airlines.loading" (scroll)="onScrollNgSelect($event, 'airlines')"
                (scrollToEnd)="onScrollToEndNgSelect('airlines')" [searchFn]="customSelectSearch" [selectOnTab]="true">
                <ng-template ng-option-tmp let-item="item">
                  <div class="font-small">
                    <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div> -->
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-status-eq" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.status">
                Status
              </label>
            </div>
            <div class="col-3 px-1">
              <select id="filter-status-eq" class="form-control form-control-sm mr-2 py-0" name="filter-status-eq"
                [(ngModel)]="filter.params.statusFlag">
                <option value="" i18n="laf|Filter@@laf.main.filter.field.statusEqual">
                  Equal
                </option>
                <option value="!" i18n="laf|Filter@@laf.main.filter.field.statusNotEqual">
                  Not equal
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <ng-select class="custom-sm p-0" [virtualScroll]="true" [hideSelected]="true"
                [(ngModel)]="filter.params.status" bindLabel="nameLocal" bindValue="id"
                [items]="baggage_statuses">
              </ng-select>
            </div>
          </div>
          <hr />
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-date-from" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.dateFrom">
                Date from
              </label>
            </div>
            <div class="col-3 px-1">
              <input type="time" class="form-control" [ngModel]="filter.params.start | date: 'HH:mm'"
                (blur)="filter.params.start = parseDate(filterStartDate.value, $event.target.value)"
                name="filterStartTime" #filterStartTime>
            </div>
            <div class="col-6 px-1">
              <input type="date" class="form-control custom-sm"
                [ngModel]="filter.params.start | date: 'yyyy-MM-dd'"
                (blur)="filter.params.start = parseDate($event.target.value, filterStartTime.value || '00:00')"
                name="filterStartDate" #filterStartDate>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-date-from" class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.dateTo">
                Date to
              </label>
            </div>
            <div class="col-3 px-1">
              <input type="time" class="form-control" [ngModel]="filter.params.finish | date: 'HH:mm'"
                (blur)="filter.params.finish = parseDate(filterFinishDate.value, $event.target.value)"
                name="filterFinishTime" #filterFinishTime>
            </div>
            <div class="col-6 px-1">
              <input type="date" class="form-control custom-sm"
                [ngModel]="filter.params.finish | date: 'yyyy-MM-dd'"
                (blur)="filter.params.finish = parseDate($event.target.value, filterFinishTime.value || '23:59')"
                name="filterFinishDate" #filterFinishDate>
            </div>
          </div>
        </div>
        <hr />
        <button class="btn btn-gray-blue-dark btn-sm float-right ml-2" (click)="filter.show = !filter.show"
          i18n="laf|Filter@@laf.main.filter.button.close">
          Close
        </button>
        <button class="btn btn-blue btn-sm float-right ml-2" (click)="filterApp()"
          i18n="laf|Filter@@laf.main.filter.button.find">
          Find
        </button>
        <button class="btn btn-blue btn-sm float-right" (click)="clearFilter()"
          i18n="laf|Filter@@laf.main.filter.button.clearAll">
          Clear all
        </button>
      </div>
      <button type="button" class="btn btn-xs btn-ico btn-light-gray mr-2" ngbTooltip="Filter on/off" placement="left"
        i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.filterOnOff" [ngClass]="{'btn-active' : filter.apply}"
        (click)="filterSwitch()">
        <i class="material-icons">filter_alt</i>
      </button>
      <button type="button" class="btn btn-xs btn-ico btn-light-gray mr-2" ngbTooltip="View archive" placement="left"
        i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.viewArchive"
        [ngClass]="{'btn-active' : filter.params.archived}" (click)="filterArchive()">
        <i class="material-icons">library_books</i>
      </button>
    </div>
  </div>
</div>

<div class="d-flex content" style="margin-left: -15px; margin-right: -15px;">
  <div class="bg-light-gray border-right folders-block h-100 pb-5 col-auto px-0">
    <div class="d-flex align-items-center pointer p-2 header-group"
        [routerLink]="['/lostfound']" routerLinkActive="bg-gray">
      <i class="material-icons mr-2 text-gray-blue-dark">work</i>
      <span class="mr-3" i18n="laf|Left panel@@laf.main.leftPanel.baggageSearch">
          Baggage search</span>
    </div>
    <div class="d-flex align-items-center pointer p-2 header-group"
        [routerLink]="['/lostitems']" routerLinkActive="bg-gray">
      <i class="material-icons mr-2 text-gray-blue-dark">local_grocery_store</i>
      <span class="mr-3" i18n="laf|Left panel@@laf.main.leftPanel.lostItems">Lost items</span>
    </div>
  </div>
  <div class="p-0 col h-100 bg-white d-flex flex-column">
    <div class="bg-light-gray border-bottom py-1 pr-3 font-small">
      <div class="d-flex justify-content-end">
        <div class="d-flex flex-nowrap mr-2 align-items-center per-page">
          <div class="text-nowrap line-height-1" i18n="laf|Baggage@@laf.baggage.table.perPage">Per page</div>
          <select id="perPage" class="form-control form-control-sm ml-2 per-page" name="perPage"
            [ngModel]="pagination.perPage" (change)="changePerPage(+$event.target.value)">
            <option *ngFor="let count of pagination.countsPerPage" [value]="count">
              {{count}}
            </option>
          </select>
        </div>
        <pagination-controls (pageChange)="loadLostItems($event)" maxSize="7" class="d-flex" directionLinks="true"
          autoHide="true" previousLabel="" nextLabel=""
          i18n-previousLabel="laf|Baggage@@laf.main.table.pagination.previousLabel"
          i18n-nextLabel="laf|Baggage@@laf.main.table.pagination.nextLabel">
        </pagination-controls>
      </div>
    </div>
    <div class="statements-table">
      <div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>
      <table class="table-striped table-border w-100 table-sticky-header font-small">
        <thead class="bg-gray text-center">
          <tr>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.claimNumber">Claim number</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.station">Station</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.date">Date</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.status">Status</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.item">Item</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.description">Description</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.weight">Weight</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.storageLocation">Storage location</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.agent">Agent</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.issuedToTheOwner">Issued to the owner</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.deliveredTo">Delivered to</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.actNumber">Act number</th>
            <th i18n="laf|Baggage@@laf.lostItems.table.column.date">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let statementItem of statements
                                  | paginate: {
                                    itemsPerPage: pagination.perPage,
                                    currentPage: pagination.pageActive,
                                    totalItems: pagination.statementsCount
                                  }; let stat_i = index"
            [ngClass]="{'bg-gray-blue-dark text-white': currentSelectedStatement === statementItem.id}"
            (click)="selectStatement(statementItem.id)"
            (dblclick)="openLostItem('/lostitem/'+statementItem.id)">
            <td>{{statementItem.name}}</td>
            <td>{{statementItem.airport}}</td>
            <td>{{statementItem.dt | date: 'dd.MM.yyyy' : 'UTC+0'}}</td>
            <td>{{getById(baggage_statuses, statementItem.statusId)?.nameLocal}}</td>
            <td>{{statementItem.found.itemName}}</td>
            <td>{{statementItem.notes}}</td>
            <td>{{statementItem.weight}}</td>
            <td>{{getById(ADMIN_DATA.storages, statementItem.storage.id)?.name}}</td>
            <td>{{statementItem.found.agent}}</td>
            <td>{{statementItem.finish.owner}}</td>
            <td>{{getById(references.airports.data, statementItem.finish.airportId)?.iata}}</td>
            <td>{{statementItem.finish.act}}</td>
            <td>{{statementItem.finish.dtAct | date: 'dd.MM.yyyy' : 'UTC+0'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
