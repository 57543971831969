export class Maintenance {
  constructor() {
    this.serviceScheduleId = null;
    this.operations = [];
  }
  flightId: number;
  serviceScheduleId: number;
  operations: Array<Operation>;
}

export class Operation {
  constructor() {
    this.id = null;
    this.name = null;
    this.operationId = null;
    this.scheduled = [];
    this.actual = [];
    this.duration = null;
    this.resources = [];
    this.processId = null;
    this.status = null;
    this.delayTime = null;
  }
  id: number;
  name: string;
  operationId: number;
  scheduled: Array<Date>;
  actual: Array<Date>;
  duration: number;
  resources: Array<OperationResource>;
  processId: number;
  status: string;
  delayTime: number;
}

export class Resource {
  constructor() {
    this.name = null;
  }
  id: number;
  name: Array<string>;
  typeId: string;
  typeName: string;
  divisionId: string;
  divisionName: string;
  classId: string;
}

export class OperationResource {
  constructor() {
    this.resourceId = null;
    this.resourceType = null;
    this.resourceType = null;
    this.duration = null;
    this.schedule = [];
    this.actual = [];
  }
  resourceType: string;
  resourceId: number;
  resource: string;
  duration: number;
  schedule: Array<Date>;
  actual: Array<Date>;
}

export class Flight {
  constructor(/*private homeAirport: number*/) {
      this.airlineId = null;
      this.airlineIata = '';
      this.aircraftTypeId = null;
      this.aircraftTypeIata = '';
      this.tailId = null;
  }

  id: number;
  airlineId: number;
  airlineIata: string;
  aircraftTypeId: number;
  aircraftTypeIata: string;
  tailId: number;
  tail: string;
  standId: number;
  stand: string;
  route: Array<string>;
  date: Array<Date>;
  maintenance: {
    arrival: Array<FlightMaintenance>
    departure: Array<FlightMaintenance>
  };
  lastupdate: Date;
  times: Array<Date>;

  /**
   * Функция проверки даты, так как она может быть пустой или равна
   * "0001-01-01T00:00:00Z"
   * @param {Date} value Дата для проверки
   * @return {boolean} Истина если дата коректная, иначе ложь
   */
   private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  // Функция получения Планового время прибытия рейса
  get scheduleDatetime(): Date {
    if (this.isDateNull(this.times[0])) {
      return new Date(this.times[0]);
    } else {
      return null;
    }
  }

  // Функция получения Ожидаемого время прибытия рейса
  get estimatedDatetime(): Date {
    if (this.isDateNull(this.times[1])) {
      return new Date(this.times[1]);
    } else {
      return null;
    }
  }
}

export class FlightMaintenance {
id: number;
name: string;
statusId: number;
status: string;
}

export class Workflow {
  flight: Flight;
  operation: Operation;
}
