/**
 * Общий файл подключения справочников системы
 */

import { ReferanceAirport } from './referance-airport';
import { ReferanceAirline } from './referance-airline';
import { ReferanceAircraft } from './referance-aircraft';
import { ReferanceMovement } from './referance-movement';
import { ReferanceDelay } from './referance-delay';
import { ReferanceSeason } from './referance-season';
import { ReferanceRegion } from './referance-region';
import { ReferanceCountry } from './referance-country';
import { ReferanceCity } from './referance-city';
import { ReferanceAircraftKind } from './referance-aircraft-kinds';
import { ReferanceAircraftClass } from './referance-aircraft-classes';
import { ReferanceAircraftType } from './referance-aircraft-types';
import { ReferanceTail } from './referance-tails';
import { ReferanceImport } from './referance-import';
import { ReferanceDelaySource } from './referance-delay-sources';
import { ReferanceRegularitie } from './referance-regularities';
import { ReferanceStand } from './referance-stand';
import { ReferanceStandType } from './referance-stand-type';
import { ReferanceTerminal } from './referance-terminal';

export { ReferanceAirport } from './referance-airport';
export { ReferanceAirline } from './referance-airline';
export { ReferanceAircraft } from './referance-aircraft';
export { ReferanceMovement } from './referance-movement';
export { ReferanceDelay } from './referance-delay';
export { ReferanceSeason } from './referance-season';
export { ReferanceRegion } from './referance-region';
export { ReferanceCountry } from './referance-country';
export { ReferanceCity } from './referance-city';
export { ReferanceAircraftKind } from './referance-aircraft-kinds';
export { ReferanceAircraftClass } from './referance-aircraft-classes';
export { ReferanceAircraftType } from './referance-aircraft-types';
export { ReferanceTail } from './referance-tails';
export { ReferanceImport } from './referance-import';
export { ReferanceDelaySource } from './referance-delay-sources';
export { ReferanceRegularitie } from './referance-regularities';
export { ReferanceStand } from './referance-stand';
export { ReferanceStandType } from './referance-stand-type';
export { ReferanceTerminal } from './referance-terminal';

export class ReferanceOrder {
  constructor() {
    this.orderBy = '';
    this.orderField = '';

    this.orderById = '';
    this.orderByIata = '';
    this.orderByIcao = '';
    this.orderByName = '';
    this.orderByLastupdate = '';
    this.orderByDtStart = '';
    this.orderByDtFinish = '';
  }

  orderBy: string;
  orderField: string;
  
  orderById: string;
  orderByIata: string;
  orderByIcao: string;
  orderByName: string;
  orderByLastupdate: string;
  orderByDtStart: string;
  orderByDtFinish: string;
}
