<!-- <div class="row">
  <div class="col-4" *ngFor="let file of files; let i = index">
    {{ file.name }}
    <button class="btn btn-ico btn-light-gray btn-xs" (click)="loadDocument(file.id)">
      <span class="material-icons text-red">file_download</span>
    </button>
    <button class="btn btn-ico btn-light-gray btn-xs" (click)="deleteDocument(file.id)">
      <span class="material-icons text-red">delete</span>
    </button>
  </div>
</div> -->

<ul class="list-group list-group-flush mb-3" *ngIf="files.length > 0; else noItems">
  <li class="list-group-item" *ngFor="let file of files; let i = index">
    <div class="d-flex justify-content-between align-items-center">
      <span>{{ file.note }}</span>
      <div>
        <span class="material-icons mr-3 pointer" (click)="loadDocument(file.id, file.name)">file_download</span>
        <span class="material-icons text-red pointer" (click)="deleteDocument(file.id)">delete</span>
      </div>
    </div>
    <div class="font-small text-gray-blue-dark">{{ file.name }}</div>
  </li>
</ul>
<ng-template #noItems>
  <p class="m-0 text-gray-blue-dark"
    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.accordeon.attachments.noItems">
    No items
  </p>
  <hr class="mb-3" />
</ng-template>

<div *ngIf="files.length < 6">
  <p class="m-0 text-gray-blue-dark font-weight-bold mb-2"
    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.accordeon.attachments.uploadDocument">
    Upload document
  </p>
  <div class="form-group">
    <input class="w-100 form-control custom-sm ng-pristine ng-valid ng-touched"
      type="text" placeholder="Note" [(ngModel)]="fileNote"
      i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.baggage.accordeon.attachments.placeholder.note">
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <label class="form-group select-file m-0">
      <input type="file" class="form-control-file" #fileInput (change)="addDocument($event.target.files)">
      <button type="button" class="btn btn-sm btn-blue">
        <span *ngIf="!fileName" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.accordeon.attachments.button.selectFile">Select file</span>
        <span *ngIf="fileName" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.accordeon.attachments.button.selected">Selected</span>
      </button>
      <span class="font-small text-gray-blue-dark ml-2">{{ fileName }}</span>
    </label>
    <button type="button" class="btn btn-sm btn-blue float-right" (click)="uploadDocument()"
      i18n="laf|Statement tabs@@laf.statement.tabs.baggage.accordeon.attachments.button.upload">
      Upload
    </button>
  </div>
</div>

<div class="progress mt-3" *ngIf="loadingDocProcess">
  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
    [attr.aria-valuenow]="loadingDocProcess" aria-valuemin="0" aria-valuemax="100" [ngStyle]
    [style]="'width: ' + loadingDocProcess + '%'"></div>
</div>