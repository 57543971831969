import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { GlobalSettings, ModuleName, LocalTimeFormat } from '@settings/global-settings';

@Pipe({ name: 'dateFormat' })
export class DateFormat implements PipeTransform {

  // currentTimezone = '';

   constructor(private globalSettings: GlobalSettings) {
  }

  transform(value: string | Date, dateFormat: string, timeZone: LocalTimeFormat): any {
    return value ? moment(value).tz(timeZone === LocalTimeFormat.UTC ? 'GMT' : this.globalSettings.timezone).format(dateFormat) : null;
  }
}
