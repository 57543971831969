export class UserGroup {
  constructor(obj?: UserGroup) {
    if (obj) {
      Object.assign(this, obj);
    } else {
      this.id = '';
      this.name = '';
      this.offices = [];
    }
  }
  id: string;
  name: string;
  offices: Array<{
    office: string,
    type: string,
  }>;
}

export class User {
  constructor(obj?: User) {
    if (obj) {
      Object.assign(this, obj);
    } else {
      this.id = '';
      this.username = '';
      this.firstName = '';
      this.lastName = '';
      this.enabled = true;
      this.groups = [];
    }
  } 
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  groups: Array<UserGroup>;
}