import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

  constructor(private el: ElementRef) { }
  ngOnInit(): void {
    this.setMask();
  }
  
  private setMask(): void {
    const input = this.el.nativeElement;
    input.addEventListener('input', () => {
      this.format(input);
    });
  }
  /* TODO: 
  * сейчас эта директива не работает в том случае, если в поле более 11 символов
  * если к полю не добавлять maxlength="16", то визуально все отображается корректно,
  * но валидацию по регулярке не проходит */
  private format(input: HTMLInputElement): void {
    const value = input.value.replace(/\D/g, '');
    const phoneNumberLength = value.length;
    
    if (phoneNumberLength === 0) {
      input.value = '';
    } else if (phoneNumberLength < 2) {
      input.value = value;
    } else if (phoneNumberLength < 5) {
      input.value = `${value.slice(0, 1)} (${value.slice(1, 4)}`;
    } else if (phoneNumberLength < 8) {
      input.value = `${value.slice(0, 1)} (${value.slice(1, 4)}) ${value.slice(4)}`;
    } else {
      input.value = `${value.slice(0, 1)} (${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7, 11)}`;
    }
  }

}
