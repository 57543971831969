import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { NGXLogger } from 'ngx-logger';
import { ECSEvent, EventProvider } from '@settings/logger-monitor';
import { AhmData, CalculationAhm, CalculationManual, Flight, User, Workspace } from './weight-balance';
import { identifierName } from '@angular/compiler';
import { RouteNetwork, RouteNetworkDocMes, RouteNetworkItem } from '../administration-module/types/referance-route-network';

@Injectable({
  providedIn: 'root'
})

export class WeightBalanceModuleRestApiService {

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings,
              private logger: NGXLogger) {
    globalSettings.loadDefaultConfig();
  }

  setDefaultHttpHeader(requestId?): object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    const httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  // Ahms
  getAhms(xRequestId?): Promise<AhmData[]> {
    this.logger.trace('getAhms',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getAhms',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AhmData[]>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data', httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAhm(id, xRequestId?): Promise<AhmData> {
    this.logger.trace('getAhm',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getAhm',
                                  'get',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                  '/ahm_data/' + id, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getWorkspaces(): Promise<Workspace[]> {
    return this.http.get<Workspace[]>(this.globalSettings.apiWeightBalanceURL + '/workspaces')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  moveDesktop(workspaceId: number, flightId: number, xRequestId?): Promise<any> {
    this.logger.trace('desktop',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.moveDesktop',
                                  'put',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  `/workspaces/${workspaceId}/${flightId}`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<any>(this.globalSettings.apiWeightBalanceURL +
                                `/workspaces/${workspaceId}/${flightId}`,
                                null,
                                httpOptions)
   .pipe(
     retry(1),
     catchError(this.handleError)
   ).toPromise();
  }

  // Flights
  getFlights(filterParams?, workspace?, xRequestId?): Promise<Flight[]> {
    let url = '/flights';
    const params = [];
    if (filterParams) {
      for (const key in filterParams) {
        if ((key === 'start' || key === 'finish') && filterParams[key]) {
          params.push(key + '=' + filterParams[key].toISOString());
        } else if (filterParams[key] !== null && filterParams[key] !== '') {
          params.push(key + '=' + filterParams[key]);
        }
      }
    }
    if (workspace !== null) {
      params.push('workspace=' + workspace);
    }
    
    if (params.length > 0) {
      url += '?' + params.join('&');
      
    }

    this.logger.trace('getFlights',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getFlights',
                                  'get',
                                  filterParams,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL + url));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Flight[]>(this.globalSettings.apiWeightBalanceURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getFlight(id: number, xRequestId?): Promise<Flight> {
    this.logger.trace('getFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getFlight',
                                  'get',
                                  identifierName,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/flights/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Flight>(this.globalSettings.apiWeightBalanceURL +
                                '/flights/' + id, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addFlight(flight: any, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('addFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.addFlight',
                                  'post',
                                  JSON.stringify(flight),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/flights'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                             '/flights',
                                             JSON.stringify(flight),
                                             httpOptions)
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  updateFlight(flight: Flight, xRequestId?): Promise<Flight> {
    this.logger.trace('updateFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.updateFlight',
                                  'put',
                                  JSON.stringify(flight),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/flights/' + flight.id));
   const httpOptions = this.setDefaultHttpHeader(xRequestId);
   return this.http.put<Flight>(this.globalSettings.apiWeightBalanceURL +
                                '/flights/' +
                                flight.id,
                                JSON.stringify(flight),
                                httpOptions)
   .pipe(
     retry(1),
     catchError(this.handleError)
   ).toPromise();
  }

  // Users
  getUsers(xRequestId?): Promise<User[]> {
    this.logger.trace('getFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getUsers',
                                  'get',
                                  identifierName,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/admin/users'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<User[]>(this.globalSettings.apiWeightBalanceURL + '/admin/users', httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  setUser(id, assignee, xRequestId?): Promise<any> {
    this.logger.trace('desktop',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.setUser',
                                  'post',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  `/flights/${id}/assign/${assignee}`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL + `/flights/${id}/assign/${assignee}`,
    null,
    httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  // Calculate
  calculate(calculate, xRequestId?): Observable<CalculationAhm> {
    this.logger.trace('calculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.calculate',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculate'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<CalculationAhm>(this.globalSettings.apiWeightBalanceURL +
                                          '/calculate',
                                          JSON.stringify(calculate),
                                          httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Chart data
  getChart(calculate, xRequestId?): Promise<any> {
    this.logger.trace('getChart',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getChart',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/chart'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL +
                              '/chart',
                              JSON.stringify(calculate),
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Get delta ballast
  getDeltaBallast(calculate, xRequestId?): Promise<any> {
    this.logger.trace('getDeltaBallast',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getDeltaBallast',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/ballast_delta'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL +
                              '/ballast_delta',
                              JSON.stringify(calculate),
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Get delta crew
  getDeltaCrew(calculate, xRequestId?): Promise<any> {
    this.logger.trace('getDeltaBallast',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getDeltaCrew',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/crew_delta'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL +
                              '/crew_delta',
                              JSON.stringify(calculate),
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Calculations
  getCalculation(flightId, xRequestId?): Promise<CalculationAhm> {
    this.logger.trace('getCalculation',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getCalculation',
                                  'get',
                                  flightId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculations/' + flightId));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<CalculationAhm>(this.globalSettings.apiWeightBalanceURL +
                                         '/calculations/' + flightId,
                                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addCalculate(calculate: any, xRequestId?): Promise<HttpResponse<any>> {
    this.logger.trace('addCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.addCalculate',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculations'));
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                             '/calculations',
                                             JSON.stringify(calculate),
                                             httpOptions)
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateCalculate(id, calculate, xRequestId?): Promise<CalculationAhm> {
    this.logger.trace('updateCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.updateCalculate',
                                  'post',
                                  [id, JSON.stringify(calculate)],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculations/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<CalculationAhm>(this.globalSettings.apiWeightBalanceURL +
                                         '/calculations/' + id,
                                         JSON.stringify(calculate),
                                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // calculations manual
  getCalculationManual(flightId, xRequestId?): Promise<CalculationManual> {
    this.logger.trace('getCalculationManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getCalculationManual',
                                  'get',
                                  flightId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/manual_loadsheet/' + flightId));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<CalculationManual>(this.globalSettings.apiWeightBalanceURL +
                                            '/manual_loadsheet/' + flightId,
                                            httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addCalculateManual(calculate: any, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('addCalculateManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.addCalculateManual',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/manual_loadsheet'));
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                             '/manual_loadsheet',
                                             JSON.stringify(calculate),
                                             httpOptions)
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  updateCalculateManual(id, calculate, xRequestId?): Promise<CalculationManual> {
    this.logger.trace('updateCalculateManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.updateCalculateManual',
                                  'put',
                                  [id, JSON.stringify(calculate)],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/manual_loadsheet/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<CalculationManual>(this.globalSettings.apiWeightBalanceURL +
                                            '/manual_loadsheet/' + id,
                                            JSON.stringify(calculate),
                                            httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  createDocuments(flightId, xRequestId?) {
    this.logger.trace('createDocuments',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.createDocuments',
                                  'post',
                                  JSON.stringify(flightId),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/create'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post(this.globalSettings.apiWeightBalanceURL +
                          '/documents/' + flightId + '/create',
                          JSON.stringify(flightId),
                          httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  loadDocument(flightId, edition, url, xRequestId?): Promise<any> {
    this.logger.trace('loadDocument',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.loadDocument',
                                  'get',
                                  [flightId, edition, url],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/editions/' +
                                  edition + url));
    // return Observable.of('TODO: change to real data');
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiWeightBalanceURL +
                              '/documents/' + flightId + '/editions/' +
                              edition + url,
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  loadTelegram(flightId, tlgName, xRequestId?): Promise<any> {
    this.logger.trace('loadTelegram',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.loadTelegram',
                                  'get',
                                  [flightId, tlgName],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/telegrams/' + flightId + '/' + tlgName));
    // return Observable.of('TODO: change to real data');
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiWeightBalanceURL +
                              '/telegrams/' + flightId + '/' + tlgName,
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  sendTelegram(
    flightId: number, tlgName: string,
    addresses: Array<{channel: number, address: string}>, xRequestId?
  ): Promise<any> {
    this.logger.trace('sendTelegram',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.sendTelegram',
                                  'post',
                                  [flightId, tlgName],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/telegrams/' + flightId + '/' + tlgName + '/send'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post(this.globalSettings.apiWeightBalanceURL +
                          '/telegrams/' + flightId + '/' + tlgName + '/send',
                          JSON.stringify(addresses),
                          httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  loadRouteNetworks(
    airlineId: number, airports: Array<number>,
    type: string, name: string, xRequestId
  ): Promise<{ channel: number, address: string }[]>  {
    this.logger.trace('sendTelegram',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.sendTelegram',
                                  'post',
                                  [airlineId, airports, type, name],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/admin/route_networks?airline=' + airlineId + '&airport=' + airports.toString()));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<RouteNetwork[]>(this.globalSettings.apiWeightBalanceURL +
                        '/admin/route_networks?airline=' + airlineId + '&airport=' + airports.toString())
    .pipe(
      retry(1),
      map(data => {
        // собираем массив, channel - канал, address - куда отправляем
        const res = [];
        let flightType = 0;
        data.forEach( (el: RouteNetwork) => {
          el[type].forEach( (docItem: RouteNetworkDocMes) => {
            let airportIndex = airports.indexOf(el.airportId);
            // Проверка на наличие одного аэропорта в маршруте 2 раза,
            // для него надо установить тип Прилет/Вылет
            if (airportIndex !== -1 && airports.indexOf(el.airportId) !== airports.lastIndexOf(el.airportId)) {
              airportIndex = -1;
            }
            switch (airportIndex) {
              // Если первый пункт маршрута, то это Вылет
              case 0:
                flightType = 2;
                break;
              // Если последний пункт маршрута, то это Прилет
              case airports.length - 1:
                flightType = 1;
                break;
              // Все остальные варианты это Прилет/Вылет
              // если стоит в середине маршрута или встречается в нем
              // несколько раз
              default:
                flightType = 3;
                break;
            }
            if (docItem.arrDep === flightType || flightType === 3) {
              docItem.docs.forEach( (doc: RouteNetworkItem) => {
                if (doc.name === name && doc.enabled) {
                  const channel = el.addresses.find(address => address.address === docItem.address);
                  if (channel) {
                    res.push({ channel: channel.channelId, address: docItem.address });
                  }
                }
              });
            }
          });
        });
        return res;
      }),
      catchError(this.handleError)
    ).toPromise();

  }

  getDocumentEditions(flightId, xRequestId?): Promise<[]> {
    this.logger.trace('getDocumentEditions',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getDocumentEditions',
                                  'get',
                                  flightId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/editions'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<[]>(this.globalSettings.apiWeightBalanceURL +
                             '/documents/' + flightId + '/editions',
                             httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  getLoadsheet(url, id, xRequestId): Observable<any> {
    this.logger.trace('getLoadsheet',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getLoadsheet',
                                  'get',
                                  [url, id],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  url + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get(this.globalSettings.apiWeightBalanceURL + url + id,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  saveDocument(flightId, edition, url, xRequestId?): Observable<any> {
    this.logger.trace('saveDocument',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.saveDocument',
                                  'get',
                                  [flightId, edition, url],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/editions/' +
                                  edition + url));
    let params = new HttpParams();
    params = params.append('export', 'pdf');
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['params'] = params;
    httpOptions['responseType'] = 'blob';
    return this.http.get(this.globalSettings.apiWeightBalanceURL +
                         '/documents/' + flightId + '/editions/' + edition + url,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // ahmData
  getAhmData(tailId, flightId, xRequestId?): Promise<AhmData> {
    this.logger.trace('getAhmData',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getAhmData',
                                  'get',
                                  [tailId, flightId],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/ahm_data_by_tail/' + tailId + '/' +
                                  flightId));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                  '/ahm_data_by_tail/' + tailId + '/' + flightId,
                                  httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // reference
  getReference(name, xRequestId?): Promise<any[]> {
    this.logger.trace('getReference',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getReference',
                                  'get',
                                  name,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/master_data/' + name));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiWeightBalanceURL +
                                '/master_data/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
