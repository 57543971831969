<div class="row mt-2">
    <div class="col-2 pr-1">
      <div class="card">
        <div class="card-header bg-gray-blue-dark text-center text-white font-weight-bold"
             i18n="adm|Main left menu - name@@adm.main.leftMenu.name">
          Modules
        </div>
        <ul class="list-group list-group-flush">
          <li id="module-communication"
              class="list-group-item"
              *ngIf="isComModule"
              [routerLink]="['communication']"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}"
              i18n="adm|Main left menu - communication module@@adm.main.leftMenu.item.comMod">
              Communication module
          </li>
          <li id="module-informational"
              class="list-group-item"
              [routerLink]="['informational']"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}"
              i18n="adm|Main left menu - informing@@adm.main.leftMenu.item.informing"
              *ngIf="isInforming">
            Informing
          </li>
          <li id="module-techprocesses"
              class="list-group-item"
              [routerLink]="['technical']"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}"
              i18n="adm|Main left menu - technical processes@@adm.main.leftMenu.item.techProcesses"
              *ngIf="isWorkflow">
            Technical processes
          </li>
          <li id="module-boarding-control"
              class="list-group-item"
              [routerLink]="['boarding-control']"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}"
              i18n="adm|Main left menu - boarding control@@adm.main.leftMenu.item.boardingControl"
              *ngIf="isWorkflow">
            Boarding control
          </li>
          <li id="module-weight-balance"
              class="list-group-item"
              [routerLink]="['weight-balance']"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}"
              i18n="adm|Main left menu - weight balance@@adm.main.leftMenu.item.weightBalance"
              *ngIf="isBalance">
            Weight balance
          </li>
          <li id="module-lost-found"
              class="list-group-item"
              [routerLink]="['lost-found']"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}"
              i18n="adm|Main left menu - weight balance@@adm.main.leftMenu.item.lostFound"
              *ngIf="isLostFound">
            Lost and found
          </li>
          <li id="module-flights"
              class="list-group-item"
              [routerLink]="['flights']"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}"
              i18n="adm|Main left menu - flights@@adm.main.leftMenu.item.flights"
              *ngIf="isFlights">
            Flights
          </li>
        </ul>
      </div>
    </div>
    <div class="col-10 pl-0">
      <router-outlet></router-outlet>
    </div>
</div>
