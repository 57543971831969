// Каналы передачи сообщений
export class Channel {
    id: number;
    name: string;
    lastupdate: string;
}

// Категория срочности сообщения
export class Urgency {
    id: number;
    name: Array<string>;
    lastupdate: string;

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }

    set nameEnglish(value) {
      this.name[0] = value;
    }

    get nameLocal(): string {
      if ((this.name) && (this.name.length === 2) && this.name[1] != '') {
        return this.name[1];
      } if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }

    set nameLocal(value) {
      this.name[1] = value;
    }
}

// Типы сообщений
export class Type {
    constructor() {
        this.name = ['', ''];
    }

    id: number;
    name: Array<string>;
    lastupdate: string;

    get nameEnglish(): string {
      if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }

    set nameEnglish(value) {
      this.name[0] = value;
    }

    get nameLocal(): string {
      if ((this.name) && (this.name.length === 2) && this.name[1] != '') {
        return this.name[1];
      } if ((this.name) && (this.name.length >= 1)) {
        return this.name[0];
      } else {
        return null;
      }
    }

    set nameLocal(value) {
      this.name[1] = value;
    }
}

// Рейсы
export class Flight {
    id: number;
    airlineIata: string;
    airlineIcao: string;
    arrdep: number;
    airlineCode: string;
    flight: string;
    route: Array<string>;
    times: Array<string>;
}

export class FilterParams {
    constructor() {
        this.flight = [];
        this.recipient = [];
        this.recipientFlag = '';
        this.sender = [];
        this.senderFlag = '';
        this.urgencyFlag = '';
        this.urgencyValue = [];
        this.subject = '';
        this.depth = 'current';
        this.airlineFlag = '';
        this.airlineValue = [];
        this.typeFlag = '';
        this.typeValue = [];
        this.channelFlag = '';
        this.channelValue = [];
        this.textFlag = '';
        this.textValue = [];
        this.direction = -1;
        this.unread = false;
        this.noFlight = false;
        this.start = null;
        this.folder = null;
        this.mark = null;
        this.all = null;
        this.userFlag = '';
        this.userValue = '';
        this.aftnNumber = [];
        this.routeFlag = '';
        this.routeValue = [];
    }

    page: string;
    limit: string;
    folder: string;
    flight: Array<string>;
    recipientFlag: string;
    recipient: Array<string>;
    sender: Array<string>;
    senderFlag: string;
    urgencyFlag: string;
    urgencyValue: Array<number>;
    subject: string;
    depth: string;
    unread: boolean;
    direction: number;
    airlineFlag: string;
    airlineValue: Array<number>;
    typeFlag: string;
    typeValue: Array<number>;
    channelFlag: string;
    channelValue: Array<number>;
    textFlag: string;
    textValue: Array<string>;
    noFlight: boolean;
    start: string;
    finish: string;
    mark: string;
    all: string;
    userFlag: string;
    userValue: string;
    aftnNumber: Array<string>;
    routeFlag: string;
    routeValue: Array<number>;
}