
/**
* Функция, которая вычисляет общий вес в секции
* @param hold все багажники
* @param section имя секции
*/
export function getLoadWeight(hold, section: string): number {
    return hold.filter(el => el.name === section)
      .reduce((acc, el) => {
        return acc += el.weight + el.uldWeight;
      }, 0);
}
