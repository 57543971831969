<div class="content-parking bg-light-gray">
  <div class="mb-2 p-3 aero-top-panel shadow">
    <div class="row">
      <div class="col-8">
        <button type="button" class="btn btn-sm btn-light-gray mr-1">
          <i class="material-icons">insert_invitation</i>
          <span>Book parking</span>
        </button>
        <button type="button" class="btn btn-sm btn-light-gray mr-1"
                [disabled]="!selectedFlight || !selectedParking || parking.flightId"
                (click)="setPlane()">
          <i class="material-icons">airplanemode_active</i>
          <span>Set plane</span>
        </button>
        <button type="button" class="btn btn-sm btn-light-gray mr-1"
                [disabled]="!selectedFlight || !flight.parkingId"
                (click)="remotePlane()">
          <i class="material-icons">airplanemode_inactive</i>
          <span>Remote plane</span>
        </button>
        <button type="button" class="btn btn-sm btn-light-gray mr-1"
                [disabled]="!selectedParking || !parking.flightId"
                (click)="clearParking()">
          <i class="material-icons">clear</i>
          <span>Clear parking</span>
        </button>
        <!--<button type="button" class="btn btn-sm btn-light-gray mr-1">
          <i class="material-icons">swap_calls</i>
          <span>Plane towing</span>
        </button>
        <button type="button" class="btn btn-sm btn-light-gray mr-1">
          <i class="material-icons">not_interested</i>
          <span>Close parking</span>
        </button>-->
        <button type="button" class="btn btn-sm btn-light-gray mr-1"
                [disabled]="(!parking.closeFrom && !parking.closeTo) || (parking.closeFrom > todayDate) || (parking.closeTo < todayDate)"
                (click)="openParking(parking.id)">
          <i class="material-icons">done</i>
          <span>Open parking</span>
        </button>
        <button *ngIf="userRole=='admin'" type="button" class="btn btn-sm btn-blue mr-1" (click)="createParking()">
          <i class="material-icons">queue</i>
          <span>Add parking</span>
        </button>
      </div>
      <div class="col-2 input-group">
        <select name="userRole" id="userRole" [(ngModel)]="userRole" class="form-control form-control-sm mr-2">
          <option value="user" [selected]="userRole=='user'">User</option>
          <option value="admin" [selected]="userRole=='admin'">Admin</option>
        </select>
        <div class="btn-group">
          <div class="dropdown">
            <button type="button"
                    class="btn btn-xs btn-ico btn-light-gray dropdown-toggle"
                    id="selectView"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
              <i class="material-icons" *ngIf="mapView">streetview</i> 
              <i class="material-icons" *ngIf="!mapView">table_rows</i> 
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="selectView">
              <button type="button" class="dropdown-item" (click)="mapView=true">
                <i class="material-icons">streetview</i>
                Map view
              </button>
              <button type="button" class="dropdown-item" (click)="mapView=false">
                <i class="material-icons">table_rows</i>
                Table view
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        Image scale 
        <input type="range" min="0" max="10" id="size" [(ngModel)]="sizeMap" (input)="selectSizeMap()" value="1">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-8 pr-0">
      <div class="map-zone" *ngIf="mapView">
        <div class="map">
          <img src="../assets/img/parking/map.png" alt="" [ngStyle]="{'width': 100+sizeMap*10+'%'}">
          <div class="parkings example-boundary">
            <div class="parking p-1"
                 *ngFor="let oneParking of parkings"
                 cdkDragBoundary=".example-boundary"
                 cdkDrag
                 (cdkDragEnded)="dragEnd($event, parking.id)"
                 (mousedown)="editParking(oneParking.id)"
                 [ngStyle]="{'width': (oneParking.width + ( (oneParking.width / 100) * (10 * sizeMap) ) ) + 'px',
                            'height': (oneParking.height + ( (oneParking.height / 100) * (10 * sizeMap) ) ) + 'px',
                            'top': (oneParking.positionY + ( (oneParking.positionY / 100) * (10 * sizeMap) ) ) + 'px',
                            'left': (oneParking.positionX + ( (oneParking.positionX / 100) * (10 * sizeMap) ) ) + 'px',
                            'font-size': (12 + sizeMap) + 'px'}"
                 [ngClass]="{'free': oneParking.flightId==null, 'busy': oneParking.flightId!=null,
                            'closed': (oneParking.closeFrom < todayDate) && (oneParking.closeTo > todayDate),
                            'selected': parking.id==oneParking.id}">
              <div cdkDragHandle [ngClass]="{'handler': userRole=='admin'}"></div>
              <div *ngIf="oneParking.flightId" class="row flights-info">
                <div class="col-6 pr-1">
                  <div class="font-weight-bold">{{oneParking.flightInfo.arrival}}</div>
                  <div>
                    <i class="material-icons" [ngStyle]="{'font-size': (12 + sizeMap) + 'px'}">flight_land</i>
                    {{oneParking.flightInfo.arrivalTime | date : 'HH:mm'}}
                  </div>
                  <div>{{oneParking.flightInfo.arrivalTime | date : 'dd.MM.yyyy'}}</div>
                </div>
                <div class="col-6 pl-0 text-right">
                  <div class="font-weight-bold">{{oneParking.flightInfo.departure}}</div>
                  <div>
                    <i class="material-icons" [ngStyle]="{'font-size': (12 + sizeMap) + 'px'}">flight_takeoff</i>
                    {{oneParking.flightInfo.departureTime | date : 'HH:mm'}}
                  </div>
                  <div>{{oneParking.flightInfo.departureTime | date : 'dd.MM.yyyy'}}</div>
                </div>
              </div>
              <div class="parking-name" [ngStyle]="{'font-size': (18 + sizeMap) + 'px'}">
                {{oneParking.name}}
              </div>
              <div *ngIf="oneParking.flightId" class="aircraft">
                <div>{{oneParking.flightInfo.plane}}</div>
                <div>{{oneParking.flightInfo.aircraft}}</div>
              </div>
              <div class="closed" *ngIf="(oneParking.closeFrom < todayDate) && (oneParking.closeTo > todayDate)">
                <div class="close-reason">{{oneParking.closeReasonName[0]}}</div>
                <div class="close-date">
                  <i class="material-icons" [ngStyle]="{'font-size': (12 + sizeMap) + 'px'}">not_interested</i>
                  Закрыта до<br>
                  {{oneParking.closeTo | date : 'dd.MM.yyy HH:mm'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 pl-0">
      <form *ngIf="(action=='add' || action=='edit') && userRole=='admin'" class="p-2">
        <p class="mb-3 h6 card__header--important">Append/Edit parking</p>
        <div class="row mb-3">
          <div class="col-3 pr-0">
            <label for="parkingName" class="col-form-label col-form-label-sm">Name</label>
          </div>
          <div class="col-9 pl-0">
            <input type="text" name="parkingName" id="parkingName" class="form-control" [(ngModel)]="parking.name">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3 pr-0">
            <label for="parkingPositionX" class="col-form-label col-form-label-sm">Position X</label>
          </div>
          <div class="col-3 pl-0">
            <input type="number" name="parkingPositionX" id="parkingPositionX" class="form-control" [(ngModel)]="parking.positionX">
          </div>
          <div class="col-3 pr-0">
            <label for="parkingPositionY" class="col-form-label col-form-label-sm">Position Y</label>
          </div>
          <div class="col-3 pl-0">
            <input type="number" name="parkingPositionY" id="parkingPositionY" class="form-control" [(ngModel)]="parking.positionY">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3 pr-0">
            <label for="parkingWidth" class="col-form-label col-form-label-sm">Size width</label>
          </div>
          <div class="col-3 pl-0">
            <input type="number" name="parkingWidth" id="parkingWidth" class="form-control" [(ngModel)]="parking.width">
          </div>
          <div class="col-3 pr-0">
            <label for="parkingHeight" class="col-form-label col-form-label-sm">Size height</label>
          </div>
          <div class="col-3 pl-0">
            <input type="number" name="parkingHeight" id="parkingHeight" class="form-control" [(ngModel)]="parking.height">
          </div>
        </div>
        <p class="mb-3 h6 card__header--important">Conditions</p>
        <div class="row mb-3">
          <div class="col-3 pr-0">
            <label for="parkingInformation" class="col-form-label col-form-label-sm">Information</label>
          </div>
          <div class="col-9 pl-0">
            <input type="text"
                   name="parkingInformation"
                   id="parkingInformation"
                   class="form-control"
                   [(ngModel)]="parking.information">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3 pr-0">
            <label for="parkingWarning" class="col-form-label col-form-label-sm">Warning</label>
          </div>
          <div class="col-9 pl-0">
            <input type="text"
                   name="parkingWarning"
                   id="parkingWarning"
                   class="form-control"
                   [(ngModel)]="parking.warning">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  (click)="action=''">
            <i class="material-icons">cancel</i>
            <span>Cancel</span>
          </button>
          <button type="button"
                  class="btn btn-sm btn-red"
                  (click)="deleteParking(parking.id)">
            <i class="material-icons">delete</i>
            <span>Delete</span>
          </button>
          <button type="button"
                  class="btn btn-sm btn-blue"
                  (click)="addParking(parkings.length+1)">
            <i class="material-icons">save</i>
            <span i18n="pkg|button save@@pkg.button.save">Save</span>
          </button>
        </div>
      </form>
      <div *ngIf="userRole=='user'" class="p-2">
        <p class="mb-3 h6 card__header--important">Flights</p>
        <div class="bg-white p-2">
          <table class="w-100">
            <tr class="border-bottom text-center">
              <th>Arrival</th>
              <th>Plane</th>
              <th>Departure</th>
              <th>Arrival time</th>
              <th>Departure time</th>
              <th>Parking</th>
              <th>Aircraft</th>
            </tr>
            <tbody>
              <tr *ngFor="let flight of flights"
                  class="border-bottom"
                  [ngClass]="{'bg-light-gray': selectedFlight==flight.id}"
                  (click)="selectFlight(flight.id)">
                <td>{{flight.arrival}}</td>
                <td>{{flight.plane}}</td>
                <td>{{flight.departure}}</td>
                <td>{{flight.arrivalTime | date: 'dd.MM.yyyy HH:mm'}}</td>
                <td>{{flight.departureTime | date: 'dd.MM.yyyy HH:mm'}}</td>
                <td class="text-center">{{flight.parkingName}}</td>
                <td>{{flight.aircraft}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <form *ngIf="action=='edit' && userRole=='user'" class="p-2">
        <p class="mb-3 h6 card__header--important">Parking {{parking.name}}</p>
        <div class="row mb-2">
          <div class="col-3 pr-0">Towing from</div>
          <div class="col-3 pr-0 pl-1">
            <input type="text" class="form-control form-control-sm"
                   name="towingFrom"
                   id="towingFrom"
                   [(ngModel)]="parking.name"
                   disabled>
          </div>
          <div class="col-3 pr-0 pl-1">
            <select class="form-control form-control-sm"
                    name="towingTo" id="towingTo"
                    #towingTo>
              <option *ngFor="let oneParking of parkings"
                      value="{{oneParking.id}}"
                      [disabled]="(oneParking.closeFrom < todayDate) && (oneParking.closeTo > todayDate) || oneParking.flightId">
                {{oneParking.name}}
              </option>
            </select>
          </div>
          <div class="col-3 pl-1">
            <button class="btn btn-h-30 btn-blue w-100"
                    [disabled]="!parking.flightId"
                    (click)="towingPlane(towingTo.value)">
              <i class="material-icons">swap_calls</i>
              <span>Plane towing</span>
            </button>
          </div>
        </div>
        <hr />
        <div class="row mb-2">
          <div class="col-3 pr-0">Close from</div>
          <div class="col-3 pr-0 pl-1">
            <input type="date"
                 class="form-control"
                 [ngModel]="parking.closeFrom | date: 'yyyy-MM-dd'"
                 (input)="parking.closeFrom = parseDate($event.target.value, closeFromTime.value)"
                 name="closeFromDate"
                 #closeFromDate="ngModel">
          </div>
          <div class="col-3 pr-0 pl-1">
            <input type="time"
                 class="form-control mr-1"
                 [ngModel]="parking.closeFrom | date: 'HH:mm'"
                 (input)="parking.closeFrom = parseDate(closeFromDate.value, $event.target.value)"
                 name="closeFromTime"
                 #closeFromTime="ngModel">
          </div>
          <div class="col-3 pl-1"></div>
        </div>
        <div class="row mb-2">
          <div class="col-3 pr-0">To</div>
          <div class="col-3 pr-0 pl-1">
            <input type="date"
                 class="form-control"
                 [ngModel]="parking.closeTo | date: 'yyyy-MM-dd'"
                 (input)="parking.closeTo = parseDate($event.target.value, closeToTime.value)"
                 name="closeToDate"
                 #closeToDate="ngModel">
          </div>
          <div class="col-3 pr-0 pl-1">
            <input type="time"
                 class="form-control mr-1"
                 [ngModel]="parking.closeTo | date: 'HH:mm'"
                 (input)="parking.closeTo = parseDate(closeToDate.value, $event.target.value)"
                 name="closeToTime"
                 #closeToTime="ngModel">
          </div>
          <div class="col-3 pl-1"></div>
        </div>
        <div class="row mb-2">
          <div class="col-3 pr-0">Reason</div>
          <div class="col-6 pr-0 pl-1">
            <select class="form-control form-control-sm"
                    name="closeReasonId" id="closeReasonId"
                    #closeReasonId>
              <option disabled [selected]="parking.closeReasonId==null"></option>
              <option *ngFor="let reason of closeReasons"
                      [selected]="reason.id == parking.closeReasonId"
                      value="{{reason.id}}">
                {{reason.nameLocal}}
              </option>
            </select>
          </div>
          <div class="col-3 pl-1">
            <button class="btn btn-h-30 btn-blue w-100" (click)="closeParking(closeReasonId.value)">
              <i class="material-icons">not_interested</i>
              <span>Close parking</span>
            </button>
          </div>
        </div>
        <p class="mb-3 h6 card__header--important">Conditions</p>
        <div class="row mb-2">
          <div class="col-3 pr-0">Information</div>
          <div class="col-9 pl-1">{{parking.information}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-3 pr-0">Warning</div>
          <div class="col-9 pl-1">{{parking.warning}}</div>
        </div>
      </form>
    </div>
  </div>
  
  <!-- Сообщение об ошибке -->
  <div *ngIf="errorMessage !== ''" class="ms-modal-message">
    <div class="modal-content w-75">
      <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                            'bg-warning': errorType == 'warning',
                                            'bg-danger': errorType == 'error'}">
        <h5 *ngIf="errorType == 'info'" class="modal-title">
          Information
        </h5>
        <h5 *ngIf="errorType == 'warning'" class="modal-title">
          Warning
        </h5>
        <h5 *ngIf="errorType == 'error'" class="modal-title">
          Error
        </h5>
        <button type="button"
                class="close"
                (click)="clearErrorMess()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{errorMessage}}
      </div>
      <div class="modal-footer">
        <button type="button"
                *ngIf="action == 'delete'"
                class="btn btn-sm btn-secondary"
                (click)="clearErrorMess()">
          No
        </button>
      </div>
    </div>
  </div>
</div>