<div class="modal-dialog modal-xl">
  <div class="modal-content bg-light-gray">
    <form #filterForm="ngForm">
      <div class="modal-header">
        <h5 class="modal-title" id="modalContentBaggage">
          <span i18n="laf|Content modal@@laf.modal.baggageContent.header">Contents</span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="col-3 pr-0">
            <div class="input-group flex-nowrap">
              <div class="input-group-prepend custom-sm">
                <span class="input-group-text font-small py-0"
                  i18n="laf|Content modal@@laf.modal.baggageContent.enter.category">
                  Category
                </span>
              </div>
              <ng-select #bagContentItem class="w-100 custom-sm bg-white"
                [items]="references.baggage_internal_categories.data" bindLabel="name" bindValue="id"
                name="contentCategory" [(ngModel)]="baggageInternalCategoryId"
                (change)="findBaggageContentAndGroup('items', $event)" [selectOnTab]="true"
                [virtualScroll]="true">
              </ng-select>
            </div>
          </div>
          <div class="col-4 pr-0">
            <div class="input-group flex-nowrap">
              <div class="input-group-prepend custom-sm">
                <span class="input-group-text font-small py-0"
                  i18n="laf|Content modal@@laf.modal.baggageContent.enter.content">
                  Content
                </span>
              </div>
              <ng-select #bagContentCategory class="w-100 custom-sm bg-white"
                [items]="baggageInternalForCategory" bindLabel="name" bindValue="id" name="contentItems"
                [(ngModel)]="baggageInternalId" (change)="findBaggageContentAndGroup('group', $event)"
                [selectOnTab]="true" [virtualScroll]="true">
                <ng-template ng-label-tmp let-item="item">
                  {{ this.globalSettings.language === 'en' ? item.nameEnglish : item.nameLocal }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-4 pr-0">
            <div class="input-group input-group-sm flex-nowrap">
              <div class="input-group-prepend custom-sm">
                <span class="input-group-text font-small py-0"
                  i18n="laf|Content modal@@laf.modal.baggageContent.enter.description">
                  Description
                </span>
              </div>
              <input class="form-control inp" type="text" name="internalDescription"
                [(ngModel)]="baggageInternalDescription">
            </div>
          </div>
          <div class="col-1 pl-2">
            <button type="button" class="btn btn-h-31 btn-blue"
              (click)="addBaggageInternalElement()"
              [disabled]="!baggageInternalId"
              i18n="laf|Content modal@@laf.modal.baggageContent.button.add">
              Add
            </button>
          </div>
        </div>
        <div class="row px-3" [formGroup]="parentForm">
          <h5 class="font-weight-bold text-blue h5 mb-3"
            i18n="laf|Content modal@@laf.modal.baggageContent.table.header">
            List of items
          </h5>
          <table class="w-100 mb-3 table-border contenteditable" formArrayName="internalElements">
            <tbody>
              <ng-container *ngFor="let internalGroup of internalElements.controls; let i=index" [formGroupName]="i">
                <tr>
                  <td colspan="3" class="font-weight-bold bg-light-yellow">
                    {{ getLocalName(internalGroup.get('categoryName')) }}
                  </td>
                </tr>
                <ng-container formArrayName="internals">
                  <tr class="bg-light text-dark" *ngFor="let element of getInternalsAsArray(i).controls; let j = index" [formGroupName]="j">
                    <td class="pl-3">
                      {{ getLocalName(element.get('name')) }}
                    </td>
                    <td class="pl-3">
                      {{element.get('description').value}}
                    </td>
                    <td class="text-center align-middle">
                      <span class="material-icons text-red" (click)="removeInternal(i, j)">close</span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal"
          i18n="laf|Content modal@@laf.modal.baggageContent.button.close">
          Close
        </button>
      </div>
    </form>
  </div>
</div>
