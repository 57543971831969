export class RouteNetwork {
    constructor(obj?: RouteNetwork) {
        this.id = obj?.id || null;
        this.airlineId = obj?.airlineId || null;
        this.airportId = obj?.airportId || null;
        this.documents = obj?.documents || [];
        this.messages = obj?.messages || [];
        this.addresses = obj?.addresses || [];
        this.lastupdate = obj?.lastupdate ? new Date(obj.lastupdate) : null;
    }
    id: number | null;
    airline?: string;
    airlineId: number | null;
    airport?: string;
    airportId: number | null;
    addresses: Array<RouteNetworkAddress>;
    documents: Array<RouteNetworkDocMes>;
    messages: Array<RouteNetworkDocMes>;
    lastupdate: Date | null;
}

export class RouteNetworkAddress {
    constructor() {
        this.channel = '';
        this.channelId = null;
        this.address = '';
    }
    channel: string;
    channelId: number | null;
    address: string;
}

export class RouteNetworkDocMes {
    constructor() {
        this.address = '';
        this.arrDep = null;
        this.docs = [];
    }
    address: string;
    arrDep: number | null;
    docs: Array<RouteNetworkItem>;
}

export class RouteNetworkItem {
    constructor(name: string) {
        this.name = name || '';
        this.enabled = false;
    }
    name: string;
    enabled: boolean;
}

export enum ACTIONS {
    ADD,
    EDIT
}

export const ARRDEP = [
    {id: 1, name: 'Arrival'},
    {id: 2, name: 'Departure'}
]
