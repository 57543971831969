import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalSettings } from '@settings/global-settings';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { WorkspaceDistribution } from '../types/reference-filter';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getFilters(): Promise<WorkspaceDistribution[]> {
    return this.http.get<WorkspaceDistribution[]>(this.globalSettings.apiWeightBalanceURL +
                                   '/admin/filters')
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  getFilter(id: number): Promise<WorkspaceDistribution> {
    return this.http.get<WorkspaceDistribution>(this.globalSettings.apiWeightBalanceURL +
                                 '/admin/filters/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  addFilter(filter: WorkspaceDistribution): Promise<WorkspaceDistribution> {
    return this.http.post<WorkspaceDistribution>(this.globalSettings.apiWeightBalanceURL +
                                                '/admin/filters',
                                                JSON.stringify(filter),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  saveFilter(id: number, filter: WorkspaceDistribution): Promise<WorkspaceDistribution> {
    return this.http.put<WorkspaceDistribution>(this.globalSettings.apiWeightBalanceURL +
                                                '/admin/filters/' + id,
                                                JSON.stringify(filter),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  deleteFilter(id: number): Promise<WorkspaceDistribution> {
    return this.http.delete<WorkspaceDistribution>(this.globalSettings.apiWeightBalanceURL +
                                                   '/admin/filters/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(error.error);
  }
}
