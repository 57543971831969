import { Injectable } from "@angular/core";
import * as Rx from "rxjs";

@Injectable()
export class WebsocketService {
  constructor() {}

  public subject: Rx.Subject<MessageEvent>;
  public ws: WebSocket;

  public connect(url): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  public disconnect() {
    this.ws.close();
  }

  private create(url): Rx.Subject<MessageEvent> {
    this.ws = new WebSocket(url);

    /*ws.onerror = function(ev) {
      alert('[error]'+JSON.stringify(ev));
    };
    ws.onclose = function(ev) {
      alert('[onclose]'+JSON.stringify(ev));
    };*/

    let observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);

      return this.ws.close.bind(this.ws);
    });

    let observer = {
      next: (data: Object) => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      }
    };
    return Rx.Subject.create(observer, observable);
    //return Rx.Subject.create(observer, observable);
  }
}
