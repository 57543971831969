export class ReferanceTerminal {
  constructor() {
    this.id = 0;
    this.name = '';
    this.dtRange = [null, null];
    this.lastupdate = undefined;
  }

  id: number;
  name: string;
  private dtRange: Array<Date>;
  lastupdate: string;

  /**
   * Функции чтения/записи времени актуальности записи
   */

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}
