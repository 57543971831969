export class References {
  constructor() {
    this.statement_types = {loading: true, data: []};
    this.airlines = {loading: true, data: []};
    this.airports = {loading: true, data: []};
    this.baggage_categories = {loading: true, data: []};
    this.baggage_types = {loading: true, data: []};
    this.baggage_materials = {loading: true, data: []};
    this.baggage_colors = {loading: true, data: []};
    this.baggage_elements = {loading: true, data: []};
    this.irregularity_codes = {loading: true, data: []};
    this.baggage_damage_types = {loading: true, data: []};
    this.baggage_damage_levels = {loading: true, data: []};
    this.baggage_damage_locations = {loading: true, data: []};
    this.baggage_internals = {loading: true, data: []};
    this.baggage_internal_categories = {loading: true, data: []};
    this.baggage_statuses = {loading: true, data: []};
    this.baggage_brands = {loading: true, data: []};

  }
  statement_types?: {loading: boolean, data: any[]};
  airlines?: {loading: boolean, data: any[]};
  airports?: {loading: boolean, data: any[]};
  baggage_categories?: {loading: boolean, data: any[]};
  baggage_types?: {loading: boolean, data: any[]};
  baggage_materials?: {loading: boolean, data: any[]};
  baggage_colors?: {loading: boolean, data: any[]};
  baggage_elements?: {loading: boolean, data: any[]};
  irregularity_codes?: {loading: boolean, data: any[]};
  baggage_damage_types?: {loading: boolean, data: any[]};
  baggage_damage_levels?: {loading: boolean, data: any[]};
  baggage_damage_locations?: {loading: boolean, data: any[]};
  baggage_internals?: {loading: boolean, data: any[]};
  baggage_internal_categories?: {loading: boolean, data: any[]};
  baggage_statuses?: {loading: boolean, data: any[]};
  baggage_brands?: {loading: boolean, data: any[]};
}


export class Reference {
  id: number;
  name: Array<string>;

  get nameRu(): string {
    return this.name && this.name[1] ? this.name[1] : '';
  }

  get nameEn(): string {
    return this.name && this.name[0] ? this.name[0] : '';
  }
}

export enum REFERENCES_LIST {
  airlines = 'airlines',
  airports = 'airports',
  statement_types = 'statement_types',
  baggage_categories = 'baggage_categories',
  baggage_types = 'baggage_types',
  baggage_materials = 'baggage_materials',
  baggage_colors = 'baggage_colors',
  baggage_elements = 'baggage_elements',
  irregularity_codes = 'irregularity_codes',
  baggage_damage_types = 'baggage_damage_types',
  baggage_damage_levels = 'baggage_damage_levels',
  baggage_damage_locations = 'baggage_damage_locations',
  baggage_internals = 'baggage_internals',
  baggage_internal_categories = 'baggage_internal_categories',
  baggage_statuses = 'baggage_statuses',
  baggage_brands = 'baggage_brands',
}

export enum STATEMENTS {
  Lost = 1, // Неприбытие
  Pilferage = 2, // Недостача
  Damage = 3, // Повреждение
  Delayed = 4, // Задержанный
  OnHand = 5, // Невостребованный
  FoundProperty = 6, // Потерянные вещи
  Forward = 7, // Засланный
  Hold = 8, // Задержанный
}

export enum STATUSES {
  IssuedCourier = 6, // Выдан курьеру
  Issued = 7, // Выдан
  Sent = 8, // Отправлен
  Closed = 11, // Закрыт
  Cancelled = 15, // Отменено
}
