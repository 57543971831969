import { Injectable } from '@angular/core';
import { FilterParams } from '../types/filter-params';

@Injectable()

export class ViewParamsService {

  constructor() { }

  // filter = {
  //   params: new FilterParams(),
  //   apply: false,
  //   show: false,
  // };

  filterParams = new FilterParams();
  filterApply = false;
  filterShow = false;

  showedStatement = {
    statementsAll: true,
    statements: [],
    timesAll: false,
    times: [],
    storageAll: false,
    storage: [],
  };

  filterLoadAnimation = {
    name: false
  };

  pagination = {
    countsPerPage: [20, 40, 80],
    perPage: 20,
    pageActive: 1,
    statementsCount: 0,
    counters: null,
  };
  
  interval: any;

  changeMark(mark?: string) {
    if (mark) {
      this.filterParams.mark = mark;
    } else {
      this.filterParams.mark = null;
    }
  }

  clearFilterParametr(field: string) {
    switch (field) {
      case 'name':
        this.filterParams.name = [];
        break;
      case 'tag':
        this.filterParams.tag = [];
        break;
      case 'passenger':
        this.filterParams.passenger = '';
        break;
      case 'pnr':
        this.filterParams.pnr = '';
        break;
      case 'flight':
        this.filterParams.flight = '';
        break;
      case 'airport':
        this.filterParams.airport = [];
        break;
      case 'baggageType':
        this.filterParams.baggageType = [];
        break;
      case 'baggageColor':
        this.filterParams.baggageColor = [];
        break;
      case 'baggageMaterial':
        this.filterParams.baggageMaterial = [];
        break;
      case 'baggageExternalElements':
        this.filterParams.baggageExternalElements = [];
        break;
      case 'airline':
        this.filterParams.airline = [];
        break;
      case 'status':
        this.filterParams.status = '';
        break;
      case 'start':
        this.filterParams.start = null;
        break;
      case 'finish':
        this.filterParams.finish = null;
        break;
      case 'date':
        this.filterParams.start = null;
        this.filterParams.finish = null;
        break;
      default:
        break;
    }

    if (this.quickSearchCheck()) {
      this.filterApply = false;
    }
  }

  // Обработка значения, введенного в поле фильтра
  addTagFilterFromArray(names, key) {
    if (!names) {
      return;
    }

    names = names.toUpperCase().split(' ');

    // Скрытие оригинальной строки ng-select, так как
    // не корректно отрабатывает добавление элементов.
    // После скрытия и повторной отрисовки - все отлично.
    // Нюанс работы с компонентом.
    this.filterLoadAnimation[key] = true;
    if (names.length > 1) {
      // Добавление всех элементов в адреса, если выше проверки пройденны
      names.forEach(element => {
        if (element.length !== 0 && !this.filterParams[key].includes(element)) {
          this.filterParams[key] = [...this.filterParams[key], element.trim()];
        }
      });
    } else if (!this.filterParams[key].includes(names[0])) {
      // Если в массиве один элемент, использование стандартного метода добавления
      // единичной записи для ng-select
      this.filterParams[key] = [...this.filterParams[key], names[0].trim()];
    }
    this.interval = setTimeout(() => {
      this.filterLoadAnimation[key] = false;
    }, 250);
  }

  quickSearchCheck() {
    const newFilter = new FilterParams();
    newFilter.archived = this.filterParams.archived;
    return JSON.stringify(this.filterParams) === JSON.stringify(newFilter);
  }

  filterArchive() {
    this.filterParams.archived = !this.filterParams.archived;
  }

  filterApp() {
    this.filterApply = true;
    this.filterShow = !this.filterShow;
  }

  filterSwitch() {
    this.filterApply = !this.filterApply;
  }

  clearFilter() {
    this.filterParams = new FilterParams();
    this.filterApply = false;
  }

  showFilter() {
    this.filterShow = !this.filterShow;
  }
  
  changePerPage(perPage) {
    this.pagination.perPage = perPage;
  }

  toggleDisabledItem() {
    if (this.showedStatement.statementsAll) {
      this.showedStatement.statements = [];
      this.showedStatement.statementsAll = false;
    }
  }

}
