import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { LostFoundRestApiService } from '../services/lost-found-rest-api.service'
import { Office } from '../types/offices';
import { User, UserGroup } from '../types/users';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgSelectFunctions } from '@shared/functions/ngSelectFunctions';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';
import { GlobalI18n } from '@settings/global-i18n';

@Component({
  selector: 'app-am-lost-found',
  templateUrl: './am-lost-found.component.html',
  styleUrls: ['./am-lost-found.component.less']
})
export class AmLostFoundComponent implements OnInit {
  @ViewChild(ModalMessageComponent) modalError: ModalMessageComponent;
  @ViewChild('closebutton') closeButton: any;
  
  activeTab = 'tab-offices';
  loading = false;

  officesArray: Array<Office> = [];
  office: Office = new Office();
  officeForm: UntypedFormGroup;

  users: Array<User> = [];
  user: User = new User();
  userForm: UntypedFormGroup;
  groupsIds = [];

  userGroups: Array<UserGroup> = [];
  userGroup: UserGroup = new UserGroup();
  userGroupForm: UntypedFormGroup;

  selectFuc = new NgSelectFunctions();

  currentPage = 1;
  itemsPerPage = 30;

  references = {
    airports: {data: [], loading: true},
    airlines: {data: [], loading: true},
  }

  constructor(
    public restApi: LostFoundRestApiService,
    public globalSettings: GlobalSettings,
    private fb: UntypedFormBuilder,
    public globalI18n: GlobalI18n,
  ) {
    globalSettings.loadDefaultConfig();
  }

  async ngOnInit() {
    this.createOffice();
    this.loadReferances();
    this.loadOffices();
  }

  get phones() {
    return this.officeForm.get('data').get('phones') as UntypedFormArray;
  }

  get emails() {
    return this.officeForm.get('data').get('emails') as UntypedFormArray;
  }

  get addresses() {
    return this.officeForm.get('data').get('addresses') as UntypedFormArray;
  }

  get urls() {
    return this.officeForm.get('data').get('urls') as UntypedFormArray;
  }

  get notes() {
    return this.officeForm.get('data').get('notes') as UntypedFormArray;
  }

  get workingHours() {
    return this.officeForm.get('data').get('workingHours') as UntypedFormArray;
  }

  get officesForGroup() {
    return this.userGroupForm.get('offices') as UntypedFormArray;
  }

  loadReferances() {
    this.references.airlines.loading = true;
    this.restApi.getAirlines().subscribe(data => {
      this.references.airlines.data = data;
      this.references.airlines.loading = false;
    });
    this.references.airports.loading = true;
    this.restApi.getAirports().subscribe(data => {
      this.references.airports.data = data;
      this.references.airports.loading = false;
    });
  }

  // Offices
  async createOfficeForm(office?: Office) {
    const officeForm = this.fb.group({
      id: [office?.id || null],
      name: [office?.name || null, [Validators.required]],
      airportId: [office?.airportId || null, [Validators.required]],
      airlineId: [office?.airlineId || null, [Validators.required]],
      baseAp: [office?.baseAp || null],
      lastUpdate: [office?.lastUpdate || null],
      dtRange: this.fb.array(office?.dtRange || [null, null]),
      data: this.fb.group({
        phones: this.fb.array(office?.data?.phones || []),
        emails: this.fb.array([]),
        addresses: this.fb.array(office?.data?.addresses || []),
        urls: this.fb.array(office?.data?.urls || []),
        notes: this.fb.array(office?.data?.notes || []),
        workingHours: this.fb.array(office?.data?.workingHours || []),
      })
    });
    officeForm.updateValueAndValidity();
    office.data.emails.forEach(el => {
      const emails = officeForm.get('data').get('emails') as UntypedFormArray;
      emails.push(new FormControl(el, [Validators.email]));
    })
    return officeForm;
  }

  loadOffices() {
    return this.restApi.getOffices().subscribe((data: Office[]) => {
      this.officesArray = [];
      for (const item of data) {
        const type = new Office();
        Object.assign(type, item);
        this.officesArray.push(type);
      }
    });
  }

  async createOffice() {
    this.loading = true;
    this.office = new Office();
    this.officeForm = await this.createOfficeForm(this.office);
    this.loading = false;
  }

  loadOffice(id: string) {
    this.loading = true;
    return this.restApi.getOffice(id).subscribe(async data => {
      this.office = new Office(data);
      this.officeForm = await this.createOfficeForm(data);
      this.loading = false;
    })
  }

  async addOffice() {
    if (this.officeForm.valid) {
      this.office = new Office(this.officeForm.value);
      this.restApi.addOffice(this.office).subscribe(() => {
        this.closeButton.nativeElement.click();
        this.loadOffices();
      });
    } else {
      this.officeForm.markAllAsTouched();
    }
  }

  updateOffice(id: string) {
    if (this.officeForm.valid) {
      this.office = new Office(this.officeForm.value);
      this.restApi.updateOffice(id, this.officeForm.value).subscribe(() => {
        this.closeButton.nativeElement.click();
        this.loadOffices();
      });
    } else {
      this.officeForm.markAllAsTouched();
    }
  }

  // Users
  async createUserForm(user?: User) {
    this.groupsIds = [];
    
    const userForm = this.fb.group({
      id: [user?.id || null],
      username: [user?.username || null],
      firstName: [user?.firstName || null],
      lastName: [user?.lastName || null],
      enabled: [user?.enabled || null],
      groupsIds: this.fb.array([]),
      // groups: this.fb.array([]),
    });
    userForm.updateValueAndValidity();

    if (user.groups) {
      this.groupsIds = user.groups.map(el => el.id);
    }
    return userForm;
  }

  loadUsers() {
    return this.restApi.getUsers().subscribe((data: User[]) => {
      this.users = [];
      for (const item of data) {
        const type = new User();
        Object.assign(type, item);
        this.users.push(type);
      }
    });
  }

  async createUser() {
    this.loading = true;
    this.user = new User();
    this.userForm = await this.createUserForm(this.user);
    this.loading = false;
  }

  loadUser(id: string) {
    this.loading = true;
    return this.restApi.getUser(id).subscribe(async data => {
      this.user = new User(data);
      this.userForm = await this.createUserForm(data);
      this.loading = false;
    })
  }

  async addUser() {
    if (this.userForm.valid) {
      this.user = new User(this.userForm.value);
      this.user.groups = await this.generateGroups(this.groupsIds);
      this.restApi.addUser(this.user).subscribe(() => {
        this.closeButton.nativeElement.click();
        this.loadUsers();
      });
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  async updateUser(id: string) {
    if (this.userForm.valid) {
      this.user = new User(this.userForm.value);
      this.user.groups = await this.generateGroups(this.groupsIds);
      this.restApi.updateUser(id, this.user).subscribe(() => {
        this.closeButton.nativeElement.click();
        this.loadUsers();
      });
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  async generateGroups(groupsIds: Array<string>) {
    if (groupsIds) {
      return groupsIds.map(id => {
        const group = this.userGroups.find(group => group.id === id);
        if (group) return group;
      })
    } else {
      return [];
    }
  }

  // Groups
  async createGroupForm(group?: UserGroup) {
    const userGroupForm = this.fb.group({
      id: [group?.id || null],
      name: [group?.name || null],
      offices: this.fb.array([]),
    });
    userGroupForm.updateValueAndValidity();

    if (group.offices) {
      const offices = userGroupForm.get('offices') as UntypedFormArray;
      group.offices.forEach(office => {
        offices.push(this.fb.group({
          office: [office?.office || null],
          type: [office?.type || null],
        }))
      })
    }
    return userGroupForm;
  }

  loadUserGroups() {
    return this.restApi.getGroups().subscribe((data: UserGroup[]) => {
      this.userGroups = [];
      for (const item of data) {
        const type = new UserGroup();
        Object.assign(type, item);
        this.userGroups.push(type);
      }
    });
  }

  async createUserGroup() {
    this.loading = true;
    this.userGroup = new UserGroup();
    this.userGroupForm = await this.createGroupForm(this.userGroup);
    this.loading = false;
  }

  loadUserGroup(id: string) {
    this.loading = true;
    return this.restApi.getGroup(id).subscribe(async data => {
      this.userGroup = new UserGroup(data);
      this.userGroupForm = await this.createGroupForm(data);
      this.loading = false;
    })
  }

  async addUserGroup() {
    if (this.userGroupForm.valid) {
      this.userGroup = new UserGroup(this.userGroupForm.value);
      this.restApi.addGroup(this.userGroupForm.value).subscribe(() => {
        this.closeButton.nativeElement.click();
        this.loadUserGroups();
      });
    } else {
      this.userGroupForm.markAllAsTouched();
    }
  }

  updateUserGroup(id: string) {
    if (this.userGroupForm.valid) {
      this.userGroup = new UserGroup(this.userGroupForm.value);
      this.restApi.updateGroup(id, this.userGroupForm.value).subscribe(() => {
        this.closeButton.nativeElement.click();
        this.loadUserGroups();
      });
    } else {
      this.userGroupForm.markAllAsTouched();
    }
  }

  groupsString(groups: Array<UserGroup>) {
    if (groups) {
      return groups.map(group => group.name).join(', ');
    } else {
      return '';
    }
  }

  async deleteItem(array: UntypedFormArray, index: number) {
    const mess = this.globalI18n.getMessage(ModuleName.LostFound, 'deleteItem');
    const errType = 'warning';
    await this.modalError.waitAnswer(mess, errType).then(async res => {
      if (res) {
        array.removeAt(index);
      }
    });
  }

  addItemData(arrayName: string) {
    const array = this.officeForm.get('data').get(arrayName) as UntypedFormArray;
    if (arrayName === 'emails') {
      array.push(new FormControl(null, [Validators.email]));
    } else {
      array.push(new FormControl(null));
    }
  }

  addOfficeInGroup() {
    this.officesForGroup.push(this.fb.group({office: [null], type: [null]}));
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    this.currentPage = 1;
    switch (this.activeTab) {
      case 'tab-offices': {
        this.loadOffices();
        break;
      }
      case 'tab-users': {
        this.createUser();
        this.loadUsers();
        if (this.userGroups.length === 0) {
          this.loadUserGroups();
        }
        break;
      }
      case 'tab-groups': {
        this.createUserGroup();
        this.loadUserGroups();
        if (this.officesArray.length === 0) {
          this.loadOffices();
        }
        break;
      }
    }
  }
}
