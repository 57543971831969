export const languageList = [
    { value: 1, label: 'LAT' },
    { value: 2, label: 'RU' }
];

export enum typeOfCargo {
    BAGGAGE = 'Baggage',
    CARGO = 'Cargo',
    MAIL = 'Mail',
    FKT = 'FKT',
    EIC = 'EIC',
    CSU = 'CSU',
    EXP = 'EXP',
    SPB = 'SPB',
    ELI = 'ELI',
    PER = 'PER',
    RMD = 'RMD',
    ICE = 'ICE',
    PIL = 'PIL',
    PEF = 'PEF',
    BALLAST = 'Ballast'
};
