<div class="row btn-light-gray workflow-container pb-3">
  <div class="col-8">
    <div class="mb-2 p-3 aero-top-panel shadow">
      <div class="row align-items-center justify-content-between">
        <div class="col-4">
          <button type="button" class="btn mr-2 btn-blue" i18n="wfm|Workflow module@@wfm.controlPanel.button.action">Action</button>
        </div>
        <div class="col-8 pr-1 text-right d-flex align-items-center">
          <div class="dropdown mr-2">
            <button class="btn btn-xs btn-ico btn-light-gray dropdown-toggle" type="button"
              id="changeFlightDisplay" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="material-icons" *ngIf="viewParams.flights === 'grid'">view_module</i>
              <i class="material-icons" *ngIf="viewParams.flights === 'table'">view_headline</i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuExportAirports">
              <button class="dropdown-item" type="button" (click)="changeView('flights', 'grid')">
                <i class="material-icons">view_module</i>
                Grid view
              </button>
              <button class="dropdown-item" type="button" (click)="changeView('flights', 'table')">
                <i class="material-icons">view_headline</i>
                Table view
              </button>
            </div>
          </div>
          <div class="d-flex w-100">
            <div class="filter-block-wrap mr-2" (click)="viewParams.showFilter = !viewParams.showFilter">
              <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
              </div>
              <div class="d-flex align-items-center text-dark-gray">
                <i class="material-icons mx-1">arrow_drop_down</i>
              </div>
            </div>
          </div>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray mr-2"
                  ngbTooltip="Filter on/off"
                  i18n-ngbTooltip="cmn|Main buttons - filter on/off@@cmn.main.button.filterOnOff"
                  [ngClass]="{'btn-active' : viewParams.filterSwitch}">
            <i class="material-icons">filter_alt</i>
          </button>
        </div>
      </div>
    </div>
    <div class="flight-cards row mr-0" *ngIf="viewParams.flights === 'grid'">
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3"
           *ngFor="let flight of flights">
        <div class="card bg-light-gray finished p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>{{flight.flight}}</span>
              <div>{{flight.aircraftType}}&nbsp;/&nbsp;{{flight.tail}}</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i>&nbsp;{{flight.scheduleDatetime | date: 'HH:mm'}}</span>
              <div>{{flight.scheduleDatetime | date: 'dd.MM.yyyy'}}</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process bg-medium-gray">Обслуживание завершено</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-{{flight.route[0].iata}}</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>{{flight.stand}}</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19!</span>/23!</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card bg-light-gray finished p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process bg-medium-gray">Обслуживание завершено</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process text-white bg-red">ЗПР</div>
            <div class="process text-white bg-gray-blue-dark">ПП</div>
            <div class="process text-white bg-yellow">ПБГ</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process text-white bg-red">ЗПР</div>
            <div class="process text-white bg-gray-blue-dark">ПП</div>
            <div class="process text-white bg-yellow">ПБГ</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process text-white bg-red">ЗПР</div>
            <div class="process text-white bg-gray-blue-dark">ПП</div>
            <div class="process text-white bg-yellow">ПБГ</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card border-warning p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process text-white bg-red">ЗПР</div>
            <div class="process text-white bg-gray-blue-dark">ПП</div>
            <div class="process text-white bg-yellow">ПБГ</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process text-white bg-red">ЗПР</div>
            <div class="process text-white bg-gray-blue-dark">ПП</div>
            <div class="process text-white bg-yellow">ПБГ</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card border-danger p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process text-white bg-red">ЗПР</div>
            <div class="process text-white bg-gray-blue-dark">ПП</div>
            <div class="process text-white bg-yellow">ПБГ</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pr-0 mb-3">
        <div class="card p-2">
          <div class="title d-flex justify-content-between">
            <div>
              <span>SU 1400</span>
              <div>A-320 / VP-BPQ</div>
            </div>
            <div class="text-right">
              <span><i class="material-icons">flight_takeoff</i> 12:55</span>
              <div>12.12.2021</div>
            </div>
          </div>
          <div class="processes d-flex">
            <div class="process text-white bg-red">ЗПР</div>
            <div class="process text-white bg-gray-blue-dark">ПП</div>
            <div class="process text-white bg-yellow">ПБГ</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flight-bottom">
              <span>Направление</span>
              <div>SVX-YKS</div>
            </div>
            <div class="flight-bottom">
              <span>Парковка</span>
              <div>5</div>
            </div>
            <div class="flight-bottom">
              <span>Выполнено ТП</span>
              <div><span>19</span>/23</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flight-table card p-3" *ngIf="viewParams.flights === 'table'">
      <div class="row">
        <div class="col-6 pr-0">
          <div class="flights-table">
            <div class="flight-cards-titles d-flex justify-content-between">
              <div class="col1 font-weight-bold">Рейсы</div>
              <div class="col2">Маршрут</div>
              <div class="col3">Дата</div>
              <div class="col4">ВС / Борт</div>
              <div class="col5">Парковка</div>
            </div>
            <div class="flight-row border bg-light-color px-2 mb-2 bg-medium-gray"
                 *ngFor="let flight of flights">
              <div class="col1">{{flight.flight}}</div>
              <div class="col2">{{flight.route[0].iata}} - DME!</div>
              <div class="col3">{{flight.scheduleDatetime | date: 'dd.MM.yy HH:mm'}}</div>
              <div class="col4">{{flight.aircraftType}}&nbsp;/&nbsp;{{flight.tail}}</div>
              <div class="col5">{{flight.stand}}</div>
            </div>
            <div class="flight-row border px-2 mb-2">
              <div class="col1">SU 1400</div>
              <div class="col2">SVO - SVX</div>
              <div class="col3">12.12.21 12:55</div>
              <div class="col4">А-320 / VP-BPQ</div>
              <div class="col5">7</div>
            </div>
            <div class="flight-row border border-warning px-2 mb-2">
              <div class="col1">SU 1400</div>
              <div class="col2">SVO - SVX</div>
              <div class="col3">12.12.21 12:55</div>
              <div class="col4">А-320 / VP-BPQ</div>
              <div class="col5">7</div>
            </div>
            <div class="flight-row border border-danger px-2 mb-2">
              <div class="col1">SU 1400</div>
              <div class="col2">SVO - SVX</div>
              <div class="col3">12.12.21 12:55</div>
              <div class="col4">А-320 / VP-BPQ</div>
              <div class="col5">7</div>
            </div>
          </div>
        </div>
        <div class="col-6 pl-2">
          <div class="service-table">
            <div class="flight-cards-titles d-flex justify-content-between">
              <div class="col1 font-weight-bold text-center w-100">Рейсы</div>
            </div>
            <div class="flight-row border bg-light-color px-2 mb-2 bg-medium-gray"
                 *ngFor="let flight of flights">
              
              <span class="material-icons">flight_takeoff</span>
              <div class="processes d-flex">
                <div class="process">Завершено</div>
              </div>
              <div class="flight-bottom">
                <span>Выполнено</span>
                <div><span>39</span></div>
              </div>
            </div>
            <div class="flight-row border bg-light-color px-2 mb-2">
              <span class="material-icons">flight_takeoff</span>
              <div class="processes d-flex">
                <div class="process text-white bg-red">ЗПР</div>
                <div class="process text-white bg-gray-blue-dark">ПП</div>
                <div class="process text-white bg-yellow">ПБГ</div>
              </div>
              <div class="flight-bottom">
                <span>Выполнено</span>
                <div><span class="font-weight-bold">39</span>/23</div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 pr-0">
                <div class="flight-row border bg-light-color px-2 mb-2 bg-medium-gray">
                  <span class="material-icons">flight_land</span>
                  <div class="processes d-flex">
                    <div class="process">Завершено</div>
                  </div>
                  <div class="flight-bottom">
                    <span>Выполнено</span>
                    <div><span>39</span></div>
                  </div>
                </div>
              </div>
              <div class="col-6 pl-2">
                <div class="flight-row border bg-light-color px-2 mb-2">
                  <span class="material-icons">flight_takeoff</span>
                  <div class="processes d-flex">
                    <div class="process text-white bg-red">ЗПР</div>
                    <div class="process text-white bg-gray-blue-dark">ПП</div>
                    <div class="process text-white bg-yellow">ПБГ</div>
                  </div>
                  <div class="flight-bottom">
                    <span>Выполнено</span>
                    <div><span class="font-weight-bold">39</span>/23</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flight-row border bg-light-color px-2 mb-2">
              <span class="material-icons">flight_takeoff</span>
              <div class="processes d-flex">
                <div class="process text-white bg-red">ЗПР</div>
                <div class="process text-white bg-gray-blue-dark">ПП</div>
                <div class="process text-white bg-yellow">ПБГ</div>
              </div>
              <div class="flight-bottom">
                <span>Выполнено</span>
                <div><span class="font-weight-bold">39</span>/23</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4 bg-white border pt-3 right-panel">
    <div class="btn-group btn-group btn-group-toggle w-100" data-toggle="buttons">
      <label class="btn font-small pt-1 btn-xs"
             [ngClass]="{'active bg-blue text-white': viewParams.rightPanel==='general',
                              'bg-light-gray border': viewParams.rightPanel==='flight'}">
        <input type="radio"
               name="options"
               id="table"
               (click)="changeView('rightPanel', 'general')">
          <span i18n="wfm|Workflow module@@wfm.rightPanel.button.generalData">General data</span>
      </label>
      <label class="btn font-small pt-1 btn-xs"
             [ngClass]="{'active bg-blue text-white': viewParams.rightPanel==='flight',
                  'bg-light-gray border': viewParams.rightPanel==='general'}">
        <input type="radio"
               name="options"
               id="chart"
               (click)="changeView('rightPanel', 'flight')">
          <span i18n="wfm|Workflow module@@wfm.rightPanel.button.flightData">Flight data</span>
      </label>
    </div>
    <div class="statuses mt-3" *ngIf="viewParams.rightPanel==='general'">
      <p class="font-weight-bold h5 mb-3 flex-grow-1">Flight service statuses</p>
      <div class="row statuses">
        <div class="col-4 pr-0">
          <div class="card danger bg-light-gray mb-2 p-3">
            <div class="title">Problems</div>
            <div class="count">2</div>
          </div>
          <button class="btn mr-2 btn-light-gray w-100">Show</button>
        </div>
        <div class="col-4 pr-0">
          <div class="card success bg-light-gray mb-2 p-3">
            <div class="title">Problems</div>
            <div class="count">8</div>
          </div>
          <button class="btn mr-2 btn-light-gray w-100">Show</button>
        </div>
        <div class="col-4">
          <div class="card warning bg-light-gray mb-2 p-3">
            <div class="title">Problems</div>
            <div class="count">2</div>
          </div>
          <button class="btn mr-2 btn-light-gray w-100">Show</button>
        </div>
      </div>
    </div>
    <div class="mt-3" *ngIf="viewParams.rightPanel==='flight'">
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
          <a id="tab-flight-info" [ngClass]="{'active': viewParams.activeTab == 'tab-flight-info'}" class="nav-link active"
            (click)="changeTab($event)"
            i18n="wfm|Workflow module@@wfm.rightPanel.tab.flightInfo">
            Flight info
          </a>
        </li>
        <li class="nav-item">
          <a id="tab-operations-info" [ngClass]="{'active': viewParams.activeTab == 'tab-operations-info'}" class="nav-link active"
            (click)="changeTab($event)"
            i18n="wfm|Workflow module@@wfm.rightPanel.tab.operationsInfo">
            Operations info
          </a>
        </li>
      </ul>

      <div *ngIf="viewParams.activeTab == 'tab-flight-info'">
        <div class="card p-3 mb-3">
          <p class="font-weight-bold mb-3" i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.flight">Flight</p>
          <div class="row">

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightAirline"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.airline">
                  Airline
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightAirline"
                     [(ngModel)]="flight.airlineIata">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightFlight"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.flight">
                  Flight
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightFlight">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightDate"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.date">
                  Date
                </label>
              </div>
              <input type="date" class="form-control"
                  [ngModel]="flight.lastupdate | date: 'yyyy-MM-dd'"
                  (input)="flight.lastupdate = parseDate($event.target.value, flightTime.value)"
                  name="flightDate"
                  #flightDate="ngModel">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightFlight"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.airline">
                  Time
                </label>
              </div>
              <input type="time" class="form-control" [ngModel]="flight.lastupdate | date: 'HH:mm'"
                  (input)="flight.lastupdate = parseDate(flightDate.value, $event.target.value)"
                  name="flightTime"
                  #flightTime="ngModel">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightType"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.type">
                  Type
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightType"
                     [(ngModel)]="flight.aircraftTypeIata">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightTerminal"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.terminal">
                  Terminal
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightTerminal">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightFrom"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.from">
                      From
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightFrom">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightTo"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.to">
                      To
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightTo">
            </div>

          </div>
        </div>
        <div class="card p-3 mb-3">
          <p class="font-weight-bold mb-3" i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.loading">Loading</p>
          <div class="row">

            <div class="input-group input-group-sm col-md-4 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightPassengers"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.passengers">
                  Passengers
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightPassengers">
            </div>

            <div class="input-group input-group-sm col-md-4 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightVIP"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.vip">
                      VIP
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightVIP">
            </div>

            <div class="input-group input-group-sm col-md-4 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightTransfer"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.transfer">
                  Transfer
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightTransfer">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightBaggage"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.baggage">
                  Baggage, kg
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightBaggage">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightPost"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.post">
                  Post, kg
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightPost">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightFuel"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.fuel">
                  Fuel, kg
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightFuel">
            </div>

            <div class="input-group input-group-sm col-md-6 pr-0 mb-3">
              <div class="input-group-prepend">
                <label for="flightTotal"
                      class="input-group-text"
                      i18n="wfm|Workflow module@@wfm.rightPanel.tab.flight.flightInfo.total">
                  Total, kg
                </label>
              </div>
              <input type="number"
                     class="form-control"
                     id="flightTotal">
            </div>

          </div>
        </div>
      </div>
      <div *ngIf="viewParams.activeTab == 'tab-operations-info'" [ngClass]="{'showDetail': viewParams.showDetail, 'hideDetail': !viewParams.showDetail}">
        <button type="button"
                class="btn btn-sm btn-primary my-3"
                data-toggle="modal"
                data-target=".bd-example-modal-xl">Add+</button>
        <div class="operations-block text-center">
          <div class="card p-2 operation">
            <div class="title">Заправка ВС</div>
            <div class="status bg-hight-gray my-1">Завершено</div>
            <div class="times d-flex justify-content-between">
              <span>12:20</span>
              <span> - </span>
              <span>12:36</span>
            </div>
          </div>
          <div class="card p-2 operation">
            <div class="title">Заправка ВС</div>
            <div class="status bg-hight-gray my-1">Завершено</div>
            <div class="times d-flex justify-content-between">
              <span>12:20</span>
              <span> - </span>
              <span>12:36</span>
            </div>
          </div>
          <div class="card p-2 operation">
            <div class="title">Заправка ВС</div>
            <div class="status bg-hight-gray my-1">Завершено</div>
            <div class="times d-flex justify-content-between">
              <span>12:20</span>
              <span> - </span>
              <span>12:36</span>
            </div>
          </div>
          <div class="card p-2 operation">
            <div class="title">Заправка ВС</div>
            <div class="status">Завершено</div>
            <div class="times">
              <span>12:20</span>
              <span> - </span>
              <span>12:36</span>
            </div>
          </div>
          <div class="card p-2 operation">
            <div class="title">Заправка ВС</div>
            <div class="status">Завершено</div>
            <div class="times">
              <span>12:20</span>
              <span> - </span>
              <span>12:36</span>
            </div>
          </div>
          <div class="card p-2 operation">
            <div class="title">Заправка ВС</div>
            <div class="status">Завершено</div>
            <div class="times">
              <span>12:20</span>
              <span> - </span>
              <span>12:36</span>
            </div>
          </div>
        </div>
        <div class="flight-info border-top">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <p class="m-0" i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.detailHeader">
              Detail
            </p>
            <button class="flight-info-btn" (click)="viewParams.showDetail = !viewParams.showDetail">
              <i class="material-icons">arrow_right</i>
            </button>
          </div>
          <table class="w-100">
            <tr>
              <th i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.techprocess">Techprocess</th>
              <td>Загрузка бортового питания</td>
            </tr>
            <tr>
              <th i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.scheduledTime">Scheduled time</th>
              <td>начало 12:37 окончание 12:49</td>
            </tr>
            <tr>
              <th i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.actualTime">Actual time</th>
              <td></td>
            </tr>
            <tr>
              <th i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.resources">Resources</th>
              <td>Braem ASG 8280 автолифт</td>
            </tr>
            <tr>
              <th i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.operators">Operators</th>
              <td>Иванов А.А, Смердов А.В.</td>
            </tr>
            <tr>
              <th i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.status">Status</th>
              <td>Задержка</td>
            </tr>
            <tr>
              <th i18n="wfm|Workflow module@@wfm.rightPanel.operationsInfo.delayTime">Delay time</th>
              <td>3 минуты</td>
            </tr>
          </table>
        </div>
    </div>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-xl"
     data-backdrop="static"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myExtraLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xxl">
    <div class="modal-content bg-light-gray">
      <div class="modal-header">
        <h5 class="modal-title">
          <span i18n="wb|Main weight balance calculation@@wb.main.calculation.operation">Operation</span>
        </h5>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body bg-white">
        <div class="d-flex">
          <div class="card w-100 mx-2">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wf|Workflow@@wf.operationGroup">
                  Operation
                </p>
              <div class="d-flex flex-column">
                <div class="input-group input-group-sm my-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Name</span>
                  </div>
                  <input [(ngModel)]="currentOperation.name" type="text" class="form-control">
                </div>
                <div class="input-group input-group-sm my-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Operation</span>
                  </div>
                  <ng-select class="form-control form-control-sm custom-sm p-0 my-2"
                          [items]="referenceOperations"
                          bindLabel="name"
                          bindValue="id"
                          [(ngModel)]="currentOperation.operationId"
                          #airlineIata="ngModel"
                          required>
                  </ng-select>
                </div>
                <div class="input-group input-group-sm my-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Schedule date and time</span>
                  </div>
                  <input type="date" class="form-control">
                  <input type="time" class="form-control">
                </div>
                <div class="input-group input-group-sm my-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Actual date and time</span>
                  </div>
                  <input type="date" class="form-control">
                  <input type="time" class="form-control">
                </div>
                <div class="input-group input-group-sm my-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Status</span>
                  </div>
                  <input type="text" class="form-control">
                </div>
                <div class="input-group input-group-sm my-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Duration</span>
                  </div>
                  <input type="number" class="form-control" [(ngModel)]="currentOperation.duration">
                </div>
              </div>
            </div>
          </div>

          <div class="card w-100 mx-2">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wf|Workflow@@wf.resourceGroup">
                  Resource
                </p>
                <table class="table table-hover w-100">
                  <thead>
                    <tr>
                      <th i18n="adm|Modal Comunication module filters - table condition column field@@adm.comMod.modal.filters.table.condition.column.field">
                        Resource
                      </th>
                      <th class="text-center text-uppercase"
                          i18n="adm|Modal Comunication module filters - table condition column operator@@adm.comMod.modal.filters.table.condition.column.operator">
                        Type
                      </th>
                      <th i18n="adm|Modal Comunication module filters - table condition column value@@adm.comMod.modal.filters.table.condition.column.value">
                        Schedule
                      </th>
                      <th class="text-center" i18n="adm|Modal Comunication module filters - table condition column delete@@adm.comMod.modal.filters.table.condition.column.delete">
                        Actual
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let resource of currentOperation.resources; let i = index">
                      <td>
                        <ng-select class="form-control form-control-sm custom-sm p-0 my-2"
                          [items]="referenceResource"
                          bindLabel="name"
                          bindValue="id"
                          [(ngModel)]="currentOperation.resources[i].resourceId"
                          required>
                        </ng-select>
                      </td>
                      <td>
                        <input type="text" class="form-control" disabled [value]="currentOperation.resources[i].resourceType">
                      </td>
                      <td>
                        <label for="">Schedule</label>
                      </td>
                      <td>
                        <label for="">Actual</label>
                      </td>
                      <td>
                        <button type="button"
                                class="btn btn-outline-danger btn-sm"
                                (click)="resourceDelete(i)">
                          <i class="material-icons m-0">clear</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-12 mt-2">
                    <button type="button"
                            class="btn btn-sm btn-blue float-right"
                            (click)="addResource()">
                        <i class="material-icons m-0">add</i><span i18n="adm|Modal Comunication module filters - add condition@@adm.comMod.modal.filters.button.addCondition">Add condition</span>
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button btn-blue"
                class="btn btn-sm btn-blue"
                i18n="wf|Workflow@@wf.button.append"
                (click)="addOperation()">
          Append
        </button>
        <button type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
                i18n="wf|Workflow@@wf.button.close">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Сообщение об ошибке -->
<div *ngIf="error.errorMessage !== ''" class="ms-modal-message">
  <div class="modal-content w-50">
    <div class="modal-header" [ngClass]="{'bg-info': error.errorType == 'info',
                                        'bg-warning': error.errorType == 'warning',
                                        'bg-danger': error.errorType == 'error'}">
      <h5 *ngIf="error.errorType == 'info'"
          class="modal-title"
          i18n="sch|Modal error - header information@@sch.modal.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="error.errorType == 'warning'"
          class="modal-title"
          i18n="sch|Modal error - header warning@@sch.modal.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="error.errorType == 'error'"
          class="modal-title"
          i18n="sch|Modal error - header error@@sch.modal.errMes.header.err">
        Error
      </h5>
      <button type="button"
              class="close"
              (click)="clearErrorMess()"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="white-space: pre-line">
      {{error.errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-sm btn-secondary"
              (click)="clearErrorMess()"
              i18n="sch|Modal error - button no@@sch.modal.errMes.button.no">
        Edit
      </button>
    </div>
  </div>
</div>
