import { AbstractControl } from "@angular/forms";

export function parseDate(date: string, time?): Date | null {
  if (time && date) {
    return new Date(date + 'T' + time);
  } else if (!time && date) {
    return new Date(date);
  }
  return null;
}

export function parseDateReactiveForm(input: AbstractControl, date: string, time?: string){
  if (time) {
    input.setValue((new Date(date + 'T' + time)).toISOString());
  } else {
    input.setValue((new Date(date)).toISOString());
  }
}
