export class Office {
  constructor(obj?: Office) {
    if (obj) {
      Object.assign(this, obj);
    } else {
      this.id = '';
      this.name = '';
      this.airportId = null;
      this.airlineId = null;
      this.baseAp = null;
      this.lastUpdate = null;
      this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
      this.data = {
        phones: [],
        emails: [],
        addresses: [],
        urls: [],
        notes: [],
        workingHours: [],
      }
    }
  }
  id: string;
  name: string;
  airportId: number;
  airlineId: number;
  baseAp: number;
  lastUpdate: Date;
  dtRange: Array<Date>;
  data: {
    phones: Array<string>;
    emails: Array<string>;
    addresses: Array<string>;
    urls: Array<string>;
    notes: Array<string>;
    workingHours: Array<string>;
  }
}
