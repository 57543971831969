<div [formGroup]="statementForm">
  <div class="row border-bottom py-2 justify-content-between font-small mb-2">
    <div class="col-6 pl-0">
      <button class="btn btn-sm btn-ico btn-light-gray ml-3 mr-2" [routerLink]="['/lostitems/' + statement.id]">
        <i class="material-icons mr-2">arrow_back_ios</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.claims">Claims</span>
      </button>
      <button type="button" class="btn btn-xs btn-light-gray mr-2" (click)="sendToArchive()"
        *ngIf="!statementForm?.get('archived')?.value">
        <i class="material-icons">archive</i>
        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.sendToArchive">
          Send to archive
        </span>
      </button>
      <button type="button" class="btn btn-xs btn-light-gray mr-2" (click)="restoreFromArchive()"
        *ngIf="statementForm?.get('archived')?.value">
        <i class="material-icons">unarchive</i>
        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.restoreFromArchive">
          Restore from archive
        </span>
      </button>
      <button type="button" class="btn btn-xs btn-light-gray mr-2"
        (click)="downloadDocument(statementForm.get('id').value, 'lost_item')">
        <i class="material-icons">print</i>
        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.print">
          Print
        </span>
      </button>
      <a class="btn btn-sm btn-ico btn-light-gray mr-2">
        <i class="material-icons mr-2">history</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.history">History</span>
      </a>
    </div>
    <div class="col-6">
      <div class="d-flex align-items-center">
        <div class="input-group input-group-sm mr-2">
          <div class="input-group-prepend">
            <span class="input-group-text font-small"
              i18n="laf|Main - control panel@@laf.main.controlPanel.label.claim">
              Claim
            </span>
          </div>
          <input type="text" class="form-control form-control-sm" [value]="statementForm?.get('name')?.value" disabled>
        </div>
        <div class="input-group input-group-sm mr-2">
          <div class="input-group-prepend">
            <span class="input-group-text font-small"
              i18n="laf|Main - control panel@@laf.main.controlPanel.label.station">
              Station
            </span>
          </div>
          <input type="text" class="form-control form-control-sm" [value]="statementForm?.get('airport')?.value"
            disabled>
        </div>
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text font-small"
              i18n="laf|Main - control panel@@laf.main.controlPanel.label.agent">
              Agent
            </span>
          </div>
          <input type="text" class="form-control form-control-sm"
            [value]="statementForm?.get('found')?.get('agent')?.value" disabled>
        </div>
        <button type="button" class="btn btn-xs btn-blue ml-2" (click)="saveLostItem()"
          *ngIf="statementForm?.get('id')?.value">
          <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.save">
            Save
          </span>
        </button>
        <button type="button" class="btn btn-xs btn-blue ml-2" (click)="addLostItem()"
          *ngIf="!statementForm?.get('id')?.value" i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.append">
          Append
        </button>
      </div>
    </div>
  </div>
  <div class="position-relative h-100">
    <div class="loading" *ngIf="loading; else: statementBlock">
      <div class="cssload-spin-box"></div>
    </div>
    <ng-template #statementBlock>
      <div class="row">
        <div class="col-4 pr-0">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="laf|Statement tabs@@laf.statementLost.description.descriptionOfTheFoundItem">
                Description of the found item</p>
              <div class="row mb-3">
                <div class="col-md-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.station">Station</span>
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm bg-white select-long" [items]="references.airports.data"
                      bindLabel="iata" bindValue="id" appendTo="body" [virtualScroll]="true"
                      [loading]="references.airports.loading"
                      (scroll)="ngSelectFunctions.onScroll($event, references.airports, 'airports')"
                      (scrollToEnd)="ngSelectFunctions.onScrollToEnd(references.airports, 'airports')"
                      formControlName="airportId" [searchFn]="customSearchAirport" [selectOnTab]="true">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.date">Date</span>
                      </span>
                    </div>
                    <input type="date" class="form-control custom-sm"
                      (change)="parseDateReactiveForm(statementForm.get('dt'), $event.target.value)"
                      [value]="statementForm.get('dt').value | date: 'yyyy-MM-dd'">
                  </div>
                </div>
                <div class="col-md-4 pr-0">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.weight">Weight</span>
                      </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" name="weight" formControlName="weight">
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.status">Status</span>
                      </span>
                    </div>
                    <ng-select [items]="baggage_statuses" class="w-100 custom-sm select-long" bindLabel="nameLocal"
                      bindValue="id" name="status" appendTo="body" [selectOnTab]="true"
                      [loading]="references.baggage_statuses.loading" [clearable]="false" formControlName="statusId">
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-group flex-nowrap mb-3" formGroupName="found">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.name">Name</span>
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="name" formControlName="itemName">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.description">Description</span>
                      </span>
                    </div>
                    <textarea class="form-control form-control-sm" rows="2"
                      formControlName="notes">{{ statementForm.get('notes).value }}</textarea>
                  </div>
                </div>
                <div class="col-md-12 d-flex">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.content">Content</span>
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" disabled [value]="getContent()">
                  </div>
                  <button class="btn btn-h-29 btn-ico btn-outline-secondary" type="button">
                    <i class="material-icons text-dark-gray" ngbTooltip="Edit" data-toggle="modal"
                      [attr.data-target]="'#baggage-contend-modal'"
                      i18n-ngbTooltip="laf|Statement tabs@@laf.statementLost.description.button.edit">edit</i>
                  </button>
                </div>
              </div>
              <hr>
              <ng-container *ngIf="statementForm.get('id').value !== null; else LoadPhotoDisable">
                <p class="text-gray-blue-dark m-0">
                  <strong i18n="laf|Statement tabs@@laf.statementLost.description.photo">Photo</strong>&nbsp;
                  <span i18n="laf|Statement tabs@@laf.statementLost.description.photoNote">Click on photo to view or
                    edit</span>
                </p>
                
                <div class="row mb-3">
                  <div class="col-4" *ngFor="let file of files; let i = index">
                    <div class="photo-item" (click)="loadPhoto(file.id, preview)">
                      <img [src]="showPhoto(file)" [alt]="file.name">
                      <button class="btn btn-ico btn-light-gray btn-xxs" (click)="deletePhoto(file.id, $event)">
                        <span class="material-icons text-red">delete</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-4" *ngIf="files.length < 6">
                    <label class="photo-item">
                      <span class="material-icons">add</span>
                      <input type="file" class="form-control-file" #fileInput (change)="uploadPhoto($event.target)">
                    </label>
                  </div>
                </div>
                <div class="progress" *ngIf="loadingPhotoProcess">
                  <div class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        [attr.aria-valuenow]="loadingPhotoProcess"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        [style]="'width: ' + loadingPhotoProcess + '%'"></div>
                </div>
              </ng-container>
              <ng-template #LoadPhotoDisable>
                <p class="text-gray-blue-dark m-0">
                  <strong i18n="laf|Statement tabs@@laf.statementLost.description.photo">Photo</strong>&nbsp;
                  <span i18n="laf|Statement tabs@@laf.statementLost.description.photo.saveClaim">Save claim to attach photo</span>
                </p>
              </ng-template>
              <hr>
              <p class="text-gray-blue-dark font-weight-bold"
                i18n="laf|Statement tabs@@laf.statement.tabs.baggage.openingInspection">
                Opening inspection
              </p>
              <div class="input-group flex-nowrap" formGroupName="found">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0">
                    <span i18n="laf|Statement tabs@@laf.statementLost.description.witness">Witness</span>
                  </span>
                </div>
                <input type="text" class="w-100 form-control custom-sm" name="name" formControlName="witness">
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 pr-0">
          <div class="card mb-3" formGroupName="found">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.detectionPlace">
                Detection place</p>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.name">Name</span>
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="detectionPlaceName"
                      formControlName="name">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.airline">Airline</span>
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm bg-white select-long" [items]="references.airlines.data"
                      bindLabel="iata" bindValue="id" appendTo="body" [virtualScroll]="true"
                      [loading]="references.airlines.loading"
                      (scroll)="ngSelectFunctions.onScroll($event, references.airlines, 'airlines')"
                      (scrollToEnd)="ngSelectFunctions.onScrollToEnd(references.airlines, 'airlines')"
                      [searchFn]="customSearchAirport" [selectOnTab]="true" formControlName="airlineId">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.flight">Flight</span>
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="detectionPlaceFlight"
                      formControlName="flight">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.date">Date</span>
                      </span>
                    </div>
                    <input type="date" class="form-control custom-sm"
                      (change)="parseDateReactiveForm(statementForm.get('found').get('dt'), $event.target.value)"
                      [value]="statementForm.get('found').get('dt').value | date: 'yyyy-MM-dd'">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" formGroupName="storage">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="laf|Statement tabs@@laf.statementLost.storage.storage">
                Storage</p>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.storage.name">Name</span>
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm bg-white select-long" [items]="ADMIN_DATA.storages"
                      bindLabel="name" bindValue="id" appendTo="body" name="storageName" appendTo="body"
                      formControlName="id">
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6 pr-0">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.storage.dateStart">Start</span>
                      </span>
                    </div>
                    <input type="date" class="form-control custom-sm"
                      (change)="parseDateReactiveForm(statementForm.get('storage').get('dtStart'), $event.target.value)"
                      [value]="statementForm.get('storage').get('dtStart').value | date: 'yyyy-MM-dd'">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.storage.dateEnd">End</span>
                      </span>
                    </div>
                    <input type="date" class="form-control custom-sm"
                      (change)="parseDateReactiveForm(statementForm.get('storage').get('dtFinish'), $event.target.value)"
                      [value]="statementForm.get('storage').get('dtFinish').value | date: 'yyyy-MM-dd'">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card mb-3" formGroupName="finish">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3"
                i18n="laf|Statement tabs@@laf.statementLost.realization.delivery">
                Delivery</p>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.realization.issuedToOwner">Issued to
                          owner</span>
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="realizationIssued"
                      formControlName="owner">
                  </div>
                </div>
              </div>
              <p class="mb-2 h6 border-top py-2 font-weight-bold text-gray-blue-dark"
                i18n="laf|Statement tabs@@laf.statementLost.realization.sent">Sent</p>
              <div class="row mb-3">
                <div class="col-md-12 mb-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.airline">Airline</span>
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm bg-white select-long" [items]="references.airlines.data"
                      bindLabel="iata" bindValue="id" appendTo="body" [virtualScroll]="true"
                      [loading]="references.airlines.loading"
                      (scroll)="ngSelectFunctions.onScroll($event, references.airlines, 'airlines')"
                      (scrollToEnd)="ngSelectFunctions.onScrollToEnd(references.airlines, 'airlines')"
                      [searchFn]="customSearchAirport" [selectOnTab]="true" formControlName="airlineId">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.description.deliveredTo">Delivered to</span>
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm bg-white select-long" [items]="references.airports.data"
                      bindLabel="iata" bindValue="id" name="deliveredTo" appendTo="body" [virtualScroll]="true"
                      [loading]="references.airports.loading"
                      (scroll)="ngSelectFunctions.onScroll($event, references.airports, 'airports')"
                      (scrollToEnd)="ngSelectFunctions.onScrollToEnd(references.airports, 'airports')"
                      [searchFn]="customSearchAirport" [selectOnTab]="true" formControlName="airportId">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.flight">Flight</span>
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="detectionPlaceFlight"
                      formControlName="flight">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.date">Date</span>
                      </span>
                    </div>
                    <input type="date" class="form-control custom-sm"
                      (change)="parseDateReactiveForm(statementForm.get('finish').get('dtSent'), $event.target.value)"
                      [value]="statementForm.get('finish').get('dtSent').value | date: 'yyyy-MM-dd'">
                  </div>
                </div>
              </div>
              <p class="mb-2 h6 border-top py-2 font-weight-bold text-gray-blue-dark"
                i18n="laf|Statement tabs@@laf.statementLost.realization.act">Act</p>
              <div class="row">
                <div class="col-md-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.number">Number</span>
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="detectionPlaceNumber"
                      formControlName="act">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statementLost.detectionPlace.date">Date</span>
                      </span>
                    </div>
                    <input type="date" class="form-control custom-sm"
                      (change)="parseDateReactiveForm(statementForm.get('finish').get('dtAct'), $event.target.value)"
                      [value]="statementForm.get('finish').get('dtAct').value | date: 'yyyy-MM-dd'">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Модальное окно редактирования содержимого багажа -->
      <div class="modal fade" data-backdrop="static" id="baggage-contend-modal" tabindex="-1"
        aria-labelledby="modalContentBaggage" aria-hidden="true">
        <content-modal [references]="references" [internalElements]="internalElements" [parentForm]="statementForm">
        </content-modal>
      </div>
    </ng-template>
  </div>
  <ng-template #preview let-modal>
    <div class="modal-header">
      <button type="button" class="close" (click)="modal.dismiss(); imageBlobUrl = null" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      <div class="d-flex justify-content-center p-3" *ngIf="!imageBlobUrl">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <img [src]="imageBlobUrl">
    </div>
  </ng-template>
  <app-modal-message></app-modal-message>
</div>

<ngb-alert #selfClosingAlert *ngIf="messageAlert.text" [type]="messageAlert.type" [dismissible]="false"
    class="alert-fixed-bottom" (closed)="messageAlert.text = ''; messageAlert.type = ''">
    {{ messageAlert.text }}
</ngb-alert>