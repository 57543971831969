import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { AdministrationRestApiService } from '../services/administration-rest-api.service';
import {
  InstallationWorkplace,
  DictReasonWanted,
  DictReasonSolution,
  DictWorkplace,
  DictStatusPassenger,
  DictGeo, DictTerminal
} from '../types/boardingcontrol';
import { GlobalI18n } from '@settings/global-i18n';

@Component({
  selector: 'app-am-boarding-control',
  templateUrl: './am-boarding-control.component.html',
  styleUrls: ['./am-boarding-control.component.less']
})

export class AmBoardingControlComponent implements OnInit{
  installationWorkplaces: Array<InstallationWorkplace> = [];
  installationWorkplace: InstallationWorkplace = new InstallationWorkplace();

  dictWorkplaces: Array<DictWorkplace> = [];
  dictWorkplace: DictWorkplace = new DictWorkplace();

  dictReasonSolutions: Array<DictReasonSolution> = [];
  dictReasonSolution: DictReasonSolution = new DictReasonSolution();

  dictReasonWanteds: Array<DictReasonWanted> = [];
  dictReasonWanted: DictReasonWanted = new DictReasonWanted();

  dictStatusPassengers: Array<DictStatusPassenger> = [];
  dictStatusPassenger: DictStatusPassenger = new DictStatusPassenger();

  dictTerminals: Array<DictTerminal> = [];
  dictTerminal: DictTerminal = new DictTerminal();

  dictGeos: Array<DictGeo> = [];
  dictGeo: DictGeo = new DictGeo();

  newRecord: boolean;

  @ViewChild('InstallationPlaceForm') InstallationPlaceForm: any;
  @ViewChild('InstallationPlaceModalForm') InstallationPlaceModalForm: any;

  @ViewChild('WorkplacePlaceForm') WorkplacePlaceForm: any;
  @ViewChild('WorkplaceModalForm') WorkplaceModalForm: any;

  @ViewChild('SolutionPlaceForm') SolutionPlaceForm: any;
  @ViewChild('SolutionModalForm') SolutionModalForm: any;

  @ViewChild('ReasonWantedForm') ReasonWantedForm: any;
  @ViewChild('ReasonWantedModalForm') ReasonWantedModalForm: any;

  @ViewChild('StatusPassForm') StatusPassForm: any;
  @ViewChild('StatusPassModalForm') StatusPassModalForm: any;

  constructor(
    private globalSettings: GlobalSettings,
    public restApi: AdministrationRestApiService,
    private globalI18n: GlobalI18n
  ) {
    globalSettings.loadDefaultConfig();
  }

  ngOnInit() {
    this.newRecord = false;

    this.loadInstallationPlaces();
    this.loadWorkplaces();
    this.loadSolutions();
    this.loadReasonWanted();
    this.loadStatusPassenger();

    this.loadTerminals();
    this.loadGeo();
  }

  loadInstallationPlaces() {
    return this.restApi.getInstallationPlaces(this.globalSettings.language)
    .subscribe((data: InstallationWorkplace[]) => {
      Object.assign(this.installationWorkplaces, data);
      console.log('loadInstallationPlaces');
      console.log(JSON.stringify(this.installationWorkplaces));
    });
  }

  loadWorkplaces() {
    return this.restApi.getWorkplaces().subscribe((data: DictWorkplace[]) => {
      Object.assign(this.dictWorkplaces, data);
    });
  }

  loadSolutions() {
    return this.restApi.getSolutions().subscribe((data: DictReasonSolution[]) => {
      Object.assign(this.dictReasonSolutions, data);
    });
  }

  loadReasonWanted() {
    return this.restApi.getReasonWanteds().subscribe((data: DictReasonWanted[]) => {
      Object.assign(this.dictReasonWanteds, data);
    });
  }

  loadStatusPassenger() {
    return this.restApi.getStatusPassengers().subscribe((data: DictStatusPassenger[]) => {
      Object.assign(this.dictStatusPassengers, data);
    });
  }

  loadTerminals() {
    return this.restApi.getTerminals().subscribe((data: DictTerminal[]) => {
      Object.assign(this.dictTerminals, data);
    });
  }

  loadGeo() {
    return this.restApi.getGeos().subscribe((data: DictGeo[]) => {
      Object.assign(this.dictGeos, data);
    });
  }

  delPlace(id: number) {
    this.restApi.deletePlace(id).subscribe(data => {
        for (let i = 0; i < this.installationWorkplaces.length; i++) {
          if (this.installationWorkplaces[i].id == this.installationWorkplace.id) {
            this.installationWorkplaces.splice(i, 1);
            break;
          }
        }
      },
      err => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  editPlace(id: number){
    this.newRecord = false;
    return this.restApi.getPlace(id).subscribe((data: InstallationWorkplace) => {
      console.log('installationWorkplace = ' + JSON.stringify(data));
      Object.assign(this.installationWorkplace, data);
    });
  }

  savePlace() {
    this.InstallationPlaceForm.form.markAllAsTouched();
    if (this.InstallationPlaceForm.valid) {
      this.restApi.setPlace(this.installationWorkplace).subscribe(data => {
          for (let i = 0; i < this.installationWorkplaces.length; i++) {
            if (this.installationWorkplaces[i].id == this.installationWorkplace.id) {
              this.installationWorkplaces[i].id = this.installationWorkplace.id;
              this.installationWorkplaces[i].idWorkPlace = this.installationWorkplace.idWorkPlace;
              this.installationWorkplaces[i].idTerminal = this.installationWorkplace.idTerminal;
              this.installationWorkplaces[i].idGeo = this.installationWorkplace.idGeo;
              this.installationWorkplaces[i].workplaceName = this.getWorkplaceName(this.installationWorkplace.idWorkPlace);
              this.installationWorkplaces[i].terminalName = this.getTerminalName(this.installationWorkplace.idTerminal);
              this.installationWorkplaces[i].geoName = this.getGeoName(this.installationWorkplace.idGeo);
              this.installationWorkplaces[i].name = this.installationWorkplace.name;
              this.installationWorkplaces[i].gate = this.installationWorkplace.gate;
              this.installationWorkplaces[i].timeEnter = this.installationWorkplace.timeEnter;
              this.installationWorkplaces[i].timeInterval = this.installationWorkplace.timeInterval;
              this.installationWorkplaces[i].passportScanner = this.installationWorkplace.passportScanner;
              this.installationWorkplaces[i].showTabScanner = this.installationWorkplace.showTabScanner;
              this.installationWorkplaces[i].showTabWanted = this.installationWorkplace.showTabWanted;
              this.installationWorkplaces[i].showClearArea = this.installationWorkplace.showClearArea;
              this.installationWorkplaces[i].showReport = this.installationWorkplace.showReport;
              this.installationWorkplaces[i].guid = this.installationWorkplace.guid;
              this.installationWorkplaces[i].showSecondNumber = this.installationWorkplace.showSecondNumber;
              this.installationWorkplaces[i].reReadSec = this.installationWorkplace.reReadSec;
              this.installationWorkplaces[i].aspectRatio4x3 = this.installationWorkplace.aspectRatio4x3;
              this.installationWorkplaces[i].dateStartCurrentFlightsHours = this.installationWorkplace.dateStartCurrentFlightsHours;
              this.installationWorkplaces[i].dateEndCurrentFlightsHours = this.installationWorkplace.dateEndCurrentFlightsHours;
              this.installationWorkplaces[i].reasonBaggageMobile = this.installationWorkplace.reasonBaggageMobile;
              this.installationWorkplaces[i].autoPass = this.installationWorkplace.autoPass;
            }
          }
          this.InstallationPlaceModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  getTerminalName(id: number): string {
    let name = '';
    for (let i = 0; i < this.dictTerminals.length; i++) {
      if (this.dictTerminals[i].id == id) {
        name = this.dictTerminals[i].name;
        break;
      }
    }
    return name;
  }

  getWorkplaceName(id: number): string {
    let name = '';
    for (let i = 0; i < this.dictWorkplaces.length; i++) {
      if (this.dictWorkplaces[i].id == id) {
        name = this.dictWorkplaces[i].name;
        break;
      }
    }
    return name;
  }

  getGeoName(id: number): string {
    let name = '';
    for (let i = 0; i < this.dictGeos.length; i++) {
      if (this.dictGeos[i].id == id) {
        name = this.dictGeos[i].name;
        break;
      }
    }
    return name;
  }

  /*workplace*/
  /*newWorkplace() {
    this.newRecord = true;
    this.dictWorkplace.id = 0;
    this.dictWorkplace.name = "";
  }

  addWorkplace(){
    this.WorkplacePlaceForm.form.markAllAsTouched();
    if (this.WorkplacePlaceForm.valid) {
      this.restApi.insertWorkplace(this.dictWorkplace).subscribe(data => {
          let obj = JSON.parse(JSON.stringify(data));

          const work = new DictWorkplace();
          work.id = obj.body.id;
          work.name = this.dictWorkplace.name;
          this.dictWorkplaces.push(work);

          this.dictWorkplace.id = 0;
          this.dictWorkplace.name = "";
          this.WorkplaceModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  delWorkplace(id: number) {
    this.restApi.deleteWorkplace(id).subscribe(data => {
        for (let i = 0; i < this.dictWorkplaces.length; i++) {
          if (this.dictWorkplaces[i].id == this.dictWorkplace.id) {
            this.dictWorkplaces.splice(i,1);
            break;
          }
        }
      },
      err => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  editWorkplace(id: number){
    this.newRecord = false;
    return this.restApi.getWorkplace(id).subscribe((data: DictWorkplace) => {
      Object.assign(this.dictWorkplace, data);
    });
  }

  saveWorkplace() {
    this.WorkplacePlaceForm.form.markAllAsTouched();
    if (this.WorkplacePlaceForm.valid) {
      this.restApi.setWorkplace(this.dictWorkplace).subscribe(data => {
          for (let i = 0; i < this.dictWorkplaces.length; i++) {
            if (this.dictWorkplaces[i].id == this.dictWorkplace.id) {
              this.dictWorkplaces[i].name = this.dictWorkplace.name;
            }
          }
          this.WorkplaceModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }*/

  /*solution*/
  newSolution() {
    this.newRecord = true;
    this.dictReasonSolution.id = 0;
    this.dictReasonSolution.idWorkplace = -1;
    this.dictReasonSolution.name = "";
    this.dictReasonSolution.passStop = 0;
  }

  addSolution(){
    this.SolutionPlaceForm.form.markAllAsTouched();
    if (this.SolutionPlaceForm.valid) {
      this.restApi.insertSolution(this.dictReasonSolution).subscribe(data => {
          let obj = JSON.parse(JSON.stringify(data));
          const sol = new DictReasonSolution();
          sol.id = obj.body.id;
          sol.idWorkplace = this.dictReasonSolution.idWorkplace;
          sol.name = this.dictReasonSolution.name;
          sol.passStop = this.dictReasonSolution.passStop;
          this.dictReasonSolutions.push(sol);

          this.dictReasonSolution.id = 0;
          this.dictReasonSolution.idWorkplace = -1;
          this.dictReasonSolution.name = '';
          this.dictReasonSolution.passStop = 0;
          this.SolutionModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  delSolution(id: number) {
    this.restApi.deleteSolution(id).subscribe(data => {
        for (let i = 0; i < this.dictReasonSolutions.length; i++) {
          if (this.dictReasonSolutions[i].id == this.dictReasonSolution.id) {
            this.dictReasonSolutions.splice(i, 1);
            break;
          }
        }
      },
      err => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  editSolution(id: number){
    this.newRecord = false;
    return this.restApi.getSolution(id).subscribe((data: DictReasonSolution) => {
      Object.assign(this.dictReasonSolution, data);
    });
  }

  saveSolution() {
    this.SolutionPlaceForm.form.markAllAsTouched();
    if (this.SolutionPlaceForm.valid) {
      this.restApi.setSolution(this.dictReasonSolution).subscribe(data => {
          for (let i = 0; i < this.dictReasonSolutions.length; i++) {
            if (this.dictReasonSolutions[i].id == this.dictReasonSolution.id) {
              this.dictReasonSolutions[i].idWorkplace = this.dictReasonSolution.idWorkplace;
              this.dictReasonSolutions[i].name = this.dictReasonSolution.name;
              this.dictReasonSolutions[i].passStop = this.dictReasonSolution.passStop;
              this.dictReasonSolutions[i].workPlaceName = this.dictReasonSolution.workPlaceName;
            }
          }
          this.SolutionModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }
  /*wanted*/
  newReasonWanted() {
    this.newRecord = true;
    this.dictReasonWanted.id = 0;
    this.dictReasonWanted.name = '';
  }

  addReasonWanted(){
    this.ReasonWantedForm.form.markAllAsTouched();
    if (this.ReasonWantedForm.valid) {
      this.restApi.insertReasonWanted(this.dictReasonWanted).subscribe(data => {
          let obj = JSON.parse(JSON.stringify(data));

          const rw = new DictReasonWanted();
          rw.id = obj.body.id;
          rw.name = this.dictReasonWanted.name;
          rw.baggage = this.dictReasonWanted.baggage;

          this.dictReasonWanteds.push(rw);

          this.dictReasonWanted.id = 0;
          this.dictReasonWanted.name = '';
          this.dictReasonWanted.baggage = false;

          this.ReasonWantedModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  delReasonWanted(id: number) {
    this.restApi.deleteReasonWanted(id).subscribe(data => {
        for (let i = 0; i < this.dictReasonWanteds.length; i++) {
          if (this.dictReasonWanteds[i].id == this.dictReasonWanted.id) {
            this.dictReasonWanteds.splice(i, 1);
            break;
          }
        }
      },
      err => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  editReasonWanted(id: number){
    this.newRecord = false;
    return this.restApi.getReasonWanted(id).subscribe((data: DictReasonWanted) => {
      Object.assign(this.dictReasonWanted, data);
    });
  }

  saveReasonWanted() {
    this.ReasonWantedForm.form.markAllAsTouched();
    if (this.ReasonWantedForm.valid) {
      this.restApi.setReasonWanted(this.dictReasonWanted).subscribe(data => {
          for (let i = 0; i < this.dictReasonWanteds.length; i++) {
            if (this.dictReasonWanteds[i].id == this.dictReasonWanted.id) {
              this.dictReasonWanteds[i].name = this.dictReasonWanted.name;
              this.dictReasonWanteds[i].baggage = this.dictReasonWanted.baggage;
            }
          }
          this.ReasonWantedModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  get denyStatus(): string {
    return this.globalI18n.getMessage(ModuleName.BoardingControl, 'deny');
  }

  get allowStatus(): string {
    return this.globalI18n.getMessage(ModuleName.BoardingControl, 'allow');
  }

  /*status passenger*/
  /*newStatusPassenger() {
    this.newRecord = true;
    this.dictStatusPassenger.id = 0;
    this.dictStatusPassenger.name = "";
  }

  addStatusPassenger(){
    this.StatusPassForm.form.markAllAsTouched();
    if (this.StatusPassForm.valid) {
      this.restApi.insertStatusPassenger(this.dictStatusPassenger).subscribe(data => {
          let obj = JSON.parse(JSON.stringify(data));

          const sp = new DictStatusPassenger();
          sp.id = obj.body.id;
          sp.name = this.dictStatusPassenger.name
          this.dictStatusPassengers.push(sp);

          this.dictStatusPassenger.id = 0;
          this.dictStatusPassenger.name = "";
          this.StatusPassModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }

  delStatusPassenger(id: number) {
    this.restApi.deleteStatusPassenger(id).subscribe(data => {
        for (let i = 0; i < this.dictStatusPassengers.length; i++) {
          if (this.dictStatusPassengers[i].id == this.dictStatusPassenger.id) {
            this.dictStatusPassengers.splice(i,1);
            break;
          }
        }
      },
      err => {
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      }
    );
  }

  editStatusPassenger(id: number){
    this.newRecord = false;
    return this.restApi.getStatusPassenger(id).subscribe((data: DictStatusPassenger) => {
      Object.assign(this.dictStatusPassenger, data);
    });
  }

  saveStatusPassenger() {
    this.StatusPassForm.form.markAllAsTouched();
    if (this.StatusPassForm.valid) {
      this.restApi.setStatusPassenger(this.dictStatusPassenger).subscribe(data => {
          for (let i = 0; i < this.dictStatusPassengers.length; i++) {
            if (this.dictStatusPassengers[i].id == this.dictStatusPassenger.id) {
              this.dictStatusPassengers[i].name = this.dictStatusPassenger.name;
            }
          }
          this.StatusPassModalForm.nativeElement.click();
        },
        err => {
          console.log('Error: ' + err.message + '\ndetail:' + err.detail);
        }
      );
    }
  }*/

}
