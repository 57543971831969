export class Message {
  constructor(obj?: Message) {
    if (obj) {
      Object.assign(this, obj);
    } else {
      this.uid = '';
      this.dtInsert = new Date();
      this.recipients = '';
      this.message = '';
      this.sent = false;
    }
  }
  uid: string;
  dtInsert: Date;
  recipients: string;
  message: string;
  sent: boolean;
}

export class MessageForSent {
  constructor(message?: string) {
    if (message) {
      this.message = message;
    }
    this.message = '';
    this.recipients = [];
  }
  recipients: Array<Recipient>;
  message: string;
  dtInsert?: Date;
}

export class Recipient {
  constructor(recipient: string, channel: number) {
    if (recipient && channel) {
      this.address = recipient;
      this.channel = channel;
    }
  }
  channel: number;
  address: string;
}
