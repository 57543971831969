import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Slot } from '../slot-coordination-module-data/slot';
import { retry, catchError } from 'rxjs/operators';
import {
  ReferanceAirline,
  ReferanceAirport,
  ReferanceMovement,
  ReferanceAircraft,
  ReferanceGeoTypes,
  ReferanceSeasons,
  ReferanceScheduleTypes,
} from '../slot-coordination-module-data/referance';
import { GlobalSettings } from '@settings/global-settings';

@Injectable({
  providedIn: 'root'
})
export class SlotCoordinationModuleRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getSlots(season: number, schedule: number): Observable<Slot[]> {
    return this.http.get<Slot[]>(this.globalSettings.apiSlotCoordinationURL + '/flights'
                                                                    + '?season=' + season
                                                                    + '&schedule=' + schedule)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSlot(id: number): Observable<Slot> {
    return this.http.get<Slot>(this.globalSettings.apiSlotCoordinationURL +
                               '/flights/' +
                               id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addSlot(slot: any): Observable<Slot> {
    return this.http.post<Slot>(this.globalSettings.apiSlotCoordinationURL +
                                            '/flights',
                                            JSON.stringify(slot),
                                            this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateSlot(id: number, slot: any): Observable<Slot> {
   return this.http.put<Slot>(this.globalSettings.apiSlotCoordinationURL +
                              '/flights/' +
                              id,
                              JSON.stringify(slot),
                              this.httpOptions)
   .pipe(
     retry(1),
     catchError(this.handleError)
   );
  }

  deleteSlot(id: string) {
    return this.http.delete<Slot>(this.globalSettings.apiSlotCoordinationURL +
                                    '/flights/' +
                                    id,
                                  this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Movements
  getMovements(): Promise<ReferanceMovement[]> {
    return this.http.get<ReferanceMovement[]>(this.globalSettings.apiSlotCoordinationURL +
                                              '/movement_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Airports
  getAirports(): Promise<ReferanceAirport[]> {
    return this.http.get<ReferanceAirport[]>(this.globalSettings.apiSlotCoordinationURL + '/airports')
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  // Aircraft Types
  getAircraftTypes(): Promise<ReferanceAircraft[]> {
    return this.http.get<ReferanceAircraft[]>(this.globalSettings.apiSlotCoordinationURL +
                                                '/aircraft_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Airlines
  getAirlines(): Promise<ReferanceAirline[]> {
    return this.http.get<ReferanceAirline[]>(this.globalSettings.apiSlotCoordinationURL +
                                           '/airlines')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Geo Types
  getGeoTypes(): Promise<ReferanceGeoTypes[]> {
    return this.http.get<ReferanceGeoTypes[]>(this.globalSettings.apiSlotCoordinationURL +
                                           '/geo_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Seasons
  getSeasons(): Promise<ReferanceSeasons[]> {
    return this.http.get<ReferanceSeasons[]>(this.globalSettings.apiSlotCoordinationURL +
                                           '/seasons')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // ScheduleTypes
  getScheduleTypes(): Promise<ReferanceScheduleTypes[]> {
    return this.http.get<ReferanceScheduleTypes[]>(this.globalSettings.apiSlotCoordinationURL +
                                           '/schedule_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
